import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './MyNetworking.less';
import axios from '../../../network/axios/$axios';
import { getBriefcasesList } from '../../../actions/BriefcaseAction';
import UsersList from './UsersList';
import { message } from 'antd';
import i18next from 'i18next';
import HeaderTitle from '../../../components/HeaderTitle';

const MyNetworking = (props) => {
  const { briefcasesData, userInfo, getBriefcasesList } = props;

  // 重新请求briefcases列表
  const getBriefcaseslist = () => {
    getBriefcasesList(userInfo.id);
  };
  // 添加或者移除书签
  const addOrDeleteToBriefcases = (data, arr, value) => {
    if (value === 0) {
      // 删除Briefcase
      axios('deleteBriefcases', { path: arr[0]._id })
        .then((response) => {
          console.log('/deleteBriefcases=', response);
          getBriefcaseslist();
          message.success(i18next.t('translations.bookmark-deleted-success'));
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      // 新增Briefcase
      axios('addBriefcases', { data: {
        "model": "Networking/Networking",
        "refId": data._id }})
        .then((response) => {
          console.log('/addBriefcases=', response);
          getBriefcaseslist();
          message.success(i18next.t('translations.bookmark-added-success'));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const visitors = briefcasesData.network;
  return (
    <div className="my-networking-page">
      <HeaderTitle title={i18next.t('translations.my-networking')} />
      <UsersList visitors={visitors || []} onChange={addOrDeleteToBriefcases} {...props} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.loginUserInfo.userInfo,
  briefcasesData: state.briefcases.briefcasesData
});

const mapDispatchToProps = (dispatch) => ({
  getBriefcasesList: (userId) => {
    dispatch(getBriefcasesList(userId));
  }
});

const enhance = compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps));

export default enhance(MyNetworking);
