import React, { useState } from 'react';
import { Modal, Row } from 'antd';
import get from 'lodash/get';
import { getRectByCoords } from '../Utils/Util';
import { Link } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import Zoom from './Zoom';
import axios from '../network/axios/$axios';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import linkDecorator from '../Utils/linkDecorater'

function ClickArea(props) {
  const { clickables, bgwidth, bgheight, primary, page, is2D } = props;
  const [visiable, setVisiable] = useState(false);
  const [linkHref, setLinkHref] = useState('');
  // const [target, setTarget] = useState('');
  const [linkType, setLinkType] = useState('');
  const [modalItem, setModalItem] = useState({});

  const openModal = (item) => {
    if (get(item, 'target') === '_self') {
      // 新页面打开iframe形式
      props.UPDATE_SELF_IFRAME_ITEM(item);
      props.history.push(`/embeddedPage`);
      return;
    }
    // 保存内容类型：linkType供popup使用, 暫時有iframe, zoom, video, image, 如果沒有設置默認為用iframe直接打開
    setLinkType(get(item, 'linkType'));
    setModalItem(item);
    if (get(item, 'target') === '_popup') {
      setVisiable(true);
      // landing没有src
      setLinkHref(get(item, 'src') || get(item, 'href'));
      // setTarget('_popup');
    } else if (
      get(item, 'linkType') === 'zoom' ||
      get(item, 'linkType') === undefined
    ) {
      setVisiable(true);
      setLinkHref(get(item, 'href'));
      // setTarget('_zoom');
    }
    if (linkType === 'video') {
      const video = document.getElementById('video');
      video.play();
    }
    clickUpload(item.title);
  };

  const handleCancel = () => {
    setVisiable(false);
    if (linkType === 'video') {
      const video = document.getElementById('video');
      video.pause();
    }
  };
  //统计点击事件
  const clickUpload = (fieldname) => {
    axios('activity', {
      data: {
        event: `[Click] ${page} links ` + fieldname
      }
    }).then((response) => {
      console.log('/activity', response);
    });
  };

  return (
    <React.Fragment>
      {clickables.map((ori, index) => {
        let item = JSON.parse(JSON.stringify(ori))
        item.to = item.href
        item.href = linkDecorator(item.href)
        // if (!item.src || item.src == "") item.src = item.href
        // if (!item.href || item.href == "") item.href = item.src
        let isYT = !item.src && get(item, 'href')?.search(/(youtube)|(youtu\.be)/) > -1
        let source = get(item, 'src', get(item, 'href', ''))
        if ((isYT || ((!item.src || !item.href || item.href == item.src) && source?.search(/(.jpg|.png|.jpeg|.mp4)$/) > -1))) item.target = "_popup"

        if (is2D && (!item.src && !isYT)) {
          return null;
        }
        const { width, height, top, left } = getRectByCoords(
          bgwidth,
          bgheight,
          item.coordinates
        );
        if (!item.src && isYT) {
          const vid = get(item, 'href').replace(/.*\//, '').replace(/(^.*\?v=)|(&.*$)/g, '')
          return (
            [<div style={{
              position: 'absolute',
              width: `${width}%`,
              height: `${height}%`,
              top: `${top}%`,
              left: `${left}%`, zIndex: "2", cursor: "pointer"
            }} onClick={() => openModal(
              { target: "_popup", src: `https://www.youtube.com/embed/${vid}?autoplay=1&mute=1&loop=1&playlist=${vid}` }
            )} />,
            <div
              key={index}
              style={{
                position: 'absolute',
                width: `${width}%`,
                height: `${height}%`,
                top: `${top}%`,
                left: `${left}%`
              }}
              className="flex-center"
            >
              <iframe style={{ width: "100%", height: "100%" }} src={`https://www.youtube.com/embed/${vid}?autoplay=1&mute=1&loop=1&controls=0&disablekb=1&fs=0&playlist=${vid}`}
              />
            </div>]
          )
        }
        if (
          get(item, 'target') === '_popup' ||
          get(item, 'target') === '_zoom' ||
          get(item, 'target') === '_self'
        ) {
          return (
            <a
              onClick={() => openModal(item)}
              key={index}
              target="_popup"
              title={get(item, 'title')}
              style={{
                position: 'absolute',
                width: `${width}%`,
                height: `${height}%`,
                top: `${top}%`,
                left: `${left}%`
              }}
              className="flex-center"
            >
              {/* linkType === 'video' ExpoHall/Booth页面接口没返回linkType字段*/}
              {item.src &&
                (get(item, 'src').indexOf('mp4') > -1 ||
                  get(item, 'src').indexOf('youtube') > -1 ||
                  get(item, 'src').indexOf('vimeo') > -1 ? (
                  <video
                    muted
                    autoPlay
                    playsInline //解决手机端无法自动播放功能
                    loop
                    style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '100%' }}
                    src={source}
                  />
                ) : (
                  <div className="flex-center" style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
                    <img
                      style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '100%' }}
                      src={source}
                    />
                  </div>
                ))}
            </a>
          );
        }
        if (get(item, 'target') === '_blank') {
          return (
            <a
              onClick={() => clickUpload(item.title)}
              key={index}
              style={{
                position: 'absolute',
                width: `${width}%`,
                height: `${height}%`,
                top: `${top}%`,
                left: `${left}%`
              }}
              href={get(item, 'href')}
              target={get(item, 'target')}
              title={get(item, 'title')}
              alt={get(item, 'alt')}
            >
              {item.src &&
                (get(item, 'src').indexOf('mp4') > -1 ? (
                  <video
                    autoPlay
                    playsInline //解决手机端无法自动播放功能
                    muted
                    loop
                    style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '100%' }}
                    src={source}
                  />
                ) : (
                  <div className="flex-center" style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
                    <img
                      style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '100%' }}
                      src={source}
                    />
                  </div>
                ))}
            </a>
          );
        }
        return (
          <Link
            onClick={() => clickUpload(item.title)}
            key={index}
            style={{
              position: 'absolute',
              width: `${width}%`,
              height: `${height}%`,
              top: `${top}%`,
              left: `${left}%`,
            }}
            to={get(item, 'to')}
            target={get(item, 'target')}
            title={get(item, 'title')}
            alt={get(item, 'alt')}
          >
            <div className="flex-center" style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
              {item.src && (
                <img
                  style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '100%' }}
                  src={source}
                />
              )}
            </div>
          </Link>
        );
      })}
      <Modal
        visible={visiable}
        width={800}
        title={null}
        footer={null}
        closeIcon={<CloseOutlined style={{ color: '#2d3436' }} />}
        onCancel={handleCancel}
      >
        {linkType === 'zoom' ||
          linkType === 'iframe' ||
          linkType === undefined ? (
          <Zoom link={linkHref} />
        ) : (
          <Row align="middle">
            {linkType === 'video' ? (
              <video
                id="video"
                controls
                autoPlay
                playsInline //解决手机端无法自动播放功能
                loop
                style={{ width: '100%', height: '100%' }}
                src={linkHref}
              />
            ) : (
              <img src={linkHref} style={{ width: '100%', height: '100%' }} />
            )}
          </Row>
        )}
      </Modal>
    </React.Fragment>
  );
}
const mapDispatchToProps = (dispatch) => ({
  UPDATE_SELF_IFRAME_ITEM: (item) => {
    dispatch({
      type: 'UPDATE_SELF_IFRAME_ITEM',
      data: item
    });
  }
});
export default connect(null, mapDispatchToProps)(withRouter(ClickArea));
