import React, { useEffect, useState } from 'react';
import { Row, Col, message, List, Spin, Rate } from 'antd';
import './ContactList.less';
import {
  LoadingOutlined,
} from '@ant-design/icons';
import i18next from 'i18next';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import axios from '../../../network/axios/$axios';
import { connect } from 'react-redux';
import { CHAT_SWITCH } from '../../../actions/LoginUserAction'
import UsersList from '../../BusinessMatching/components/UsersList'

// const fakeDataUrl = 'https://randomuser.me/api/?results=5&inc=name,gender,email,nat&noinfo';

const ContactList = (props) => {
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [firstLetter, setFirstLetter] = useState('');
  const { onClickMeet, primary, onChange, visitors, leftFilterCheckedValues, selectedRole, searchType, keyword } = props;
  console.log(primary, 'primary');
  useEffect(() => {
    fetchData();
  }, []);
  // 上层点击字母更新
  useEffect(() => {
    setFirstLetter(props.letter);
  }, [props.letter]);
  useEffect(() => {
    if (selectedRole !== '') {
      const list = allData.filter(obj => obj.role === selectedRole);
      if (keyword !== '') {
        searchByKeyword(list);
      } else {
        setData(list);
      }
    } else {
      if (keyword !== '') {
        searchByKeyword();
      } else {
        setData(allData);
      }
    }
  }, [selectedRole, searchType, keyword]);

  const searchByKeyword = (arr) => {
    const datas = arr ? arr : allData;
    let list = [];
    if (searchType === 'all') {
      list = datas.filter(obj => {
        let combined = Object.values(obj.info).join(' ');
        return keyword.split(' ').every(e => combined.search(RegExp(e, "i")) > -1)
      }
      );
      // list = datas.filter(obj => (obj.info.firstname && ((obj.info.firstname).toLowerCase()).indexOf(keyword) > -1) || (obj.info.lastname && ((obj.info.lastname).toLowerCase()).indexOf(keyword) > -1) || (obj.info.jobTitle && ((obj.info.jobTitle).toLowerCase()).indexOf(keyword) > -1) || (obj.info.companyName && ((obj.info.companyName).toLowerCase()).indexOf(keyword) > -1) || (obj.info.countryId && ((obj.info.countryId).toLowerCase()).indexOf(keyword) > -1));

    } else if (searchType === 'name') {
      list = datas.filter(obj => (obj.info.firstname && ((obj.info.firstname).toLowerCase()).indexOf(keyword) > -1) || (obj.info.lastname && ((obj.info.lastname).toLowerCase()).indexOf(keyword) > -1));
    } else if (searchType === 'job') {
      list = datas.filter(obj => (obj.info.jobTitle && ((obj.info.jobTitle).toLowerCase()).indexOf(keyword) > -1));
    } else if (searchType === 'company') {
      list = datas.filter(obj => (obj.info.companyName && ((obj.info.companyName).toLowerCase()).indexOf(keyword) > -1));
    } else if (searchType === 'country') {
      list = datas.filter(obj => (obj.info.CountryId && ((obj.info.CountryId).toLowerCase()).indexOf(keyword) > -1));
    }
    setData(list);
  };

  // const getOrCreatePrivateChat = (person) => {
  //   chatSwitch(false)
  //   axios('getOrCreatePrivateChat', {
  //     data: {
  //       target_user: {
  //         id: person.id,
  //         role: person.role,
  //         _id: person._id,
  //       }
  //     }
  //   })
  //     .then((response) => {
  //       setPrivateId(response.id);
  //       props.dispatchChatSwitch(true);
  //       // 保存当前的全局聊天记录
  //       // this.setState({ speakers: speakers })
  //     });
  // };

  const fetchData = () => {
    axios('networkingPeople', {})
      .then((response) => {
        setData(response.data);
        //setAllData(response.data);
        // setData(sortByFirstLetter(response.data));
        setAllData(sortByFirstLetter(response.data));
        setLoading(false)
      });
  };
  // 根据名字首字母排序
  const sortByFirstLetter = (data) => {
    return data.sort((a, b) => {
      const nameA = `${a.info?.firstname || ""} ${a.info?.lastname || ""}`.trim()
      const nameB = `${b.info?.firstname || ""} ${b.info?.lastname || ""}`.trim()
      return nameA.localeCompare(nameB, { sensitivity: "base" })
    })
  };
  // 根据排序数据，生成TEST结果
  // const mapTEST = (data) => {
  //   for (let index = 0; index < TEST.length; index++) {
  //     const item = TEST[index];
  //     item.range = []; // 初始化，筛选的时候
  //     data.forEach((sub, i) => {
  //       if (sub.info.firstname && sub.info.firstname[0].toUpperCase() === item.title) {
  //         // 首次次
  //         if (item.range[0] === undefined) {
  //           item.range[0] = i;
  //         }
  //         item.range[1] = i;
  //       }
  //     });
  //   }
  // };
  // const handleInfiniteOnLoad = () => {
  //   setLoading(true);
  //   if (data.length > 26) {
  //     message.warning('Infinite List loaded all');
  //     setHasMore(false);
  //     setLoading(false);
  //     return;
  //   }
  //   fetchData().then((res) => {
  //     let list = data.concat(res.results);
  //     setData(list);
  //     setLoading(false);
  //   });
  // };
  // 按照首字母刷选联系人
  // const filterByFirstName = (item) => {
  //   console.log('item', item);
  //   setFirstLetter(item);
  // };
  //是否显示头的算法
  // const getHeaderTitle = (index) => {
  //   let flag = null;
  //   TEST.map((item) => {
  //     if (
  //       index + 1 > item.range[0] &&
  //       index < item.range[1] + 1 &&
  //       ((index - item.range[0]) % 10 === 0 || index === item.range[0])
  //     ) {
  //       flag = item.title;
  //     }
  //   });
  //   return flag;
  // };
  // 显示关于我们弹窗
  // const makeModalShow = (item) => {
  //   setModelItem(item);
  //   setIsModalVisible(true);
  // };
  // // 隐藏关于我们弹窗
  // const makeModalHiddle = () => {
  //   setIsModalVisible(false);
  // };
  //跳转Visit Booth页面
  // const makeComfirm = () => {
  //   props.history.push('/ExpoHall/Booth/61e82adc1a22ff9af99387dc');
  // };
  // const chatSwitch = (swith) => {
  //   props.dispatchChatSwitch(false);
  // };
  // 左侧筛选过滤
  const leftFilerHandle = (data) => {
    if (leftFilterCheckedValues.length === 0) {
      return data;
    }
    return data.filter(itemA => {
      const { personalInterest } = itemA.info;
      if (personalInterest) {
        let result = false;
        for (let item of leftFilterCheckedValues) {
          if (personalInterest.includes(item)) {
            result = true;
            break;
          }
        }
        return result;
      }
      // 没有兴趣
      return false;
    })
  };
  // 字母过滤
  // const fistLetterFilerHandle = (data) => {
  //   if (!firstLetter || firstLetter === 'ALL') {
  //     return data
  //   }
  //   const list = data.filter(obj => (obj.info.firstname && (obj.info.firstname.indexOf(firstLetter) === 0 || obj.info.firstname.indexOf(firstLetter.toLowerCase()) === 0)));
  //   return list;
  // };
  let filterData = leftFilerHandle(data);
  // filterData = fistLetterFilerHandle(filterData);
  // mapTEST(sortByFirstLetter(filterData));
  if (loading) {
    return (
      <div className='flex-center hp-py-16' style={{ backgroundColor: '#fff', borderRadius: '7px' }}>
        <LoadingOutlined />
        <span className='hp-mt-8'>{i18next.t('translations.loading')}</span>
      </div>
    )
  }
  return (
    <div className="contact-list">
      {/* <Row className="pT20 pB20 pL10 pR10 flex flex_rl bgWhite">
        {LIST.map((item, index) => {
          return (
            <div
              key={index}
              className="pointer"
              onClick={() => filterByFirstName(item)}
            >
              <span className="filter-title">{item}</span>
            </div>
          );
        })}
      </Row> */}
      <div className="infinite-container">
        <UsersList dataProp={filterData} allDataProp={allData} visitors={visitors || []} onChange={onChange} {...props} />
        {/* <InfiniteScroll
          initialLoad={false}
          pageStart={0}
        >
          <List
            split={false}
            dataSource={filterData}
            renderItem={(item, i) => {
              const title = getHeaderTitle(i);
              const arr = visitors.filter(v => v.refId === item._id);
              const isSelected = arr.length > 0;
              return (
                <List.Item key={item.id}>
                  <div style={{ width: '100%', position: 'relative'}}>
                    {title && <Row className="list-header">{title}</Row>}
                    <div className="row">
                      <Row align="middle" className="flex flex_rl flex_1">
                        <Col
                          xs={6}
                          ms={6}
                          md={4}
                          onClick={() => makeModalShow(item)}
                          className="pointer"
                          style={{ paddingLeft: '2rem', paddingRight: '2rem' }}
                        >
                          <img className="user" src={noProfilePic} />
                        </Col>
                        <Col
                          xs={18}
                          ms={18}
                          md={20}
                          onClick={() => makeModalShow(item)}
                          className="pointer flex flex_d_c flex_a_c flex_j_c"
                          style={{ textAlign: 'left' }}
                        >
                          <div className="flex flex_a_c">
                            <div className="type contact-type flex-row-center">
                              <img src={guestsvg} />
                              <span style={{ fontSize: 12, marginLeft: '0.5rem' }}>
                                {get(item, 'role') === 'Visitor'
                                  ? i18next.t('translations.visitor')
                                  : i18next.t('translations.exhibitor')}
                              </span>
                            </div>
                          </div>
                          <div className="row-title">
                            <span>{get(item, 'info.firstname')} {get(item, 'info.lastname')}</span>
                          </div>
                          <div className='company-name'>
                            <span>{get(item, 'info.CompanyName')}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row  className='mT30'>
                        <Col>
                          <div
                            onClick={() => getOrCreatePrivateChat(item)}
                            className="pointer flex flex_a_c bgColor pro-btn btn"
                          >
                            <img src={charsvg} />
                            <span className='mL10'>{i18next.t('translations.chat')}</span>
                          </div>
                        </Col>
                        <Col  className='mL20'>
                          {get(item, 'role') === 'Visitor' && (
                            <div
                              onClick={() => onClickMeet(item)}
                              className="pointer flex flex_a_c fColor bColor btn"
                            >
                              <img src={calendar} />
                              <span className='mL10'>{i18next.t('translations.requestMeet')}</span>
                            </div>
                          )}
                        </Col>
                      </Row>
                      <div className="pointer rate">
                        <Rate style={{ fontSize: 28 }} onChange={(e) => onChange(item, arr, e)} count="1" value={isSelected ? 1 : 0} />
                      </div>
                    </div>
                  </div>
                </List.Item>
              );
            }}
          >
            {loading && hasMore && (
              <div className="loading-container">
                <Spin />
              </div>
            )}
          </List>
        </InfiniteScroll> */}
      </div>
      {/* <ContactInfoModal
        isModalVisible={isModalVisible}
        handleCancel={makeModalHiddle}
        handleOk={makeComfirm}
        modelItem={modelItem}
        primary={primary}
      /> */}
      {/* {(chatSwitch && privateId) && <ChatMessage private_active_id={privateId} close={() => chatSwitch(false)} />} */}
    </div>
  );
};

const enhance = compose(withTranslation('translations'));

// export default enhance(ContactList);
const mapStateToProps = (state) => ({
  primary: state.profile.primary,
  // language: state.profile.language,
  chatSwitch: state.loginUserInfo.chatSwitch,
});
const mapDispatchToProps = (dispatch) => ({
  dispatchChatSwitch: (flag) => {
    dispatch(CHAT_SWITCH(flag));
  }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(enhance(ContactList));