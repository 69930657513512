import React, { Component } from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import Zoom from '../../components/Zoom';

class Lobby extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
    this.props.SHOW_BACK(true);
  }
  componentWillUnmount() {
    // console.log('componentWillUnmount-componentWillUnmount');
    this.props.SHOW_BACK(false);
  }
  render() {
    // const { detail } = this.state;
    const { selfIframeItem } = this.props;
    const linkType = get(selfIframeItem, 'linkType');
    if (linkType === 'iframe' || linkType === 'video' || linkType === 'image') {
      return (
        <iframe src={selfIframeItem?.href} style={{ width: '100%' }} height={window.innerHeight - 64 - 70} frameBorder="0" title='iframe'></iframe>
      )
    }
    return (
      <div>
        {selfIframeItem && <Zoom height={window.innerHeight - 64 - 70} link={selfIframeItem.src || selfIframeItem.href} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.loginUserInfo.userInfo,
  selfIframeItem: state.loginUserInfo.selfIframeItem,
  primary: state.profile.primary,
  language: state.profile.language
});
const mapDispatchToProps = (dispatch) => ({
  BACK_IFRAME: () => {
    dispatch({
      type: 'BACK_IFRAME',
      // data: item
    });
  },
  SHOW_BACK: (data) => {
    dispatch({
      type: 'SHOW_BACK',
      data: data
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Lobby);
