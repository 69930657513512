import React from "react";
import { Link } from "react-router-dom";
import queryString from 'query-string';
import { Row, Col, Form, Input, Button, message } from "antd";

import LeftContent from "../leftContent";
import apiAxios from "../../../network/axios/$axios";
import i18next from "i18next";

export default function ResetPassword(props) {
  const { token, email } = queryString.parse(props.location.search);

  // 更改密码
  const onChangePassword = (values) => {
    apiAxios('resetPassword', {
      data: {
        id: email, pass: values.NewPassword, token
      }
    })
      .then((response) => {
        console.log('/resetPassword=', response);
        message.success(i18next.t('translations.update-password-success'));
        props.history.replace('/');
      })
      .catch((error) => {
        console.log(error);
        message.error(i18next.t('translations.invalid-username'));
      });
  };


  return (
    <Row gutter={[32, 0]} className="hp-authentication-page bgWhite">
      <LeftContent />

      <Col lg={12} span={24} className="hp-py-sm-0 hp-py-md-64 hp-pb-96">
        <Row className="hp-h-100" align="middle" justify="center">
          <Col
            xxl={15}
            xl={15}
            lg={20}
            md={20}
            sm={24}
            className="hp-px-sm-8 hp-pt-24 hp-pb-48"
          >
            <h1>Reset Password</h1>
            <p className="hp-mt-8 hp-text-color-black-60">
              Email verification is done. Please choose another password
            </p>

            <Form
              layout="vertical"
              name="basic"
              className="hp-mt-sm-16 hp-mt-32"
              onFinish={onChangePassword}
            >
              <Form.Item label="Password :" name="NewPassword">
                <Input.Password
                  id="password"
                  placeholder="At least 6 characters"
                />
              </Form.Item>

              <Form.Item
                label="Confirm Password :" 
                name="NewPasswordConfirmation"
                rules={[{ required: true, message: i18next.t('translations.comfirm-password') },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('NewPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(i18next.t('translations.password-policy-passwords')));
                  },
                }),
                ]}
              >
                <Input.Password
                  id="confirm-password"
                  placeholder="At least 6 characters"
                />
              </Form.Item>

              <Form.Item className="hp-mt-16 hp-mb-8">
                <Button block type="primary" htmlType="submit">
                  Reset Password
                </Button>
              </Form.Item>
            </Form>

            <div className="hp-form-info hp-text-center">
              <span className="hp-text-color-black-80 hp-text-color-dark-40 hp-caption hp-mr-4">
                Go back to
              </span>

              <Link
                to="/"
                className="hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-caption"
              >
                Login
              </Link>
            </div>

          </Col>
        </Row>
      </Col>
    </Row>
  );
}
