import { Modal, Row, Col, Card, Tooltip } from 'antd';
import {
  YoutubeFilled,
  LinkedinFilled,
  InstagramOutlined,
  TwitterOutlined
} from '@ant-design/icons';
import React, { useState } from 'react';
import './SocialMediaModal.less';
import PopUpModal from './PopUpModal';
import get from 'lodash/get';

export default function SocialMediaModal(props) {
  const { data = [], primary, language } = props;
  const [detailisModalVisible, setDetailisModalVisible] = useState(false);
  const [item, setItem] = useState({});
  //点击弹窗显示详情
  const openDetailModal = (obj) => {
    setItem(obj);
    setDetailisModalVisible(true);
  };
  // 隐藏详情modal
  const detailModalHiddle = () => {
    setDetailisModalVisible(false);
  };
  return (
    <div className="social-media-page">
      <Row>
        {data.map((item, index) => {
          let icon = null;
          switch (item.social) {
            case 'YouTu':
              icon = (
                <div style={{ fontSize: '6rem', color: 'red' }}>
                  <YoutubeFilled className="pointer" />
                </div>
              );
              break;
            case 'Instagram':
              icon = (
                <div style={{ fontSize: '6rem', color: '#c03772' }}>
                  <InstagramOutlined className="pointer" />
                </div>
              );
              break;
            case 'Link':
              icon = (
                <div style={{ fontSize: '6rem', color: '#0678b6' }}>
                  <LinkedinFilled className="pointer" />
                </div>
              );
              break;
            case 'Twitter':
              icon = (
                <div style={{ fontSize: '6rem', color: '#37b1e1' }}>
                  <TwitterOutlined className="pointer" />
                </div>
              );
            default:
              icon = (
                <img src={item.iconSrc} alt="" className="pointer" />
              );
              break;
          }
          return (
            <Card key={index} className='list-item hp-mr-16 hp-mb-16' style={{ height: "auto" }}>
              {item.target === '_popup' ? <div className="flex-center">
                <a className='icon' onClick={() => openDetailModal(item)}>
                  {icon}
                </a>
                <span className='hp-mt-8 display_title space'>{get(item.desplay_title, language)}</span>
              </div> : <div className="flex-center">
                <a className='icon' href={item.href} target='_blank'>
                  {icon}
                </a>
                <Tooltip placement='bottom' title={get(item.display_description, language)} className='hp-mt-8 display_title'>
                  {get(item.desplay_title, language)}
                </Tooltip>
              </div>}
            </Card>
          );
        })}
      </Row>
      <PopUpModal
        isModalVisible={detailisModalVisible}
        handleCancel={detailModalHiddle}
        item={item}
        primary={primary}
        language={language}
      />
    </div>
  );
}
