/*
 * @Description: 
 * @Version: 1.0
 * @Autor: ricky shen
 * @Date: 2022-03-20 13:24:35
 * @LastEditors: ricky shen
 * @LastEditTime: 2022-03-22 11:01:38
 */
import React, { Component } from 'react';
import { Row, List, Col } from 'antd';
import { connect } from 'react-redux';
import './AgItemSpeakerList.less';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';

class SpeakerList extends Component {
  constructor(props) {
    super();
  }
  componentDidMount() { }

  clickItem = (item) => {
    // 页面跳转
    this.props.history.push({
      pathname: '/SpeakerDetail/' + item._id,
      state: { params: item }
    });
  };

  render() {
    const { data, language } = this.props;
    return (
      <div className="AgItemSpeakerList">
        <List
          grid={{
            gutter: [2, 2],
            xs: 1,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 3,
            xxl: 3
          }}
          dataSource={data}
          renderItem={(item, index) => {
            return (
              <List.Item key={index}>
                <div>
                  <Row align="middle" justify='center' style={{ alignItems: 'flex-start' }}>
                    <Col span="5">
                      <img className='profilePic' src={get(item, 'profilePic.src')} alt={get(item, 'profilePic.alt')} title={get(item, 'profilePic.title')} />
                    </Col>
                    <Col span="18" offset='1'>
                      <div className="speakerName">
                        <span>{get(get(item, 'display_name'), language)}</span>
                      </div>
                      <div className="speakerJobTitle f25">
                        <div>{get(get(item, 'display_jobTitle'), language)}</div>
                        <div>{get(get(item, 'display_company'), language)}</div>
                      </div>
                    </Col>
                  </Row>
                  {/* <img className='profilePic' src={get(item, 'profilePic.src')} alt={get(item, 'profilePic.alt')} title={get(item, 'profilePic.title')} />
                  <div className="speakerName">
                    <span>{get(get(item, 'display_name'), language)}</span>
                  </div>
                  <div className="speakerJobTitle f25">
                    <span>{get(get(item, 'display_company'), language)}</span><span>{get(get(item, 'display_jobTitle'), language)}</span>
                  </div> */}
                </div>
              </List.Item>
            );
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.profile.language
});

export default connect(mapStateToProps, null)(withRouter(SpeakerList));
