import { Modal, Row } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import Zoom from './Zoom';
import get from 'lodash/get';

const PopUpModal = (props) => {
  const { isModalVisible, handleCancel, primary, language, item } = props;

  return (
    <Modal
      width={800}
      title={
        <div>
          {get(item.display_title, language)}
        </div>
      }
      visible={isModalVisible}
      footer={null}
      onCancel={handleCancel}
      closeIcon={<CloseOutlined />}
    >
      <Zoom link={item.href} />
    </Modal>
  );
};

export default PopUpModal;
