import React, { Component } from 'react';
import { Row, List } from 'antd';
import { connect } from 'react-redux';
import './SocialList.less';
import s4 from '../../../Image/SocialLounge/s4.png';
import s6 from '../../../Image/SocialLounge/s6.png';
import s8 from '../../../Image/SocialLounge/s8.png';
import s10 from '../../../Image/SocialLounge/s10.png';
import s12 from '../../../Image/SocialLounge/s12.png';
import s20 from '../../../Image/SocialLounge/s20.png';
import { compareDate } from '../../../Utils/Util';
import { LockFilled } from '@ant-design/icons';
import noprofilepic from '../../../Image/no-profile-pic.png';
import { get } from 'lodash';

class SocialList extends Component {
  constructor(props) {
    super();
  }
  componentDidMount() {}

  clickItem = (item) => {
    this.props.clickItem(item);
  };

  render() {
    const { data, language, link, joinSuccess, username } = this.props;
    const imgSrc = {
      4: s4,
      6: s6,
      8: s8,
      10: s10,
      12: s12,
      20: s20
    };
    return (
      <div className="social-list">
        <List
          grid={{
            gutter: [16, 16],
            xs: 1,
            sm: 2,
            md: 4,
            lg: 4,
            xl: 4,
            xxl: 4
          }}
          dataSource={data}
          renderItem={(item, index) => {
            let isColsed = false;
            if (item.startTime !== '') {
              const isNotStart = compareDate(item.startTime, new Date());
              if (isNotStart) {
                isColsed = true;
              } else {
                if (item.endTime !== '') {
                  const isNotEnd = compareDate(item.endTime, new Date());
                  if (!isNotEnd) {
                    isColsed = true;
                  }
                }
              }
            }
            const selected = item.url === link;
            const max_participants = get(item, 'DailyCoRawData.max_participants', 4);
            let pic = '';
            if (max_participants === 4) {
              pic= 'pic-4';
            } else if (max_participants === 6) {
              pic= 'pic-6';
            } else if (max_participants === 8) {
              pic= 'pic-8';
            } else if (max_participants === 10 || max_participants === 12) {
              pic= 'pic-10';
            } else if (max_participants === 20) {
              pic= 'pic-20';
            }
            return (
              <List.Item key={index} onClick={() => {
                if(isColsed) return;
                this.clickItem(item);
              }}>
                <div className={isColsed ? 'list-content disabled' : "list-content pointer"}>
                  <div className="flex flex_rl social-title">
                    <div style={{width: '90%'}}>
                      <Row className="f16 bold">
                        <span>{index + 1}</span>
                      </Row>
                      <Row className="f14 bold">
                        <span>{item.display_name[language]}{item.hasPass ? ` (Pwd: ${item.password})` : ''}</span>
                      </Row>
                    </div>
                    {item.hasPass && <LockFilled />}
                  </div>
                  <Row className="img">
                    <div className="social-img">
                      <img className="item-img" src={imgSrc[max_participants]} alt="" />
                      <img className="icon" src={item.icon.src} alt="" />
                    </div>
                    {joinSuccess && selected && <img className={`${pic} pointer`} src={noprofilepic} title={username} />}
                  </Row>
                  <Row className="status">
                    <span className={isColsed ? 'closed' : 'open'}>{isColsed ? 'Closed' : 'Open'}</span>
                  </Row>
                </div>
              </List.Item>
            );
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.profile.language
});

export default connect(mapStateToProps,null)(SocialList);
