import React, { Component } from 'react';
import { Breadcrumb, Col, Row } from 'antd';
import './index.less';
import SearchList from './components/SearchList';
import mainSp from '../../Image/product/amine-additives.png';
import SearchCondition from './components/SearchCondition';
import { searchProductList } from '../../actions/ProductAction';
import { connect } from 'react-redux';

class ProductSearchScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      keyword: ''
    };
  }

  componentDidMount() {
    this.searchListByKeyword(1);
  }
  //搜索框值变化
  onChange = (value) => {
    this.setState({ keyword: value });
  };
  //产品分类值变化
  onChangeCategories = (categories) => {
    this.setState({ categories }, () => {
      this.searchListByKeyword(1);
    });
  };
  //根据条件搜索产品列表
  searchListByKeyword = (page) => {
    const { categories, keyword } = this.state;
    this.props.searchProductList(categories, keyword, page);
  };

  render() {
    const { searchProductData } = this.props;
    return (
      <div className="content-view">
        <Breadcrumb separator=">" className="breadcrumb">
          <Breadcrumb.Item>Lobby</Breadcrumb.Item>
          <Breadcrumb.Item>Search</Breadcrumb.Item>
        </Breadcrumb>
        <Row className="search-page">
          <Col xs={14} sm={12} md={6}>
            <div style={{ marginBottom: '2rem' }}>
              <SearchCondition
                selectAction={this.onChangeCategories}
                {...this.props}
              />
              <img className="bottom-img" src={mainSp} alt="" />
            </div>
          </Col>
          <Col xs={10} sm={12} md={18}>
            <SearchList
              data={searchProductData.data}
              total={searchProductData.total}
              onChange={this.onChange}
              onSearch={this.searchListByKeyword}
              {...this.props}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  searchProductData: state.product.searchProductData
});

const mapDispatchToProps = (dispatch) => ({
  searchProductList: (categories, keyword, page) => {
    dispatch(searchProductList(categories, keyword, page));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSearchScreen);
