import React, { useEffect, useState } from 'react';
import { Row, Col, Table } from 'antd';
import i18next from 'i18next';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import axios from '../../../network/axios/$axios';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { CHAT_SWITCH } from '../../../actions/LoginUserAction'
import ChatMessage from '../../../components/ChatMessage';
import './UserList.less';
import ScheduleMeetingModal from './ScheduleMeetingModal';
import { RiHeartFill } from 'react-icons/ri';

function UsersList(props) {
  const { onChange, visitors, selectedRole, searchType, keyword, primary, dataProp, allDataProp, data } = props;
  const [allData, setAllData] = useState([]);
  const [peoples, setPeoples] = useState([]);
  const [channel_url, setChannelURL] = useState('');
  const [visiable, setVisiable] = useState(false);
  const [selectedItem, setSelectedItem] = useState();

  useEffect(() => {
    // 来自network页面
    if (allDataProp) {
      setPeoples(dataProp);
      setAllData(allDataProp);
    } else {
      peoplerecommendation();
    }
  }, [dataProp]);

  // useEffect(() => {
  //   if (selectedRole !== '') {
  //     const list = allData.filter(obj => obj.role === selectedRole);
  //     if (keyword !== '') {
  //       searchByKeyword(list);
  //     } else {
  //       setPeoples(list);
  //     }
  //   } else {
  //     if (keyword !== '') {
  //       searchByKeyword();
  //     } else {
  //       setPeoples(allData);
  //     }
  //   }
  // }, [selectedRole, searchType, keyword]);
  // 轮播数据
  const peoplerecommendation = () => {
    axios('peoplerecommendation', {})
      .then((response) => {
        // console.log('peoplerecommendation', response);
        setPeoples(response.data);
        setAllData(response.data);
      });
  };
  // chat-use
  const getOrCreatePrivateChat = (person) => {
    chatSwitch(false)
    axios('getOrCreatePrivateChat', {
      data: {
        target_user_id: person._id
      }
    })
      .then((response) => {
        if (response == "") {
          const timeout = setTimeout(() => {
            axios('getOrCreatePrivateChat', {
              data: {
                target_user_id: person._id
              }
            }).then((response2) => {
              setChannelURL(response2.channel_url);
              props.dispatchChatSwitch(true);
            });
          }, 500)
        } else {
          setChannelURL(response.channel_url);
          props.dispatchChatSwitch(true);
        }
      });
  };
  const chatSwitch = (swith) => {
    props.dispatchChatSwitch(false);
  };
  const searchByKeyword = (arr) => {
    const datas = arr ? arr : allData;
    let list = [];
    if (searchType === 'all') {
      list = datas.filter(obj => (obj.info.firstname && obj.info.lastname && (obj.info.lastname + obj.info.firstname).indexOf(keyword) > -1) || (obj.info.jobTitle && obj.info.jobTitle.indexOf(keyword) > -1) || (obj.info.companyName && obj.info.companyName.indexOf(keyword) > -1) || (obj.info.CountryId && obj.info.CountryId.indexOf(keyword) > -1));
    } else if (searchType === 'name') {
      list = datas.filter(obj => (obj.info.firstname && obj.info.lastname && (obj.info.lastname + obj.info.firstname).indexOf(keyword) > -1));
    } else if (searchType === 'job') {
      list = datas.filter(obj => (obj.info.jobTitle && obj.info.jobTitle.indexOf(keyword) > -1));
    } else if (searchType === 'company') {
      list = datas.filter(obj => (obj.info.companyName && obj.info.companyName.indexOf(keyword) > -1));
    } else if (searchType === 'country') {
      list = datas.filter(obj => (obj.info.CountryId && obj.info.CountryId.indexOf(keyword) > -1));
    }
    setPeoples(list);
  };
  const list = [];
  peoples.map(item => {
    list.push({
      ...item,
      ...item?.info
    })
  })

  const getRowClassName = (record, index) => {
    let className = "";
    className = index % 2 === 0 ? "oddRow" : "evenRow";
    return className;
  }

  const columns = [
    {
      title: "",
      dataIndex: "avatar",
      render: (dataIndex, record) => {
        let str = '';
        if (record && record.firstname) {
          str = (record.firstname).substring(0, 1);
        } else if (record && record.lastname) {
          str = (record.lastname).substring(0, 1);
        }
        return (
          <div className="flex-row-center">
            <div className="flex-row-center" style={{ minWidth: 50, width: 50, height: 50, borderRadius: 25, backgroundColor: '#C4E2FC' }}>
              <span>{str}</span>
            </div>
          </div>
        );
      },
      width: 200
    },
    {
      title: i18next.t('translations.userlist-name'),
      dataIndex: "firstname",
      render: (dataIndex, record) => {
        return (
          <div className="flex-column-center">
            <div>
              <span>{get(record, 'firstname', '') + ' ' + get(record, 'lastname')}</span>
            </div>
            <div>
              <span>{get(record, 'jobTitle')}</span>
            </div>
          </div>
        );
      },
      width: 200
    },
    {
      title: i18next.t('translations.company'),
      dataIndex: "companyName",
      width: 'auto',
      width: 200
    },
    {
      title: i18next.t('translations.type'),
      dataIndex: "role",
      render: (dataIndex, record) => {
        let role = '';
        let customCSS = '';
        if (dataIndex === 'Visitor') {
          role = i18next.t('translations.visitor');
          customCSS = 'visitor';
        } else if (dataIndex === 'Organizer') {
          role = i18next.t('translations.organizer');
          customCSS = 'organizer';
        } else {
          role = i18next.t('translations.exhibitor');
          customCSS = 'exhibitor';
        }
        return (
          <Row justify="center">
            <div className={'flex-row-center ' + customCSS}>
              <span>
                {role}
              </span>
            </div>
          </Row>
        );
      },
      width: 200
    },
    {
      title: i18next.t('translations.action'),
      dataIndex: "action",
      width: 200,
      render: (dataIndex, record) => {
        const arr = visitors.filter(v => v.refId === record._id || v._id === record._id);
        const isSelected = arr.length > 0;
        return (
          <Row justify="center">
            <div className="hp-text-right flex-center hp-mr-24 pointer" onClick={() => onChange(record, arr, isSelected ? 0 : 1)}>
              <div className="yuan flex-center" style={{ borderColor: isSelected ? '#f02000' : '' }}>
                <RiHeartFill style={{ color: isSelected ? '#f02000' : '#B2BEC3' }} />
              </div>
              <span style={{ color: isSelected ? '#f02000' : '' }}>{i18next.t('translations.bookmark')}</span>
            </div>
            <div className="hp-text-right flex-center hp-mr-24 pointer" onClick={() => {
              setVisiable(true);
              setSelectedItem(record);
            }}>
              <div className="yuan flex-center" style={{ borderColor: '#019EAF' }}>
                <span className="remix-icon">
                  <i className="ri-calendar-2-fill" style={{ color: '#019EAF' }} />
                </span>
              </div>
              <span style={{ color: '#019EAF' }}>{i18next.t('translations.meet')}</span>
            </div>
            <div className="hp-text-right flex-center hp-mr-24 pointer" onClick={() => getOrCreatePrivateChat(record)}>
              <div className="yuan flex-center" style={{ borderColor: '#019EAF' }}>
                <span className="remix-icon">
                  <i className="ri-chat-smile-fill" style={{ color: '#019EAF' }} />
                </span>
              </div>
              <span style={{ color: '#019EAF' }}>{i18next.t('translations.chat')}</span>
            </div>
          </Row>
        )
      },
    },
  ];

  const handleCancel = () => {
    setVisiable(false)
  }

  return (
    <Col className="hp-contact-card user-list">
      <Table
        style={{ paddingTop: 20, backgroundColor: '#fff', borderRadius: 7 }}
        pagination={false}
        columns={columns}
        dataSource={data ? data : list}
        // scroll={{ x: 'calc(500px + 50%)' }}
        scroll={{ x: '100%' }}
        rowClassName={getRowClassName}
      />
      {(chatSwitch) && <ChatMessage selected_channel_url={channel_url} close={() => chatSwitch(false)} />}
      <ScheduleMeetingModal isModalVisible={visiable} selectedItem={selectedItem} handleCancel={() => handleCancel()} primary={primary} {...props} />
    </Col>
  );
};

const mapStateToProps = (state) => ({
  chatSwitch: state.loginUserInfo.chatSwitch,
  primary: state.profile.primary
});
const mapDispatchToProps = (dispatch) => ({
  dispatchChatSwitch: (flag) => {
    dispatch(CHAT_SWITCH(flag));
  }
});
const enhance = compose(withTranslation('translations'));

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(enhance(UsersList));
