import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Radio, Divider, Button } from 'antd';
import './FilterCheck.less';
import i18next from 'i18next';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
// import axios from '../../../network/axios/$axios';
import get from 'lodash/get';
import { RiArrowRightSLine, RiArrowDownSLine } from "react-icons/ri";

const { Search } = Input;

const FilterCheck = (props) => {
  const [filterTitles, setFilterTitles] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [webTypeSwitch, setWebTypeSwitch] = useState(false);
  const [topicsSwitch, settopicsSwitch] = useState(true);
  const [dateSwitch, setDateSwitch] = useState(false);

  // const [filterSubTitles, setFilterSubTitles] = useState([]);
  // const [hasMore, setHasMore] = useState(true);
  const { language, dateTimes, primary, currenDate, onDateChange, webtTpes, onwebTypeChange, topics, onTopicChange, currentType, currenTopicIndex, remoteFilter } = props;
  useEffect(() => {
  }, []);
  const webTypeChoose = (e) => {
    onwebTypeChange(e.target.value);
  };
  const onRemoteFilter = () => {
    remoteFilter();
  };
  const down = <RiArrowDownSLine size={24} className="hp-collapse-arrow hp-text-color-black-60"/>;
  const up = <RiArrowRightSLine
      size={24}
      className="hp-collapse-arrow hp-text-color-black-60"
    />
  const dateTimesList = [{title: 'All'}, ...dateTimes];
  return (
    <div className="filter-check2">
      {/* 
      {dateTimes && dateTimes.length && <div>
        <div onClick={() => { setDateSwitch(!dateSwitch) }} className='filter-title flex flex_a_c flex_rl'>
          <span className=''>{i18next.t('translations.date')}</span>
          <span className='mL05'>{dateSwitch ? down : up}</span>
        </div>
        {dateSwitch && dateTimesList.map((dateObj, index) => (
          <div onClick={() => onDateChange(dateObj.title)} className={'hp-mt-10 sub-title ' + (currenDate === dateObj.title ? 'selected': '')} key={dateObj.title}>
            <div className='flex flex_a_c'>
              <i className="ri-checkbox-blank-circle-fill icon-bc icon-f" />
              <span className='mL05'>{dateObj.title}</span>
            </div>
          </div>
        ))}
      </div>}
      <Divider />
      {webtTpes && webtTpes.length && <div>
        <div onClick={() => { setWebTypeSwitch(!webTypeSwitch) }} className='filter-title flex flex_a_c flex_rl'>
          <span className='hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30'>{i18next.t('translations.webinar-type')}</span>
          <span className='mL05'>{webTypeSwitch ? down : up}</span>
        </div>
        {webTypeSwitch &&  <Radio.Group onChange={webTypeChoose} value={currentType}>
          {webtTpes && webtTpes.map(type => (
            <Row key={type} className={'hp-mt-10 sub-title ' + (currentType === type ? 'selected': '')}>
                <Radio value={type}>{type}</Radio>
            </Row>
          ))}
        </Radio.Group>}
      </div>}
      <Divider />
       */}
      {topics && topics.length > 0 && <div>
        <div onClick={() => { settopicsSwitch(!topicsSwitch) }} className='filter-title flex flex_a_c flex_rl'>
          {/* <span className=''>{i18next.t('translations.topic')}</span> */}
          <span className=''>{i18next.t('translations.date')}</span>
          <span className='mL05'>{topicsSwitch ? down : up}</span>
        </div>
        {topicsSwitch && topics && topics.map((topic, index) => (
          <div onClick={() => onTopicChange(topic, index)} className={'hp-mt-10 sub-title ' + (currenTopicIndex === index ? 'selected': '')} key={get(get(topic, 'display_name'), language)}>
            <div className='flex'>
              <i className="ri-price-tag-3-fill icon-bc" />
              <span className='mL05'>{get(get(topic, 'display_name'), language)}</span>
            </div>
          </div>
        ))}
        <Divider className='hp-mb-0' />
      </div>}
      <Button onClick={() => onRemoteFilter()} block type="primary" className="hp-mt-32" style={{ fontSize: 11, textTransform: 'uppercase' }}>{i18next.t('translations.filter')}</Button>

    </div>
  );
};

const enhance = compose(withTranslation('translations'));

export default enhance(FilterCheck);
