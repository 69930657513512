import day from 'dayjs';
import axios from '../network/axios/$axios';
const INVALID_DATE = '--';
export const DATE_FORMAT_MONTH_DATE_CHINESE = 'M月D日';
export const DISPLAYED_DATE_FORMAT_YEAR = 'YYYY年M月D日';
export const DISPLAYED_HH_MM = 'HH:mm';

const getDisplayedDate = date =>
  (date === '1950-01-01' || date === '1970-01-01' ? INVALID_DATE : date);

export const getRegionDateArray = (openTime) => {
  let y = new Date().getFullYear();
  let m = new Date().getMonth() + 1;
  let d = new Date().getDate();
  let start = [],
    end = []; //start起始时间数组，end结束时间数组
  for (let i = 0, len = openTime.length; i < len; i++) {
    //将时间字符串转换成日期格式并存入开始时间数组和结束时间数组
    let [s, e] = openTime[i].split('-');
    start.push(new Date(y + '/' + m + '/' + d + ' ' + s));
    end.push(new Date(y + '/' + m + '/' + d + ' ' + e));
  }
  let list = [];
  function formatTime(time) {
    //时间为个位数则补零
    return time < 10 ? '0' + time : time;
  }
  for (let i = 0, len = start.length; i < len; i++) {
    let len2 = (end[i].getTime() - start[i].getTime()) / (30 * 60 * 1000); //计算各子区间以半小时为间隔拆分后的数组长度
    for (let j = 0; j < len2; j++) {
      if (start[i].getTime() + 30 * 60 * 1000 <= end[i].getTime()) {
        //将各子区间日期按半小时递增转换为时间并存入list数组
        let ss = new Date(start[i].getTime() + 30 * 60 * 1000 * j),
          ee = new Date(start[i].getTime() + 30 * 60 * 1000 * (j + 1));
        list.push([
          formatTime(ss.getHours()) + ':' + formatTime(ss.getMinutes()),
          formatTime(ee.getHours()) + ':' + formatTime(ee.getMinutes())
        ]);
      }
    }
  }
  list = list.map((item) => {
    return item.join('-');
  });
  return list;
};

export const getSingleDateArray = (openTime) => {
  let y = new Date().getFullYear();
  let m = new Date().getMonth() + 1;
  let d = new Date().getDate();
  let start = [],
    end = []; //start起始时间数组，end结束时间数组
  for (let i = 0, len = openTime.length; i < len; i++) {
    //将时间字符串转换成日期格式并存入开始时间数组和结束时间数组
    let [s, e] = openTime[i].split('-');
    start.push(new Date(y + '/' + m + '/' + d + ' ' + s));
    end.push(new Date(y + '/' + m + '/' + d + ' ' + e));
  }
  let list = [];
  function formatTime(time) {
    //时间为个位数则补零
    return time < 10 ? '0' + time : time;
  }
  for (let i = 0, len = start.length; i < len; i++) {
    let len2 = (end[i].getTime() - start[i].getTime()) / (30 * 60 * 1000); //计算各子区间以半小时为间隔拆分后的数组长度
    for (let j = 0; j < len2; j++) {
      if (start[i].getTime() + 30 * 60 * 1000 <= end[i].getTime()) {
        //将各子区间日期按半小时递增转换为时间并存入list数组
        let ss = new Date(start[i].getTime() + 30 * 60 * 1000 * j);
        list.push(
          formatTime(ss.getHours()) + ':' + formatTime(ss.getMinutes())
        );
      }
    }
  }
  return list;
};

export const getTreeData = (list) => {
  const array = [];
  list.map((item) => {
    const obj = {
      id: item.id,
      key: item.id,
      title: item.category_name
    };
    const id = item.id;
    const arr = id.split('.');
    if (arr.length === 1) {
      obj.parent_id = null;
    } else if (arr.length === 2) {
      obj.parent_id = arr[0];
    } else if (arr.length === 3) {
      obj.parent_id = arr[0] + '.' + arr[1];
    }
    array.push(obj);
  });
  const result = getTrees(array);
  return result;
};

const getTrees = (list, parent_id = 0) => {
  let parentObj = {};
  list.forEach((o) => {
    parentObj[o.id] = o;
  });
  if (!parent_id) {
    return list
      .filter((o) => !parentObj[o.parent_id])
      .map((o) => ((o.children = getTrees(list, o.id)), o));
  } else {
    return list
      .filter((o) => o.parent_id == parent_id)
      .map((o) => ((o.children = getTrees(list, o.id)), o));
  }
};

export const splitArr = (arr, num) => {
  //arr是你要分割的数组，num是以几个为一组
  let newArr = []; //首先创建一个新的空数组。用来存放分割好的数组
  for (let i = 0; i < arr.length;) {
    //注意：这里与for循环不太一样的是，没有i++
    newArr.push(arr.slice(i, (i += num)));
  }
  return newArr;
};

/**
 * 根据日期字符串获取星期几
 * @param dateString 日期字符串（如：2020-05-02）
 * @returns {String}
 */
export const getWeek = (dateString) => {
  let dateArray = dateString.split("-");
  let date = new Date(dateArray[0], parseInt(dateArray[1] - 1), dateArray[2]);
  const weeks = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  return weeks[date.getDay()];
};
/**
 * 根据日期匹配月
 */
export const getMonth = (dateString) => {
  let date = new Date(dateString);
  var month = (date.getMonth());
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  return months[month];
};
/**
 * 根据日期字符返回28 April 2021 (Wed)
 * @param dateString 日期字符串（如：2022-01-25T17:00:00.000Z）
 * @returns {String}
 */
export const dateToStringTitle = (dateString) => {
  const str = dateString.split(' ');
  let date = new Date(str[0]);
  var year = date.getFullYear();
  var day = (date.getDate()).toString();

  if (day.length === 1) {
    day = "0" + day;
  }
  var dateTime = day + ' ' + getMonth(date) + ' ' + year + ' (' + getWeek(dateToString(dateString)) + ')';
  return dateTime;
};
export const format = function (Q) { return Q < 10 ? '0' + Q : Q };
/**
 * 根据日期返回时分
 */
export const getTime = (dateString) => {
  let date = new Date(dateString);
  console.log(date.getMinutes());
  return format(date.getHours()) + ':' + format(date.getMinutes());
};
/**
 * 根据日期字符返回yyyy-yy-dd
 * @param dateString 日期字符串（如：2022-01-25T17:00:00.000Z）
 * @returns {String}
 */
export const dateToString = (dateString) => {
  const str = dateString.split(' ');
  let date = new Date(str[0]);
  var year = date.getFullYear();
  var month = (date.getMonth() + 1).toString();
  var day = (date.getDate()).toString();
  if (month.length === 1) {
    month = "0" + month;
  }
  if (day.length === 1) {
    day = "0" + day;
  }
  var dateTime = year + "-" + month + "-" + day;
  return dateTime;
};

// 比较时间大小
export const compareDate = (date1, date2) => {
  var oDate1 = new Date(date1);
  var oDate2 = new Date(date2);
  if (oDate1.getTime() > oDate2.getTime()) {
    return true; //第一个大
  } else {
    return false; //第二个大
  }
};
// 动态计算页面位置坐标
export const getRectByCoords = (originWidth, originHeight, coords) => {
  let width = 0;
  let height = 0;
  let left = 0;
  let top = 0;
  const list = coords.split(',');
  if (list.length === 4) {
    let result = [];
    // 如果坐标反过来了需要调整位置
    if (Number(list[0]) > Number(list[2])) {
      result = [list[2], list[3], list[0], list[1]];
    } else {
      result = list;
    }
    result.map((v, i) => {
      if (i === 0) {
        left = Number(v) / originWidth;
      } else if (i === 1) {
        top = Number(v) / originHeight;
      } else if (i === 2) {
        width = (Number(v) - Number(result[0])) / originWidth;
      } else if (i === 3) {
        height = (Number(v) - Number(result[1])) / originHeight;
      }
    });
  }
  return {
    width: (width * 100).toFixed(2),
    height: (height * 100).toFixed(2),
    left: (left * 100).toFixed(2),
    top: (top * 100).toFixed(2)
  };
};
/**
 * 判断视频格式
 */
export const isMovie = (link) => {
  if (!link) {
    return false;
  }
  return link.indexOf('.mp4') !== -1;
};

export const getUuid = () => {
  var s = [];
  var hexDigits = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ"
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = "4";
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)
  s[8] = s[13] = s[18] = s[23] = "-";
  let uuid = s.join("");
  return uuid
};

export const formatMonthDay = date => {
  if (date === null || date === undefined) {
    return INVALID_DATE;
  }
  const str = date.split(' ');
  let dateString = str[0];
  return getDisplayedDate(day(dateString).format(DATE_FORMAT_MONTH_DATE_CHINESE));
};

export const formatDateChinese = date => {
  if (date === null || date === undefined) {
    return INVALID_DATE;
  }
  const str = date.split(' ');
  let dateString = str[0];
  return getDisplayedDate(day(dateString).format(DISPLAYED_DATE_FORMAT_YEAR));
};
// 获取时分
export const getHHmm = date => {
  if (date === null || date === undefined) {
    return INVALID_DATE;
  }
  return getDisplayedDate(day(date).format(DISPLAYED_HH_MM));
};
// 转换时区方法，dateStr：标准时间
export const formatTimezone = (dateStr, offset) => {
  if (!dateStr) {
    return '';
  }
  // 后台返回时间是0时区：2022-03-31T15:50:00.000Z,New Date会默认转为当前时区, 删除0Z，
  const dateStr2 = dateStr.replace('0Z', '');
  // 将传入的时间转换为new Date时间格式
  const toDate = new Date(dateStr2);
  // 距离返回 1970 年 1 月 1 日的毫秒数。
  var localTime = toDate.getTime();
  // 3600000 * Number(offset)：对应时区毫秒差值
  var korean = localTime + 3600000 * Number(offset);
  var nd = new Date(korean);
  console.log("时区 " + offset);  // Korean time is 
  console.log("转换前 " + dateStr2);  // Korean time is 
  console.log("转换后 " + day(nd).format('YYYY/MM/DD HH:mm'));  // Korean time is 
  return day(nd).format('YYYY/MM/DD HH:mm');
};
//统计进入当前页面事件
export const enterCurrentPage = () => {
  axios('enterPage', {
    data: {
      href: document.location.toString().replace(document.location.origin, '')
    }
  }).then((response) => {
    console.log('/Event/PageEvent', response);
  });
};
// 计算2个日期之间相隔的天数
export const getDaysBetween = (date1, date2) => {
  const startDate = Date.parse(date1);
  const endDate = Date.parse(date2);
  if (startDate > endDate) {
    return 0;
  }
  if (startDate == endDate) {
    return 1;
  }
  const days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
  return days;
};
// js 获取当前日期到之后n天的日期区间
export const getTwoDateRangeDate = (startday, days) => {
  var dateList = [];
  let startDate = new Date(startday);
  let endDate = new Date();
  endDate.setDate(startDate.getDate() + days);
  while ((endDate.getTime() - startDate.getTime()) >= 0) {
    let year = startDate.getFullYear();
    let month = (startDate.getMonth() + 1).toString().length === 1 ? "0" + (startDate.getMonth() + 1).toString() : (
      startDate.getMonth() + 1);
    let day = startDate.getDate().toString().length === 1 ? "0" + startDate.getDate() : startDate.getDate();
    dateList.push(year + "-" + month + "-" + day);
    startDate.setDate(startDate.getDate() + 1);
  }
  return dateList;
};
//计算半个小时以后的时间
export const get30MinLaterTime = (time) => {
  const y = new Date().getFullYear();
  const m = new Date().getMonth() + 1;
  const d = new Date().getDate();
  const ss = new Date(y + '/' + m + '/' + d + ' ' + time);
  const ee = new Date(ss.getTime() + 30 * 60 * 1000);
  const hours = new Date(ee).getHours();
  const min = new Date(ee).getMinutes();
  const result = (hours.toString().length > 1 ? hours : '0' + hours) + ':' + (min.toString().length > 1 ? min : '0' + min);
  return result === '00:00' ? '24:00' : result;
};
