import React, { Component } from 'react';
import './index.less';
import i18next from 'i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import './index.less';
import SpeakerList from './components/SpeakerList';
import axios from '../../network/axios/$axios';
import { enterCurrentPage } from '../../Utils/Util';
import BreadCrumbs from '../../components/BreadCrumbs';
import { LoadingOutlined } from '@ant-design/icons';
import Game from '../../game/Game';

class SocialLoungeScreen extends Component {
  constructor(props) {
    super();
    this.state = {
      speakers: [],
      loading: true
    };
  }
  getSpeakerByIds = () => {
    axios('getAllSpeaker', { params: {} })
      .then((response) => {
        const speakers = response.data.sort(function (a, b) { return a.seq - b.seq });
        this.setState({ speakers: speakers, loading: false })
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false })
      });
  };
  componentDidMount() {
    this.getSpeakerByIds();
    enterCurrentPage();
  }

  render() {
    const { speakers, loading } = this.state;
    const { menu, location, language } = this.props;
    const pathname = location.pathname;
    const arr = menu.filter(obj => obj.href === pathname);
    return (
      <div className="speakers-page page-container center">
        <Row gutter={[32, 32]}>
          <BreadCrumbs
            breadCrumbActive={arr.length && (arr[0].display_title)[language]}
          />
        </Row>
        <div className="title">{i18next.t('translations.speakers-title')}</div>
        {loading ? <div className='flex-center hp-py-16' style={{ backgroundColor: '#fff', borderRadius: '7px' }}>
          <LoadingOutlined />
          <span className='hp-mt-8'>{i18next.t('translations.loading')}</span>
        </div> : <SpeakerList data={speakers} />}
        <Game link='#/Speaker' />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  primary: state.profile.primary,
  language: state.profile.language,
  menu: state.profile.menu
});

const enhance = compose(withTranslation('translations'), connect(mapStateToProps, null));

export default enhance(SocialLoungeScreen);
