/*
 * @Description: 
 * @Version: 1.0
 * @Autor: ricky shen
 * @Date: 2022-02-10 15:14:41
 * @LastEditors: ricky shen
 * @LastEditTime: 2022-04-13 14:44:53
 */
import React, { Component } from 'react';
import './WebinarType.less';
import './TabPaneItem.less';
import i18next from 'i18next';
// import { Link } from 'react-router-dom'
import { Button, Row, Col } from 'antd';
// import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
// const hoverClass = {

// }
class WebinarType extends Component {
  // constructor(props) {
  //   super(props);
  //   // this.state = {
  //   //   currentType: 'All'
  //   // }
  // }
  componentDidMount() {
  }
  choose = (type) => {
    // this.setState({
    //   currentType: type
    // })
    // this.props.onwebTypeChange(type, true);
    this.props.onwebTypeChange(type);
  };
  render() {
    const { webtTpes, currentType, keyV } = this.props;
    // const { currentType } = this.state;
    return (
      <div className='tabCotent WebinarType'>
        <div className='webinar-type'>{i18next.t('translations.webinar-type')}</div>
        <div className='flex'>
          <Button key={keyV + 'All'} shape="round" className={'antBtnRound mL20 pB20 ' + (currentType === 'All' ? 'agendaBtn' : '')} size="large" onClick={this.choose.bind(this, 'All')}>{i18next.t('translations.all')}</Button>
          {webtTpes.map(type => {
            const classN = type === 'Live' ? 'cynBtn' : 'greenBtn';
            let hoverClass = '';
            if (type === 'Live' && currentType === 'Live') {
              hoverClass = 'cynBtnHover'
            }
            if (type === 'Recordings' && currentType === 'Recordings') {
              hoverClass = 'greenBtnHover'
            }
            // const hoverClass = currentType === 'Live' ? 'cynBtnHover' : 'greenBtnHover';
            return <Button onClick={this.choose.bind(this, type)} shape="round" className={'antBtnRound mL20 pB20 ' + classN + ' ' + hoverClass} size="large" key={type + keyV}>{type}</Button>
          })}
        </div>
      </div>
    );
  }
}

export default WebinarType;
