import React, { Component } from 'react';
import { Breadcrumb } from 'antd';
import './index.less';
import CardList from './components/CardList';
import { connect } from 'react-redux';
import { getProductList } from '../../actions/ProductAction';
import { SearchOutlined } from '@ant-design/icons';

class ProductListScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { getProductList } = this.props;
    getProductList(1);
  }

  getListData = (page) => {
    const { getProductList } = this.props;
    getProductList(page);
  };

  render() {
    const { productData } = this.props;
    return (
      <div className="content-view">
        <Breadcrumb separator=">" className="breadcrumb">
          <Breadcrumb.Item>Lobby</Breadcrumb.Item>
          <Breadcrumb.Item>Products</Breadcrumb.Item>
        </Breadcrumb>
        <div className="content">
          <CardList
            data={productData.data}
            total={productData.total}
            getListData={this.getListData}
            {...this.props}
          />
        </div>
        <div
          onClick={() => this.props.history.push('/product-search')}
          className="search-btn"
        >
          <SearchOutlined style={{ color: 'white', fontSize: '28px' }} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  productData: state.product.productData
});

const mapDispatchToProps = (dispatch) => ({
  getProductList: (page) => {
    dispatch(getProductList(page));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductListScreen);
