import React, { Component } from 'react';
import './Room.less';
let callFrame = null;

class Room extends Component {
  constructor(props) {
    super();
  }

  componentDidMount() {
    const { link, username, onClosed, onJoined } = this.props;
    const container = document.querySelector("#daily_container");
    const properties = {
      iframeStyle: {
        position: 'fixed',
        border: '1px solid black',
        width: '25%',
        height: '100%',
        top: '',
        left: '',
        right: 0,
        bottom: ''
      },
      showLeaveButton: true,
      showFullscreenButton: true
    };
    callFrame = window.DailyIframe.createFrame(container, properties);
    // 加入room
    callFrame.join({
      url: link,
      userName: username
    });
    // 监听离开room事件
    callFrame.on('left-meeting', (event) => {
      console.log('left meeting', event);
      callFrame.destroy();
      onClosed();
    });
    // 监听加入room成功
    callFrame.on('joined-meeting', (event) => {
      console.log('joined-meeting', event);
      onJoined();
    });
    // 监听room新加入人
    callFrame.on('participant-joined', (event) => {
      console.log('participant-joined', event);
    });
    // 监听room有人离开
    callFrame.on('participant-left', (event) => {
      console.log('participant-left', event);
    });
  }
  // 关闭视频窗口
  onClosed = () => {
    const { onClosed } = this.props;
    callFrame.destroy();
    onClosed();
  };

  render() {
    return (
      <div className="room" id="daily_container">
        <span className="close pointer" onClick={() => this.onClosed()}>close</span>
      </div>
    );
  }
}

export default Room;
