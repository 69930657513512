import React, { useMemo, useState } from "react";

import {
  Avatar,
  Badge,
  Button,
  Card,
  CardHeader,
  Fade,
  Paper,
  Popper,
  Typography,
  Grid,
  IconButton as MIconButton
} from "@material-ui/core";
import { Boop } from '../CustomChatEngineV2/components/ChatEngine/components/Effects'
// import { Avatar } from '../CustomChatEngineV2/components/ChatEngine/components/components/Avatar'
import { Col,Container,Row  } from 'react-grid-system'
import { MoreVert as MoreVertIcon } from "@material-ui/icons";
import { makeStyles, withStyles } from "@material-ui/styles";
import useSendbirdStateContext from "@sendbird/uikit-react/useSendbirdStateContext";
import sendBirdSelectors from "@sendbird/uikit-react/sendbirdSelectors";
import { u as useLocalization } from '@sendbird/uikit-react/LocalizationContext-a21fd99c.js';


import { getLastMessageCreatedAt } from "./utils";



const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 22,
    height: 22,
    border: `2px solid white`
  }
}))(Avatar);
const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: "rgba(25, 118, 210, 0.2)"
  }
}));

export default function CustomizedChannelPreviewItem(props) {
  const classes = useStyles();
  const { dateLocale, stringSet } = useLocalization();

  const store = useSendbirdStateContext();
  const onLeaveChannel = sendBirdSelectors.getLeaveGroupChannel(store);

  // props
  const { userId, channel, currentChannelUrl } = props;
  const { name } = channel;
  const channelName = name;
  const { lastMessage } = channel;

  //useState
  const [openLeaveChannel, setOpenLeaveChannel] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  // useMemo
  const isCurrentChannel = useMemo(() => {
    return channel.url === currentChannelUrl;
  }, [currentChannelUrl, channel.url]);

  const channelAvatar = useMemo(() => {
    // if (channel.coverUrl) {
    //   return <Avatar src={channel.coverUrl} />;
    // }
    const { members } = channel;
    const membersExcludingMe = members.filter(
      (member) => member.userId !== userId
    );
    const [firstMember, secondMember] = membersExcludingMe;
    return (
      <Badge
        overlap="circle"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        badgeContent={
          membersExcludingMe.length > 1 && (
            <SmallAvatar
              alt="second member"
              src={secondMember && secondMember.profileUrl}
            />
          )
        }
      >
        
        <Avatar >{firstMember ? firstMember.nickname.substring(0, 2).toUpperCase() : ''}</Avatar>
      </Badge>
    );
  }, [userId, channel]);

  const channelSubTitle = useMemo(() => {
    const { lastMessage } = channel;
    if(lastMessage ==null){
        return <div/>
    }
    return (
        
        <>
        <Grid container justify="space-between">  
            <Typography inline variant="caption" align="left">
                {(lastMessage.messageType === "admin" ||
                    lastMessage.messageType === "user") &&
                    (lastMessage.message.length < 20
                        ? lastMessage.message
                        : `${lastMessage.message.slice(0, 20)}...`)}
                {lastMessage.messageType === "file" &&
                    (lastMessage.url.length < 30
                        ? lastMessage.url
                        : `${lastMessage.url.slice(0, 30)}...`)}
            </Typography>
            <Typography inline variant="caption" align="right">
            {getLastMessageCreatedAt(channel, dateLocale)}
            </Typography>
        </Grid>
            
            </>
    );
  }, [lastMessage]);


  const channelTitle = useMemo(() => {
    // if (channelName) {
    //   return channelName;
    // }
    
    
    const membersNamesExcludingMe = channel.members
      .filter((member) => member.userId !== userId)
      .map((member) => member.nickname || member.userId)
      .join(", ");
    return membersNamesExcludingMe.length < 30
      ? membersNamesExcludingMe.length === 0
        ? "(No members)"
        : membersNamesExcludingMe
      : `${membersNamesExcludingMe.slice(0, 30)}...`;
  }, [userId, channel, channelName]);

  // channel type filter
  if (
    channel.isOpenChannel() ||
    channel.isFrozen ||
    channel.isEphemeral ||
    channel.isSuper ||
    channel.isBroadcast
  ) {
    return null;
  }


  return (
    <div className="customized-channelpreview-item">
      <Card className={isCurrentChannel ? classes.selected : "" } square>
        <CardHeader
          avatar={channelAvatar}
          title={channelTitle}
          subheader={
            lastMessage && (channelSubTitle
            )
          }
        />
      </Card>
    </div>
  );
}
const styles={
    chatListContainer: { 
        height: '100%', 
        width: "100%",
        maxHeight: '100vh', 
        overflow: 'scroll', 
        overflowX: 'hidden',
        borderRight: '1px solid #DFE6E9', 
        backgroundColor: 'white',
        fontFamily: 'Avenir',
        overflowX: "hidden",
        overflowY: "scroll",
    },
    chatsContainer: { 
        width: '100%', 
        height: '100%',
        backgroundColor: 'white', 
        borderRadius: '0px 0px 24px 24px',
        
    },
    chatContainer: { 
        padding: "5px 15px 5px 15px",
        // padding: '8px', 
        // paddingLeft: '4px',
        cursor: 'pointer',
        borderBottom: '1px solid #DFE6E9'
    },
    titleText: { 
        fontSize: '16px', 
        paddingBottom: '4px', 
        whiteSpace: 'nowrap', 
        overflow: 'hidden' 
    },
    messageText: {
        width: '75%',
        color: 'rgba(99,110,114, 1)', 
        fontSize: '12px', 
        whiteSpace: 'nowrap', 
        overflow: 'hidden',
        display: 'inline-block'
    },
    activeChat: {
        backgroundColor: '#F7FAFC',
        border: '0px solid white',
    },
}
