import React from 'react';
import { selected2dOr3dModel } from '../../../actions/ProfileAction';
import { Switch, Col } from 'antd';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

function HeaderSwitch(props) {
  const { selectedModel, selected2dOr3dModel } = props;
  const threeRoutes = [
    '/Landing3d',
    '/Lobby3d',
    '/ExpoHall3d',
    '/SocialLounge3d'
  ];
  const nomalRoutes = ['/Landing', '/Lobby', '/ExpoHall', '/SocialLounge'];

  const location = useLocation();

  const onSwitchChange = (value) => {
    selected2dOr3dModel(value ? '3d' : '2d');
    if (value) {
      // 切换到3D模式
      if (nomalRoutes.indexOf(location.pathname) > -1) {
        window.location.href = window.location.href + '3d';
      }
    } else {
      // 切换到2D模式
      if (threeRoutes.indexOf(location.pathname) > -1) {
        let str = window.location.href;
        str = str.replace('3d', '');
        window.location.href = str;
      }
    }
  };

  return (
    <Col className="hp-languages hp-mr-8">
      {/* <Switch checkedChildren="3D" unCheckedChildren="2D" checked={selectedModel === '3d'} onChange={onSwitchChange} /> */}
    </Col>
  );
}

const mapStateToProps = (state) => ({
  selectedModel: state.profile.selectedModel
});

const mapDispatchToProps = (dispatch) => ({
  selected2dOr3dModel: (model) => {
    dispatch(selected2dOr3dModel(model));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderSwitch);
