
import S3 from 'aws-sdk/clients/s3';
import https from "https"
// const OSSURL = 'https://vp-dev.chefdigital.io'
const ossClient = new S3({
    accessKeyId: "AKIAU3VOTTKPGQOPGB75",
    secretAccessKey: "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",
    region: "ap-east-1"
});
const S3BUCKET = "chef-metaverse-s3";

export const fetchBlob = (url) => {
    return fetch(url).then(res => res.blob())
}

export const uploadToS3 = async (link, name) => {
    try {
        const currBlob = await fetchBlob(link)
        const result = await new Promise((resolve, reject) => {
            ossClient.upload({
                Bucket: S3BUCKET,
                Key: `upload/usermodel/${name}.glb`,
                Body: new File([currBlob], `/${name}.glb`)
            }, { partSize: 30 * 1024 * 1024, queueSize: 4 }, (err, data) => {
                resolve(data)
            });
        });
        return `https://watsons-demo.chefdigital.io/upload/usermodel/${name}.glb`;
    } catch (e) {
        console.log('error', e)
    }
}