import React, { Component } from 'react';
import { List, Pagination, Input, Row, Card } from 'antd';
import './SearchList.less';
import productImg from '../../../Image/product/product-img.png';
const { Search } = Input;

class SearchList extends Component {
  clickItem(item) {
    this.props.history.push({
      pathname: '/product-detail/' + item.id,
      state: { params: item }
    });
  }

  render() {
    const { onChange, onSearch, data, total } = this.props;
    return (
      <div className="product-area">
        <div className="search">
          <Search
            bordered={false}
            placeholder="Enter a keyword to search"
            onSearch={() => onSearch(1)}
            onChange={(e) => onChange(e.target.value)}
          />
          <div className="search-line" />
        </div>
        <div className="search-title">
          <span>Quick Links: Products Highlight</span>
        </div>
        <div className="product-card">
          <List
            grid={{
              gutter: [8, 16],
              xs: 1,
              sm: 2,
              md: 4
            }}
            dataSource={data}
            renderItem={(item) => (
              <List.Item key={item.id}>
                <Card
                  hoverable
                  bordered={false}
                  bodyStyle={{ padding: 0 }}
                  onClick={() => this.clickItem(item)}
                >
                  <div className="product-list">
                    <Row>
                      <div className="status-area">
                        <span className="status-text">{item.status}</span>
                      </div>
                      <img
                        className="product-img"
                        alt=""
                        src={
                          item.media.length ? item.media[0].link : productImg
                        }
                      />
                    </Row>
                    <Row className="product-title">
                      <span className="span">{item.name.en}</span>
                    </Row>
                    <Row className="product-des">
                      <span className="span">{item.short_description.en}</span>
                    </Row>
                  </div>
                </Card>
              </List.Item>
            )}
          />
        </div>
        <div className="pagination">
          <Pagination
            showSizeChanger={false}
            className="page-item"
            defaultCurrent={1}
            total={total}
            pageSize={12}
            onChange={(page) => onSearch(page)}
          />
        </div>
      </div>
    );
  }
}

export default SearchList;
