import React, { createRef, useEffect, useState } from 'react';

import { Row, Col, Rate } from 'antd';
import './SwiperList.less';
import arrowLeft from '../../../Image/product/arrow-left.png';
// import { OTHER_PRODUCT_LIST } from '../../product/constants';
import arrowRight from '../../../Image/product/arrow-right.png';
import {
  StarOutlined,
  WechatOutlined,
  ScheduleOutlined,
  EnvironmentOutlined
} from '@ant-design/icons';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';
import noProfilePic from '../../../Image/no-profile-pic.png';
import i18next from 'i18next';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import axios from '../../../network/axios/$axios';
import get from 'lodash/get';
import { connect } from 'react-redux';
// chat-use
import { CHAT_SWITCH } from '../../../actions/LoginUserAction'
import ChatMessage from '../../../components/ChatMessage';

const SwiperList = (props) => {
  const { onClickMeet, primary, onChange, visitors, selectedRole, searchType, keyword } = props;
  const [allData, setAllData] = useState([]);
  const [peoples, setPeoples] = useState([]);
  // chat-use
  const [channel_url, setChannelURL] = useState('');

  const slider = createRef();
  const clickLeftAction = () => {
    slider.current.slickPrev();
  };

  const clickRightAction = () => {
    slider.current.slickNext();
  };

  useEffect(() => {
    peoplerecommendation();
  }, []);

  useEffect(() => {
    if (selectedRole !== '') {
      const list = allData.filter(obj => obj.role === selectedRole);
      if (keyword !== '') {
        searchByKeyword(list);
      } else {
        setPeoples(list);
      }
    } else {
      if (keyword !== '') {
        searchByKeyword();
      } else {
        setPeoples(allData);
      }
    }
  }, [selectedRole, searchType, keyword]);

  const searchByKeyword = (arr) => {
    const datas = arr ? arr : allData;
    let list = [];
    if (searchType === 'all') {
      list = datas.filter(obj => (obj.info.firstname && obj.info.lastname && (obj.info.firstname + obj.info.lastname).indexOf(keyword) > -1) || (obj.info.jobTitle && obj.info.jobTitle.indexOf(keyword) > -1) || (obj.info.CompanyName && obj.info.CompanyName.indexOf(keyword) > -1) || (obj.info.CountryId && obj.info.CountryId.indexOf(keyword) > -1));
    } else if (searchType === 'name') {
      list = datas.filter(obj => (obj.info.firstname && obj.info.lastname && (obj.info.firstname + obj.info.lastname).indexOf(keyword) > -1));
    } else if (searchType === 'job') {
      list = datas.filter(obj => (obj.info.jobTitle && obj.info.jobTitle.indexOf(keyword) > -1));
    } else if (searchType === 'company') {
      list = datas.filter(obj => (obj.info.CompanyName && obj.info.CompanyName.indexOf(keyword) > -1));
    } else if (searchType === 'country') {
      list = datas.filter(obj => (obj.info.CountryId && obj.info.CountryId.indexOf(keyword) > -1));
    }
    setPeoples(list);
  };
  // 轮播数据
  const peoplerecommendation = () => {
    axios('peoplerecommendation', {})
      .then((response) => {
        // console.log('peoplerecommendation', response);
        setPeoples(response.data);
        setAllData(response.data);
      });
  };
  // chat-use
  const getOrCreatePrivateChat = (person) => {
    chatSwitch(false)
    axios('getOrCreatePrivateChat', {
      data: {
        target_user_id: person._id
      }
    })
      .then((response) => {
        if (response == "") {
          const timeout = setTimeout(() => {
            axios('getOrCreatePrivateChat', {
              data: {
                target_user_id: person._id
              }
            }).then((response2) => {
              setChannelURL(response2.channel_url);
              props.dispatchChatSwitch(true);
            });
          }, 500)
        } else {
          setChannelURL(response.channel_url);
          props.dispatchChatSwitch(true);
        }
      });
  };
  const chatSwitch = (swith) => {
    props.dispatchChatSwitch(false);
  }

  const renderChildrenView = (list, index) => {
    return (
      <div key={index}>
        <Row>
          {list.map((obj, i) => {
            const arr = visitors.filter(v => v.refId === obj._id);
            const isSelected = arr.length > 0;
            return (
              <Col
                xs={24}
                md={11}
                key={index * 2 + i}
                className={
                  i === 1 ? 'list-content' : 'list-content swiper-cell'
                }
                style={{ marginLeft: i === 0 ? '2rem' : '0rem' }}
              >
                {!get(obj, 'info.CompanyName') ? (
                  <Row style={{ height: '1.5rem' }} />
                ) : (
                  <Row>
                    <div className="type" style={{ backgroundColor: primary }}>
                      {/* <span>eClinics</span> */}
                      <span style={{ fontSize: 12 }}>{get(obj, 'info.CompanyName')}</span>
                    </div>
                  </Row>
                )}
                <Row style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                  <Col md={4}>
                    <img className="user" src={noProfilePic} />
                  </Col>
                  <Col md={12} style={{ textAlign: 'left' }}>
                    <div className="username">
                      {/* <span>Wai Man Peter Wong</span> */}
                      <span>{get(obj, 'info.lastname')}{get(obj, 'info.firstname')}</span>
                    </div>
                    <div className="">
                      <div className="description">
                        {/* <span>Standard Chartered Bank</span> */}
                        <span>{get(obj, 'info.jobTitle')}</span>
                      </div>
                      {/* <div>
                        <span>ED</span>
                      </div> */}
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className="flex flex_a_c flex_j_c type contact-type">
                      <EnvironmentOutlined
                        style={{
                          color: '#ffffff',
                          marginRight: '1rem'
                        }}
                      />
                      <span>
                        {/* {index === 2
                          ? i18next.t('translations.visitor')
                          : i18next.t('translations.exhibitor')} */}
                        {get(obj, 'role')}
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col md={7} style={{ textAlign: 'center' }}>
                    <div
                      className="pointer flex flex_d_c flex_a_c"
                    >
                      <Rate style={{ width: '3rem', marginTop: '-15px' }} onChange={(e) => onChange(obj, arr, e)} count="1" value={isSelected ? 1 : 0} />
                      <span style={{ marginTop: '-10px' }}>{i18next.t('translations.bookmark')}</span>
                    </div>
                  </Col>
                  {get(obj, 'role') === 'Visitor' && <Col md={10} style={{ textAlign: 'center' }}>
                    <div
                      onClick={() => onClickMeet()}
                      className="pointer flex flex_d_c"
                    >
                      <ScheduleOutlined />
                      <span>{i18next.t('translations.meet')}</span>
                    </div>
                  </Col>}
                  <Col md={7} style={{ textAlign: 'center' }}>
                    <div onClick={() => { getOrCreatePrivateChat(obj) }} className="pointer flex flex_d_c">
                      <WechatOutlined />
                      <div className="f16">
                        {i18next.t('translations.chat')}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  };

  const renderSliderView = () => {
    const ArrowLeft = (props) => (
      <img
        onClick={() => clickLeftAction()}
        className="arrow-left"
        alt=""
        src={arrowLeft}
      />
    );
    const ArrowRight = (props) => (
      <img
        onClick={() => clickRightAction()}
        className="arrow-right"
        alt=""
        src={arrowRight}
      />
    );
    const settings = {
      autoplay: false,
      dots: false,
      infinite: true,
      speed: 500,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      prevArrow: <ArrowLeft />,
      nextArrow: <ArrowRight />
    };
    // console.log('peoples', peoples);
    const list = splitArr(peoples, 2);
    // console.log('peoples', list);
    if (list && list.length > 0) {
      return (
        <Slider ref={slider} className="recommend-contact-list" {...settings}>
          {list.map((item, index) => renderChildrenView(item, index))}
        </Slider>
      );
    }
    return '';
  };

  const splitArr = (arr, num) => {
    //arr是你要分割的数组，num是以几个为一组
    let newArr = []; //首先创建一个新的空数组。用来存放分割好的数组
    for (let i = 0; i < arr.length;) {
      //注意：这里与for循环不太一样的是，没有i++
      newArr.push(arr.slice(i, (i += num)));
    }
    return newArr;
  };

  return (
    <div className="swiper-list-page">
      <div>
        <Row justify="space-between">
          <span className="title">Recommendations for you</span>
          <Link className="title" to="/Contact/SeeAllRecommendationMatching">
            See all
          </Link>
        </Row>
        <Row align="middle" className="other-product-area">
          {renderSliderView()}
        </Row>
      </div>
      {(chatSwitch) && <ChatMessage selected_channel_url={channel_url} close={() => chatSwitch(false)} />}
    </div>
  );
};

const enhance = compose(withTranslation('translations'));
// chat-use
const mapStateToProps = (state) => ({
  // primary: state.profile.primary,
  // language: state.profile.language,
  chatSwitch: state.loginUserInfo.chatSwitch,
});
const mapDispatchToProps = (dispatch) => ({
  dispatchChatSwitch: (flag) => {
    dispatch(CHAT_SWITCH(flag));
  }
});
// export default enhance(SwiperList);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(enhance(SwiperList));
