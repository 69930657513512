import React, { useEffect, useState } from 'react';
import './SingleTimeList.less';
import { getSingleDateArray } from '../../../Utils/Util';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import unClickIcon from '../../../Image/ico-unable.png';
import { isEmpty } from 'lodash';
import { saveSelectedTabKey } from '../../../actions/BusinessMatchingAction';
import { connect } from 'react-redux';

const SingleTimeList = (props) => {
  const {
    date,
    start_time,
    end_time,
    selectedDate,
    onSelect,
    selectAllItem,
    refresh,
    primary,
    unavailableList,
    meetings,
    selectTab,
    dateList,
    saveSelectedTabKey
  } = props;
  const [data, setData] = useState({});
  const [selectDate, setStateDate] = useState();
  const openTime = [`${start_time} - ${end_time}`];
  const lists = getSingleDateArray(openTime);

  useEffect(() => {
    const result = {};
    // 根据日期遍历数据
    dateList.map(date => {
      let list = lists;
      if (selectTab === 1) {
        list = list.filter(v => unavailableList.indexOf(dayjs(date + ' ' + v).unix()) < 0)
      } else if (selectTab === 2) {
        list = list.filter(v => meetings.indexOf(dayjs(date + ' ' + v).unix()) > -1)
      }
      const arr = [];
      list.forEach((item, index) => {
        const dateTime = date + ' ' + item;
        const dateUnix = dayjs(dateTime).unix();
        const currrentUnix = dayjs().unix();
        arr.push({
          time: item,
          disabled: unavailableList.indexOf(dateUnix) > -1, // 不可用但是可以点击
          existed: meetings.indexOf(dateUnix) > -1, // 已经存在的会议不可点击
          pastTime: dateUnix <= currrentUnix // 过去的时间
        });
      });
      result[date] = arr;
    })
    setData(result);
  }, [date, end_time, start_time, refresh, selectedDate, unavailableList, selectTab, dateList]);

  // 选择日期
  const onSelectHandle = (date) => {
    setStateDate(date);
  }
  // 选择时间段
  const onClickItem = (date, item, allDisabled) => {
    if (item.time === 'All-Day' && allDisabled) {
      // 全部可用
      selectAllItem(date, lists, allDisabled);
    } else if (item.time === 'All-Day' && !allDisabled) {
      // 全部不可用
      selectAllItem(date, lists, allDisabled);
    } else if (!item.existed && !item.pastTime && !allDisabled) {
      onSelect(date, item.time);
    }
  }

  const goMyMeeting = () => {
    saveSelectedTabKey('2');
  }

  const list = !isEmpty(data) ? [{ time: 'All-Day', disabled: false, existed: false }, ...data[date]] : [];
  return (
    <div className="single-time-list">
      <div className='left-view'>
        {list.map((item, index) => (
          <div key={item.time + index} className='item flex-row-center'>
            {item.time}
          </div>
        ))}
      </div>
      <div className='item-list'>
        {dateList.map((obj, i) => {
          const arr = obj.split('-');
          const str = arr[2] + '/' + arr[1] + '/' + arr[0];
          const items = !isEmpty(data) ? data[obj] : [];
          const infos = items.filter(v => v.disabled);
          let allDisabled = false;
          if (infos.length === items.length) {
            allDisabled = true;
          }
          const itemss = [{ time: 'All-Day', disabled: allDisabled, existed: false, pastTime: false }, ...items];
          return (
            <div>
              <div key={i} className="cloumn pointer flex-center" onClick={() => onSelectHandle(obj)}>
                <span style={{ color: selectDate === obj ? primary : '' }}>{str}</span>
              </div>
              {itemss.map((item) => {
                if (item.existed) {
                  return (
                    <div key={item.time} className='cell pointer flex-center' onClick={() => goMyMeeting()}>
                      <span className="remix-icon f18">
                        <i className="ri-calendar-2-fill" style={{ color: '#019EAF' }} />
                      </span>
                    </div>
                  )
                }
                return (
                  <div key={item.time} style={(item.pastTime || allDisabled) && item.time !== 'All-Day' ? { backgroundColor: '#F6F9FB', cursor: 'not-allowed' } : {}} className='cell pointer flex-center' onClick={() => onClickItem(obj, item, allDisabled)}>
                    {(item.disabled || item.pastTime) && <img className='unclik-icon' src={unClickIcon} />}
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  saveSelectedTabKey: (key) => {
    dispatch(saveSelectedTabKey(key));
  }
});

const enhance = compose(withTranslation('translations'), connect(null, mapDispatchToProps));

export default enhance(SingleTimeList);
