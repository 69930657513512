import storage from 'redux-persist/es/storage';
import { persistReducer } from 'redux-persist';
import product from './product';
import loginUserInfo from './loginUserInfo';
import Agenda from './Agenda';
import businessMatching from './BusinessMatching';
import briefcases from './briefcases';
import profile from './profile';

const productConfig = {
  key: 'product',
  storage,
  debug: false
  //blacklist: []
};
const loginUserInfoConfig = {
  key: 'loginUserInfo',
  storage,
  debug: false
  //blacklist: []
};
const AgendaConfig = {
  key: 'Agenda',
  storage,
  debug: false
  //blacklist: []
};
const BusinessMatchingConfig = {
  key: 'businessMatching',
  storage,
  debug: false,
  blacklist: []
};
const BriefcasesConfig = {
  key: 'briefcases',
  storage,
  debug: false,
  blacklist: []
};
const ProfileConfig = {
  key: 'profile',
  storage,
  debug: false,
  blacklist: []
};
const reducers = {
  loginUserInfo: persistReducer(loginUserInfoConfig, loginUserInfo),
  product: persistReducer(productConfig, product),
  Agenda: persistReducer(AgendaConfig, Agenda),
  businessMatching: persistReducer(BusinessMatchingConfig, businessMatching),
  briefcases: persistReducer(BriefcasesConfig, briefcases),
  profile: persistReducer(ProfileConfig, profile)
};

export default reducers;
