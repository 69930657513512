import { Link } from "react-router-dom";
import { Col, Breadcrumb } from "antd";
import './BreadCrumbs.less';
import i18next from 'i18next';
import { connect } from "react-redux";


const BreadCrumbs = (props) => {
  const {
    breadCrumbParent,
    breadCrumbParent2,
    breadCrumbParent3,
    breadCrumbActive,
    language,
    menu,
    color,
    selectedModel,
  } = props;
  const nomalRoutes = ['/Landing', '/Lobby', '/ExpoHall', '/Gallery', '/SocialLounge'];
  const arr = menu.filter(obj => obj.display_title[language] === breadCrumbParent);
  let href = `${arr.length ? arr[0].href : ''}`;
  if (selectedModel === '3d' && nomalRoutes.indexOf(href) > -1) {
    href = href + '3D';
  }

  return (
    <Col className="bread-crumbs">
      <Breadcrumb separator="/" className="hp-d-flex hp-flex-wrap hp-mt-16">
        <Breadcrumb.Item>
          <Link to={selectedModel === '3d' ? '/Landing3D' : '/Landing'} style={{ color, fontSize: '13px' }}>{i18next.t('translations.home')}</Link>
        </Breadcrumb.Item>

        {breadCrumbParent && (
          <Breadcrumb.Item>
            <Link to={breadCrumbParent === 'MeetingManagement' ? '/MeetingManagement' : href} style={{ color, fontSize: '13px' }}>{breadCrumbParent}</Link>
          </Breadcrumb.Item>
        )}

        {breadCrumbParent2 && (
          <Breadcrumb.Item style={{ color, fontSize: '13px' }}>{breadCrumbParent2}</Breadcrumb.Item>
        )}

        {breadCrumbParent3 && (
          <Breadcrumb.Item style={{ color, fontSize: '13px' }}>{breadCrumbParent3}</Breadcrumb.Item>
        )}

        <Breadcrumb.Item style={{ color, fontSize: '13px' }}>{breadCrumbActive}</Breadcrumb.Item>
      </Breadcrumb>
    </Col>
  );
};

const mapStateToProps = (state) => ({
  language: state.profile.language,
  menu: state.profile.menu,
  selectedModel: state.profile.selectedModel,
});

export default connect(
  mapStateToProps,
  null
)(BreadCrumbs);