import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from '../network/axios/$axios';
import ClickArea from '../components/ClickArea';
import get from 'lodash/get';
import Zoom from '../components/Zoom';
import { CloseOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { isMovie, getUuid, format, enterCurrentPage } from '../Utils/Util';
import { autoRegisterFetch, getUserDetail } from '../actions/LoginUserAction';
import LandingCSS from './Landing2.module.css';
import VirtualBooth from './VirtualBooth';
const JWT = require('jsonwebtoken');

class Landing3d extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detail: {},
      isMp4: false,
      isMp4Landing: false,
      visiable: false,
      enableComingSoon: false,
      isTimer: false, // 倒计时
      day: '00',
      hour: '00',
      minute: '00',
      seconds: '00',
      url: '',
      fetching: true
    };
  }
  openModal = () => {
    this.setState({
      visiable: true
    });
  };

  handleCancel = () => {
    this.setState({
      visiable: false
    });
  };
  componentDidMount() {
    setTimeout(() => {
      this.getUserDetail();
    }, 1500);
    // 1.从缓存获取token
    const token = localStorage.getItem('token');
    // 2.从url链接获取是否携带jwt信息
    const url = document.location.toString();
    const arrObj = url.split('?');
    let jwt = null;
    if (arrObj.length > 1) {
      const arrPara = arrObj[1].split('&');
      let arr = [];
      for (let i = 0; i < arrPara.length; i++) {
        arr = arrPara[i].split('=');
        if (arr !== null && (arr[0] === 'token' || arr[0] === 'jwt')) {
          localStorage.setItem('token', arr[1]);
          jwt = arr[1];
          this.getUserId(jwt);
        }
      }
    }
    axios('LandingPage').then((response) => {
      console.log('/landing-page', response);
      this.setState({ detail: response.data[0].data });
      if (!token && !jwt) {
        this.isCheckGuestLogin(response.data[0].data);
      }
      this.isCheckComingSoon(response.data[0].data);
      // comingSoon状态下
      if (response.data[0].data.comingSoon.background.src.indexOf('.mp4') !== -1) {
        // 视频播放器
        this.setState({ isMp4: true });
      }
      // 正常数据下
      if (response.data[0].data.background.src.indexOf('.mp4') !== -1) {
        // 视频播放器
        this.setState({ isMp4Landing: true });
      }
    });
    enterCurrentPage();
  }
  // 判断是否允许Guest Login,如果允许创建一个游客账户
  isCheckGuestLogin = (data) => {
    if (data.enableGuestLogin) {
      // 注册游客账户
      const { autoRegisterFetch, language } = this.props;
      const values = {
        id: getUuid(),
        pass: '123456',
        language
      };
      autoRegisterFetch(values);
    } else {
      if (process.env.NODE_ENV === 'development') {
        window.location.href = '/';
      } else {
        window.location.href = process.env.REACT_APP_HOME_PAGE;
      }
    }
  };
  // 判断是否允许coming Soon,如果允许显示comingSoon里边的信息,隐藏header/footer/nav
  isCheckComingSoon = (data) => {
    const startTime = new Date(data.comingSoon.timer);
    const nowTime = new Date();
    const time = startTime - nowTime;
    if (data.enableComingSoon && data.comingSoon.enableTimer && time > 0) {
      this.setState({ isTimer: true });
      this.timerCount(data.comingSoon.timer, data);
    }
    if (data.enableComingSoon) {
      this.setState({ enableComingSoon: true, detail: data.comingSoon });
    }
  };
  // 判断是否允许注册
  isCheckEnableRegistration = (enableRegistration) => {
    if (enableRegistration) {
      this.props.history.push('/register');
    }
  };
  timerCount = (time, data) => {
    const starttime = new Date(time);
    const timerFn = () => {
      const nowtime = new Date();
      const time = starttime - nowtime;
      const day = format(parseInt(time / 1000 / 60 / 60 / 24)); //天
      const hour = format(parseInt((time / 1000 / 60 / 60) % 24)); //小时
      const minute = format(parseInt((time / 1000 / 60) % 60)); //分钟
      const seconds = format(parseInt((time / 1000) % 60)); //秒
      this.setState({
        day,
        hour,
        minute,
        seconds
      });
      if (day <= 0 && hour <= 0 && minute <= 0 && seconds <= 0) {
        // 倒计时结束重载页面
        this.setState({ enableComingSoon: false, detail: data });
        clearInterval(sj);
      }
    };
    var sj = setInterval(timerFn, 1000);
  };
  //获取用户id,根据用户id获取用户信息
  getUserId = (token) => {
    const { getUserDetail } = this.props;
    const decoded = JWT.decode(token);
    getUserDetail({ _id: decoded._id });
  };
  // 获取用户信息
  getUserDetail = () => {
    const { userInfo } = this.props;
    axios('getUserDetail', { path: userInfo._id })
      .then((response) => {
        console.log('/visitors=', response);
        this.setState({ url: response['3DUrl'], fetching: false })
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const {
      background,
      links,
      plane,
      pedestrian,
      enableRegistration,
    } = this.state.detail;
    const { isMp4, isMp4Landing, visiable, enableComingSoon, day, hour, minute, seconds, isTimer, url, fetching } =
      this.state;
    if (enableComingSoon) {
      return (
        <div className={LandingCSS.fullScreen}>
          <div
            id="animation_container"
            className={LandingCSS.baseSize1 + ' ' + LandingCSS.landing}
          >
            {!isMp4 ? (
              <a
                onClick={() =>
                  this.isCheckEnableRegistration(enableRegistration)
                }
                title={background.title}
              >
                <img src={background.src} alt="" className={LandingCSS.bgImg} />
              </a>
            ) : (
              <video
                autoPlay
                playsInline //解决手机端无法自动播放功能
                loop
                className="video"
                src={background.src}
                style={{
                  width: '100%',
                  height: '100%'
                }}
              />
            )}
            {isTimer && <div className={LandingCSS.timer}>
              <span>{day}</span>天<span>{hour}</span>:<span>{minute}</span>:
              <span>{seconds}</span>
            </div>}
          </div>
        </div>
      );
    }
    if (fetching) {
      return (
        <div className="loading"><Spin /></div>
      )
    }
    return (
      <div className={LandingCSS.landing}>
        <VirtualBooth url={url} links={links} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  primary: state.profile.primary,
  language: state.profile.language,
  token: state.loginUserInfo.token,
  userInfo: state.loginUserInfo.userInfo
});

const mapDispatchToProps = (dispatch) => ({
  autoRegisterFetch: (data) => {
    dispatch(autoRegisterFetch(data));
  },
  getUserDetail: (data) => {
    dispatch(getUserDetail(data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Landing3d);
