import { Button, Divider, Dropdown, Input, Menu, Modal, Row } from 'antd';
import './ScheduleMeetingModal.less';
import React, { Component } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import apiAxios from '../../../network/axios/$axios';
import dayjs from 'dayjs';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import './MeetingDetailModal.less';
import { get30MinLaterTime } from '../../../Utils/Util';
import { get } from 'lodash';
const { TextArea } = Input;

class MeetingDetailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valueMessage: '',
      data: {},
      persons: []
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.isModalVisible && nextProps.isModalVisible) {
      apiAxios('getFilterMeeting', {
        path: nextProps.selectedItem._id
      })
        .then((response) => {
          console.log('/myPrivateMeeting=', response);
          this.setState({ data: response })
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  // 获取联系人列表
  fetchData = () => {
    apiAxios('networkingPeople', {})
      .then((response) => {
        console.log('networkingPeople', response);
        this.setState({ persons: response.data });
      });
  };
  // 输入理由
  onChange = (e) => {
    const { value } = e.target;
    this.setState({
      valueMessage: value,
    });
  };
  //取消会议
  cancelMeeting = () => {
    const { valueMessage } = this.state;
    const { selectedItem, handleCancel } = this.props;
    apiAxios('editPrivateMeeting', {
      pathObj: { id: selectedItem._id, status: 'CANCELLED' },
      data: {
        note: valueMessage
      }
    })
      .then((response) => {
        console.log('/editPrivateMeeting=', response);
        handleCancel();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { isModalVisible, handleCancel } = this.props;
    const { data, persons, valueMessage } = this.state;
    let date = '';
    let time = '';
    let endTime = '';
    if (data.startTime) {
      const str = (data.startTime).split('T');
      date = str[0];
      time = (str[1]).substring(0, 5);
      endTime = get30MinLaterTime(time);
    }
    let createMan = {};
    let toMan = {};
    if (data.ParticipantIDs) {
      const person1 = persons.filter(v => v._id === data.ParticipantIDs[0]);
      const person2 = persons.filter(v => v._id === data.ParticipantIDs[1]);
      createMan = person1[0];
      toMan = person2[0];
    }
    const startUnix = dayjs(date + ' ' + time).unix();
    const currrentUnix = dayjs().unix();

    return (
      <Modal
        width={800}
        className="meeting-detail-modal"
        title={<div>{i18next.t('translations.business-matching-view-detail')}</div>}
        visible={isModalVisible}
        onCancel={() => {
          handleCancel();
          this.setState({
            valueMessage: ''
          });
        }}
        closeIcon={<CloseOutlined />}
        footer={
          <div className="flex flex_j_r">
            <Button
              style={{
                fontWeight: 'bold',
              }}
              onClick={() => {
                this.setState({
                  valueMessage: ''
                });
                handleCancel();
              }}
            >
              {i18next.t('translations.close')}
            </Button>
            {(get(data, 'status') !== 'CANCELLED') && currrentUnix < startUnix && <Button
              disabled={!valueMessage}
              style={{
                background: '#019EAF',
                color: 'white',
                fontWeight: 'bold',
                borderRadius: '.8rem'
              }}
              onClick={() => this.cancelMeeting()}
            >
              {i18next.t('translations.confirm')}
            </Button>}
          </div>
        }
      >
        <div className='content-area'>
          <div className="action-title">
            <span>{i18next.t('translations.business-matching-view-detail')}</span>
          </div>
          <Row className="bold f18 pT10">
            <span>
              {i18next.t('translations.created-by')}：{get(createMan, 'info.lastname') + ' ' + get(createMan, 'info.firstname')}
            </span>
          </Row>
          <Row className="bold f18 pT10">
            <span>
              {i18next.t('translations.participant')}：{get(toMan, 'info.lastname') + ' ' + get(toMan, 'info.firstname')}
            </span>
          </Row>
          <Row className="f16 pT10">
            <span className="bold">
              {i18next.t('translations.meeting-status')}：
            </span>
            <span>{get(data, 'status')}</span>
          </Row>
          <Row className="f16">
            <span className="bold">{i18next.t('translations.time')}：</span>
            <span>{`${date}(${time}-${endTime})`}</span>
          </Row>
          <Row className="f16">
            <span className="bold">
              {i18next.t('translations.meeting-reason')}：
            </span>
            <span>{get(data, 'notes.PENDING')}</span>
          </Row>
          <Row className="f16">
            <span className="bold">
              {i18next.t('translations.meeting-link')}：
            </span>
            <a target='_blank' href={get(data, 'url')}>{get(data, 'url')}</a>
          </Row>
          <Row className="input-area f16 bold">
            <span>
              {get(data, 'status') !== 'CANCELLED' && currrentUnix < startUnix && 'Enter reason if you want to cancel：'}
              {get(data, 'status') === 'CANCELLED' && `${i18next.t('translations.reason-cancel')}：`}
            </span>
            {get(data, 'status') !== 'CANCELLED' && currrentUnix < startUnix && <span style={{ color: 'red' }}>{`(${i18next.t('translations.required')})`}</span>}
          </Row>
          {get(data, 'status') !== 'CANCELLED' && currrentUnix < startUnix ? <TextArea rows={3} value={valueMessage} onChange={this.onChange} /> : <span>{get(data, 'notes.CANCELLED')}</span>}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  primary: state.profile.primary,
  userInfo: state.loginUserInfo.userInfo,
});

const enhance = compose(withTranslation('translations'), connect(mapStateToProps, null));

export default enhance(MeetingDetailModal);
