import { Button, Input, Modal } from 'antd';
import './CancelModal.less';
import React, { Component } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import i18next from 'i18next';
const { TextArea } = Input;

class CancelModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valueMessage: ''
    };
  }
  onChange = (e) => {
    const { value } = e.target;
    this.setState({
      valueMessage: value
    });
  };
  render() {
    const { isModalVisible, handleCancel, handleOk, primary } = this.props;
    const { valueMessage } = this.state;

    return (
      <Modal
        width={500}
        className="cancel-modal"
        title={<div style={{padding: '15px 20px', color: '#000'}}>{i18next.t('translations.reason-cancel')}</div>}
        visible={isModalVisible}
        onCancel={() => {
          handleCancel();
          this.setState({
            valueMessage: ''
          });
        }}
        closeIcon={<CloseOutlined />}
        footer={
          <div className="flex flex_rl">
            <Button
              style={{
                background: '#6c757d',
                color: 'white',
                fontWeight: 'bold',
                borderRadius: '.8rem'
              }}
              onClick={() => {
                handleCancel();
                this.setState({
                  valueMessage: ''
                });
              }}
            >
              {i18next.t('translations.close')}
            </Button>
            <Button
              disabled={!valueMessage}
              style={{
                background: primary,
                color: 'white',
                fontWeight: 'bold',
                borderRadius: '.8rem'
              }}
              onClick={() => {
                handleOk(valueMessage);
                this.setState({
                  valueMessage: ''
                });
              }}
            >
              {i18next.t('translations.confirm')}
            </Button>
          </div>
        }
      >
        <div className="fw700">{i18next.t('translations.message')}</div>
        <div>
          <TextArea value={valueMessage} rows={4} onChange={this.onChange} />
        </div>
      </Modal>
    );
  }
}
export default CancelModal;
