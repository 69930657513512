export const PRODUCT_LIST = [
  {
    id: 'a26f7b1d-d7b8-4d33-b5b2-5955f0b6a87c',
    name: {
      en: 'Defoamer P-25 Defoamer for Industrial Sewage, Waterproof Paint',
      sc: '測試P'
    },
    short_description: {
      en: 'Powder Coatings Technology',
      sc: '測試short_d'
    },
    description: {
      en: 'Defoamer, Surfactant, Wetting Agent, Surface Active Agent, Chemical Auxiliary, Dispersant, Chemical Additive, Antifoam, Non-Foam Wetting Agent, Antimicrobial Additive',
      sc: '測試d'
    },
    tags_string:
      'Polyester Powder Coating,Epoxy Polyester Powder Coating,Pure Polyester Powder Coating,Electrostatic Powder Coating,Acrylic Powder Coating,Spray Powder Coating,Paint Powder Coating,Color Powder Coating,Metallic Powder Coating,Metal Powder Coating',
    categories: [
      {
        display_name: {
          en: 'UV/EB Technology & Products',
          sc: '測試1'
        },
        id: 'test1'
      },
      {
        display_name: {
          en: 'Black Powder Coatings',
          sc: '測試2'
        },
        id: 'test2'
      },
      {
        display_name: {
          en: 'Acrylic Powder Coatings',
          sc: '測試3'
        },
        id: 'test3'
      }
    ],
    media: [
      {
        id: 'uuid1',
        type: 'IMAGE',
        link: 'LINK_TO_IMAGE'
      },
      {
        id: 'uuid2',
        type: 'IMAGE',
        link: 'LINK_TO_IMAGE'
      }
    ],
    mediaOrder: 'uuid1,uuid2',
    thumbnail: 'LINK_TO_IMAGE',
    sponsor: 'NONE',
    created_at: '2021-07-04T18:03:22.228Z',
    root_id: '1752deb4-6e04-4c5f-a698-9d8c259a1a1e',
    product: {
      merchant_id: 'test'
    },
    status: 'APPROVED',
    target_market: 'Afghanistan,Albania,Algeria,Hong Kong,China',
    image_url: [1, 2, 3, 4, 5, 6]
  },
  {
    id: 'a26f7b1d-d7b8-4d33-b5b2-5955f0b6a87c',
    name: {
      en: 'Defoamer P-25 Defoamer for Industrial Sewage, Waterproof Paint',
      sc: '測試P'
    },
    short_description: {
      en: 'Powder Coatings Technology',
      sc: '測試short_d'
    },
    description: {
      en: 'Defoamer, Surfactant, Wetting Agent, Surface Active Agent, Chemical Auxiliary, Dispersant, Chemical Additive, Antifoam, Non-Foam Wetting Agent, Antimicrobial Additive',
      sc: '測試d'
    },
    tags_string:
      'Polyester Powder Coating,Epoxy Polyester Powder Coating,Pure Polyester Powder Coating,Electrostatic Powder Coating,Acrylic Powder Coating,Spray Powder Coating,Paint Powder Coating,Color Powder Coating,Metallic Powder Coating,Metal Powder Coating',
    categories: [
      {
        display_name: {
          en: 'UV/EB Technology & Products',
          sc: '測試1'
        },
        id: 'test1'
      },
      {
        display_name: {
          en: 'Black Powder Coatings',
          sc: '測試2'
        },
        id: 'test2'
      },
      {
        display_name: {
          en: 'Acrylic Powder Coatings',
          sc: '測試3'
        },
        id: 'test3'
      }
    ],
    media: [
      {
        id: 'uuid1',
        type: 'IMAGE',
        link: 'LINK_TO_IMAGE'
      },
      {
        id: 'uuid2',
        type: 'IMAGE',
        link: 'LINK_TO_IMAGE'
      }
    ],
    mediaOrder: 'uuid1,uuid2',
    thumbnail: 'LINK_TO_IMAGE',
    sponsor: 'NONE',
    created_at: '2021-07-04T18:03:22.228Z',
    root_id: '1752deb4-6e04-4c5f-a698-9d8c259a1a1e',
    product: {
      merchant_id: 'test'
    },
    status: 'APPROVED',
    target_market: 'Afghanistan,Albania,Algeria,Hong Kong,China',
    image_url: [1, 2, 3, 4, 5, 6]
  },
  {
    id: 'a26f7b1d-d7b8-4d33-b5b2-5955f0b6a87c',
    name: {
      en: 'Defoamer P-25 Defoamer for Industrial Sewage, Waterproof Paint',
      sc: '測試P'
    },
    short_description: {
      en: 'Powder Coatings Technology',
      sc: '測試short_d'
    },
    description: {
      en: 'Defoamer, Surfactant, Wetting Agent, Surface Active Agent, Chemical Auxiliary, Dispersant, Chemical Additive, Antifoam, Non-Foam Wetting Agent, Antimicrobial Additive',
      sc: '測試d'
    },
    tags_string:
      'Polyester Powder Coating,Epoxy Polyester Powder Coating,Pure Polyester Powder Coating,Electrostatic Powder Coating,Acrylic Powder Coating,Spray Powder Coating,Paint Powder Coating,Color Powder Coating,Metallic Powder Coating,Metal Powder Coating',
    categories: [
      {
        display_name: {
          en: 'UV/EB Technology & Products',
          sc: '測試1'
        },
        id: 'test1'
      },
      {
        display_name: {
          en: 'Black Powder Coatings',
          sc: '測試2'
        },
        id: 'test2'
      },
      {
        display_name: {
          en: 'Acrylic Powder Coatings',
          sc: '測試3'
        },
        id: 'test3'
      }
    ],
    media: [
      {
        id: 'uuid1',
        type: 'IMAGE',
        link: 'LINK_TO_IMAGE'
      },
      {
        id: 'uuid2',
        type: 'IMAGE',
        link: 'LINK_TO_IMAGE'
      }
    ],
    mediaOrder: 'uuid1,uuid2',
    thumbnail: 'LINK_TO_IMAGE',
    sponsor: 'NONE',
    created_at: '2021-07-04T18:03:22.228Z',
    root_id: '1752deb4-6e04-4c5f-a698-9d8c259a1a1e',
    product: {
      merchant_id: 'test'
    },
    status: 'APPROVED',
    target_market: 'Afghanistan,Albania,Algeria,Hong Kong,China',
    image_url: [1, 2, 3, 4, 5, 6]
  },
  {
    id: 'a26f7b1d-d7b8-4d33-b5b2-5955f0b6a87c',
    name: {
      en: 'Defoamer P-25 Defoamer for Industrial Sewage, Waterproof Paint',
      sc: '測試P'
    },
    short_description: {
      en: 'Powder Coatings Technology',
      sc: '測試short_d'
    },
    description: {
      en: 'Defoamer, Surfactant, Wetting Agent, Surface Active Agent, Chemical Auxiliary, Dispersant, Chemical Additive, Antifoam, Non-Foam Wetting Agent, Antimicrobial Additive',
      sc: '測試d'
    },
    tags_string:
      'Polyester Powder Coating,Epoxy Polyester Powder Coating,Pure Polyester Powder Coating,Electrostatic Powder Coating,Acrylic Powder Coating,Spray Powder Coating,Paint Powder Coating,Color Powder Coating,Metallic Powder Coating,Metal Powder Coating',
    categories: [
      {
        display_name: {
          en: 'UV/EB Technology & Products',
          sc: '測試1'
        },
        id: 'test1'
      },
      {
        display_name: {
          en: 'Black Powder Coatings',
          sc: '測試2'
        },
        id: 'test2'
      },
      {
        display_name: {
          en: 'Acrylic Powder Coatings',
          sc: '測試3'
        },
        id: 'test3'
      }
    ],
    media: [
      {
        id: 'uuid1',
        type: 'IMAGE',
        link: 'LINK_TO_IMAGE'
      },
      {
        id: 'uuid2',
        type: 'IMAGE',
        link: 'LINK_TO_IMAGE'
      }
    ],
    mediaOrder: 'uuid1,uuid2',
    thumbnail: 'LINK_TO_IMAGE',
    sponsor: 'NONE',
    created_at: '2021-07-04T18:03:22.228Z',
    root_id: '1752deb4-6e04-4c5f-a698-9d8c259a1a1e',
    product: {
      merchant_id: 'test'
    },
    status: 'APPROVED',
    target_market: 'Afghanistan,Albania,Algeria,Hong Kong,China',
    image_url: [1, 2, 3, 4, 5, 6]
  },
  {
    id: 'a26f7b1d-d7b8-4d33-b5b2-5955f0b6a87c',
    name: {
      en: 'Defoamer P-25 Defoamer for Industrial Sewage, Waterproof Paint',
      sc: '測試P'
    },
    short_description: {
      en: 'Powder Coatings Technology',
      sc: '測試short_d'
    },
    description: {
      en: 'Defoamer, Surfactant, Wetting Agent, Surface Active Agent, Chemical Auxiliary, Dispersant, Chemical Additive, Antifoam, Non-Foam Wetting Agent, Antimicrobial Additive',
      sc: '測試d'
    },
    tags_string:
      'Polyester Powder Coating,Epoxy Polyester Powder Coating,Pure Polyester Powder Coating,Electrostatic Powder Coating,Acrylic Powder Coating,Spray Powder Coating,Paint Powder Coating,Color Powder Coating,Metallic Powder Coating,Metal Powder Coating',
    categories: [
      {
        display_name: {
          en: 'UV/EB Technology & Products',
          sc: '測試1'
        },
        id: 'test1'
      },
      {
        display_name: {
          en: 'Black Powder Coatings',
          sc: '測試2'
        },
        id: 'test2'
      },
      {
        display_name: {
          en: 'Acrylic Powder Coatings',
          sc: '測試3'
        },
        id: 'test3'
      }
    ],
    media: [
      {
        id: 'uuid1',
        type: 'IMAGE',
        link: 'LINK_TO_IMAGE'
      },
      {
        id: 'uuid2',
        type: 'IMAGE',
        link: 'LINK_TO_IMAGE'
      }
    ],
    mediaOrder: 'uuid1,uuid2',
    thumbnail: 'LINK_TO_IMAGE',
    sponsor: 'NONE',
    created_at: '2021-07-04T18:03:22.228Z',
    root_id: '1752deb4-6e04-4c5f-a698-9d8c259a1a1e',
    product: {
      merchant_id: 'test'
    },
    status: 'APPROVED',
    target_market: 'Afghanistan,Albania,Algeria,Hong Kong,China',
    image_url: [1, 2, 3, 4, 5, 6]
  },
  {
    id: 'a26f7b1d-d7b8-4d33-b5b2-5955f0b6a87c',
    name: {
      en: 'Defoamer P-25 Defoamer for Industrial Sewage, Waterproof Paint',
      sc: '測試P'
    },
    short_description: {
      en: 'Powder Coatings Technology',
      sc: '測試short_d'
    },
    description: {
      en: 'Defoamer, Surfactant, Wetting Agent, Surface Active Agent, Chemical Auxiliary, Dispersant, Chemical Additive, Antifoam, Non-Foam Wetting Agent, Antimicrobial Additive',
      sc: '測試d'
    },
    tags_string:
      'Polyester Powder Coating,Epoxy Polyester Powder Coating,Pure Polyester Powder Coating,Electrostatic Powder Coating,Acrylic Powder Coating,Spray Powder Coating,Paint Powder Coating,Color Powder Coating,Metallic Powder Coating,Metal Powder Coating',
    categories: [
      {
        display_name: {
          en: 'UV/EB Technology & Products',
          sc: '測試1'
        },
        id: 'test1'
      },
      {
        display_name: {
          en: 'Black Powder Coatings',
          sc: '測試2'
        },
        id: 'test2'
      },
      {
        display_name: {
          en: 'Acrylic Powder Coatings',
          sc: '測試3'
        },
        id: 'test3'
      }
    ],
    media: [
      {
        id: 'uuid1',
        type: 'IMAGE',
        link: 'LINK_TO_IMAGE'
      },
      {
        id: 'uuid2',
        type: 'IMAGE',
        link: 'LINK_TO_IMAGE'
      }
    ],
    mediaOrder: 'uuid1,uuid2',
    thumbnail: 'LINK_TO_IMAGE',
    sponsor: 'NONE',
    created_at: '2021-07-04T18:03:22.228Z',
    root_id: '1752deb4-6e04-4c5f-a698-9d8c259a1a1e',
    product: {
      merchant_id: 'test'
    },
    status: 'APPROVED',
    target_market: 'Afghanistan,Albania,Algeria,Hong Kong,China',
    image_url: [1, 2, 3, 4, 5, 6]
  }
];

export const OTHER_PRODUCT_LIST = [
  {
    title: 'Heat Transfer Wood Effect Electrostatic',
    company: 'Zhongshan HLM powder coating Ltd.'
  },
  {
    title: '752f Sand Effect Heat Resisitant Powder',
    company: 'Zhongshan HLM powder coating Ltd.'
  },
  {
    title:
      'Anti Graffiti Antic Antibacterial Powder Anti Fire Graffiti Coating',
    company: 'Jiangmen Wonder Industrial Co., Ltd.'
  },
  {
    title: '(Free Sample) Epoxy/Polyester Resin Dyes Powder Coatings….',
    company: 'Nanjing Powder New Material Co….'
  },
  {
    title: '(Free Sample) Epoxy/Polyester Resin Dyes Powder Coatings….',
    company: 'Nanjing Powder New Material Co….'
  },
  {
    title: '(Free Sample) Epoxy/Polyester Resin Dyes Powder Coatings….',
    company: 'Nanjing Powder New Material Co….'
  }
];

export const SEARCH_CONDITIONS_DATA = {
  exhibition: [
    'Electroplating & Finishing Technology',
    'Environmental, Safety  & Protection',
    'Coating Applications & Coating Protection',
    'International Machinery, Instrument & Services',
    'China Machinery, Instrument & Services',
    'Powder Coatings Technology',
    'UV/EB Technology & Products',
    'China Raw Materials & International Raw Materials'
  ],
  category: [
    'Powder Coatings Technology',
    'UV/EB Technology & Products ',
    'Coating Applications & Coating Protection',
    'Raw Materials For Coatings, link & Adhesives',
    'Coating Applications & Coating Protection'
  ],
  region: [
    'China',
    'Hong Kong',
    'Macau',
    'International Machinery, Instrument & Services',
    'China Machinery, Instrument & Services',
    'Powder Coatings Technology',
    'UV/EB Technology & Products',
    'China Raw Materials & International Raw Materials'
  ]
};
