/*
 * @Description: 
 * @Version: 1.0
 * @Autor: ricky shen
 * @Date: 2022-02-10 15:14:41
 * @LastEditors: ricky shen
 * @LastEditTime: 2022-04-13 14:44:15
 */
import React, { Component } from 'react';
import './Topic.less';
import './TabPaneItem.less';
import { Button, Row, Col } from 'antd';
import get from 'lodash/get';
import { TagOutlined } from '@ant-design/icons';
import i18next from 'i18next';

class WebinarType extends Component {
  // constructor(props) {
  //   super();
  //   this.state = {
  //     currenIndex: ''
  //   }
  // }
  componentDidMount() {

  }
  choose = (topic, index) => {
    // this.setState({
    //   currenIndex: index
    // })
    this.props.onTopicChange(topic, index);
  };
  // 过滤个数
  getCount = (currentData, topic) => {
    let sourceData = currentData;
    // 判断数组或者对象
    if (!(currentData instanceof Array)) {
      // 对象变数组，统一处理
      sourceData = [currentData];
    }
    // console.log('sourceData', sourceData);
    var count = 0;
    sourceData.forEach(first => {
      if (first.data) {
        // 判断是否存在分类id
        const isExitArr = first.data.filter(second => {
          return second.categories && second.categories.indexOf(topic._id) !== -1;
        });
        if (isExitArr && isExitArr.length) {
          count += isExitArr.length;
          // console.log('isExitArr-topic._id', topic._id);
          // console.log('isExitArr', isExitArr);
          // console.log('isExitArr-count', count);
        }
      }
    });
    return count;
  };

  render() {
    const { topics, language, currentData, currenIndex } = this.props;
    // const { currenIndex } = this.state;
    // console.log('currentData', currentData);
    const hoverAll = currenIndex === 'All' ? 'btnPhover' : '';

    return (
      <div className='tabCotent Topic'>
        <div className='topic-type'>{i18next.t('translations.topic')}</div>
        <div className='flex'>
          <Button icon={<TagOutlined />} onClick={this.choose.bind(this, 'All', 'All')} shape="round" className={'antBtnRound mL20 pB20 btnP ' + hoverAll} size="large" key={'all'}>{i18next.t('translations.all')}</Button>
          {topics.map((topic, index) => {
            const hoverC = currenIndex === index ? 'btnPhover' : '';
            return <Button icon={<TagOutlined />} onClick={this.choose.bind(this, topic, index)} shape="round" className={'antBtnRound mL20 pB20 btnP ' + hoverC} size="large" key={get(get(topic, 'display_name'), language)}>{get(get(topic, 'display_name'), language)}
              <div className={'tag flex flex_j_c flex_a_c fontMbs'}>{this.getCount(currentData, topic)}</div>
            </Button>
          })}
        </div>
      </div>
    );
  }
}

export default WebinarType;
