import { applyMiddleware, createStore, compose } from 'redux';
import { persistStore, persistCombineReducers } from 'redux-persist';
import reconciler from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/es/storage';
import rootReducers from '../reducers';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
const logger = createLogger();

const middleWares = [thunkMiddleware, logger];

const config = {
  key: 'root',
  storage,
  stateReconciler: reconciler, //合并模式
  debug: false
};

const reducers = persistCombineReducers(config, rootReducers);
const enhances = [applyMiddleware(...middleWares)];

const configureStore = (initialState) => {
  const store = createStore(reducers, initialState, compose(...enhances));
  persistStore(store); //暂时磁盘保存数据
  return store;
};

export default configureStore;
