import { Row, Modal } from 'antd';
import { WechatOutlined, CloseOutlined } from '@ant-design/icons';
import './ChatModal.less';
import React from 'react';
import noProfilePic from '../Image/no-profile-pic.png';
import i18next from 'i18next';

export default function ChatModal(props) {
  const { isModalVisible, handleCancel, primary, handleOk } = props;
  return (
    <Modal
      width={500}
      className="bold"
      visible={isModalVisible}
      footer={null}
      title={<div>{i18next.t('translations.chat')}</div>}
      onCancel={handleCancel}
      closeIcon={<CloseOutlined />}
    >
      <Row justify="space-between" className="chat-modal">
        <div className="flex">
          <div className="user-area">
            <img className="user" src={noProfilePic} />
          </div>
          <div className="name">
            <a
              href="http://www.entrelink.hk/"
              target="_blank"
              className="fColor"
            >
              Robert
            </a>
          </div>
        </div>
        <div onClick={() => handleOk()} className="pointer flex flex_d_c">
          <WechatOutlined />
          <div className="f16">{i18next.t('translations.chat')}</div>
        </div>
      </Row>
    </Modal>
  );
}
