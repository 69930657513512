/*
 * @Description:
 * @Version: 1.0
 * @Autor: ricky shen
 * @Date: 2022-03-20 13:24:35
 * @LastEditors: ricky shen
 * @LastEditTime: 2022-03-26 00:06:55
 */
import React, { Component } from 'react';
import { Row, Divider, Col, Tabs } from 'antd';
import { connect } from 'react-redux';
import './JoinInfoTabPane.less';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';
// import { UserAddOutlined } from '@ant-design/icons';
// const { TabPane } = Tabs;
import { getTime } from '../../../Utils/Util';

class JoinInfoTabPane extends Component {
  constructor(props) {
    super();
  }
  componentDidMount() {}
  render() {
    const { data, language } = this.props;
    return (
      <div className="JoinInfoTabPane">
        <div className='mB20'><span className={get(data, 'type') === 'Recordings' ? 'type type-rec' : 'type type-live'}>{get(data, 'type')}</span></div>
        <div className="color f20 pB10">
          {/* {get(data, 'timetitle')}  */}
          {getTime(get(data, 'startDate'))} -{' '}
          {getTime(get(data, 'endDate'))}
        </div>
        <div className="f20 bold pB10 color2">
          {get(get(data, 'display_name'), language)}
        </div>
        <div className="pB20">
          <div
            dangerouslySetInnerHTML={{
              __html: get(get(data, 'display_description'), language)
            }}
          />
        </div>
        {/* <div className="f20 bold pB10 color">Speakers</div> */}
        <Divider />
        {data && data.event_speakers && data.event_speakers.map((speaker, index) => (
            <Row
              key={speaker._id}
              align="middle"
              justify="start"
              className={index !== 0 && 'pT20'}
            >
              <Col span="4">
                <img
                  className="profilePic"
                  src={get(speaker, 'profilePic.src')}
                  alt={get(speaker, 'profilePic.alt')}
                  title={get(speaker, 'profilePic.title')}
                />
              </Col>
              <Col span="15" offset="1">
                <div className="speakerName">
                  <span>{get(get(speaker, 'display_name'), language)}</span>
                </div>
                <div className="speakerJobTitle f25">
                  <div>{get(get(speaker, 'display_jobTitle'), language)}</div>
                  <div>{get(get(speaker, 'display_company'), language)}</div>
                </div>
              </Col>
            </Row>
          ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.profile.language
});

export default connect(mapStateToProps, null)(withRouter(JoinInfoTabPane));
