import React from "react";
import { Link } from "react-router-dom";

import { Row, Col, Form, Input, Button, message } from "antd";

import LeftContent from "../leftContent";
import apiAxios from "../../../network/axios/$axios";
import i18next from "i18next";

export default function RecoverPassword(props) {

  const onFinish = (values) => {
    console.log(values);
    apiAxios('forgetPassword', {
      data: {
        id: values.email
      }
    })
      .then((response) => {
        console.log('/forgetPassword=', response);
        props.history.replace('/ResetPassword?token=' + response.token + '&email=' + values.email);
      })
      .catch((error) => {
        console.log(error);
        message.error(i18next.t('translations.invalid-email'));
      });
  };

  return (
    <Row gutter={[32, 0]} className="hp-authentication-page bgWhite">
      <LeftContent />

      <Col lg={12} span={24} className="hp-py-sm-0 hp-py-md-64 hp-pb-96">
        <Row className="hp-h-100" align="middle" justify="center">
          <Col
            xxl={15}
            xl={15}
            lg={20}
            md={20}
            sm={24}
            className="hp-px-sm-8 hp-pt-24 hp-pb-48"
          >
            <h1 className="hp-mb-sm-0 bold">Recover Password</h1>
            <p className="hp-mt-sm-0 hp-mt-8 hp-text-color-black-60">
              We’ll e-mail you instructions on how to reset your password.
            </p>

            <Form
              layout="vertical"
              name="basic"
              className="hp-mt-sm-16 hp-mt-32"
              onFinish={onFinish}
            >
              <Form.Item label="E-mail :" name="email">
                <Input id="validating" placeholder="you@example.com" />
              </Form.Item>

              <Form.Item className="hp-mt-16 hp-mb-8">
                <Button block type="primary" htmlType="submit">
                  Reset Password
                </Button>
              </Form.Item>
            </Form>

            <div className="hp-form-info hp-text-center">
              <span className="hp-text-color-black-80 hp-text-color-dark-40 hp-caption hp-mr-4">
                Go back to
              </span>

              <Link
                to="/"
                className="hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-caption"
              >
                Login
              </Link>
            </div>

          </Col>
        </Row>
      </Col>
    </Row>
  );
}
