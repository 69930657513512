import { Link } from "react-router-dom";

import { Dropdown, Col, Divider, Row } from "antd";
import { UserOctagon, Flag, Calendar, Calculator, Logout } from 'iconsax-react';
import i18next from 'i18next';
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import './HeaderUser.less';
import { connect } from "react-redux";
import { saveSelectedTabKey } from "../../../actions/BusinessMatchingAction";

function HeaderUser(props) {
  const { userInfo, loginOut, saveSelectedTabKey } = props;
  let str = '';
  if (userInfo && userInfo.firstname) {
    str = (userInfo.firstname).substring(0, 1);
  }
  let role = '';
  let customCSS = '';
  if (userInfo.role === 'Visitor') {
    role = i18next.t('translations.visitor');
    customCSS = 'visitor';
  } else if (userInfo.role === 'Organizer') {
    role = i18next.t('translations.organizer');
    customCSS = 'organizer';
  } else {
    role = i18next.t('translations.exhibitor');
    customCSS = 'exhibitor';
  }
  const menu = (
    <div className="hp-user-dropdown hp-border-radius hp-bg-black-0 hp-bg-dark-100 hp-border-color-dark-80 hp-py-24 hp-px-18 hp-mt-16 header-user">
      <Row className="flex">
        <Col span={24}>
          <div className="flex-row-center" style={{ justifyContent: 'flex-start'}}>
            <div className="hp-border-radius-lg hp-overflow-hidden hp-m-4 hp-d-flex flex-row-center" style={{ minWidth: 50, width: 50, height: 50, borderRadius: 25, backgroundColor: '#C4E2FC' }}>
              <span className="f25">{str}</span>
            </div>
            <div className="flex_d_c hp-ml-8">
              <div>
                <span className="username">Hi, {userInfo?.firstname ||"" + ' ' + userInfo?.lastname||""}</span>
              </div>
              <div className={'flex-row-center ' + customCSS}>
                <span>
                  {role}
                </span>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Divider className="hp-mt-18 hp-mb-12" />

      <Row>
        <Col span={24}>
          <Link to="/Profile" className="hp-d-flex-center hp-p1-body hp-font-weight-500 hp-my-4 hp-py-8 hp-px-10 hp-d-block hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-border-radius" style={{ marginLeft: -10, marginRight: -10 }}>
            <span className="remix-icon">
              <i className="ri-user-fill" />
            </span>
            <span className="hp-ml-8">{i18next.t('translations.my-profile')}</span>
          </Link>
        </Col>

        <Col span={24}>
          <Link to="/Briefcase" className="hp-d-flex-center hp-p1-body hp-font-weight-500 hp-my-4 hp-py-8 hp-px-10 hp-d-block hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-border-radius" style={{ marginTop: -7, marginLeft: -10, marginRight: -10 }}>
            <span className="remix-icon">
              <i className="ri-bookmark-3-fill" />
            </span>
            <span className="hp-ml-8">{i18next.t('translations.my-bookmarks')}</span>
          </Link>
        </Col>

        {/* <Col span={24}>
          <Link to="/Availability" className="hp-d-flex-center hp-p1-body hp-font-weight-500 hp-my-4 hp-py-8 hp-px-10 hp-d-block hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-border-radius" style={{ marginTop: -7, marginLeft: -10, marginRight: -10 }}>
            <span className="remix-icon">
              <i className="ri-calendar-check-fill" />
            </span>
            <span className="hp-ml-8">{i18next.t('translations.availability')}</span>
          </Link>
        </Col>

        <Col span={24}>
          <Link to="/MeetingManagement" className="hp-d-flex-center hp-p1-body hp-font-weight-500 hp-my-4 hp-py-8 hp-px-10 hp-d-block hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-border-radius" style={{ marginTop: -7, marginLeft: -10, marginRight: -10 }}>
            <span className="remix-icon">
              <i className="ri-calendar-2-fill" />
            </span>
            <span className="hp-ml-8">{i18next.t('translations.meeting-management')}</span>
          </Link>
        </Col> */}

        <Col span={24}>
          <Link to="/ChangePassword" className="hp-d-flex-center hp-p1-body hp-font-weight-500 hp-my-4 hp-py-8 hp-px-10 hp-d-block hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-border-radius" style={{ marginTop: -7, marginLeft: -10, marginRight: -10 }}>
            <span className="remix-icon">
              <i className="ri-lock-password-fill" />
            </span>
            <span className="hp-ml-8">{i18next.t('translations.change-password')}</span>
          </Link>
        </Col>

        <Col span={24}>
          <a onClick={() => loginOut()}
            className="hp-d-flex-center hp-p1-body hp-font-weight-500 hp-my-4 hp-py-8 hp-px-10 hp-d-block hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-border-radius" style={{ marginTop: -7, marginLeft: -10, marginRight: -10 }}
          >
            <span className="remix-icon">
              <i className="ri-logout-box-r-fill" />
            </span>
            <span className="hp-ml-8">{i18next.t('translations.logout')}</span>
          </a>
        </Col>
      </Row>
    </div>
  );

  return (
    <Col className="hp-ml-8">
      <Dropdown overlay={menu} placement="bottomLeft">
        <div className="hp-border-radius-xl hp-cursor-pointer hp-border-1 hp-border-color-dark-80">
          <div className="hp-border-radius-lg hp-bg-info-4 hp-m-4 hp-d-flex flex-row-center" style={{ minWidth: 32, width: 32, height: 32 }}>
            <span className="f25">{str}</span>
          </div>
        </div>
      </Dropdown>
    </Col>
  );
};

const mapDispatchToProps = (dispatch) => ({
  saveSelectedTabKey: (key) => {
    dispatch(saveSelectedTabKey(key));
  },
});

const enhance = compose(
  withTranslation('translations'),
  connect(null, mapDispatchToProps)
);

export default enhance(HeaderUser);
