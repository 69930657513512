import React, { useEffect, useState } from 'react';
import { Row, Col, Checkbox, Input, Divider, Button } from 'antd';
import './FilterCheck.less';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import axios from '../../../network/axios/$axios';
import get from 'lodash/get';
import { RiArrowRightSLine, RiArrowDownSLine } from "react-icons/ri";

const { Search } = Input;

const ContactList = (props) => {
  const [filterTitles, setFilterTitles] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  // const [filterSubTitles, setFilterSubTitles] = useState([]);
  // const [hasMore, setHasMore] = useState(true);
  const { language, onLeftFilter, primary, onSelect, onSearch } = props;

  useEffect(() => {
    networkingFilter();
  }, []);
  // 左侧标题
  const networkingFilter = () => {
    axios('NetworkingFilter', {})
      .then((response) => {
        console.log('NetworkingFilter', response);
        setFilterTitles(response.data[0].data.filters);
      });
  };
  // 左侧子标题
  const networkingFilterSub = (titleObj) => {
    axios('registrationFormChoices', {
      path: `?filter={"field":"${titleObj.RegistrationChoices}"}`
    }).then((res) => {
      console.log('/registrationFormChoices', res);
      // setFilterSubTitles(res.data);
      titleObj.filterSubTitles = res.data;
      const filterTitlesTemp = [...filterTitles];   //复制数组--浅拷贝
      setFilterTitles(filterTitlesTemp);
    });
  };

  const fetchData = () => {
  };
  // 选择多选框
  const onChange = (checkedValues) => {
    // console.log('checked = ', checkedValues);
    onLeftFilter(checkedValues);
  }
  // 点击filter标题下拉上拉
  const filterTitleHandle = (titleObj) => {
    console.log('titleObj = ', titleObj);
    titleObj.showMore = !titleObj.showMore;
    const filterTitlesTemp = [...filterTitles];   //复制数组--浅拷贝
    setFilterTitles(filterTitlesTemp);
    networkingFilterSub(titleObj);
  }
  const onSearchByKeyword = (value) => {
    onSearch('all', value);
  };

  const onSearchChange = (e) => {
    setSearchValue(e.target.value);
  };
  const onRemoteFilter = () => {
    window.location.reload();
  };
  // const down = <DownOutlined />;
  // const up = <UpOutlined />;
  const down = <RiArrowDownSLine size={24} className="hp-collapse-arrow hp-text-color-black-60"/>;
  const up = <RiArrowRightSLine
      size={24}
      className="hp-collapse-arrow hp-text-color-black-60"
    />
  return (
    <div className="filter-check">
      {/* <Row>
        <Col md={24}>
          <Search
            allowClear
            style={{ borderColor: primary }}
            placeholder={'Search'}
            onSearch={onSearchByKeyword}
            onChange={onSearchChange}
            value={searchValue}
            enterButton
          />
        </Col>
      </Row> */}
      {/* <Row align='middle' justify='space-between'>
        <Col span={12} className='filter-t'>Filters</Col>
      </Row> */}
      <Checkbox.Group style={{ width: '100%' }} onChange={onChange}>
        {filterTitles && filterTitles.map(title => (
          <div key={title.RegistrationChoices}>
            <div onClick={() => { filterTitleHandle(title) }} className='filter-title flex flex_a_c flex_rl'>
              <span className='hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30'>{get(title.display_title, [language])}</span>
              <span className='mL05'>{title.showMore ? down : up}</span>
            </div>
            {title.showMore && <Row>
              {title.filterSubTitles && title.filterSubTitles.map(subTitle => <Col key={subTitle._id} span={24}><Checkbox value={subTitle._id}>{get(subTitle.display_name, [language])}</Checkbox></Col>)}
            </Row>}
          </div>
        ))}
      </Checkbox.Group>
      <Divider />
      <Button onClick={() => onRemoteFilter()} block type="primary" className="hp-mt-32">{i18next.t('translations.filter')}</Button>
    </div>
  );
};

const enhance = compose(withTranslation('translations'));

export default enhance(ContactList);
