import React, { Component } from 'react';
import './index.less';
import i18next from 'i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import './index.less';
import ResultType from './components/ResultType';
import ResultList from './components/ResultList';
import axios from '../../network/axios/$axios';
import { selectedType } from '../../actions/ProfileAction';
import { enterCurrentPage } from '../../Utils/Util';
import { getBriefcasesList } from '../../actions/BriefcaseAction';
import apiAxios from '../../network/axios/$axios';

class SearchScreen extends Component {
  constructor(props) {
    super();

    this.state = {
      selectedValue: props.searchType,
      categoryList: [],
      count: 0,
      list: [],
      keyword: '',
      isFilter: false,
      searchResult: {},
      allResult: {},
      speakers: []
    };
  }
  componentDidMount() {
    const { getBriefcasesList, userInfo } = this.props;
    this.getData();
    enterCurrentPage();
    getBriefcasesList(userInfo.id);
    // this.getSpeakerByIds();
  }

  componentWillReceiveProps(nextProps) {
    this.props = nextProps;
    this.getData();
  }

  // 查询所有speaker
  getSpeakerByIds = () => {
    apiAxios('getAllSpeaker', { params: {} })
      .then((response) => {
        const speakers = response.data.sort(function (a, b) { return a.seq - b.seq });
        this.setState({ speakers: speakers })
        // 缓存
        sessionStorage.setItem('ALLSpeakers', JSON.stringify(speakers));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getData = () => {
    const { searchType } = this.props;
    const { isFilter, keyword } = this.state;
    const url = document.location.toString();
    const arrObj = url.split('?');
    if (arrObj.length > 1) {
      const arrPara = arrObj[1].split('&');
      let arr = [];
      for (let i = 0; i < arrPara.length; i++) {
        arr = arrPara[i].split('=');
        if (arr[0] === 'Query' && arr[1]) {
          let decodeKeyword = decodeURIComponent(arr[1])
          if (isFilter && decodeKeyword === keyword) return;
          if (searchType === 'ALL') {
            axios('search', { params: { keyword: decodeKeyword, skip: 0 } })
              .then((datas) => {
                console.log('/search=', datas);
                const res = {
                  Agenda: datas.Agenda,
                  Booth: datas.Booth,
                  // Speaker: datas.Speaker
                };
                const keys = ['Agenda', 'Booth'];
                let count = 0;
                let currentData = [];
                keys.map(key => {
                  count += res[key].length;
                  const list = res[key];
                  list.map(v => v.tag = key);
                  currentData = [...currentData, ...list];
                });
                this.setState({ categoryList: ['ALL', ...keys], count, list: currentData, keyword: decodeKeyword, searchResult: res, allResult: res })
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            axios('search', { params: { keyword: decodeKeyword, skip: 0 } })
              .then((res) => {
                console.log('/search=', res);
                const keys = ['Agenda', 'Booth'];
                let currentData = [];
                keys.map(key => {
                  const list = res[key];
                  list.map(v => v.tag = key);
                  currentData = [...currentData, ...list];
                });
                this.setState({ categoryList: ['ALL', ...keys], list: currentData, keyword: decodeKeyword })
              })
              .catch((error) => {
                console.log(error);
              });
            axios('search', { path: searchType, params: { keyword: decodeKeyword, skip: 0 } })
              .then((res) => {
                console.log('/search=', res);
                const list = res[searchType];
                list.map(v => v.tag = searchType);
                this.setState({ selectedValue: searchType, count: list.length, searchResult: res });
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
      }
    }
  };

  selectedType = (value) => {
    const { list, keyword, allResult } = this.state;
    const { selectedType } = this.props;
    if (value === 'ALL') {
      this.setState({ selectedValue: value, count: list.length, isFilter: true, searchResult: allResult });
    } else {
      axios('search', { path: value, params: { keyword, skip: 0 } })
        .then((res) => {
          console.log('/search=', res);
          const list = res[value];
          list.map(v => v.tag = value);
          this.setState({ selectedValue: value, count: list.length, isFilter: true, searchResult: res });
        })
        .catch((error) => {
          console.log(error);
        });
    }
    selectedType(value);
  };

  render() {
    const { categoryList, count, searchResult, speakers } = this.state;
    const { searchType } = this.props;
    return (
      <div className="search-result-page">
        <div className="title">
          <span>
            {i18next.t('translations.total')} {count}{' '}
            {i18next.t('translations.result')}
          </span>
        </div>
        <Row className="pT10">
          <Col xs={{ span: 24 }} md={{ span: 6 }}>
            <ResultType searchType={searchType} data={categoryList} selectedType={this.selectedType} />
          </Col>
          <Col style={{ width: 32 }} />
          <Col
            className="bri-right-section"
            xs={{ span: 24 }}
            md={{ span: 17 }}
          >
            <ResultList data={searchResult} categoryList={categoryList} speakers={speakers} {...this.props} />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  primary: state.profile.primary,
  language: state.profile.language,
  searchType: state.profile.searchType,
  userInfo: state.loginUserInfo.userInfo,
  briefcasesData: state.briefcases.briefcasesData,
  zoneNumber: state.loginUserInfo.zoneNumber, // 时区数字
});

const mapDispatchToProps = (dispatch) => ({
  selectedType: (value) => {
    dispatch(selectedType(value));
  },
  getBriefcasesList: (userId) => {
    dispatch(getBriefcasesList(userId));
  }
});

const enhance = compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps));

export default enhance(SearchScreen);
