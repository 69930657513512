import { CHANGE_MAIN_COLOR, CHANGE_LANGUAGE, SELECTED_TYPE, SELECTED_2D_OR_3D_MODEL, SAVE_MENU } from '../constants/ActionTypes';

const initialState = {
  primary: '#019EAF',
  language: 'en-us',
  searchType: 'ALL',
  selectedModel: '3d',
  menu: []
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_MAIN_COLOR:
      return Object.assign({}, state, {
        primary: action.data
      });
    case CHANGE_LANGUAGE:
      return Object.assign({}, state, {
        language: action.data
      });
    case SELECTED_TYPE:
      return Object.assign({}, state, {
        searchType: action.data
      });
    case SELECTED_2D_OR_3D_MODEL:
      return Object.assign({}, state, {
        selectedModel: action.data
      });
    case SAVE_MENU:
      return Object.assign({}, state, {
        menu: action.data
      });
    default:
      return state;
  }
};

export default profile;
