import React, { Component } from 'react';
import indexCss from './Agenda.module.css';
import './globalAgenda.less'
import { Divider, Tabs, Spin, message, Row, Col } from 'antd';
// import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom'
import TabPaneItem from './components/TabPaneItem';
import TabSpeakerPaneItem from './components/TabSpeakerPaneItem';
import TabSpeakerPaneItemAll from './components/TabSpeakerPaneItemAll';
import { fetchAgenDaList, addMysession } from '../../actions/AgendaAction';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import TopHeader from "./components/TopHeader";
import axios from '../../network/axios/$axios';
import { formatTimezone, dateToStringTitle, enterCurrentPage } from '../../Utils/Util';
import { getBriefcasesList } from '../../actions/BriefcaseAction';
import WebinarType from './components/WebinarType';
import Topic from './components/Topic';
import HeaderTitle from '../../components/HeaderTitle';
import FilterCheck from './components/FilterCheck';
import BreadCrumbs from '../../components/BreadCrumbs';
import Game from '../../game/Game';

const { TabPane } = Tabs;

class Agenda extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentData: {},
      selectedKey: 'All', // 被选中的key
      agendaList: [],
      agendaListByTypes: [], // 临时保存选择webType数据，让Topci下标使用
      refersh: false,
      fetching: true,
      selectIndex: 0,
      mySessionsList: [],
      webtTpes: [], // 分类
      originResponse: [], // 原数据
      topics: [],
      currentType: 'Show All', // 默认web分类
      topicCurrenIndex: 'All', // 默认topic是all
      speakers: [],
    };
  }
  remoteFilter = () => {
    this.componentDidMount();
    this.setState({ currentType: 'Show All', topicCurrenIndex: 'All', selectedKey: 'All' });
  }
  // 根据getTime(data.startDate)时间排序
  sortByStartDate(data) {
    return data.sort((a, b) => {
      return new Date(a.startDate) - new Date(b.startDate);
    })
  }
  filterAgendaBySpeakerId(speakerId, data) {
    console.log('cesi', speakerId, data);
    return data.filter(v => v.speakers.indexOf(speakerId) !== -1);
  }
  componentDidMount() {
    this.getBriefcaseslist();
    this.getTopicCategory();
    this.getSpeakerByIds();
    axios('eventAgenda')
      .then((response) => {
        const { zoneNumber, speakerId } = this.props;
        this.getWebTypes(response.data);
        this.mySession(mySessions => {
          const list = [];
          // speaker 进入：筛选相关speakerId的agenda
          if (speakerId) {
            response.data = this.filterAgendaBySpeakerId(speakerId, response.data);
          }
          response.data = this.sortByStartDate(response.data);
          response.data.map(item => {
            const result = mySessions.find(v => v.AgendaId === item._id);
            // item.mustJoinBefore = 'Mon Oct 31 2022 09:25:03 GMT+0800 (GMT+08:00)'
            // item.canJoinAfter = 'Mon Oct 31 2022 09:26:03 GMT+0800 (GMT+08:00)'
            if (result) {
              // 标记被add
              item.mySessionId = result._id;
            }
            const arr = list.filter(v => v.key === dateToStringTitle(formatTimezone(item.startDate, zoneNumber)));
            if (arr.length > 0) {
              const info = arr[0];
              const datas = info.data;
              datas.push(item);
            } else {
              list.push({
                title: dateToStringTitle(formatTimezone(item.startDate, zoneNumber)),
                key: dateToStringTitle(formatTimezone(item.startDate, zoneNumber)),
                data: [item]
              })
            }
          });
          this.setState({ agendaListByTypes: list, agendaList: list, fetching: false, originResponse: [...list] })
          console.log('处理后agenda', list);
        });
      })
      .catch((error) => {
        console.log(error);
      });
    enterCurrentPage();
    this.forceUpdateTimerId = setInterval(() => {
      this.forceUpdate();
    }, 10000);
  }
  componentWillUnmount() {
    clearInterval(this.forceUpdateTimerId);
  }
  // 查询所有speaker
  getSpeakerByIds = () => {
    axios('getAllSpeaker', { params: { fields: ['name', 'display_name', 'display_company', 'display_jobTitle', 'profilePic'] } })
      .then((response) => {
        const speakers = response.data.sort(function (a, b) { return a.seq - b.seq });
        this.setState({ speakers: speakers })
        // 缓存
        sessionStorage.setItem('ALLSpeakers', JSON.stringify(speakers));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // topic
  getTopicCategory = () => {
    axios('category')
      .then((res) => {
        console.log('/getTopicCategory=', res);
        this.setState({ topics: res.data })
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // 请求mySession，判断是否被Add
  mySession = (cb) => {
    axios('mySession')
      .then((res) => {
        console.log('/mySession=', res);
        const data = res.data;
        const arr = data.reduce((prev, cur) => prev.includes(cur.AgendaId) ? prev : [...prev, cur.AgendaId], []);
        this.setState({ mySessionsList: arr });
        cb(res.data || {});
      })
      .catch((error) => {
        console.log(error);
      });
  };
  onFinish = (values) => {
    const { userForgotPassword } = this.props;
    userForgotPassword(values, this);
  };
  callback = (key, noTimeclick) => {
    this.setState({ refersh: true });
    console.log('key......', key);
    setTimeout(() => {
      var data = {
        selectedKey: key,
        refersh: false,
        topicCurrenIndex: 'All', // topic重置为All，不然数据不准确
        agendaList: this.state.agendaListByTypes, // 重置为webtype筛选的数据
      }
      if (noTimeclick) {
        data = {
          selectedKey: key,
          refersh: false,
        }
      }
      this.setState(data)
    }, 300);
  };
  // 根据时间，返回数据:解决选择时间后，add或者remove状态不更新
  getCurrentDataBySelectedKey = (selectedKey, isTopic) => {
    const { agendaList, agendaListByTypes } = this.state;
    // top数字使用，只能用webType得到的数据计算下班
    const tempData = isTopic ? agendaListByTypes : agendaList;
    const currentData = tempData.filter(data => {
      return data.key === selectedKey;
    });
    return currentData[0];
  };
  // 处理web type分类
  getWebTypes = (datas) => {
    const types = ['Show All'];
    datas.forEach(item => {
      const ishas = types.indexOf(item.type);
      if (ishas === -1) {
        types.push(item.type);
      }
    });
    this.setState({
      webtTpes: types,
    });
  };
  // 筛选Webinar Type
  // 1.正常点击webType执行->callback(selectedKey)
  onwebTypeChange = (type) => {
    const { originResponse, agendaList, selectedKey } = this.state;
    // 必须深度克隆,否则数据不更新
    const newAgendaList = JSON.parse(JSON.stringify(agendaList));
    // 处理源数据
    originResponse.forEach((firstItem, index) => {
      if (firstItem.data) {
        // 过滤
        newAgendaList[index].data = firstItem.data.filter(item => {
          if (type === 'Show All') {
            return true;
          }
          return item.type === type;
        })
      }
    });
    // topic重置为All，不然数据不准确
    this.setState({
      agendaListByTypes: newAgendaList, agendaList: newAgendaList, currentType: type, topicCurrenIndex: 'All'
    }, () => {
      // 正常点击webType
      if (selectedKey) {
        this.callback(selectedKey, true); // 更新时间选择
      }
    })
    // console.log('agendaList111', newAgendaList)
  };
  // 根据上面topic数据筛选
  onTopicChange = (topic, index) => {
    const { agendaListByTypes, agendaList, selectedKey } = this.state;
    // 必须深度克隆,否则数据不更新
    const newAgendaList = JSON.parse(JSON.stringify(agendaList));
    // 处理webTypes数据
    agendaListByTypes.forEach((firstItem, index) => {
      if (firstItem.data) {
        // 过滤
        newAgendaList[index].data = firstItem.data.filter(item => {
          if (topic === 'All') {
            return true;
          }
          return item.categories && item.categories.indexOf(topic._id) !== -1;
        })
      }
    });
    this.setState({ agendaList: newAgendaList, currentTopic: topic, topicCurrenIndex: index }, () => {
      // 正常点击webType
      if (selectedKey) {
        this.callback(selectedKey, true); // 更新时间选择
      }
    })

  };
  // 新增mysession
  addMysession = (data) => {
    const { isGuestLogin } = this.props;
    console.log('addMysession', data);
    if (isGuestLogin) {
      this.goRegisterPage();
    } else {
      // 新增MySession
      axios('addMySession', {
        data: {
          "AgendaId": data._id
        }
      })
        .then((response) => {
          console.log('/addMysession=', response);
          message.success(i18next.t('translations.add-to-my-session-success'));
          this.componentDidMount();
        })
        .catch((error) => {
          console.log(error);
        });
    }
    this.clickUpload('add Mysession ' + data.name);
  };
  //跳转注册页面
  goRegisterPage = () => {
    this.props.history.push('/register');
  };
  // 删除
  remove = (data) => {
    console.log('remove', data);
    // 删除Briefcase
    axios('deleteMySession', { path: data.mySessionId })
      .then((response) => {
        console.log('/deleteMySession=', response);
        message.success(i18next.t('translations.remove-from-my-session-success'));
        this.componentDidMount();
      })
      .catch((error) => {
        console.log(error);
      });
    this.clickUpload('delete Mysession ' + data.name);
  };
  //统计点击事件
  clickUpload = (fieldname) => {
    axios('activity', {
      data: {
        event: '[Click] Agenda ' + fieldname
      }
    })
      .then(response => {
        console.log('/activity', response);
      })
  };
  // 重新请求briefcases列表
  getBriefcaseslist = () => {
    const { userInfo, getBriefcasesList } = this.props;
    getBriefcasesList(userInfo.id);
  };
  // 根据关键词搜索
  searchPeopleByKeyword = (type, value) => {
    // this.setState({ searchType: type, keyword: value })
  };
  render() {
    const { agendaListByTypes, selectedKey, agendaList, fetching, refersh, selectIndex, mySessionsList, webtTpes, topics, currentType, topicCurrenIndex, speakers } = this.state;
    const currentData = this.getCurrentDataBySelectedKey(selectedKey);
    const { briefcasesData, userInfo, primary, language, location, menu, speakerId, links } = this.props;
    const eventAgendas = briefcasesData.event_agenda || []; // 防止报错
    const pathname = location?.pathname;
    const arr = menu.filter(obj => obj.href === pathname);
    console.log('render', new Date())
    return (
      <div className={indexCss.agenda + ' page-container center + agenda'}>
        {!speakerId && <><Row gutter={[32, 32]}>
          <BreadCrumbs
            breadCrumbActive={arr.length && (arr[0].display_title)[language]}
          />
        </Row>
          <HeaderTitle title={i18next.t('translations.agenda')} /></>}
        {fetching ? <div className="loading"><Spin /></div>
          :
          <div>
            <div>
              <Row>
                {!speakerId && <Col xs={{ span: 24 }} md={{ span: 6 }}>
                  <FilterCheck remoteFilter={this.remoteFilter} dateTimes={agendaList} currenDate={selectedKey} onDateChange={this.callback} onwebTypeChange={this.onwebTypeChange} currentType={currentType} webtTpes={webtTpes} primary={primary} onSearch={this.searchPeopleByKeyword.bind(this)} language={language}
                    topics={topics} onTopicChange={this.onTopicChange} currenTopicIndex={topicCurrenIndex} />
                </Col>}
                {currentData && <Col xs={{ span: 24 }} md={{ span: speakerId ? 24 : 18 }}>
                  {refersh ? <div className="loading"><Spin /></div> : <TabSpeakerPaneItemAll newDate={new Date()}  speakerId={speakerId} {...this.props} currentData={currentData} addMysession={this.addMysession} remove={this.remove} eventAgendas={eventAgendas} userInfo={userInfo} fetchBriefcase={this.getBriefcaseslist} primary={primary} selectIndex={selectIndex} speakers={speakers} />}
                </Col>}
                {/* 默认展示所有 */}
                <Col xs={{ span: 24 }} md={{ span: speakerId ? 24 : 18 }}>
                  {selectedKey === 'All' && agendaList && agendaList.map(data => {
                    return (
                      <TabSpeakerPaneItemAll newDate={new Date()} speakerId={speakerId} {...this.props} currentData={data} key={data.key} addMysession={this.addMysession} remove={this.remove} eventAgendas={eventAgendas} userInfo={userInfo} fetchBriefcase={this.getBriefcaseslist} primary={primary} language={language} selectIndex={selectIndex} speakers={speakers} />
                    )
                  })}
                </Col>
              </Row>
            </div>
          </div>}
        <Game link={links || '#/Agenda'} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  agendaList: state.Agenda.agendaList,
  userInfo: state.loginUserInfo.userInfo,
  briefcasesData: state.briefcases.briefcasesData,
  primary: state.profile.primary,
  language: state.profile.language,
  isGuestLogin: state.loginUserInfo.isGuestLogin,
  zoneNumber: state.loginUserInfo.zoneNumber, // 时区数字
  menu: state.profile.menu
});

const mapDispatchToProps = (dispatch) => ({
  fetchAgenDaList: (data) => {
    dispatch(fetchAgenDaList(data));
  },
  getBriefcasesList: (userId) => {
    dispatch(getBriefcasesList(userId));
  }
});
const enhance = compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps)
);
export default enhance(Agenda);
// export default connect(mapStateToProps, mapDispatchToProps)(Agenda);
