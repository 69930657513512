export default {
    "paintings-006": {
        characterModel: {
            x: -70,
            y: -30,
            z: 280,
            rx: 0,
            ry: -4.5,
            rz: 0
        },
        controls: {
            x: -170,
            y: 65,
            z: 280
        },
        painting:{
            name: "Hummingbird",
            link: "https://8.210.208.234/3d/joystick/vr_art_gallery/textures/paintings-006.jpg"
        }
    },
    "paintings-007": {
        characterModel: {
            x: -70,
            y: -30,
            z: 170,
            rx: 0,
            ry: -4.5,
            rz: 0
        },
        controls: {
            x: -170,
            y: 65,
            z: 170
        },
        painting:{
            name: "Fisherman",
            link: "https://8.210.208.234/3d/joystick/vr_art_gallery/textures/paintings-007.jpg"
        }
    },
    "paintings-008": {
        characterModel: {
            x: -70,
            y: -30,
            z: -65,
            rx: 0,
            ry: -4.5,
            rz: 0
        },
        controls: {
            x: -170,
            y: 65,
            z: -65
        },
        painting:{
            name: "Countryside",
            link: "https://8.210.208.234/3d/joystick/vr_art_gallery/textures/paintings-008.jpg"
        }
    },
    "paintings-009": {
        characterModel: {
            x: -70,
            y: -30,
            z: -155,
            rx: 0,
            ry: -4.5,
            rz: 0
        },
        controls: {
            x: -170,
            y: 65,
            z: -155
        },
        painting:{
            name: "Face",
            link: "https://8.210.208.234/3d/joystick/vr_art_gallery/textures/paintings-009.jpg"
        }
    },
    "paintings-010": {
        characterModel: {
            x: -70,
            y: -30,
            z: -240,
            rx: 0,
            ry: -4.5,
            rz: 0
        },
        controls: {
            x: -170,
            y: 65,
            z: -240
        },
        painting:{
            name: "Angel",
            link: "https://8.210.208.234/3d/joystick/vr_art_gallery/textures/paintings-010.jpg"
        }
    },
    "paintings-011": {
        characterModel: {
            x: -70,
            y: -30,
            z: -535,
            rx: 0,
            ry: -4.5,
            rz: 0
        },
        controls: {
            x: -170,
            y: 65,
            z: -535
        },
        painting:{
            name: "Angel",
            link: "https://8.210.208.234/3d/joystick/vr_art_gallery/textures/paintings-011.png"
        }
    },
    "paintings-017": {
        characterModel: {
            x: -280,
            y: -30,
            z: -535,
            rx: 0,
            ry: -1.6,
            rz: 0
        },
        controls: {
            x: -180,
            y: 65,
            z: -535
        },
        painting:{
            name: "Lighthouse",
            link: "https://8.210.208.234/3d/joystick/vr_art_gallery/textures/paintings-017.jpg"
        }
    },
    "paintings-018": {
        characterModel: {
            x: -400,
            y: -30,
            z: 215,
            rx: 0,
            ry: -4.5,
            rz: 0
        },
        controls: {
            x: -500,
            y: 65,
            z: 215
        },
        painting:{
            name: "Autumn",
            link: "https://8.210.208.234/3d/joystick/vr_art_gallery/textures/paintings-018.jpg"
        }
    },
    "paintings-019": {
        characterModel: {
            x: -400,
            y: -30,
            z: 50,
            rx: 0,
            ry: -4.5,
            rz: 0
        },
        controls: {
            x: -500,
            y: 65,
            z: 50
        },
        painting:{
            name: "Horse",
            link: "https://8.210.208.234/3d/joystick/vr_art_gallery/textures/paintings-018.jpg"
        }
    },
    "paintings-020": {
        characterModel: {
            x: -400,
            y: -30,
            z: -130,
            rx: 0,
            ry: -4.5,
            rz: 0
        },
        controls: {
            x: -500,
            y: 65,
            z: -130
        },
        painting:{
            name: "City",
            link: "https://8.210.208.234/3d/joystick/vr_art_gallery/textures/paintings-020.jpg"
        }
    },
    "paintings-021": {
        characterModel: {
            x: -400,
            y: -30,
            z: -330,
            rx: 0,
            ry: -4.5,
            rz: 0
        },
        controls: {
            x: -500,
            y: 65,
            z: -330
        },
        painting:{
            name: "Fox",
            link: "https://8.210.208.234/3d/joystick/vr_art_gallery/textures/paintings-021.jpg"
        }
    },
    "paintings-022": {
        characterModel: {
            x: -400,
            y: -30,
            z: -510,
            rx: 0,
            ry: -4.5,
            rz: 0
        },
        controls: {
            x: -500,
            y: 65,
            z: -510
        },
        painting:{
            name: "Idol",
            link: "https://8.210.208.234/3d/joystick/vr_art_gallery/textures/paintings-022.jpg"
        }
    }

}