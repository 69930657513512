import React, { Component } from 'react';
// import * as THREE from 'three';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
// import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
// import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
// import nipplejs from 'nipplejs';
import './style.css';
// import keyState from "./key-state.js"
// import paintingInfo from './paintingInfo.js';
// import $ from 'jquery'
import GameExternal from '../game/GameExternal';
import { Modal, Button } from 'antd';
import { uploadToS3 } from './upload';
import { connect } from 'react-redux';
import ExpoHallList from '../routes/ExpoHall/components/ExpoHallList';
import { CloseOutlined } from '@ant-design/icons';
import apiAxios from '../network/axios/$axios';

import i18next from 'i18next';
import { CHAT_SWITCH } from '../actions/LoginUserAction';
import ChatMessage from '../components/ChatMessage';
import ScheduleMeetingModal from '../routes/BusinessMatching/components/ScheduleMeetingModal';
import { BASE_URL } from '../network/RequestUtils';

class Hall3d extends Component {
  constructor(props) {
    super(props);
    this.state = {
      boothType: "Booth",
      scene: "Expohall",
      src: "",
      modalVisible: false,
      modalData: {},
      model: "",
      channelURL: "",
      meetingVisible: false,
      coinModelVisible: false
    }
    this.handleMessage = this.handleMessage.bind(this)
  }

  openModal = () => {
    this.setState({
      modalVisible: true
    });
  };

  handleCancel = () => {
    this.setState({
      modalVisible: false
    });
  };
  handleCoinCancel = () => {
    this.setState({ coinModelVisible: false })
  }
  handleMeetingCancel = () => {
    this.setState({
      meetingVisible: false
    });
  }
  componentWillUnmount() {
    delete this.container
    delete this.npcs
    window.removeEventListener("message", this.handleMessage)
    delete this.handleMessage
    delete this.boothData
  }
  componentDidMount() {
    let { boothType, scene } = this.state
    if (window.location.href.includes("/Gallery")) {
      this.setState({
        boothType: "Gallery",
        scene: "Gallery"
      });
      console.log("Custom hall", this.state)
      boothType = "Gallery"
      scene = "Gallery"
    }
    // this.init3d({
    //   '3DUrl': this.props.url || 'https://d1a370nemizbjq.cloudfront.net/4f3f8eda-d682-4d21-ab14-074c395ab49c.glb'
    // })
    window.addEventListener("message", this.handleMessage)
    window.testMessage = (msg) => this.handleMessage(msg)

    apiAxios('threeD', { params: { filter: { resourceId: scene } } })
      .then((response) => {
        let params = new URLSearchParams()
        const model = response.data[0]?.link
        params.append("scene", model)
        const settings = response.data[0]?.settings || {}
        Object.keys(settings).forEach(k => params.append(k, settings[k]))
        if (model) {
          const { userInfo } = this.props;
          apiAxios('threeD', { params: { filter: { resourceId: userInfo?._id || "defaultUser" } } })
            .then((response) => {
              params.append("user", response.data[0]?.link || "undefined")
              const src = `${BASE_URL}/upload/threeD/newThree.html?${params.toString()}`
              console.log('final 3d', src)
              this.setState({ src })
            })
        }
      })
      .catch((error) => {
        console.log(error);
      });


  }

  handleMessage(event) {
    try {
      let { boothType, scene } = this.state
      let data = JSON.parse(event.data)
      console.log("threeD received", data)
      const { userInfo } = this.props;
      switch (data.type) {
        case 'NoUserModel':
          this.props.history.replace("/Avatar")
          break;
        case 'SceneLoaded':
          apiAxios('threeD', { params: { filter: { resourceId: { $regex: "design" } } } })
            .then((designRes) => {
              const designs = {}
              designRes.data.forEach(em =>
                designs[em.resourceId] = em.link)

              apiAxios('Booth', { params: { filter: { boothType } } })
                // apiAxios('Booth')
                .then((response) => {
                  this.boothData = response.data;
                  let i = 0
                  apiAxios('threeD', { params: { filter: { resourceId: { "$in": this.boothData.map(em => em._id) } } } }).then((res2) => {
                    this.boothData.forEach(em => {
                      em.id = `hitboxbooth${(++i).toString().padStart(3, '0')}`
                      em.threeD = res2.data.find(em2 => em2.resourceId == em._id) || designs[`design${em.design}`] || "https://watsons-demo.chefdigital.io/upload/threeD/booth/booth01.glb"
                    })
                    this.container.contentWindow.postMessage(JSON.stringify({ type: "BoothsData", data: this.boothData }), { targetOrigin: "*" })
                  })
                })
            })

          apiAxios('getRandomUsers').then(res => {
            console.log("getRandomUsers", res)
            this.npcs = res.filter(em => em != userInfo._id);
            apiAxios('peoplerecommendation').then(res => {
              this.npcs = this.npcs.concat(res.data.map(em => em._id))
              console.log("peoplerecommendation", this.npcs)
              apiAxios('threeD', { params: { filter: { resourceId: { "$in": this.npcs } } } })
                .then(res => {
                  apiAxios("avatarDesign").then(res2 => {
                    let avatars = res2.data[0].design;
                    this.npcModels = {}
                    res.data?.forEach(m => {
                      this.npcModels[m.resourceId] = m.link
                    })
                    this.npcs.forEach(npc => {
                      if (!this.npcModels[npc]) {
                        this.npcModels[npc] = avatars[Math.floor(Math.random() * avatars.length)].src
                      }
                    })
                    console.log("npcModels", this.npcModels)
                    this.container.contentWindow.postMessage(JSON.stringify({ type: "NPCModels", data: { npc: this.npcs, model: this.npcModels } }), { targetOrigin: "*" })

                  })

                })

            })

          })
          break;

        case 'Hit':
          if (data.id?.startsWith("hitboxbooth")) {
            let order = parseInt(data.id.replace("hitboxbooth", ''))
            if (!Array.isArray(this.boothData) || !this.boothData[order - 1]) break;
            this.props.history.push(`/${scene}/Booth/` + this.boothData[order - 1]._id);
            apiAxios('activity', {
              data: {
                event: `[Click] ${scene} ` + this.boothData[order - 1].name
              }
            })

          } else if (data.id.startsWith("npc")) {
            let id = this.npcs[parseInt(data.id.replace('npc', '')) - 1]
            apiAxios('getUserDetail', { path: id })
              .then((response) => {
                this.setState({ modalData: response, modalVisible: true, model: this.npcModels[id] })

              })
              .catch((error) => {
                console.log(error);
              });
          }
          else if (data.id.startsWith("hitboxcoin")) {
            this.setState({ coinModelVisible: true })
            this.container.contentWindow.postMessage(JSON.stringify({ type: "Bypass", data: data.id }), { targetOrigin: "*" })

          }
          break
        case "User":
          uploadToS3(data.url, userInfo._id).then((link) =>
            apiAxios('createThreeD', {
              data: {
                resourceId: userInfo._id,
                link
              }
            })
              .then((response) => {
                console.log('/createThreeD=', response);
              })
              .catch((error) => {
                console.log(error);
              })
          )

      }
    }
    catch (e) {
      console.log(e)
      console.log(event.data)
    }
  }


  chartSwitch = (em) => this.props.dispatchChatSwitch(em);
  getOrCreatePrivateChat = (_id) => {
    this.chartSwitch(false)
    apiAxios('getOrCreatePrivateChat', {
      data: {
        target_user_id: _id
      }
    })
      .then((response) => {
        if (response == "") {
          const timeout = setTimeout(() => {
            apiAxios('getOrCreatePrivateChat', {
              data: {
                target_user_id: _id
              }
            }).then((response2) => {
              this.setState({ channelURL: response2.channel_url });
              this.chartSwitch(true);
            });
          }, 500)
        } else {
          this.setState({ channelURL: response.channel_url });
          this.props.dispatchChatSwitch(true);
        }
      });
  };
  render() {
    let { boothType, scene } = this.state
    const { chatSwitch } = this.props;
    return (
      <>
        {/* <div id="mobileInterface" className="noSelect">
          <div id="joystickWrapper1"></div>
        </div> */}
        <iframe ref={e => {
          this.container = e;
        }} src={this.state.src} style={{ width: '100%', height: window.innerHeight - 300 }} scrolling="no" frameBorder="0" title='iframe'></iframe>

        <Modal
          visible={this.state.modalVisible}
          width={800}
          title={`${this.state.modalData?.info?.firstname} ${this.state.modalData?.info?.lastname}`}
          footer={null}
          closeIcon={<CloseOutlined style={{ color: '#2d3436' }} />}
          onCancel={this.handleCancel}
        >
          <iframe src={`${BASE_URL}/upload/threeD/mugshot.html?model=${this.state.model}&amp;cx=0&amp;cy=40&amp;cz=24&amp;crx=-0.23&amp;cry=-0.068&amp;crz=-0.016`}></iframe>
          <Button
            type='primary'
            style={{
              fontWeight: 'bold',
              width: '180px'
            }}
            onClick={() => this.getOrCreatePrivateChat(this.state.modalData?._id)}
          >
            {i18next.t('translations.start-chat')}
          </Button>

          <Button
            type='primary'
            style={{
              fontWeight: 'bold',
              width: '180px'
            }}
            onClick={() => this.setState({ meetingVisible: true })}
          >
            {i18next.t('translations.requestMeet')}
          </Button>
          {(chatSwitch) && <ChatMessage selected_channel_url={this.state.channelURL} close={() => this.chartSwitch(false)} />}
          <ScheduleMeetingModal isModalVisible={this.state.meetingVisible} selectedItem={this.state.modalData} handleCancel={() => this.handleMeetingCancel()} />
        </Modal>
        <ExpoHallList {...this.props} scene={scene} boothType={boothType} />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  url: state.loginUserInfo.url,
  chatSwitch: state.loginUserInfo.chatSwitch,
  userInfo: state.loginUserInfo.userInfo
});

const mapDispatchToProps = (dispatch) => ({
  UPDATE_SELF_IFRAME_ITEM: (item) => {
    dispatch({
      type: 'UPDATE_SELF_IFRAME_ITEM',
      data: item
    });
  },
  dispatchChatSwitch: (flag) => {
    dispatch(CHAT_SWITCH(flag));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Hall3d);
