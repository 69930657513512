import { Modal, Row } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import React, { createRef } from 'react';
import './ProductDetailModal.less';
import i18next from 'i18next';
import product from '../Image/product.jpg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import arrowLeft from '../Image/product/arrow-left.png';
import arrowRight from '../Image/product/arrow-right.png';
import get from 'lodash/get';

const ProductDetailModal = (props) => {
  const { isModalVisible, handleCancel, data = {}, primary, language } = props;
  const slider = createRef();
  // 向左切换
  const leftBtnClick = () => {
    slider.current.slickPrev();
  };
  // 向右切换
  const rightBtnClick = () => {
    slider.current.slickNext();
  };

  const renderSliderView = () => {
    const ArrowLeft = () => (
      <img
        onClick={() => leftBtnClick()}
        className="arrow-left pointer"
        alt=""
        src={arrowLeft}
      />
    );
    const ArrowRight = () => (
      <img
        onClick={() => rightBtnClick()}
        className="arrow-right pointer"
        alt=""
        src={arrowRight}
      />
    );
    const settings = {
      autoplay: false,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      prevArrow: <ArrowLeft />,
      nextArrow: <ArrowRight />
    };
    return (
      <Slider ref={slider} {...settings} className="slider">
        {data.image &&
          data.image.map((item, index) => {
            return (
              <img
                key={index}
                className="product-img"
                src={get(item, 'src')}
                alt={get(item, 'alt')}
              />
            );
          })}
      </Slider>
    );
  };

  return (
    <Modal
      width={'120rem'}
      title={
        <div style={{ backgroundColor: primary, padding: '15px 20px' }}>
          {i18next.t('translations.product-detail-title')}
        </div>
      }
      visible={isModalVisible}
      footer={null}
      onCancel={handleCancel}
      closeIcon={<CloseOutlined style={{ color: 'white' }} />}
    >
      <div className="product-detail-modal">
        <Row align="middle" className="slider-list">
          {renderSliderView()}
        </Row>
        <div className="product-text">
          <div className="detail-title">
            {get(get(data, 'display_title'), language)}
          </div>
          <div className="detail-des1">
            <span>{get(data, 'price')}</span>
          </div>
          <div className="detail-des2">
            <div
              dangerouslySetInnerHTML={{
                __html: get(get(data, 'display_description'), language)
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProductDetailModal;
