import * as types from '../constants/ActionTypes';
import { requestAPI } from '../network/RequestUtils';
const SIZE = 8;
const SEARCH_SIZE = 12;

//获取产品列表
export function getProductList(page) {
  return (dispatch) => {
    dispatch(fetchProductList());
    requestAPI({
      path: '/product?demo=1&range=[' + [(page - 1) * SIZE, page * SIZE] + ']',
      method: 'GET'
    })
      .then((response) => {
        dispatch(receiveProductList(response));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function fetchProductList() {
  return {
    type: types.FETCH_PRODUCT_LIST
  };
}

function receiveProductList(response) {
  return {
    type: types.RECEIVE_PRODUCT_LIST,
    rawData: response
  };
}

//获取产品详情
export function getProductDetail(id) {
  return (dispatch) => {
    dispatch(fetchProductDetail());
    requestAPI({
      path: '/product/' + id + '?demo=1',
      method: 'GET'
    })
      .then((response) => {
        dispatch(receiveProductDetail(response));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function fetchProductDetail() {
  return {
    type: types.FETCH_PRODUCT_DETAIL
  };
}

function receiveProductDetail(response) {
  return {
    type: types.RECEIVE_PRODUCT_DETAIL,
    rawData: response
  };
}

//获取产品category
export function getProductCategory() {
  return (dispatch) => {
    dispatch(fetchProductCategory());
    requestAPI({
      path: '/category',
      method: 'GET'
    })
      .then((response) => {
        dispatch(receiveProductCategory(response.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function fetchProductCategory() {
  return {
    type: types.FETCH_PRODUCT_CATEGORY
  };
}

function receiveProductCategory(response) {
  return {
    type: types.RECEIVE_PRODUCT_CATEGORY,
    rawData: response
  };
}

//搜索产品
export function searchProductList(categories, keyword, page) {
  const start_index = (page - 1) * SEARCH_SIZE;
  const end_index = page * SEARCH_SIZE;
  let items = '';
  categories.map((obj) => {
    items = items + '"' + obj + '",';
  });
  if (items.endsWith(',')) {
    items = items.substr(0, items.length - 1);
  }
  const list = categories.length ? '[' + items + ']' : categories;
  return (dispatch) => {
    dispatch(fetchSearchProductList());
    requestAPI({
      path: `/product/search?categories=${list}&keyword=${keyword}&range=[${start_index},${end_index}]&merchant_id=test&demo=1`,
      method: 'GET'
    })
      .then((response) => {
        dispatch(receiveSearchProductList(response));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function fetchSearchProductList() {
  return {
    type: types.FETCH_SEARCH_PRODUCT_LIST
  };
}

function receiveSearchProductList(response) {
  return {
    type: types.RECEIVE_SEARCH_PRODUCT_LIST,
    rawData: response
  };
}
