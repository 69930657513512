import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { createAutocomplete } from '@algolia/autocomplete-core';

import { Input } from "antd";
import { SearchNormal1 } from 'iconsax-react';
import i18next from 'i18next';
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { compose } from "redux";

const pagesJson = []; //测试搜索

function HeaderSearch(props) {
  const { setSearchHeader } = props;
  const [autocompleteState, setAutocompleteState] = useState({});

  const autocomplete = useMemo(
    () =>
      createAutocomplete({
        onStateChange({ state }) {
          setAutocompleteState(state);
        },
        getSources() {
          return [
            {
              sourceId: 'pages-source',
              getItemInputValue({ item }) {
                return item.query;
              },
              getItems({ query }) {
                if (!query) {
                  return pagesJson;
                }
                return pagesJson.filter((item) => (
                  item.title.toLowerCase().includes(query.toLowerCase())
                ))
              },
              getItemUrl({ item }) {
                return item.url;
              },
              templates: {
                item({ item }) {
                  return (
                    item.title
                  );
                },
              },
            },
          ];
        },
      }),
    []
  );

  //搜索
  function onSearch(e) {
    props.history.push(`/Search?Query=${e.target.value}`);
    setSearchHeader(false);
  };

  return (
    <div {...autocomplete.getRootProps({})}>
      <Input
        {...props.inputFocusProp}
        {...autocomplete.getInputProps({})}
        placeholder={i18next.t('translations.search')+"..."}
        prefix={
          <SearchNormal1 size="22" className="hp-text-color-black-80 hp-text-color-dark-20" />
        }
        onPressEnter={onSearch}
      />
    </div>
  );
}

const enhance = compose(
  withRouter,
  withTranslation('translations')
);

export default enhance(HeaderSearch);