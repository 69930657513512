import {
  FETCH_BRIEFCASES_LIST,
  RECEIVE_BRIEFCASES_LIST
} from '../constants/ActionTypes';
import { getTreeData } from '../Utils/Util';

const initialState = {
  fetching: false,
  fetched: false,
  briefcasesData: {}
};

const briefcases = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BRIEFCASES_LIST:
      return Object.assign({}, state, {
        fetching: true
      });
    case RECEIVE_BRIEFCASES_LIST:
      return Object.assign({}, state, {
        fetching: false,
        fetched: true,
        briefcasesData: action.rawData
      });
    default:
      return state;
  }
};

export default briefcases;
