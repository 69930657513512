import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";

import { Row, Col, Drawer, Button, Dropdown, Menu, message } from "antd";
import { RiMore2Line, RiMenuFill, RiCloseFill } from "react-icons/ri";

import Breadcrumbs from "../../components/BreadCrumbs";
import InfoProfile from "./InfoProfile";
import MenuProfile from "./menu";
import PasswordProfile from "./PasswordChange";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { updateUserInfoFetch, userForgotPassword } from "../../actions/LoginUserAction";
import { connect } from "react-redux";
import Briefcase from "../Briefcase/Briefcase";
import i18next from "i18next";
import MyAvailabilityNew from '../BusinessMatching/components/MyAvailabilityNew';
import { getMyPrivateMeetingList } from "../../actions/BusinessMatchingAction";
import MyMeetings from "../BusinessMatching/components/MyMeetings";
import Avatar from "../../3d/Avatar";

function Profile(props) {
  const { userInfo, language, primary, getMyPrivateMeetingList, meetings, unavailable } = props;
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    requestMyPrivateMeetingList();
  }, [userInfo]);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const rateMenu = (
    <Menu>
      <Menu.Item key="0">Change Avatar</Menu.Item>
    </Menu>
  );

  const moreBtn = () => {
    return (
      <Dropdown overlay={rateMenu} placement="bottomLeft">
        <Button
          type="text"
          icon={<RiMore2Line className="hp-text-color-black-100 hp-text-color-dark-0" size={24} />}
        ></Button>
      </Dropdown>
    );
  }

  // 重新请求briefcases列表
  const requestMyPrivateMeetingList = () => {
    getMyPrivateMeetingList(userInfo._id);
  };

  return (
    <div className="center">
      <Row gutter={[32, 32]} className="hp-mb-32">
        <Drawer
          title={moreBtn()}
          className="hp-profile-mobile-menu"
          placement="left"
          closable={true}
          onClose={onClose}
          visible={visible}
          closeIcon={
            <RiCloseFill
              className="remix-icon hp-text-color-black-80"
              size={24}
            />
          }
        >
          <MenuProfile
            onCloseDrawer={onClose}
            moreBtnCheck="none"
            footer="none"
          />
        </Drawer>

        <Col span={24}>
          <Row gutter={[32, 32]} justify="space-between">
            <Breadcrumbs breadCrumbActive={i18next.t('translations.profile')} />

          </Row>
        </Col>

        <Col span={24}>
          {/* <Row className="hp-profile-mobile-menu-btn hp-bg-color-black-0 hp-bg-color-dark-100 hp-border-radius hp-py-12 hp-px-sm-8 hp-px-24 hp-mb-16">
            <Button
              className="hp-p-0"
              type="text"
              icon={
                <RiMenuFill
                  size={24}
                  className="remix-icon hp-text-color-black-80 hp-text-color-dark-30"
                />
              }
              onClick={showDrawer}
            ></Button>
          </Row> */}

          <Row className="hp-bg-color-black-0 hp-bg-color-dark-100 hp-border-radius hp-pr-sm-16 hp-pr-32">
            <MenuProfile moreBtn={moreBtn} userInfo={userInfo} onCloseDrawer={onClose} loadData={requestMyPrivateMeetingList} />

            <Col
              flex="1 1"
              className="hp-pl-sm-16 hp-pl-32 hp-py-sm-24 hp-py-32 hp-pb-24 hp-overflow-hidden"
            >
              <Switch>
                <Route path="/Profile" exact>
                  <InfoProfile {...props} />
                </Route>

                <Route path="/Avatar">
                  <Avatar {...props} />
                </Route>

                <Route path="/Briefcase">
                  <Briefcase {...props} />
                </Route>

                <Route path="/Availability">
                  <MyAvailabilityNew userInfo={userInfo} primary={primary} data={unavailable} meetings={meetings} getData={requestMyPrivateMeetingList} {...props} />
                </Route>

                <Route path="/MeetingManagement">
                  <MyMeetings {...props} />
                </Route>

                <Route path="/ChangePassword">
                  <PasswordProfile {...props} />
                </Route>

              </Switch>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => {
  const data = state.businessMatching.myPrivateMeetingData;
  return {
    userInfo: state.loginUserInfo.userInfo,
    language: state.profile.language,
    primary: state.profile.primary,
    unavailable: data.unavailable,
    meetings: data.meetings,
  }
};

const mapDispatchToProps = (dispatch) => ({
  userForgotPassword: (data, myThis) => {
    dispatch(userForgotPassword(data)).then((data) => {
      console.log('data', data, myThis);
      setTimeout(() => {
        myThis.props.history.replace('/');
      }, 100);
    });
  },
  updateUserInfoFetch: (data, myThis) => {
    dispatch(updateUserInfoFetch(data, (data) => {
      console.log('data', data, myThis);
      message.success(i18next.t('translations.update-profile-success'));
    }));
  },
  getMyPrivateMeetingList: (userId) => {
    dispatch(getMyPrivateMeetingList(userId));
  }
});
const enhance = compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps)
);
export default enhance(Profile);
