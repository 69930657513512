/*
 * @Description:
 * @Version: 1.0
 * @Autor: ricky shen
 * @Date: 2022-03-20 13:24:35
 * @LastEditors: ricky shen
 * @LastEditTime: 2022-03-24 18:45:18
 */
import React, { Component } from 'react';
// import { Row, List, Col, Tabs } from 'antd';
import { connect } from 'react-redux';
import './InteractionTabPane.less';
// import get from 'lodash/get';
import { withRouter } from 'react-router-dom';
// import { UserAddOutlined } from '@ant-design/icons';
// const { TabPane } = Tabs;

class InteractionTabPane extends Component {
  constructor(props) {
    super();
  }
  componentDidMount() { }
  render() {
    const { data } = this.props;
    console.log('InteractionTabPane', data);
    return (data?.Slido && (
      <div className="InteractionTabPane">
        <iframe
          className="video"
          src={data.Slido}
          style={{ width: '100%' }}
          title="Slido"
          frameBorder="0"
        ></iframe>
      </div>
    ));
  }
}

const mapStateToProps = (state) => ({
  language: state.profile.language
});

export default connect(mapStateToProps, null)(withRouter(InteractionTabPane));
