import * as types from '../constants/ActionTypes';
import axios from '../network/axios/$axios';

//获取产品列表
export function getBriefcasesList(userId) {
  return (dispatch) => {
    dispatch(fetchBriefcasesList());
    axios('briefcases')
      .then((response) => {
        let event_agenda = [];
        let exhibitor_booths = [];
        let network = [];
        response.data.map(item => {
          if (item.model === 'Booth/Booth') {
            exhibitor_booths.push(item);
          } else if (item.model === 'Agenda/Agenda') {
            event_agenda.push(item);
          } else if (item.model === 'Networking/Networking') {
            network.push(item);
          }
        });
        const data = {
          event_agenda,
          exhibitor_booths,
          network
        };
        const boothsIds = [];
        const agendaIds = [];
        const visitorIds = [];
        exhibitor_booths.map(item => boothsIds.push(item.refId));
        event_agenda.map(item => agendaIds.push(item.refId));
        network.map(item => visitorIds.push(item.refId));
        axios('Booth', { params: { filter: JSON.stringify({ _id: { "$in": boothsIds } }) } })
          .then((response) => {
            console.log('/briefcasesBooth=', response);
            const list = response.data;
            exhibitor_booths.map(item => {
              const arr = list.filter(v => v._id === item.refId);
              item.detail = arr[0];
            });
            axios('eventAgenda', { params: { filter: JSON.stringify({ _id: { "$in": agendaIds } }) } })
              .then((response) => {
                console.log('/eventAgenda=', response);
                const list = response.data;
                event_agenda.map(item => {
                  const arr = list.filter(v => v._id === item.refId);
                  item.detail = arr[0];
                });
                axios('networkingPeople', { params: { filter: JSON.stringify({ _id: { "$in": visitorIds } }) } })
                  .then((response) => {
                    console.log('/networkingPeople=', response);
                    const list = response.data;
                    network.map(item => {
                      const arr = list.filter(v => v._id === item.refId);
                      item.detail = arr[0];
                    });
                    dispatch(receiveBriefcasesList(data));
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

function fetchBriefcasesList() {
  return {
    type: types.FETCH_BRIEFCASES_LIST
  };
}

function receiveBriefcasesList(response) {
  return {
    type: types.RECEIVE_BRIEFCASES_LIST,
    rawData: response
  };
}