export default {
  login: {
    url: '/User/universal/login',
    method: 'post'
  },
  Language: {
    url: '/Info/Language',
    method: 'get'
  },
  // Landing
  LandingPage: {
    url: '/Info/Page?filter={"path":"/Landing"}',
    method: 'get'
  },
  // 菜单
  Menu: {
    url: '/Info/Menu',
    method: 'get'
  },
  // zone轮播图
  zoneBanners: {
    url: '/Info/Zone?sort={"seq":1}',
    method: 'get'
  },
  // 导航下拉描述信息
  EventAdditionalInfo: {
    url: '/Info/EventAdditionalInfo?filter={%22name%22:%22EventInfo%22}',
    method: 'get'
  },
  zones: {
    url: '/zones',
    method: 'get'
  },
  booths: {
    url: '/Booth/Booth/',
    method: 'get'
  },
  activity: {
    url: '/Event/ClickEvent',
    method: 'post'
  },
  registrationPage: {
    url: '/registration-page',
    method: 'get'
  },
  registrationFormField: {
    url: '/Info/Page?filter={"path":"/Register"}',
    method: 'get'
  },
  // ?filter={"field":<RegistrationChoice_name>} ：networking的filter子标题
  registrationFormChoices: {
    url: '/Misc/RegistrationChoices/',
    method: 'get'
  },
  register: {
    url: '/User/Visitor/',
    method: 'post'
  },
  getUserDetail: {
    url: '/User/universal/',
    method: 'get'
  },
  getJublia: {
    url: '/User/Visitor/jublia',
    method: "get"
  },
  updateUser: {
    url: '/User/',
    method: 'put'
  },
  footer: {
    url: '/Info/Footer',
    method: 'get'
  },
  footerDesign: {
    url: '/Info/Design?filter={"target":"Footer"}',
    method: 'get'
  },
  lobby: {
    url: '/Info/Page?filter={"path":"/Lobby"} ',
    method: 'get'
  },
  presenters: {
    url: '/Info/Design?filter={"target":"Host"}',
    method: 'get'
  },
  boothDesign: {
    url: '/Info/Design?filter={"target":"Booth"}',
    method: 'get'
  },
  avatarDesign: {
    url: '/Info/Design?filter={"target":"Avatar3D"}',
    method: 'get'
  },
  eventAgenda: {
    url: '/Agenda/Agenda',
    method: 'get'
  },
  eventAgendaDetail: {
    url: '/Agenda/Agenda/',
    method: 'get'
  },
  // 根据id，请求下面的人员：/Agenda/Speaker?filter={"_id":["61ef67671a22ff9af9938810","61ef67671a22ff9af9938811"]}
  SpeakerByIds: {
    url: '/Agenda/Speaker/',
    method: 'get'
  },
  // 查询所有spaker
  getAllSpeaker: {
    url: '/Agenda/Speaker',
    method: 'get'
  },
  briefcases: {
    url: '/Misc/Briefcase',
    method: 'get'
  },
  addBriefcases: {
    url: '/Misc/Briefcase',
    method: 'post'
  },
  deleteBriefcases: {
    url: '/Misc/Briefcase/',
    method: 'delete'
  },
  infoPage: {
    url: '/Info/Page?filter={"path":"/InfoPage"}',
    method: 'get'
  },
  eventInfo: {
    url: '/Info/EventInfo/61e1465711747d08fa7c519e',
    method: 'get'
  },
  Booth: {
    url: '/Booth/Booth',
    method: 'get'
  },
  product: {
    url: '/Booth/Product',
    method: 'get'
  },
  navBarItem: {
    url: '/Booth/NavBarItem/',
    method: 'get'
  },
  mySession: {
    url: '/Misc/MySession',
    method: 'get'
  },
  addMySession: {
    url: '/Misc/MySession',
    method: 'post'
  },
  deleteMySession: {
    url: '/Misc/MySession/',
    method: 'delete'
  },
  businessNature: {
    url: '/Misc/BusinessNature',
    method: 'get'
  },
  category: {
    url: '/Agenda/Category',
    method: 'get'
  },
  search: {
    url: '/Search/',
    method: 'get'
  },
  SocialMediaIcon: {
    url: '/Misc/SocialMediaIcon/',
    method: 'get'
  },
  SocialLounge: {
    url: '/Meeting/Meeting',
    method: 'get'
  },
  getOrCreateChatUser: {
    url: '/SendBird/get-or-create-chat-user',
    method: 'get'
  },
  getOrJoinPublicChat: {
    url: '/SendBird/get-or-join-public-chat',
    method: 'get'
  },
  getOrCreatePrivateChat: {
    url: '/SendBird/get-or-create-private-chat',
    method: 'post'
  },
  getOrJoinBoothChat: {
    url: '/SendBird/get-or-join-booth-chat',
    method: 'post'
  },
  resetPassword: {
    url: '/User/universal/resetPassword',
    method: 'post'
  },
  forgetPassword: {
    url: '/User/universal/forgetPassword',
    method: 'post'
  },
  enterPage: {
    url: '/Event/PageEvent',
    method: 'post'
  },
  myPrivateMeeting: {
    url: '/Meeting/MyPrivateMeeting/',
    method: 'get'
  },
  addToUnavailable: {
    url: '/Meeting/MyPrivateMeeting/',
    method: 'put'
  },
  sendNameCard: {
    url: '/SendBird/{chatId}/send-name-card',
    method: 'get'
  },
  NetworkingFilter: {
    url: '/Info/Page?filter={"path":"/Networking"}',
    method: 'get'
  },
  peoplerecommendation: {
    url: '/Network/People/recommendation',
    method: 'get'
  },
  networkingPeople: {
    url: '/Network/People',
    method: 'get'
  },
  cretePrivateMeeting: {
    url: '/Meeting/PrivateMeeting',
    method: 'post'
  },
  editPrivateMeeting: {
    url: '/Meeting/PrivateMeeting/{id}/{status}',
    method: 'post'
  },
  getFilterMeeting: {
    url: '/Meeting/PrivateMeeting/',
    method: 'get'
  },
  EnquiryMessage: {
    url: '/Booth/EnquiryMessage',
    method: 'post'
  },
  boothBanner: {
    url: '/Info/Page?filter={"path":"/Zone"}',
    method: 'get'
  },
  email: {
    url: '/Email/{trigger}',
    method: 'post'
  },
  hallTag: {
    url: '/Info/HallTag',
    method: 'get'
  },
  hallTagGroup: {
    url: '/Info/HallTagGroup?sort={"seq":"ASC"}',
    method: 'get'
  },
  infoHallBanner: {
    url: '/Info/HallBanner?sort={"seq":"ASC"}',
    method: 'get'
  },
  boothNavBarItem: {
    url: '/Booth/NavBarItem',
    method: 'get'
  },
  geExhibitortUser: {
    url: '/User/Exhibitor',
    method: 'get'
  },
  getAgendaADs: {
    url: '/Info/Page?filter={"path":"/AgendaADs"}',
    method: 'get'
  },
  checkMeeting: {
    url: '/Meeting/PrivateMeeting/check',
    method: 'post'
  },
  threeD: {
    url: '/Misc/ThreeD',
    method: 'get'
  },
  createThreeD: {
    url: '/Misc/ThreeD',
    method: 'post'
  },
  updateThreeD: {
    url: '/Misc/ThreeD/',
    method: 'put'
  },
  gameLink: {
    url: '/Game/HiddenObject/link',
    method: 'post'
  },
  gameLinkCatch: {
    url: '/Game/HiddenObject/catch',
    method: 'post'
  },
  getRandomUsers: {
    url: '/WebSocket/random_users',
    method: 'get'
  },
  instantCall: {
    url: '/Meeting/PrivateMeeting/video_call',
    method: "post"
  }

};
