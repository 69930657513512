import { Button, Divider, Dropdown, Input, Menu, Modal } from 'antd';
import './ScheduleMeetingModal.less';
import React, { Component } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import apiAxios from '../../../network/axios/$axios';
import dayjs from 'dayjs';
import { getDaysBetween, getRegionDateArray, getTwoDateRangeDate } from '../../../Utils/Util';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { saveSelectedTabKey } from '../../../actions/BusinessMatchingAction';
const { TextArea } = Input;

class ScheduleMeetingModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valueMessage: '',
      dateList: [],
      selectDate: 'Select Date',
      selectTime: 'Select Time',
      availableTimes: [],
      unavailableList: [],
      meetingList: []
    };
  }

  componentDidMount() {
    apiAxios('eventInfo')
      .then((response) => {
        console.log('/eventInfo', response);
        // const endDate = response.endDate;
        // const today = dayjs().format('YYYY-MM-DD');
        // const days = getDaysBetween(today, endDate);
        // const dateList = getTwoDateRangeDate(days);

        const today = dayjs('2023-02-28').format('YYYY-MM-DD');
        const days = getDaysBetween(today, '2023-12-25');
        const dateList = getTwoDateRangeDate(today, days);
        this.setState({ dateList });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.isModalVisible && nextProps.isModalVisible) {
      // 获取参会2个人的不可用时间段
      let secondUserId = nextProps.selectedItem._id;
      if (this.props.action === 'edit') {
        const toMan = nextProps.selectedItem.toMan;
        secondUserId = toMan._id;
      }
      const list = [this.props.userInfo._id, secondUserId];
      let unavailableList = [];
      let meetingList = [];
      list.map(userId => {
        apiAxios('checkMeeting', {
          data: {
            "id": userId
          }
        })
          .then((response) => {
            console.log('/checkMeeting', response);
            unavailableList = [...unavailableList, ...response.unavailable];
            meetingList = [...meetingList, ...response.meetings];
            // 合并2个人不可用时间
            this.setState({ meetingList, unavailableList })
          })
          .catch((error) => {
            console.log(error);
          });
      })
    }
  }

  onChange = (e) => {
    const { value } = e.target;
    this.setState({
      valueMessage: value
    });
  };

  handleMenuClick = (e) => {
    const { unavailableList, meetingList } = this.state;
    const openTime = ['00:00 - 23:59'];
    const list = getRegionDateArray(openTime);
    const arr = [];
    list.forEach((item, index) => {
      const strs = item.split('-');
      const dateTime = e + ' ' + strs[0];
      const dateUnix = dayjs(dateTime).unix();
      const currrentUnix = dayjs().unix();
      // 用户设置的不可用时间及已经预约会议的时间都不可选择
      if ([...unavailableList, ...meetingList].indexOf(dateUnix) < 0 && dateUnix > currrentUnix) {
        arr.push(item);
      }
    });
    this.setState({ selectDate: e, availableTimes: arr, selectTime: 'Select Time' });
  }

  handleMenuClick1 = (e) => {
    this.setState({ selectTime: e })
  }

  // 发送会议请求
  sendMeetingRequest = () => {
    const { userInfo, selectedItem, handleCancel } = this.props;
    const { selectDate, selectTime, valueMessage } = this.state;
    const str = selectTime.split('-');
    const date = selectDate + 'T' + str[0] + ':00.000Z';
    const list = [userInfo._id, selectedItem._id];
    apiAxios('cretePrivateMeeting', {
      data: {
        startTime: date,
        ParticipantIDs: list,
        note: valueMessage
      }
    })
      .then((response) => {
        console.log('/cretePrivateMeeting=', response);
        this.props.saveSelectedTabKey('2');
        handleCancel();
        this.setState({ selectDate: 'Select Date', selectTime: 'Select Time', valueMessage: '' })
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //更新会议
  updateMeeting = () => {
    const { selectedItem, handleCancel } = this.props;
    const { valueMessage, selectDate, selectTime } = this.state;
    const str = selectTime.split('-');
    const date = selectDate + 'T' + str[0] + ':00.000Z';
    apiAxios('editPrivateMeeting', {
      pathObj: { id: selectedItem._id, status: 'RESCHEDULE' },
      data: {
        note: valueMessage,
        startTime: date
      }
    })
      .then((response) => {
        console.log('/editPrivateMeeting=', response);
        handleCancel();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { isModalVisible, handleCancel, primary, action } = this.props;
    const { valueMessage, dateList, selectDate, selectTime, availableTimes } = this.state;

    const menu = (
      <Menu style={{ height: 300, overflowY: 'scroll' }}>
        {dateList.map((item, index) => (
          <Menu.Item key={item + index} onClick={() => this.handleMenuClick(item)}>
            {item}
          </Menu.Item>
        ))}
      </Menu>
    );

    const menu1 = (
      <Menu style={{ height: 300, overflowY: 'scroll' }}>
        {availableTimes.map((item, index) => (
          <Menu.Item key={item + index} onClick={() => this.handleMenuClick1(item)}>
            {item}
          </Menu.Item>
        ))}
        {availableTimes.length === 0 && <span>{i18next.t('translations.no-availability')}</span>}
      </Menu>
    )

    return (
      <Modal
        width={800}
        className="schedule-meeting-modal"
        title={<div>{i18next.t('translations.schedule-meeting')}</div>}
        visible={isModalVisible}
        onCancel={() => {
          handleCancel();
          this.setState({
            valueMessage: ''
          });
        }}
        closeIcon={<CloseOutlined />}
        footer={
          <div className="flex flex_j_r">
            <Button
              style={{
                fontWeight: 'bold',
              }}
              onClick={() => {
                handleCancel();
                this.setState({
                  valueMessage: ''
                });
              }}
            >
              {i18next.t('translations.cancel')}
            </Button>
            <Button
              disabled={selectDate === 'Select Date' || selectTime === 'Select Time' || !valueMessage}
              style={{
                background: primary,
                color: 'white',
                fontWeight: 'bold',
                borderRadius: '.8rem'
              }}
              onClick={() => {
                if (action === 'edit') {
                  this.updateMeeting();
                } else {
                  this.sendMeetingRequest();
                }
              }}
            >
              {i18next.t('translations.confirm')}
            </Button>
          </div>
        }
      >
        <div className='hp-p2-body'>
          <div className="fw700 f20">{i18next.t('translations.pick-a-time')}</div>
          <div className="hp-mt-8 hp-mb-16">{i18next.t('translations.choose-time-des')}</div>

          <Dropdown overlay={menu} trigger={['click']}>
            <div className="dropdown">
              <span>{selectDate === 'Select Date' ? i18next.t('translations.select-date') : selectDate}</span>
              <span className="remix-icon f18">
                <i className="ri-calendar-line" />
              </span>
            </div>
          </Dropdown>

          <Dropdown className='hp-mt-16' overlay={menu1} trigger={['click']}>
            <div className="dropdown">
              <span>{selectTime === 'Select Time' ? i18next.t('translations.select-time') : selectTime}</span>
              <span className="remix-icon f18">
                <i className="ri-calendar-line" />
              </span>
            </div>
          </Dropdown>

          <Divider style={{ backgroundColor: '#DFE6E9' }} />

          <div className="fw700 f20">{i18next.t('translations.description')}</div>
          <div className="hp-mt-8 hp-mb-16">{i18next.t('translations.meet-message')}</div>
          <div style={{ width: 280 }}>
            <TextArea value={valueMessage} rows={4} onChange={this.onChange} />
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  primary: state.profile.primary,
  userInfo: state.loginUserInfo.userInfo,
  myPrivateMeetingData: state.businessMatching.myPrivateMeetingData
});

const mapDispatchToProps = (dispatch) => ({
  saveSelectedTabKey: (key) => {
    dispatch(saveSelectedTabKey(key));
  },
});

const enhance = compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps));

export default enhance(ScheduleMeetingModal);
