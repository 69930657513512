import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom'
import i18next from 'i18next';
import Zoom2 from './Zoom2';
// import i18next from 'i18next';
// import get from 'lodash/get';
// export default function Zoom(props) {
//   const { link } = props;
//   return (
//     <div id="zoom-container">
//       <div onClick={backIframe}>返回</div>
//       {link ? <iframe src={link} style={{ width: '100%' }} height="600"></iframe> : <iframe srcDoc={ZoomHTML} style={{ width: '100%' }} height="600"></iframe>}

//     </div>
//   );
// }
class Zoom extends Component {
  back = () => {
    this.props.BACK_IFRAME();

  };
  render() {
    const { link, linkType, height, showZoomTitle } = this.props;
    console.log("zoom link", link)
    let meetingNumber, passWord;
    if (link && linkType === 'zoom') {
      sessionStorage.setItem('zoomData', link);
      const decodedLink = JSON.parse(link);
      meetingNumber = decodedLink.meetingNumber
      passWord = decodedLink.passWord
    }
    var host = window.location.toString().replace(/#.*/, '');
    if (host == "localhost") {
      host = "http://" + host + ":3000";
    }
    const zoom_url = `${host}${host.endsWith('/') ? "" : "/"}#/Zoom/${meetingNumber}${passWord ? `?p=${passWord}` : ""}`;
    const zoom_client_url = `https://success.zoom.us/j/${meetingNumber}`;
    return (
      <div id="zoom-container">
        {/* <div onClick={this.back}>返回</div> */}
        {/* {link && linkTy…pe === 'zoom' ? <Zoom2 style={{ width: '100%', height: "600px" }} zoomData={link} /> : <iframe src={link} style={{ width: '100%' }} height="600" frameBorder="0" title='iframe'></iframe>} */}
        {<iframe src={linkType == 'zoom' ? zoom_url : link} style={{ width: '100%' }} height={height || 600} frameBorder="0" title='iframe' />}
        {showZoomTitle && <div>{i18next.t('translations.zoom-title')} <a target="_blank" href={zoom_client_url}>{i18next.t('translations.links')}</a></div>}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  BACK_IFRAME: () => {
    dispatch({
      type: 'BACK_IFRAME',
      // data: item
    });
  }
});
export default connect(null, mapDispatchToProps)(Zoom);
