import * as types from '../constants/ActionTypes';
import { requestAPI } from '../network/RequestUtils';

// 我的议程
export function fetchMySessionsList(data) {
  console.log('fetchAgenDaList', data);
  return (dispatch) => {
    dispatch({
      type: types.FETCH_MY_SESSION_LIST,
      data: data
    });
    // resolve(data)
    // 告诉调用代码不需要再等待。
    return Promise.resolve(data);
  };
  return (dispatch) => {
    // 目的是为了外面能then收到里面的resolve
    return requestAPI({
      path: '',
      method: 'GET',
      params: data
    })
      .then((response) => {
        dispatch({
          type: types.FETCH_MY_SESSION_LIST,
          data: response
        });
        return Promise.resolve(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
// 获取所有议程列表
export function fetchAgenDaList(data) {
  console.log('fetchMySessionsList', data);
  return (dispatch) => {
    dispatch({
      type: types.FETCH_AGENDA_LIST,
      data: data
    });
    // resolve(data)
    // 告诉调用代码不需要再等待。
    return Promise.resolve(data);
  };
  return (dispatch) => {
    // 目的是为了外面能then收到里面的resolve
    return requestAPI({
      path: '',
      method: 'GET',
      params: data
    })
      .then((response) => {
        dispatch({
          type: types.FETCH_AGENDA_LIST,
          data: response
        });
        return Promise.resolve(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
// 

//addMysession
export function addMysession(data) {
  console.log('addMysession', data);
  return (dispatch) => {
    // 目的是为了外面能then收到里面的resolve
    return requestAPI({
      path: '',
      method: 'GET',
      params: data
    })
      .then((response) => {
        // dispatch({
        //   type: types.FETCH_AGENDA_LIST,
        //   data: response
        // });
        return Promise.resolve(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
//remove
export function remove(data) {
  console.log('remove', data);
  return (dispatch) => {
    // 目的是为了外面能then收到里面的resolve
    return requestAPI({
      path: '',
      method: 'GET',
      params: data
    })
      .then((response) => {
        // dispatch({
        //   type: types.FETCH_AGENDA_LIST,
        //   data: response
        // });
        return Promise.resolve(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };
}