import React, { Component } from 'react';
import './index.less';
import i18next from 'i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Col, Row, message } from 'antd';
import './index.less';
import { ROOM_DATA } from './constants';
import SocialList from './components/SocialList';
import Room from './components/Room';
import PasswordModal from './components/PasswordModal';
import axios from '../../network/axios/$axios';
import BreadCrumbs from '../../components/BreadCrumbs';
import Game from '../../game/Game';

class SocialLoungeScreen extends Component {
  constructor(props) {
    super();

    this.state = {
      link: '',
      isModalVisible: false,
      selectItem: null,
      joinSuccess: false,
      meetingRoom: []
    };
  }

  componentDidMount() {
    axios('SocialLounge')
      .then((response) => {
        console.log('/Meeting/Meeting', response);
        this.setState({ meetingRoom: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  clickItem = (item) => {
    if (item.hasPass) {
      this.setState({ selectItem: item, isModalVisible: true })
    } else {
      this.setState({ link: item.url })
    }
  };
  // 退出room
  onClosed = () => {
    this.setState({ link: '', joinSuccess: false })
  };

  passwordModalHiddle = () => {
    this.setState({ isModalVisible: false })
  };

  passwordHandleOk = (value) => {
    const { selectItem } = this.state;
    if (selectItem.password === value) {
      this.setState({ link: selectItem.url, isModalVisible: false })
    } else {
      message.error(i18next.t('translations.password-not-match'));
    }
  };
  // 加入room成功
  joinSuccess = () => {
    this.setState({ joinSuccess: true })
  };

  render() {
    const { username, primary, menu, location, language } = this.props;
    const pathname = location.pathname;
    const arr = menu.filter(obj => obj.href === pathname);
    const { link, isModalVisible, joinSuccess, meetingRoom } = this.state;
    return (
      <div className="social-lounge-page page-container center">
        <Row gutter={[32, 32]}>
          <BreadCrumbs
            breadCrumbActive={arr.length && (arr[0].display_title)[language]}
          />
        </Row>
        <SocialList data={meetingRoom} link={link} joinSuccess={joinSuccess} username={username} clickItem={this.clickItem.bind(this)} />
        {link && <Room link={link} username={username} onClosed={this.onClosed.bind(this)} onJoined={this.joinSuccess.bind(this)} />}
        <PasswordModal
          isModalVisible={isModalVisible}
          handleCancel={this.passwordModalHiddle}
          handleOk={this.passwordHandleOk}
          primary={primary} />
        <Game link='#/SocialLounge' />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  primary: state.profile.primary,
  language: state.profile.language,
  username: state.loginUserInfo.userInfo.firstname + ' ' + state.loginUserInfo.userInfo.lastname,
  menu: state.profile.menu
});

const enhance = compose(withTranslation('translations'), connect(mapStateToProps, null));

export default enhance(SocialLoungeScreen);
