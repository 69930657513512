import React, { Component } from 'react';
// import indexCss from './MySessions.module.css';
import indexCss from './MySessions.module.css';
import './globalAgenda.less'
import { Divider, Tabs, Spin, message, Row, Col } from 'antd';
import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import TabPaneItem from './components/TabPaneItem';
import TabSpeakerPaneItemAll from './components/TabSpeakerPaneItemAll';
import { fetchMySessionsList } from '../../actions/AgendaAction';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import TopHeader from "./components/TopHeader";
import { getBriefcasesList } from '../../actions/BriefcaseAction';
import axios from '../../network/axios/$axios';
import { dateToStringTitle, enterCurrentPage } from '../../Utils/Util';
import WebinarType from './components/WebinarType';
import Topic from './components/Topic';
import HeaderTitle from '../../components/HeaderTitle';
import FilterCheck from './components/FilterCheck';
import BreadCrumbs from '../../components/BreadCrumbs';

const { TabPane } = Tabs;

class MySessions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentData: [],
      selectedKey: 'All', // 被选中的key
      selectIndex: 1,
      mySessionsList: [],
      total: null,
      webtTpes: [], // 分类
      originResponse: [], // 原数据
      topics: [],
      currentType: 'All', // 默认web分类
      agendaListByTypes: [], // 临时保存选择webType数据，让Topci下标使用
      topicCurrenIndex: 'All', // 默认topic是all
    };
  }
  callback = (key, noTimeclick) => {
    this.setState({ refersh: true });
    setTimeout(() => {
      var data = {
        selectedKey: key,
        refersh: false,
        topicCurrenIndex: 'All', // topic重置为All，不然数据不准确
        mySessionsList: this.state.agendaListByTypes, // 重置为webtype筛选的数据
      }
      if (noTimeclick) {
        data = {
          selectedKey: key,
          refersh: false,
        }
      }
      this.setState(data)
    }, 300);

  };
  // 根据时间，返回数据:解决选择时间后，add或者remove状态不更新
  getCurrentDataBySelectedKey = (selectedKey, isTopic) => {
    const { mySessionsList, agendaListByTypes } = this.state;
    const tempData = isTopic ? agendaListByTypes : mySessionsList;
    const currentData = tempData.filter(data => {
      return data.key === selectedKey;
    });
    return currentData[0] || [];
  };
  componentDidMount() {
    this.getBriefcaseslist();
    this.getAgendaListFilterIds();
    this.getTopicCategory();
    enterCurrentPage();
  }
  // 请求agenda列表数据
  getAgendaListFilterIds = () => {
    axios('mySession')
      .then((res) => {
        console.log('/mySession=', res);
        const ids = [];
        res.data.map(item => {
          if (ids.indexOf(item.AgendaId) < 0) {
            ids.push(item.AgendaId);
          }
        });
        axios('eventAgenda', { params: { filter: { _id: ids } } })
          .then((response) => {
            console.log('/event-agenda=', response);
            this.getWebTypes(response.data);
            const list = [];
            response.data.map(item => {
              const result = res.data.filter(v => v.AgendaId === item._id);
              item.mySessionId = result[0]._id;
              const arr = list.filter(v => v.key === dateToStringTitle(item.startDate));
              if (arr.length > 0) {
                const info = arr[0];
                const datas = info.data;
                datas.push(item);
              } else {
                list.push({
                  title: dateToStringTitle(item.startDate),
                  key: dateToStringTitle(item.startDate),
                  data: [item]
                })
              }
            });
            this.setState({ agendaListByTypes: list, mySessionsList: list, fetching: false, total: ids.length, originResponse: [...list] })
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // 重新请求briefcases列表
  getBriefcaseslist = () => {
    const { userInfo, getBriefcasesList } = this.props;
    getBriefcasesList(userInfo.id);
  };
  // 删除
  remove = (data) => {
    console.log('remove', data);
    // 删除Briefcase
    axios('deleteMySession', { path: data.mySessionId })
      .then((response) => {
        console.log('/deleteMySession=', response);
        message.success(i18next.t('translations.deleted-success'));
        this.getAgendaListFilterIds();
      })
      .catch((error) => {
        console.log(error);
      });
    this.clickUpload('delete Mysession ' + data.name);
  };
  //统计点击事件
  clickUpload = (fieldname) => {
    axios('activity', {
      data: {
        event: '[Click] Agenda ' + fieldname
      }
    })
      .then(response => {
        console.log('/activity', response);
      })
  };
  onChange = (index) => {
    this.setState({ selectIndex: index })
  };
  // topic
  getTopicCategory = () => {
    axios('category')
      .then((res) => {
        console.log('/getTopicCategory=', res);
        this.setState({ topics: res.data })
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // 处理web type分类
  getWebTypes = (datas) => {
    const types = ['Show All'];
    datas.forEach(item => {
      const ishas = types.indexOf(item.type);
      if (ishas === -1) {
        types.push(item.type);
      }
    });
    // console.log('types', types);
    this.setState({
      webtTpes: types,
    });
  };
  // 筛选Webinar Type
  // 1.正常点击webType执行->callback(selectedKey)
  onwebTypeChange = (type) => {
    // let myType = type;
    // if (!fromWebType) {
    //   // 点击的时间分类
    //   myType = 'All'
    // }
    const { originResponse, mySessionsList, selectedKey } = this.state;
    // 必须深度克隆,否则数据有影响
    const newAgendaList = JSON.parse(JSON.stringify(mySessionsList));
    // 处理源数据
    originResponse.forEach((firstItem, index) => {
      if (firstItem.data) {
        // 过滤
        newAgendaList[index].data = firstItem.data.filter(item => {
          if (type === 'Show All') {
            return true;
          }
          return item.type === type;
        })
      }
    });
    this.setState({ agendaListByTypes: newAgendaList, mySessionsList: newAgendaList, currentType: type, topicCurrenIndex: 'All' }, () => {
      // 正常点击webType
      if (selectedKey) {
        this.callback(selectedKey, true); // 更新时间选择
      }
      // 选择的时间选择器分类
      // if (!fromWebType) {
      //   this.callback(type); // 更新时间选择
      // }
    })
  };
  // 根据上面topic数据筛选
  onTopicChange = (topic, index) => {
    const { agendaListByTypes, mySessionsList, selectedKey } = this.state;
    // 必须深度克隆,否则数据不更新
    const newAgendaList = JSON.parse(JSON.stringify(mySessionsList));
    // 处理webTypes数据
    agendaListByTypes.forEach((firstItem, index) => {
      if (firstItem.data) {
        // 过滤
        newAgendaList[index].data = firstItem.data.filter(item => {
          if (topic === 'All') {
            return true;
          }
          return item.categories && item.categories.indexOf(topic._id) !== -1;
        })
      }
    });
    this.setState({ mySessionsList: newAgendaList, currentTopic: topic, topicCurrenIndex: index }, () => {
      // 正常点击webType
      if (selectedKey) {
        this.callback(selectedKey, true); // 更新时间选择
      }
    })

  };
  // 根据关键词搜索
  searchPeopleByKeyword = (type, value) => {
    // this.setState({ searchType: type, keyword: value })
  };
  remoteFilter = () => {
    this.componentDidMount();
    this.setState({ currentType: 'All', topicCurrenIndex: 'All', selectedKey: 'All' });
  }

  render() {
    const { agendaListByTypes, selectedKey, selectIndex, mySessionsList, total, webtTpes, topics, currentType, topicCurrenIndex, refersh } = this.state;
    const { briefcasesData, userInfo, primary, language } = this.props;
    const eventAgendas = briefcasesData.event_agenda;
    // 根据当前时间，筛选数据
    const currentData = this.getCurrentDataBySelectedKey(selectedKey);
    const currentDataTopic = this.getCurrentDataBySelectedKey(selectedKey, true);
    const speakers = JSON.parse(sessionStorage.getItem('ALLSpeakers'));

    return (
      <div className={indexCss.mySessions + ' myAgenda'}>
        <Row gutter={[32, 32]}>
          <BreadCrumbs
            breadCrumbActive={i18next.t('translations.my-sessions')}
          // "MySessions (WEBINARS)"
          />
        </Row>
        <HeaderTitle title={i18next.t('translations.my-sessions')} />
        <div className="">
          <Row>
            <Col xs={{ span: 24 }} md={{ span: 6 }}>
              <FilterCheck remoteFilter={this.remoteFilter} dateTimes={mySessionsList} currenDate={selectedKey} onDateChange={this.callback} onwebTypeChange={this.onwebTypeChange} currentType={currentType} webtTpes={webtTpes} primary={primary} onSearch={this.searchPeopleByKeyword.bind(this)} language={language}
                topics={topics} onTopicChange={this.onTopicChange} currenTopicIndex={topicCurrenIndex} />
            </Col>
            {currentData && selectedKey !== 'All' && <Col xs={{ span: 24 }} md={{ span: 18 }}>
              {refersh ? <div className="loading"><Spin /></div> : <TabSpeakerPaneItemAll {...this.props} currentData={currentData} addMysession={this.addMysession} remove={this.remove} eventAgendas={eventAgendas} userInfo={userInfo} fetchBriefcase={this.getBriefcaseslist} primary={primary} selectIndex={selectIndex} speakers={speakers} />}
            </Col>}
            {/* 默认展示所有 */}
            <Col xs={{ span: 24 }} md={{ span: 18 }}>
              {selectedKey === 'All' && mySessionsList && mySessionsList.map(data => {
                return (
                  <TabSpeakerPaneItemAll {...this.props} currentData={data} key={data.key} addMysession={this.addMysession} remove={this.remove} eventAgendas={eventAgendas} userInfo={userInfo} fetchBriefcase={this.getBriefcaseslist} primary={primary} language={language} selectIndex={selectIndex} speakers={speakers} />
                )
              })}
            </Col>
          </Row>
          {/* <Tabs activeKey={selectedKey} onChange={this.callback} centered> */}
          {/* {mySessionsList && mySessionsList.map((data, index) => (
                <Row>
                  <Col xs={{ span: 24 }} md={{ span: 6 }}>
                    <FilterCheck onwebTypeChange={this.onwebTypeChange} currentType={currentType} webtTpes={webtTpes} primary={primary} onSearch={this.searchPeopleByKeyword.bind(this)} language={language}
                      topics={topics} onTopicChange={this.onTopicChange} currenTopicIndex={topicCurrenIndex} />
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 18 }}>
                    <TabSpeakerPaneItemAll {...this.props} currentData={currentData} remove={this.remove} eventAgendas={eventAgendas} userInfo={userInfo} fetchBriefcase={this.getBriefcaseslist} language={language} selectIndex={selectIndex} speakers={speakers} />
                  </Col>
                </Row>
            ))} */}
          {/* </Tabs> */}
          {/* 首次进入显示所有 */}
          {/* {!selectedKey && <WebinarType webtTpes={webtTpes} onwebTypeChange={this.onwebTypeChange} currentType={currentType} keyV="1" />} */}
          {/* {!selectedKey && <Topic currentData={agendaListByTypes} topics={topics} language={language} onTopicChange={this.onTopicChange} currenIndex={topicCurrenIndex} />} */}
          {/* {!selectedKey && mySessionsList && mySessionsList.map(data => {
            return (
              <TabSpeakerPaneItemAll {...this.props} currentData={data} key={data.key} remove={this.remove} eventAgendas={eventAgendas} userInfo={userInfo} fetchBriefcase={this.getBriefcaseslist} language={language} selectIndex={selectIndex} speakers={speakers} />
            )
          })} */}
          {/* {!selectedKey && <Row>
            <Col xs={{ span: 24 }} md={{ span: 6 }}>
              <FilterCheck onwebTypeChange={this.onwebTypeChange} currentType={currentType} webtTpes={webtTpes} primary={primary} onSearch={this.searchPeopleByKeyword.bind(this)} language={language}
                topics={topics} onTopicChange={this.onTopicChange} currenTopicIndex={topicCurrenIndex} />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 18 }}>
              {mySessionsList && mySessionsList.map(data => {
                return (
                  <TabSpeakerPaneItemAll {...this.props} currentData={data} key={data.key} remove={this.remove} eventAgendas={eventAgendas} userInfo={userInfo} fetchBriefcase={this.getBriefcaseslist} language={language} selectIndex={selectIndex} speakers={speakers} />
                )
              })}
            </Col>
          </Row>} */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  //mySessionsList: state.Agenda.mySessionsList,
  userInfo: state.loginUserInfo.userInfo,
  briefcasesData: state.briefcases.briefcasesData,
  primary: state.profile.primary,
  language: state.profile.language
});

const mapDispatchToProps = (dispatch) => ({
  fetchMySessionsList: (data) => {
    dispatch(fetchMySessionsList(data));
  },
  getBriefcasesList: (userId) => {
    dispatch(getBriefcasesList(userId));
  }
});
const enhance = compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps)
);
export default enhance(MySessions);
// export default connect(mapStateToProps, mapDispatchToProps)(MySessions);
