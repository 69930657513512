import React, { Component } from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import '../index.less';
import { Row, Col } from 'antd';
import CategoriesList from './CategoriesList';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import axios from '../../../network/axios/$axios';
import { getBriefcasesList } from '../../../actions/BriefcaseAction';
import CategoryFilter from './CategoryFilter';

const DEFAULT = {
  _id: 'ALL',
  display_name: {
    'en-us': "All", 'zh-hans': "所有", 'zh-hant': "所有"
  },
  isAll: true
};

class ExpoHallList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectIndex: 0, // 选择的按钮id
      exhibitorBooths: [], // 当前显示的booth
      allExhibitorBooths: [], //保存全部booth
      currentCountry: DEFAULT, //当前选择的国家分类
      currentZone: DEFAULT, //当前选择的展区分类
      currentBusiness: DEFAULT, //当前选择的业务性质
      bannerBtns: [], // 上面的banner图
      hallTagGroup: [], // 筛选条件分组
      booths: [], // 选中banner图对应的booth
      loading: true,
      hallTags: [],
      boothType: "Booth",
      scene: "Expohall",
    };
  }
  componentDidMount() {
    let { hall, category, country } = this.props
    let { boothType, scene } = this.state
    if (window.location.href.includes("/Gallery")) {
      this.setState({
        boothType: "Gallery",
        scene: "Gallery"
      });
      console.log("Custom hall", this.state)
      boothType = "Gallery"
      scene = "Gallery"
    }
    this.getBriefcaseslist();
    axios('hallTag')
      .then((res) => {
        console.log('/Info/HallTag', res);
        const list = res.data;
        axios('infoHallBanner')
          .then((response) => {
            console.log('/hall-banners', response);
            const data = response.data;
            const bannerBtns = [];
            data.map(obj => {
              const arr = list.filter(v => v._id === obj.HallTagId);
              const info = bannerBtns.filter(v => v._id === obj.HallTagId);
              if (arr.length && !info.length) {
                bannerBtns.push(arr[0])
              }
            })
            this.setState({ bannerBtns, selectIndex: bannerBtns[0]._id });
          })
          .catch((error) => {
            console.log(error);
          });
        axios('Booth', { params: { filter: { boothType } } })
          .then((response) => {
            console.log('/Booth', response.data);
            const array = response.data;
            this.setState({
              exhibitorBooths: array,
              allExhibitorBooths: array,
              booths: array,
              loading: false,
              hallTags: res.data
            }, () => {
              // 根据url参数进行筛选数据
              const list1 = list.filter(v => v._id === hall);
              const list2 = list.filter(v => v._id === category);
              const list3 = list.filter(v => v._id === country);
              if (list1.length) {
                this.selectTab(list1[0]);
              }
              if (list2.length) {
                setTimeout(() => {
                  this.onBusinessChange(list2[0]);
                }, 500)
              }
              if (list3.length) {
                setTimeout(() => {
                  this.onCountryChange(list3[0]);
                }, 1000)
              }
            });
          })
          .catch((error) => {
            console.log(error);
            this.setState({ loading: false })
          });
      })
      .catch((error) => {
        console.log(error);
      });
    axios('hallTagGroup')
      .then((res) => {
        console.log('/Info/HallTagGroup', res);
        const list = res.data;
        this.setState({ hallTagGroup: list })
      })
      .catch((error) => {
        console.log(error);
      });
  }
  //统计点击事件
  clickUpload = (fieldname) => {
    axios('activity', {
      data: {
        event: '[Click] ExpoHall ' + fieldname
      }
    }).then((response) => {
      console.log('/activity', response);
    });
  };
  // 重新请求briefcases列表
  getBriefcaseslist = () => {
    const { userInfo, getBriefcasesList } = this.props;
    getBriefcasesList(userInfo.id);
  };

  selectTab = (item) => {
    const { onSelectItem } = this.props;
    const { allExhibitorBooths, hallTagGroup, booths } = this.state;
    let currentBusiness = DEFAULT;
    let currentZone = DEFAULT;
    let currentCountry = DEFAULT;
    let list = allExhibitorBooths;
    if (!item.isAll) {
      currentBusiness = this.state.currentBusiness;
      currentZone = this.state.currentZone;
      currentCountry = this.state.currentCountry;
      const BoothIds = item.BoothIds || [];
      const arr = hallTagGroup.filter(v => v._id === item.GroupId);
      const type = arr.length ? arr[0].name : '';
      list = allExhibitorBooths.filter(v => BoothIds.includes(v._id));
      if (type === 'Business Nature') {
        currentBusiness = item;
      } else if (type === 'Zone') {
        currentZone = item;
      } else if (type === 'Country / Region') {
        currentCountry = item;
      }
    }
    this.setState({
      selectIndex: item._id,
      currentCountry,
      currentZone,
      currentBusiness,
      exhibitorBooths: list,
      booths: list
    });
    if (onSelectItem) {
      onSelectItem(item._id);
    }
    this.clickUpload(item._id);
  };

  onSelectItem = (item) => {
    this.props.history.push(`/${this.state.scene || "Expohall"}/Booth/` + item._id);
    this.clickUpload(item.name);
  };

  remoteFilter = () => {
    const { allExhibitorBooths } = this.state;
    this.setState({ currentCountry: DEFAULT, currentZone: DEFAULT, currentBusiness: DEFAULT, exhibitorBooths: allExhibitorBooths })
  }

  onCountryChange = (value) => {
    const { currentZone, currentBusiness, booths } = this.state;
    const BoothIds = value.BoothIds || [];
    let list = booths.filter(v => BoothIds.includes(v._id));
    if (value.isAll) {
      list = booths;
    }
    const list1 = !currentZone.isAll ? list.filter(v => (currentZone.BoothIds || []).includes(v._id)) : list;
    const list2 = !currentBusiness.isAll ? list1.filter(v => (currentBusiness.BoothIds || []).includes(v._id)) : list1;
    this.setState({ currentCountry: value, exhibitorBooths: list2 })
  }

  onZoneChange = (value) => {
    const { currentCountry, currentBusiness, booths } = this.state;
    const BoothIds = value.BoothIds || [];
    let list = booths.filter(v => BoothIds.includes(v._id));
    if (value.isAll) {
      list = booths;
    }
    const list1 = !currentCountry.isAll ? list.filter(v => (currentCountry.BoothIds || []).includes(v._id)) : list;
    const list2 = !currentBusiness.isAll ? list1.filter(v => (currentBusiness.BoothIds || []).includes(v._id)) : list1;
    this.setState({ currentZone: value, exhibitorBooths: list2 })
  }

  onBusinessChange = (value) => {
    const { currentCountry, currentZone, booths } = this.state;
    const BoothIds = value.BoothIds || [];
    let list = booths.filter(v => BoothIds.includes(v._id));
    if (value.isAll) {
      list = booths;
    }
    const list1 = !currentCountry.isAll ? list.filter(v => (currentCountry.BoothIds || []).includes(v._id)) : list;
    const list2 = !currentZone.isAll ? list1.filter(v => (currentZone.BoothIds || []).includes(v._id)) : list1;
    this.setState({ currentBusiness: value, exhibitorBooths: list2 })
  }
  sortBooth = (booths) =>
    booths.sort((a, b) => {
      if (typeof a.seq == 'number' && typeof b.seq == "number") return a.seq - b.seq
      if (typeof a.seq == 'number') return -1
      if (typeof b.seq == 'number') return 1
      return a.name.localeCompare(b.name, { sensitivity: "base" })
    })


  render() {
    const {
      selectIndex,
      exhibitorBooths,
      currentCountry,
      currentZone,
      currentBusiness,
      bannerBtns,
      loading,
      hallTags,
      hallTagGroup,
    } = this.state;
    const { briefcasesData, userInfo, language, primary } = this.props;
    const booths = briefcasesData.exhibitor_booths;
    const list = hallTagGroup.filter(v => v.canFilter);
    return (
      <div className="expo-hall center">
        <div className="page-container">
          <div>
            {hallTags.length > 1 && <Row className="btn-list">
              {bannerBtns.map((item, index) => (
                <Col key={index} className='col-btn flex flex_j_c'>
                  <div
                    className={
                      item._id === selectIndex
                        ? 'btn-area active pointer'
                        : 'btn-area pointer'
                    }
                    style={
                      item._id === selectIndex
                        ? { backgroundColor: '#212121' }
                        : { backgroundColor: '#ffffff' }
                    }
                    onClick={() => { this.selectTab(item) }}
                  >
                    <span
                      style={{
                        color: item._id === selectIndex ? '#ffffff' : '#212121'
                      }}
                    >
                      {get(item, `display_name.${language}`) || item.name}
                    </span>
                  </div>
                </Col>
              ))}
            </Row>}
            <Row>
              {list.length ? <Col xs={{ span: 24 }} md={{ span: 6 }}>
                <CategoryFilter
                  remoteFilter={this.remoteFilter}
                  onCountryChange={this.onCountryChange}
                  onZoneChange={this.onZoneChange}
                  onBusinessChange={this.onBusinessChange}
                  currentZone={currentZone}
                  currentCountry={currentCountry}
                  currentBusiness={currentBusiness}
                  language={language}
                />
              </Col> : null}
              <Col xs={{ span: 24 }} md={{ span: list.length ? 18 : 24 }}>
                <CategoriesList
                  data={this.sortBooth(exhibitorBooths)}
                  onSelectItem={this.onSelectItem}
                  exhibitorBooths={booths || []}
                  userInfo={userInfo}
                  fetchBriefcase={this.getBriefcaseslist}
                  loading={loading}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.loginUserInfo.userInfo,
    briefcasesData: state.briefcases.briefcasesData,
    primary: state.profile.primary,
    language: state.profile.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getBriefcasesList: (userId) => {
    dispatch(getBriefcasesList(userId));
  }
});

const enhance = compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(ExpoHallList);
