import React, { Component } from 'react';
import indexCss from './index.module.css';
import {
  Row,
  Col,
  Divider,
  Form,
  Input,
  Button,
  Checkbox,
  Radio,
  Select,
  message
} from 'antd';
import { LeftOutlined, LockFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import axios from '../../network/axios/$axios';
import { registerFetch } from '../../actions/LoginUserAction';
import { COUNTRIES } from '../../constants/GlobalData';
import get from 'lodash/get';
import i18next from 'i18next';
import LanguageView from '../../components/LanguageView';

const { Option } = Select;

class RegisterScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formFields: [],
      languageData: [{ code: "en-us" }, { code: "zh-hant" }, { code: "zh-hans" }],
    };
  }

  componentDidMount() {
    // let formArray = [];
    axios('registrationFormField')
      .then((list) => {
        console.log('/registrationFormField', list);
        // 处理数据格式
        this.processingData(get(list, 'data[0].data'));
        // this.setState({
        //   formFields: get(list, 'data[0].data')
        // });
      })
      .catch((error) => {
        console.log(error);
      });
    // axios('Language')
    //   .then((response) => {
    //     console.log('/Language', response);
    //     this.setState({ languageData: response.data });
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  }
  processingData(data) {
    data.forEach((itemF) => {
      this.hasSubRequest(itemF);
      // 只处理两次
      if (itemF.data) {
        itemF.data.forEach((itemS) => {
          this.hasSubRequest(itemS);
          if (itemS.data) {
            itemS.data.forEach((itemT) => {
              this.hasSubRequest(itemT);
            });
          }
        });
      }
    });
    setTimeout(() => {
      this.setState({
        formFields: data
      });
    }, 500);
    // console.log('processingData', data);
  }
  // 判断
  hasSubRequest(item) {
    if (item.reference) {
      console.log('item.reference', item.reference);
      axios('registrationFormChoices', {
        path: `?filter={"field":"${item.reference.replace('Misc/', '')}"}`
      }).then((res) => {
        console.log('/el.reference', res);
        item.checkBoxs = res.data;
      });
    }
  }
  onFinish = (values) => {
    console.log('Success1:', values);
    if (values.pass !== values['repeatPass']) {
      message.warning(i18next.t('translations.pass-warning'));
    } else {
      const { registerFetch, language, selectedModel } = this.props;
      values.language = language;
      registerFetch(values, selectedModel, this);
    }
  };
  getReference = (reference) => {
    return new Promise((resolve) => {
      axios(reference).then((res) => {
        // console.log('/el.reference', res);
        resolve(res.data);
      });
    });
  };
  renderChildren(el, language) {
    console.log('elelelel1', el.title);
    if (el.type === 'password' || el.type === 'passwordRepeat') {
      return (
        <Input.Password
          placeholder={el.placeholder}
          // size="large"
          addonAfter={<LockFilled />}
        />
      );
    } else if (el.type === 'radio' && el.checkBoxs && el.checkBoxs.length) {
      // const referenceData = await this.getReference(el.reference);
      // console.log('/el.reference', referenceData);
      return (
        <Radio.Group>
          {el.checkBoxs &&
            el.checkBoxs.map((item) => (
              <Radio key={item._id} value={item._id}>
                {get(item.display_name, [language])}
              </Radio>
            ))}
          {/* <Radio value="notGiving">notGiving</Radio>
          <Radio value="male">male</Radio>
          <Radio value="female">female</Radio> */}
        </Radio.Group>
      );
    } else if (
      el.type === 'tel' &&
      (el.name === 'phone' || el.name === 'mobile')
    ) {
      const prefixSelector = (
        <Form.Item name="phoneCode" noStyle>
          <Select style={{ width: 100 }}>
            {COUNTRIES.map((obj, index) => {
              return (
                <Select.Option key={index} value={obj.dialCode}>
                  +{obj.dialCode}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      );
      return <Input type="number" addonBefore={prefixSelector} style={{ width: '100%' }} />;
    } else if (el.type === 'checkboxMulti') {
      if (el.checkBoxs && el.checkBoxs.length) {
        return (
          <Checkbox.Group>
            <Row>
              {el.checkBoxs &&
                el.checkBoxs.map((item) => (
                  <Col key={item._id} span={12}>
                    <Checkbox value={item._id}>
                      {get(item.display_name, [language])}
                    </Checkbox>
                  </Col>
                ))}
            </Row>
          </Checkbox.Group>
        );
      }
      return <Checkbox>{el.value}</Checkbox>;
    } else if (el.type === 'checkbox') {
      return <Checkbox>
        <div
          dangerouslySetInnerHTML={{
            __html: get(el.display_description, [language])
          }}
        />
      </Checkbox>;
    } else if (el.type === 'select' && el.checkBoxs && el.checkBoxs.length) {
      return (
        <Select style={{ width: el.name === 'salutation' ? 100 : 350 }}>
          {el.checkBoxs &&
            el.checkBoxs.map((item) => {
              return (
                <Select.Option key={item._id} value={item._id}>
                  {get(item.display_name, [language])}
                </Select.Option>
              );
            })}
        </Select>
      );
    } else {
      return <Input placeholder={el.placeholder} />;
    }
  }
  // 判断check类型
  isCheckValue(value) {
    return ['checkboxMulti', 'checkbox', 'radio'].includes(value);
  }
  // 判断Basic Info，单独处理布局
  basicInfoLayout(title) {
    if (title === 'Salutation') {
      return 6;
    }
    if (title === 'Firstname') {
      return 8;
    }
    if (title === 'Lastname') {
      return 8;
    }
    return 22;
  }
  banck = () => {
    this.props.history.go(-1);
  };
  render() {
    const { formFields, languageData } = this.state;
    const { language, primary } = this.props;
    console.log('this.state.formFields', formFields, language);
    return (
      <Form
        name="basic"
        // wrapperCol={{ span: 24 }}
        // labelCol={{ span: 3 }}
        // wrapperCol={{ span: 20 }}
        layout="vertical"
        initialValues={{ phoneCode: '852' }}
        onFinish={this.onFinish}
      >
        {/* 第一层 */}
        {formFields &&
          formFields.map((item) => (
            <div key={item.title}>
              <div className={indexCss.headerArea}>
                <span className={indexCss.mainTitle}>
                  {/* {item.title ? item.title : '未知'} */}
                  {item.type === 'section' &&
                    get(item.display_title, [language])}
                </span>
              </div>
              {/* 第二次 */}
              {item.data ? (
                item.data.map((obj) => {
                  if (obj.data) {
                    // 第三层:可以单独判断Basic Info
                    return (
                      <Row key={obj.title} justify="space-around">
                        {obj.data.map((obj2) => (
                          <Col
                            md={this.basicInfoLayout(obj2.title)}
                            xs={24}
                            key={obj2.name}
                          >
                            <Form.Item
                              // key={obj.id}
                              label={get(obj2.display_title, [language])}
                              name={obj2.name}
                              valuePropName={this.isCheckValue(obj2.type) ? 'checked' : 'value'}
                              rules={[
                                {
                                  required: obj2.required,
                                  message:
                                    i18next.t('translations.please-input') +
                                    get(obj2.display_title, [language])
                                }
                              ]}
                            >
                              {this.renderChildren(obj2, language)}
                            </Form.Item>
                          </Col>
                        ))}
                      </Row>
                    );
                  } else {
                    return (
                      <div key={obj.title}>
                        <Form.Item
                          // key={obj.id}
                          label={get(obj.display_title, [language])}
                          name={obj.name}
                          valuePropName={this.isCheckValue(obj.type) ? 'checked' : 'value'}
                          rules={[
                            {
                              type: obj.type === 'email' ? 'email' : 'any',
                              required: obj.required,
                              message:
                                i18next.t('translations.please-input') +
                                get(obj.display_title, [language])
                            }
                          ]}
                        >
                          {this.renderChildren(obj, language)}
                        </Form.Item>
                      </div>
                    );
                  }
                })
              ) : (
                <div key={item.name}>
                  <Form.Item
                    // key={obj.id}
                    label={get(item.display_title, [language])}
                    name={item.name}
                    // 选择框类型，不设置这个值，必选校验会有问题
                    // valuePropName="checked"
                    valuePropName={this.isCheckValue(item.type) ? 'checked' : 'value'}
                    rules={[
                      {
                        required: item.required,
                        message: ''
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value && item.required) {
                            return Promise.reject(new Error(i18next.t('translations.please-input') + get(item.display_title, [language])));
                          }
                          return Promise.resolve();
                        },
                      })
                    ]}
                  >
                    {this.renderChildren(item, language)}
                  </Form.Item>
                </div>
              )}
            </div>
          ))}

        <Form.Item>
          <Button
            className={indexCss.btnPrimary}
            type="primary"
            htmlType="submit"
            size="large"
            block
          >
            {i18next.t('translations.submit')}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.loginUserInfo.userInfo,
  language: state.profile.language,
  primary: state.profile.primary,
  selectedModel: state.profile.selectedModel,
});

const mapDispatchToProps = (dispatch) => ({
  registerFetch: (data, selectedModel, myThis) => {
    dispatch(
      registerFetch(data, (data) => {
        console.log('data', data, selectedModel, myThis);
        setTimeout(() => {
          // if (selectedModel === '3d') {
          //   myThis.props.history.replace('/createHuman');
          // } else {
          myThis.props.history.replace('/Avatar');
          // }
        }, 100);
      })
    );
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterScreen);
