import React, { useState } from 'react';
import { Button, Col, Dropdown, Input, Menu, Row } from 'antd';
import {
  CaretDownOutlined,
  TeamOutlined,
  UserOutlined
} from '@ant-design/icons';
import i18next from 'i18next';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import './ContactHeader.less';
const { Search } = Input;

const ContactHeader = (props) => {
  const { primary, onSelect, onSearch } = props;
  const [searchBtnTitle, setSearchBtnTitle] = useState(
    i18next.t('translations.search-all')
  );
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [searchType, setSearchType] = useState('all');

  const onSearchByKeyword = (value) => {
    console.log(value);
    onSearch(searchType, value);
  };

  const onChange = (e) => {
    setSearchValue(e.target.value);
  };

  const clearSearchValue = () => {
    setSearchValue('');
    onSearch(searchType, '');
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <a
          onClick={() => {
            setSearchBtnTitle(i18next.t('translations.search-all'));
            setSearchType('all');
          }}
        >
          {i18next.t('translations.search-all')}
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          onClick={() => {
            setSearchBtnTitle(i18next.t('translations.search-name'));
            setSearchType('name');
          }}
        >
          {i18next.t('translations.search-name')}
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          onClick={() => {
            setSearchBtnTitle(i18next.t('translations.search-job'));
            setSearchType('job');
          }}
        >
          {i18next.t('translations.search-job')}
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          onClick={() => {
            setSearchBtnTitle(i18next.t('translations.search-company'));
            setSearchType('company');
          }}
        >
          {i18next.t('translations.search-company')}
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          onClick={() => {
            setSearchBtnTitle(i18next.t('translations.search-country'));
            setSearchType('country');
          }}
        >
          {i18next.t('translations.search-country')}
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="contact-header">
      <Row justify="space-between">
        <Col md={15}>
          <Row>
            <Col md={6}>
              <Dropdown overlay={menu} trigger={['click']}>
                <Button className="drop-down react-btn" style={{ backgroundColor: primary, width: '100%' }} onClick={(e) => e.preventDefault()}>
                  <span className='f16'>{searchBtnTitle}</span>
                  <CaretDownOutlined className="search-title" />
                </Button>
              </Dropdown>
            </Col>
            <Col md={10}>
              <Search
                className="search f16 react-btn"
                style={{ borderColor: primary, borderRadius: '4px' }}
                bordered={false}
                placeholder={searchBtnTitle}
                onSearch={onSearchByKeyword}
                onChange={onChange}
                value={searchValue}
              />
            </Col>
            <Col md={4}>
              <Button onClick={() => clearSearchValue()} className="clear-btn f16 react-btn" style={{ borderColor: primary, color: primary }}>
                {i18next.t('translations.clear-search')}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col md={7}>
          <Row justify="space-between">
            <Button
              className="btn react-btn"
              onClick={() => {
              if (selectedIndex === 1) {
                setSelectedIndex(0);
                onSelect('');
              } else {
                setSelectedIndex(1);
                onSelect('Visitor');
              }
            }}
              style={{ backgroundColor: selectedIndex === 1 ? primary : '#fff', borderColor: selectedIndex === 1 ? '#fff' : primary, color:  selectedIndex === 1 ? '#fff' : primary }}
            >
              <TeamOutlined />
              {i18next.t('translations.visitor')}
            </Button>
            <Button
              className='btn react-btn'
              style={{ backgroundColor: selectedIndex === 2 ? primary : '#fff', borderColor: selectedIndex === 2 ? '#fff' : primary, color:  selectedIndex === 2 ? '#fff' : primary }}
              onClick={() => {
              if (selectedIndex === 2) {
                setSelectedIndex(0);
                onSelect('');
              } else {
                setSelectedIndex(2);
                onSelect('Exhibitor');
              }
            }}
            >
              <UserOutlined />
              {i18next.t('translations.exhibitor')}
            </Button>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

const enhance = compose(withTranslation('translations'));

export default enhance(ContactHeader);
