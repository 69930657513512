import { Card } from 'antd';
import React from 'react';
import './BrochuresModal.less';
import get from 'lodash/get';
import i18next from 'i18next';

export default function BrochuresModal(props) {
  const { data = [], primary, language } = props;
  return (
    <div className="brochures-page">
      {data && data.map((item, index) => {
        return (
          <Card key={index} className='ItemArea'>
            <div
              href={get(item, 'href')}
              target={get(item, 'target')}
              className="f16 bold"
            >
              {get(get(item, 'desplay_title'), [language])}
            </div>
            {get(item, 'display_description') && <div className='hp-mb-16'
              dangerouslySetInnerHTML={{
                __html: get(get(item, 'display_description'), [language])
              }}
            />}
            <a className='BtnArea' href={get(item, 'file.src')} target='_blank'>
              <span className="remix-icon f18">
                <i className="ri-download-2-line" />
              </span>
              <span className='hp-ml-8'>{i18next.t('translations.click-to-download')}</span>
            </a>
          </Card>
        )
      })}
    </div>
  );
}
