import React, { Component } from 'react';
import { Button, Rate, Row, Col, Modal, message } from 'antd';
import './DetailDemand.less';
// import { getProductDetail } from '../../actions/ProductAction';
import { connect } from 'react-redux';
// import get from 'lodash/get';
import TopHeader from "./components/TopHeader";
import { CalendarOutlined, MinusOutlined, YoutubeOutlined, PlusOutlined, DownloadOutlined, CloseOutlined } from '@ant-design/icons';
import noprofilepic from '../../Image/no-profile-pic.png';
import { getWeek, dateToString } from '../../Utils/Util';
// import { BASE_URL } from '../../network/RequestUtils';
import axios from '../../network/axios/$axios';
import { getBriefcasesList } from '../../actions/BriefcaseAction';
import BrochuresModal from '../../components/BrochuresModal';
import { compareDate, formatTimezone, enterCurrentPage } from '../../Utils/Util';
import get from 'lodash/get';
import Zoom from '../../components/Zoom';
import i18next from 'i18next';

class DetailDemand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: false,
      detail: {},
      selectIndex: 0,
      brochuresisModalVisible: false,
      event_speakers: [],
      visiable: false,
      linkType: '',
      linkHref: '',
      modalItem: {},
      mySessionsList: []
    };
  }
  componentWillUnmount() {
    // console.log('componentWillUnmount-componentWillUnmount');
    this.props.SHOW_BACK(false);
  }
  componentDidMount() {
    // 展示返回按钮
    this.props.SHOW_BACK(true);
    const { id } = this.props.match.params;
    axios('eventAgendaDetail', { path: id })
      .then((response) => {
        console.log('/event-agenda=', response);
        axios('mySession')
          .then((res) => {
            console.log('/mySession=', res);
            const result = res.data.filter(v => v.AgendaId === response._id);
            this.setState({ detail: { ...response, mySessionId: result.length > 0 ? result[0]._id : null }, mySessionsList: res.data });
            if (response.speakers && response.speakers.length) {
              this.getSpeakerByIds(response.speakers);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
    enterCurrentPage();
  }
  openModal = (item) => {
    if (item.target === '_self') {
      // 当前页面iframe打开，保留上下头部信息
      // this.props.isSelfCallback(item);
      this.props.UPDATE_SELF_IFRAME_ITEM(item)
      this.props.history.push(`/embeddedPage`);
      // IframeView
      return;
    }
    if (item.target !== '_popup' && item.target !== 'zoom') {
      return;
    }
    // 保存内容类型：linkType供popup使用, 暫時有iframe, zoom, video, image, 如果沒有設置默認為用iframe直接打開
    this.setState({
      visiable: true,
      linkType: get(item, 'linkType'),
      linkHref: get(item, 'href'),
      modalItem: item,
    });
  };

  handleCancel = () => {
    this.setState({
      visiable: false,
    });
  };
  // 根据id，请求下面的人员：/Agenda/Speaker?filter={"_id":["61ef67671a22ff9af9938810","61ef67671a22ff9af9938811"]}
  getSpeakerByIds = (ids) => {
    // axios('SpeakerByIds', { params: { filter: {_id: ids}} })
    //   .then((response) => {
    //     console.log('/SpeakerByIds=', response);
    //     const speakers = response.data.sort(function (a, b) { return a.seq - b.seq });
    //     this.setState({ event_speakers: speakers })
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    // sessionStorage.setItem('ALLSpeakers', JSON.stringify(speakers));
    const ALLSpeakers = JSON.parse(sessionStorage.getItem('ALLSpeakers'));
    const speakers = this.filterSpeakers(ALLSpeakers, ids);
    this.setState({ event_speakers: speakers });
  };
  // 匹配speakers
  filterSpeakers = (allSpeakers, speakerIds) => {
    const speakerArr = [];
    speakerIds.forEach(id => {
      speakerArr.push(allSpeakers.find(speaker => speaker._id === id));
    });
    return speakerArr;
  };
  showMoreLess = (sigleObj) => {
    // this.setState({ showMore: !this.state.showMore });
    sigleObj.showMore = !sigleObj.showMore;
    const event_speakers = [...this.state.event_speakers];   //复制数组--浅拷贝

    this.setState({
      event_speakers: event_speakers
    });
  };
  // 删除
  remove = (data) => {
    console.log('remove', data);
    // 删除Briefcase
    axios('deleteMySession', { path: data.mySessionId })
      .then((response) => {
        console.log('/deleteMySession=', response);
        message.success(i18next.t('translations.remove-from-my-session-success'));
        this.props.history.goBack();
      })
      .catch((error) => {
        console.log(error);
      });
    this.clickUpload('delete Mysession ' + data.name);
  };
  // 新增mysession
  addMysession = (data) => {
    const { isGuestLogin } = this.props;
    console.log('addMysession', data);
    if (isGuestLogin) {
      this.goRegisterPage();
    } else {
      // 新增MySession
      axios('addMySession', {
        data: {
          "AgendaId": data._id
        }
      })
        .then((response) => {
          console.log('/addMysession=', response);
          message.success(i18next.t('translations.add-to-my-session-success'));
        })
        .catch((error) => {
          console.log(error);
        });
    }
    this.clickUpload('add Mysession ' + data.name);
  };
  //订阅或者取消订阅
  onAddOrDelete = (data, arr, value) => {
    const { userInfo, getBriefcasesList, isGuestLogin } = this.props;
    if (value === 0) {
      // 删除Briefcase
      axios('deleteBriefcases', { path: arr[0]._id })
        .then((response) => {
          console.log('/deleteBriefcases=', response);
          message.success(i18next.t('translations.bookmark-deleted-success'));
          getBriefcasesList(userInfo.id);
        })
        .catch((error) => {
          console.log(error);
        });
      this.clickUpload('delete Briefcases ' + arr[0].detail.name)
    } else {
      if (isGuestLogin) {
        this.goRegisterPage();
      } else {
        // 新增Briefcase
        axios('addBriefcases', {
          data: {
            "model": "Agenda/Agenda",
            "refId": data._id
          }
        })
          .then((response) => {
            console.log('/addBriefcases=', response);
            message.success(i18next.t('translations.bookmark-added-success'));
            getBriefcasesList(userInfo.id);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      this.clickUpload('add Briefcases ' + data.name);
    }
  };
  //统计点击事件
  clickUpload = (fieldname) => {
    axios('activity', {
      data: {
        event: '[Click] Agenda ' + fieldname
      }
    })
      .then(response => {
        console.log('/activity', response);
      })
  };
  //跳转注册页面
  goRegisterPage = () => {
    this.props.history.push('/register');
  };

  onChange = (index) => {
    this.setState({ selectIndex: index })
  };

  // 显示brochures弹窗
  brochuresModalShow = () => {
    this.setState({
      brochuresisModalVisible: true
    });
  };
  // 隐藏brochures弹窗
  brochuresModalHiddle = () => {
    this.setState({
      brochuresisModalVisible: false
    });
  };
  renderButton = (data) => {
    const { language } = this.props;
    /*canJoinAfter > now = coming soon
      canJoinAfter < now < endDate = Live
      endDate < now = if don't have video >> session ended, if have video >> playbac*/
    // const cominGsoonFlag = compareDate(data.canJoinAfter, new Date());
    // const liveFlag = compareDate(data.endDate, new Date());
    const timeA = data.mustJoinBefore || data.startDate;
    const timeB = data.canJoinAfter || data.endDate;
    const cominGsoonFlag = compareDate(timeA, new Date());
    const liveFlag = compareDate(timeB, new Date());
    if (cominGsoonFlag) {
      return <Button className={'agendaBtn' + ' play ' + 'antBtnRound mL20'} size="large">{i18next.t('translations.coming-soon')}</Button>;
    } else if (liveFlag && data.type === 'Live') {
      // 正在播放
      if (data.liveLink && data.liveLink.length) {
        return data.liveLink.map((video, index) => (
          <a href={video.target === '_popup' || video.target === 'zoom' || video.target === '_self' ? null : video.href} onClick={() => this.openModal(video)} target="_blank" rel="noreferrer" key={video.title} className={index > 0 && 'mL20'}>
            <Button className={'agendaBtn' + ' live ' + 'antBtnRound'} icon={<YoutubeOutlined />} size="large">{video.display_title[language]}</Button>
          </a>
        ))
      }
    } else {
      // session ended
      if (data.type === 'Recordings' && data.videos && data.videos.length) {
        const videosArr = data.videos.map((video, index) => (
          <a href={video.target === '_popup' || video.target === 'zoom' || video.target === '_self' ? null : video.href} onClick={() => this.openModal(video)} target="_blank" rel="noreferrer" key={video.title} className={index > 0 && 'mL20'}>
            <Button className={'agendaBtn' + ' play ' + 'antBtnRound'} icon={<YoutubeOutlined />} size="large">{get(get(video, 'display_title'), language)}</Button>
          </a>
          // video.display_title[language]
        ))
        // videosArr.push(<Button className={'agendaBtn' + ' ended ' + 'antBtnRound mL20'} size="large">Session Ended</Button>);
        return videosArr;
      } else {
        return <Button className={'agendaBtn' + ' ended ' + 'antBtnRound mL20'} size="large">{i18next.t('translations.session-ended')}</Button>
      }
    }
  };
  renderDownloadButton(data) {
    const { language } = this.props;
    return data.downloads && data.downloads.map((download) => (
      <a href={download.href} download={download.title} target="_blank" rel="noreferrer" key={download.title} className="mL20">
        <Button className={'mL20 mR20' + 'antBtnRound'} icon={<DownloadOutlined />} size="large">{download.display_title[language]}</Button>
      </a>
    ))
  };
  render() {
    const { detail, selectIndex, brochuresisModalVisible, event_speakers, visiable, linkType, linkHref, modalItem, mySessionsList } = this.state;
    const { briefcasesData, primary, language, zoneNumber } = this.props;
    const eventAgendas = briefcasesData.event_agenda || [];
    const arr = eventAgendas.filter(obj => obj.refId === detail._id);
    const isSelected = arr.length > 0;

    return (
      <div className="page-container center Detail-demand">
        <TopHeader selectIndex={selectIndex} onChange={this.onChange.bind(this)} primary={primary} count={mySessionsList.length} />
        <div className="title-m">
          <p className="f30">
            {get(detail.display_name, language)}
          </p>
        </div>
        <div className="flex flex_a_c">
          <div><CalendarOutlined className="f50" /></div>
          <div className="mL20">
            <b className="f18">Date</b>
            <div className="f18 gray">{detail.startDate ? dateToString(formatTimezone(detail.startDate, zoneNumber)) + ` (${getWeek(dateToString(formatTimezone(detail.startDate, zoneNumber)))})` : ''}</div>
          </div>
        </div>
        <div className="title-m">
          <div
            dangerouslySetInnerHTML={{
              __html: get(detail.display_description, language)
            }}
          />
        </div>
        <div>
          {this.renderButton(detail)}
          {this.renderDownloadButton(detail)}

          {detail.mySessionId ? <Button className={'removeBtn' + ' mL20 mR20 ' + 'antBtnRound'} icon={<MinusOutlined />} size="large" onClick={this.remove && this.remove.bind(null, detail)}>{i18next.t('translations.remove')}</Button> : <Button className={'agendaBtn' + ' mL20 mR20 ' + 'antBtnRound'} icon={<PlusOutlined />} size="large" onClick={this.addMysession && this.addMysession.bind(null, detail)} style={{ backgroundColor: primary }}>{i18next.t('translations.add-to-my-session')}</Button>}
          <Rate onChange={this.onAddOrDelete.bind(this, detail, arr)} className="mL20" count="1" value={isSelected ? 1 : 0} />
        </div>
        {event_speakers && event_speakers.map((sigleObj) => (
          <Row align="middle" className="padd" key={sigleObj._id}>
            <Col md={9} xs={24}>
              <div className="flex flex_a_c padd">
                <img className="noprofilepic" src={sigleObj.profilePic.src || noprofilepic} />
                <div className="img-des">
                  <div className="f18">{get(sigleObj.display_name, language)}</div>
                  <div className="gray f16">{get(sigleObj.display_company, language)}</div>
                  <div className="gray f16">{get(sigleObj.display_jobTitle, language)}</div>
                </div>
              </div>
            </Col>
            <Col md={15} xs={24}>
              <div className={"f18 " + (sigleObj.showMore ? '' : 'line-clamp4')}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: get(sigleObj.display_description, language)
                  }}
                />
              </div>
              <div className="show" onClick={() => { this.showMoreLess(sigleObj) }}>{sigleObj.showMore ? i18next.t('translations.show-less') : i18next.t('translations.show-more')}</div>
            </Col>
          </Row>
        ))}
        <BrochuresModal
          isModalVisible={brochuresisModalVisible}
          handleCancel={this.brochuresModalHiddle}
          primary={primary}
        />
        {visiable && <Modal
          visible={visiable}
          width={800}
          title={<div style={{ backgroundColor: primary, padding: '15px 20px' }}>{get(modalItem, `display_title[${language}]`)}</div>}
          footer={null}
          closeIcon={<CloseOutlined style={{ color: 'white' }} />}
          onCancel={this.handleCancel}
          bodyStyle={{ padding: 0 }}
        >
          {linkType === 'zoom' || linkType === 'iframe' || linkType === undefined ? <Zoom link={linkHref} /> : <Row align="middle">
            {linkType === 'video' ? <video
              autoPlay
              playsInline //解决手机端无法自动播放功能
              loop
              style={{ width: '100%', height: '100%' }}
              src={linkHref}
            /> : <img
              src={linkHref}
              style={{ width: '100%', height: '100%' }}
            />}
          </Row>}
        </Modal>}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.loginUserInfo.userInfo,
  briefcasesData: state.briefcases.briefcasesData,
  primary: state.profile.primary,
  language: state.profile.language,
  isGuestLogin: state.loginUserInfo.isGuestLogin,
  zoneNumber: state.loginUserInfo.zoneNumber, // 时区数字
});

const mapDispatchToProps = (dispatch) => ({
  getProductDetail: (id) => {
    // dispatch(getProductDetail(id));
  },
  getBriefcasesList: (userId) => {
    dispatch(getBriefcasesList(userId));
  },
  UPDATE_SELF_IFRAME_ITEM: (item) => {
    console.log('item', item)
    dispatch({
      type: 'UPDATE_SELF_IFRAME_ITEM',
      data: { ...item }
    });
  },
  // BACK_IFRAME: () => {
  //   dispatch({
  //     type: 'BACK_IFRAME',
  //     // data: item
  //   });
  // },
  SHOW_BACK: (data) => {
    dispatch({
      type: 'SHOW_BACK',
      data: data
    });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailDemand);
