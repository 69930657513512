import React from "react";

import { Row, Col, Divider, Form, Input, Button, message } from "antd";
import i18next from "i18next";
import axios from '../../network/axios/$axios';

export default function PasswordProfile(props) {
  const { userInfo } = props;
  const [form] = Form.useForm();

  const dividerClass = "hp-border-color-black-40 hp-border-color-dark-80";

  // 更改密码
  const onChangePassword = (values) => {
    console.log(values);
    axios('resetPassword', {
      data: {
        id: userInfo.email, old_pass: values.CurrentPassword, pass: values.NewPassword
      }
    })
      .then((response) => {
        console.log('/resetPassword=', response);
        message.success(i18next.t('translations.update-password-success'));
        form.resetFields();
      })
      .catch((error) => {
        console.log(error);
        message.error(i18next.t('translations.password-not-match'));
      });
  };

  return (
    <Row>
      <Col span={24}>
        <h2>{i18next.t('translations.change-password')}</h2>
        <p className="hp-p1-body hp-mb-0">
          {i18next.t('translations.update-password-des')}
        </p>

        <Divider className={dividerClass} />
      </Col>

      <Col xxl={5} xl={10} md={15} span={24}>
        <Form layout="vertical" name="basic" onFinish={onChangePassword} 
          //要清空form表单必须要在Form标签上增加form属性
          form={form}>
          <Form.Item
            label={
              <span className="hp-input-label hp-text-color-black-100 hp-text-color-dark-0">
                {i18next.t('translations.current-password')}
              </span>
            }
            name="CurrentPassword"
            rules={[{ required: true, message: i18next.t('translations.current-password') }]}
          >
            <Input.Password placeholder={i18next.t('translations.current-password')}/>
          </Form.Item>

          <Form.Item
            label={
              <span className="hp-input-label hp-text-color-black-100 hp-text-color-dark-0">
                {i18next.t('translations.new-password')}
              </span>
            }
            name="NewPassword"
            rules={[{ required: true, message: i18next.t('translations.new-password') },
            ]}
          >
            <Input.Password placeholder={i18next.t('translations.new-password')} />
          </Form.Item>

          <Form.Item
            label={
              <span className="hp-input-label hp-text-color-black-100 hp-text-color-dark-0">
                {i18next.t('translations.comfirm-password')}
              </span>
            }
            name="NewPasswordConfirmation"
            rules={[{ required: true, message: i18next.t('translations.comfirm-password') },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('NewPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(i18next.t('translations.password-policy-passwords')));
              },
            }),
            ]}
          >
            <Input.Password placeholder={i18next.t('translations.comfirm-password')} />
          </Form.Item>

          <Form.Item>
            <Button block type="primary" htmlType="submit">
              {i18next.t('translations.update')}
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}