import { Card } from 'antd';
import React from 'react';
import get from 'lodash/get';
import i18next from 'i18next';
import './Links.less';

export default function Links(props) {
  const { data = [], primary, language } = props;
  return (
    <div className='link'>
      {data && data.map((item, index) => {
        return (
          <Card key={index} className='ItemArea'>
            <div
              href={item.href}
              target={item.target}
              className='hp-mb-16 bold'
            >
              {get(item.display_title, [language])}
            </div>
            <a className='BtnArea' href={item.href} target={item.target}>
              <span className="remix-icon f18">
                <i className="ri-share-forward-line" />
              </span>
              <span className='hp-ml-8'>{i18next.t('translations.view')}</span>
            </a>
          </Card>
        )
      })}
    </div>
  );
}
