import React from 'react';
import './App.css';
import configureStore from './store/configureStore';
import { Provider } from 'react-redux';
import { Router } from './routes/Router';
import './I18n/i18n';
import './network/axios/$axios';
const store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <Router />
    </Provider>
  );
}

export default App;
