import { Row, Col, List } from 'antd';
import React, { useState } from 'react';
import './ProductModal.less';
import ProductDetailModal from './ProductDetailModal';
import get from 'lodash/get';

const ProductModal = (props) => {
  const [detailisModalVisible, setDetailisModalVisible] = useState(false);
  const [detail, setDetail] = useState({});
  const { data = [], primary, language } = props;
  // 点击产品
  const clickItem = (item) => {
    setDetail(item);
    setDetailisModalVisible(true);
  };
  // 隐藏详情modal
  const detailModalHiddle = () => {
    setDetailisModalVisible(false);
  };

  return (
    <div className="product-page">
      <List
        grid={{
          gutter: [32, 16],
          xs: 1,
          sm: 2,
          md: 4,
          lg: 4,
          xl: 4,
          xxl: 4
        }}
        dataSource={data}
        renderItem={(item, index) => (
          <List.Item key={index} onClick={() => clickItem(item)}>
            <div className="list-content pointer">
              <img className="product-img" src={get(item, 'data.image.0.src')} alt="" />
              <div className="product-content">
                <div className="title">{get(get(item, 'data.display_title'), language)}</div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: get(get(item, 'data.display_description'), language)
                  }}
                />
             </div>
            </div>
          </List.Item>
        )}
      />
      <ProductDetailModal
        isModalVisible={detailisModalVisible}
        handleCancel={detailModalHiddle}
        data={detail.data}
        primary={primary}
        language={language}
      />
    </div>
  );
};

export default ProductModal;
