import React, { Component } from 'react';
import { Tag, Button } from 'antd';
import arrowUp from '../../../Image/product/arrow-up.png';
import arrowDown from '../../../Image/product/arrow-down.png';
import './ProductTags.less';

class ProductTags extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLess: false
    };
  }

  showLessAction() {
    this.setState({ showLess: !this.state.showLess });
  }

  render() {
    const { tags } = this.props;
    const { showLess } = this.state;
    const list = tags.split(',');
    return (
      <div className={showLess ? 'tags-area tags-show-less' : 'tags-area'}>
        <div className="pointer">
          {list.map((item, index) => {
            return (
              <Tag key={index} className="tag-view">
                <span className="tags-text">{item}</span>
              </Tag>
            );
          })}
        </div>
        <Button
          onClick={() => this.showLessAction()}
          type="text"
          className="common show-btn"
        >
          <span className="show-text">
            {!showLess ? 'Show Less' : 'Show More'}
          </span>
          <img
            className="arrow-up-img"
            src={!showLess ? arrowUp : arrowDown}
            alt=""
          />
        </Button>
      </div>
    );
  }
}

export default ProductTags;
