import {
  FETCH_PRODUCT_LIST,
  RECEIVE_PRODUCT_LIST,
  FETCH_PRODUCT_DETAIL,
  RECEIVE_PRODUCT_DETAIL,
  FETCH_PRODUCT_CATEGORY,
  RECEIVE_PRODUCT_CATEGORY,
  FETCH_SEARCH_PRODUCT_LIST,
  RECEIVE_SEARCH_PRODUCT_LIST
} from '../constants/ActionTypes';
import { getTreeData } from '../Utils/Util';

const initialState = {
  fetching: false,
  fetched: false,
  productData: {},
  detailFetching: false,
  detailFetched: false,
  productDetail: {},
  categoryFetching: false,
  categoryFetched: false,
  productCategory: [],
  fetchingSearchProduct: false,
  fetchedSearchProduct: false,
  searchProductData: {}
};

const product = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_LIST:
      return Object.assign({}, state, {
        fetching: true
      });
    case RECEIVE_PRODUCT_LIST:
      return Object.assign({}, state, {
        fetching: false,
        fetched: true,
        productData: action.rawData
      });
    case FETCH_PRODUCT_DETAIL:
      return Object.assign({}, state, {
        detailFetching: true
      });
    case RECEIVE_PRODUCT_DETAIL:
      return Object.assign({}, state, {
        detailFetching: false,
        detailFetched: true,
        productDetail: action.rawData
      });
    case FETCH_PRODUCT_CATEGORY:
      return Object.assign({}, state, {
        categoryFetching: true
      });
    case RECEIVE_PRODUCT_CATEGORY:
      const data = getTreeData(action.rawData);
      return Object.assign({}, state, {
        categoryFetching: false,
        categoryFetched: true,
        productCategory: data
      });
    case FETCH_SEARCH_PRODUCT_LIST:
      return Object.assign({}, state, {
        fetchingSearchProduct: true
      });
    case RECEIVE_SEARCH_PRODUCT_LIST:
      return Object.assign({}, state, {
        fetchingSearchProduct: false,
        fetchedSearchProduct: true,
        searchProductData: action.rawData
      });
    default:
      return state;
  }
};

export default product;
