import React, { Component } from 'react';
import './index.less';
import i18next from 'i18next';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Tabs, Row } from 'antd';
import MyAvailabilityNew from './components/MyAvailabilityNew';
import MyMeetings from './components/MyMeetings';
import MyNetworking from './components/MyNetworking';
import { saveSelectedTabKey, getMyPrivateMeetingList } from '../../actions/BusinessMatchingAction';
import { connect } from 'react-redux';
import BreadCrumbs from '../../components/BreadCrumbs';
import apiAxios from '../../network/axios/$axios';
import Game from '../../game/Game';
const { TabPane } = Tabs;

class BusinessMatchingScreen extends Component {
  constructor(props) {
    super();
    this.state = {
      meetings: [],
      unavailable: []
    }
  }

  componentDidMount() {
    const { userInfo } = this.props;
    apiAxios('checkMeeting', {
      data: {
        "id": userInfo._id
      }
    })
      .then((response) => {
        console.log('/checkMeeting', response);
        this.setState({ meetings: response.meetings, unavailable: response.unavailable })
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ unavailable: nextProps.myPrivateMeetingData.unavailable })
  }

  // 重新请求briefcases列表
  getMyPrivateMeetingList = () => {
    const { userInfo, getMyPrivateMeetingList } = this.props;
    getMyPrivateMeetingList(userInfo._id);
  };

  callback = (key) => {
    this.props.saveSelectedTabKey(key);
  };

  render() {
    const { selectedKey, primary, userInfo, location, menu, language } = this.props;
    const { meetings, unavailable } = this.state;
    const pathname = location.pathname;
    const arr = menu.filter(obj => obj.href === pathname);

    return (
      <div className="business-matching-page page-container center">
        <Row gutter={[32, 32]}>
          <BreadCrumbs
            breadCrumbActive={arr.length && (arr[0].display_title)[language]}
          />
        </Row>
        <Tabs activeKey={selectedKey} onChange={this.callback} centered>
          <TabPane tab={i18next.t('translations.my-availability')} key="1">
            <div style={{ padding: '2rem', backgroundColor: '#ffffff', borderRadius: '15px' }}>
              <MyAvailabilityNew userInfo={userInfo} primary={primary} data={unavailable} meetings={meetings} getData={this.getMyPrivateMeetingList.bind(this)} {...this.props} />
            </div>
          </TabPane>
          <TabPane tab={i18next.t('translations.my-meetings')} key="2">
            <div style={{ padding: '2rem', backgroundColor: '#ffffff', borderRadius: '15px' }}>
              <MyMeetings {...this.props} />
            </div>
          </TabPane>
          <TabPane tab={i18next.t('translations.my-networking')} key="3">
            <MyNetworking primary={primary} {...this.props} />
          </TabPane>
        </Tabs>
        <Game link='#/BusinessMatching' />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { businessMatching } = state;
  const selectedKey = businessMatching.selectedKey;
  return {
    selectedKey,
    primary: state.profile.primary,
    userInfo: state.loginUserInfo.userInfo,
    myPrivateMeetingData: state.businessMatching.myPrivateMeetingData,
    menu: state.profile.menu,
    language: state.profile.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveSelectedTabKey: (key) => {
    dispatch(saveSelectedTabKey(key));
  },
  getMyPrivateMeetingList: (userId) => {
    dispatch(getMyPrivateMeetingList(userId));
  }
});

const enhance = compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(BusinessMatchingScreen);
