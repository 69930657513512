import React, { Component } from 'react';
import { Row, Col } from 'antd';
import './JoinDetailDemand.less';
import { connect } from 'react-redux';
import JoinDetailSlider from './components/JoinDetailSlider';
// import {} from '@ant-design/icons';
// import noprofilepic from '../../Image/no-profile-pic.png';
// import get from 'lodash/get';
import { isMovie } from '../../Utils/Util';
import Zoom from '../../components/Zoom';
import BreadCrumbs from '../../components/BreadCrumbs';
import temp1 from '../../Image/code/temp1.png';
import axios from '../../network/axios/$axios';
import Game from '../../game/Game';
import HeaderTitle from '../../components/HeaderTitle';
class JoinDetailDemand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detail: null,
      adsList: [],
    };
  }
  componentDidMount() {
    axios('getAgendaADs', {})
      .then((response) => {
        console.log('/getAgendaADs=', response);
        this.setState({
            adsList: response.data[0].data.banner
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getComponent(movieHref, linkType) {
    if (isMovie(movieHref)) {
      return <video
        controls
        autoPlay
        playsInline //解决手机端无法自动播放功能
        loop
        className="video"
        src={movieHref}
      />;
    } else if (linkType === 'zoom') {
      return <div><Zoom showZoomTitle={true} link={movieHref} linkType="zoom" /></div>
    } else {
      return <iframe
        className="video"
        src={movieHref}
        style={{ width: '100%' }}
        title="video"
        frameBorder="0"
      ></iframe>;
    }
  }
  render() {
    const { movieIndex, JoinDetailDemandItem, location, menu, language } = this.props;
    console.log('detail', JoinDetailDemandItem, movieIndex);
    const currentDataAgendaList = JSON.parse(
      sessionStorage.getItem('currentDataAgendaList')
    );
    let movieHref = '', linkType = '';
    // if (JoinDetailDemandItem && JoinDetailDemandItem.type === 'Live' && JoinDetailDemandItem.liveLink) {
    if (JoinDetailDemandItem && JoinDetailDemandItem.liveLink) {
      movieHref = JoinDetailDemandItem.liveLink[movieIndex]?.href;
      linkType = JoinDetailDemandItem.liveLink[movieIndex]?.linkType;
    } else if (JoinDetailDemandItem && JoinDetailDemandItem.videos) {
      movieHref = JoinDetailDemandItem.videos[movieIndex]?.href;
      linkType = JoinDetailDemandItem.videos[movieIndex]?.linkType;
    }
    // let movieHref = JoinDetailDemandItem && JoinDetailDemandItem.currentMovItem.href;
    // // if (JoinDetailDemandItem && JoinDetailDemandItem.type === 'Live' && JoinDetailDemandItem.liveLink) {
    // //   movieHref = JoinDetailDemandItem.liveLink[movieIndex].href;
    // // } else if (JoinDetailDemandItem && JoinDetailDemandItem.videos){
    // //   movieHref = JoinDetailDemandItem.videos[movieIndex].href;
    // // }
    const pathname = location.pathname;
    const arr = menu.filter(obj => obj.href === '/Agenda');
    const { adsList } = this.state;
    return (
      <div className="JoinDetailDemand page-container center">
        <Row gutter={[32, 32]}>
          <BreadCrumbs
            breadCrumbParent={arr.length && (arr[0].display_title)[language]}
            breadCrumbActive={JoinDetailDemandItem?.display_name?.[language]}
          />
        </Row>
        <HeaderTitle title={JoinDetailDemandItem?.display_name?.[language]} />

        <Row>
          <Col xs={24} sm={14}>
            {this.getComponent(movieHref, linkType)}
          </Col>
          <Col xs={24} sm={10} className="pL20">
            <JoinDetailSlider
              data={JoinDetailDemandItem}
              currentDataAgendaList={currentDataAgendaList}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              {adsList.map(item => <Col className="banner-img-box" xs={24} sm={8}>
                <a href={item.link} target="blank"><img
                  className="banner-img"
                  src={item.image.src}
                  alt=""
                /></a>
              </Col>)}
              {/* <Col className="banner-img-box" xs={8} sm={8}>
                <img
                  className="banner-img"
                  src={temp1}
                  alt=""
                />
              </Col>
              <Col className="banner-img-box" xs={8} sm={8}>
                <img
                  className="banner-img"
                  src={temp1}
                  alt=""
                />
              </Col>
              <Col className="banner-img-box" xs={8} sm={8}>
                <img
                  className="banner-img"
                  src={temp1}
                  alt=""
                />
              </Col> */}
            </Row>
          </Col>

        </Row>
        <Game link='#/JoinDetailDemand' />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.loginUserInfo.userInfo,
  briefcasesData: state.briefcases.briefcasesData,
  primary: state.profile.primary,
  language: state.profile.language,
  isGuestLogin: state.loginUserInfo.isGuestLogin,
  movieIndex: state.Agenda.movieIndex,
  JoinDetailDemandItem: state.Agenda.JoinDetailDemandItem,
  menu: state.profile.menu
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(JoinDetailDemand);
