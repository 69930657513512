import React, { Component } from 'react';
import './Profile.less';
import indexCss from '../loginRegister/index.module.css';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Checkbox,
  Radio,
  Select,
  message,
  Spin
} from 'antd';
import { LockFilled } from '@ant-design/icons';
import { connect } from 'react-redux';
import axios from '../../network/axios/$axios';
import { COUNTRIES } from '../../constants/GlobalData';
import get from 'lodash/get';
import { updateUserInfoFetch } from '../../actions/LoginUserAction';
import i18next from 'i18next';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { enterCurrentPage } from '../../Utils/Util';
import { isEmpty } from 'lodash';
const JWT = require('jsonwebtoken');
let USERID = null;

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: "Visitor",
      profile: {},
      interestList: [],
      Salutation: [],
      Gender: [],
      formFields: [],
      url: null
    };
  }
  componentDidMount() {
    axios('registrationFormField')
      .then((list) => {
        console.log('/registrationFormField', list);
        // 处理数据格式
        this.processingData(get(list, 'data[0].data'));
      })
      .catch((error) => {
        console.log(error);
      });
    axios('businessNature')
      .then((response) => {
        console.log('/businessNature=', response);
        this.setState({ interestList: response.data })
      })
      .catch((error) => {
        console.log(error);
      });
    // 1.从缓存获取token
    const token = localStorage.getItem('token');
    const decoded = JWT.decode(token);
    if (decoded) {
      USERID = decoded._id;
      this.getUserDetail();
    }
    enterCurrentPage();
  }
  getUserDetail() {
    const { userInfo } = this.props;
    axios('getUserDetail', { path: USERID || userInfo._id })
      .then((response) => {
        console.log('/user=', response);
        this.setState({ profile: response.info, url: response['3DUrl'], role: response.role })
      })
      .catch((error) => {
        console.log(error);
      });

  }
  processingData(data) {
    data.forEach((itemF) => {
      this.hasSubRequest(itemF);
      // 只处理两次
      if (itemF.data) {
        itemF.data.forEach((itemS) => {
          this.hasSubRequest(itemS);
          if (itemS.data) {
            itemS.data.forEach((itemT) => {
              this.hasSubRequest(itemT);
            });
          }
        });
      }
    });
    setTimeout(() => {
      this.setState({
        formFields: data.filter(v => v.title !== 'Account Info')
      });
      this.getUserDetail();
    }, 500);
  }
  // 判断
  hasSubRequest(item) {
    if (item.reference) {
      console.log('item.reference', item.reference);
      axios('registrationFormChoices', {
        path: `?filter={"field":"${item.reference.replace('Misc/', '')}"}`
      }).then((res) => {
        console.log('/el.reference', res);
        item.checkBoxs = res.data;
      });
    }
  }
  renderChildren(el, language) {
    console.log('elelelel1', el.title);
    if (el.type === 'password' || el.type === 'passwordRepeat') {
      return (
        <Input.Password
          placeholder={el.placeholder}
          // size="large"
          addonAfter={<LockFilled />}
        />
      );
    } else if (el.type === 'radio' && el.checkBoxs && el.checkBoxs.length) {
      return (
        <Radio.Group>
          {el.checkBoxs &&
            el.checkBoxs.map((item) => (
              <Radio key={item._id} value={item._id}>
                {get(item.display_name, [language])}
              </Radio>
            ))}
        </Radio.Group>
      );
    } else if (
      el.type === 'tel' &&
      (el.name === 'phone' || el.name === 'mobile')
    ) {
      // const prefixSelector = (
      //   <Form.Item name="phoneCode" noStyle>
      //     <Select style={{ width: 100 }}>
      //       {COUNTRIES.map((obj, index) => {
      //         return (
      //           <Select.Option key={index} value={obj.dialCode}>
      //             +{obj.dialCode}
      //           </Select.Option>
      //         );
      //       })}
      //     </Select>
      //   </Form.Item>
      // );
      return <Input type="number" style={{ width: '100%' }} />;
    } else if (el.type === 'checkboxMulti') {
      if (el.checkBoxs && el.checkBoxs.length) {
        return (
          <Checkbox.Group>
            <Row>
              {el.checkBoxs &&
                el.checkBoxs.map((item) => (
                  <Col key={item._id} span={12}>
                    <Checkbox value={item._id}>
                      {get(item.display_name, [language])}
                    </Checkbox>
                  </Col>
                ))}
            </Row>
          </Checkbox.Group>
        );
      }
      return <Checkbox valuePropName="checked">{el.value}</Checkbox>;
    } else if (el.type === 'checkbox') {
      return <Checkbox valuePropName="checked">
        <div
          dangerouslySetInnerHTML={{
            __html: get(el.display_description, [language])
          }}
        />
      </Checkbox>;
    } else if (el.type === 'select' && el.checkBoxs && el.checkBoxs.length) {
      return (
        <Select>
          {el.checkBoxs &&
            el.checkBoxs.map((item) => {
              return (
                <Select.Option key={item._id} value={item._id}>
                  {get(item.display_name, [language])}
                </Select.Option>
              );
            })}
        </Select>
      );
    } else {
      return <Input placeholder={el.placeholder} />;
    }
  }
  // 判断Basic Info，单独处理布局
  basicInfoLayout(title) {
    if (title === 'Salutation') {
      return 6;
    }
    if (title === 'Firstname') {
      return 8;
    }
    if (title === 'Lastname') {
      return 8;
    }
    return 24;
  }
  onFinish = (values) => {
    const { userInfo, role } = this.props;
    const { updateUserInfoFetch } = this.props;
    let data = { role, ...userInfo, ...values }
    console.log('updateUser be4', userInfo)
    updateUserInfoFetch(data);
  };
  // 判断check类型
  isCheckValue(value) {
    return ['checkboxMulti', 'checkbox', 'radio'].includes(value);
  }

  render() {
    const { primary, language } = this.props;
    const { profile, formFields, url } = this.state;
    if (isEmpty(profile)) {
      return <div className="loading"><Spin /></div>;
    }
    return (
      <div className="my-profile">
        <Row>
          <Col md={15} span={24}>
            <Form
              name="basic"
              layout="vertical"
              initialValues={{ phoneCode: '852' }}
              onFinish={this.onFinish}
            >
              {/* 第一层 */}
              {formFields &&
                formFields.map((item) => (
                  <div key={item.title}>
                    <div className={indexCss.headerArea}>
                      <span className={indexCss.mainTitle}>
                        {/* {item.title ? item.title : '未知'} */}
                        {item.type === 'section' &&
                          get(item.display_title, [language])}
                      </span>
                    </div>
                    {/* 第二次 */}
                    {item.data ? (
                      item.data.map((obj) => {
                        if (obj.data) {
                          // 第三层:可以单独判断Basic Info
                          return (
                            <Row key={obj.title} justify="space-between">
                              {obj.data.map((obj2) => (
                                <Col
                                  md={this.basicInfoLayout(obj2.title)}
                                  xs={24}
                                  key={obj2.name}
                                >
                                  <Form.Item
                                    // key={obj.id}profile
                                    label={get(obj2.display_title, [language])}
                                    name={obj2.name}
                                    valuePropName={this.isCheckValue(obj2.type) ? 'checked' : 'value'}
                                    rules={[
                                      {
                                        required: obj2.required,
                                        message:
                                          i18next.t('translations.please-input') +
                                          get(obj2.display_title, [language])
                                      }
                                    ]}
                                    initialValue={profile[obj2.name]}
                                  >
                                    {this.renderChildren(obj2, language)}
                                  </Form.Item>
                                </Col>
                              ))}
                            </Row>
                          );
                        } else {
                          // 过滤密码
                          if (obj.type === 'password') {
                            return null;
                          }
                          return (
                            <div key={obj.title}>
                              <Form.Item
                                // key={obj.id}
                                label={get(obj.display_title, [language])}
                                name={obj.name}
                                valuePropName={this.isCheckValue(obj.type) ? 'checked' : 'value'}
                                rules={[
                                  {
                                    required: obj.required,
                                    message:
                                      i18next.t('translations.please-input') +
                                      get(obj.display_title, [language])
                                  }
                                ]}
                                initialValue={profile[obj.name]}
                              >
                                {this.renderChildren(obj, language)}
                              </Form.Item>
                            </div>
                          );
                        }
                      })
                    ) : (
                      <div key={item.name}>
                        {item.type === 'checkbox' ? <Form.Item
                          // key={obj.id}
                          label={get(item.display_title, [language])}
                          name={item.name}
                          // valuePropName="checked"
                          valuePropName={this.isCheckValue(item.type) ? 'checked' : 'value'}
                          rules={[
                            {
                              required: item.required,
                              message:
                                i18next.t('translations.please-input') +
                                get(item.display_title, [language])
                            }
                          ]}
                          initialValue={profile[item.name]}
                        >
                          {this.renderChildren(item, language)}
                        </Form.Item> : <Form.Item
                          // key={obj.id}
                          label={get(item.display_title, [language])}
                          name={item.name}
                          rules={[
                            {
                              required: item.required,
                              message:
                                i18next.t('translations.please-input') +
                                get(item.display_title, [language])
                            }
                          ]}
                          initialValue={profile[item.name]}
                        >
                          {this.renderChildren(item, language)}
                        </Form.Item>}
                      </div>
                    )}
                  </div>
                ))}

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  block
                >
                  {/* SUBMIT */}
                  <span className="bold">{i18next.t('translations.update')}</span>
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.loginUserInfo.userInfo,
  primary: state.profile.primary,
  language: state.profile.language,
  role: state.loginUserInfo.role
});

const mapDispatchToProps = (dispatch) => ({
  updateUserInfoFetch: (data) => {
    dispatch(updateUserInfoFetch(data, (data) => {
      console.log('data', data);
      message.success(i18next.t('translations.update-profile-success'));
    }));
  }
});
const enhance = compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps)
);
export default enhance(Profile);
