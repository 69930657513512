import React from 'react';
import get from 'lodash/get';
import SpeicalOffersCSS from './SpeicalOffers.module.css';

export default function Links(props) {
  const { data = [], primary, language } = props;
  return (
    <div className={SpeicalOffersCSS.SpeicalOffers}>
      {data && data[0] && <div
        dangerouslySetInnerHTML={{
          __html: data[0].display_description ? get(data[0].display_description, [language]) : data[0].content
        }}
      />}
    </div>
  );
}
