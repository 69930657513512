import * as types from '../constants/ActionTypes';

// 主题色
export const changeMainColor = (color) => {
  return {
    type: types.CHANGE_MAIN_COLOR,
    data: color
  };
};

// 语言
export const changeLanguage = (language) => {
  return {
    type: types.CHANGE_LANGUAGE,
    data: language
  };
};

// 搜索分类
export const selectedType = (value) => {
  return {
    type: types.SELECTED_TYPE,
    data: value
  };
};

// 选择2d或者3d模式
export const selected2dOr3dModel = (model) => {
  return {
    type: types.SELECTED_2D_OR_3D_MODEL,
    data: model
  };
}

// 菜单
export const saveMenu = (menu) => {
  return {
    type: types.SAVE_MENU,
    data: menu
  };
}