/*
 * @Description: 聊天按钮组件
 * @Version: 1.0
 * @Date: 2022-04-06 17:00:41
 * @LastEditTime: 2022-04-28 18:19:32
 */
import React, { Component } from 'react';
import './ChatMessage.less';
import { connect } from 'react-redux';
import { LOG_CHART_USER } from '../actions/LoginUserAction';
// import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
// import { ChatEngine, ChatEngineWrapper, Socket, ChatFeed, ChatList } from 'react-chat-engine';
import { CloseOutlined, LeftOutlined } from '@ant-design/icons';
import "@sendbird/uikit-react/dist/index.css";
import CustomizedApp from "./chatengine-sendbird/customSendbird"
import SBProvider from "@sendbird/uikit-react/SendbirdProvider";
import axios from '../network/axios/$axios';

// import { ChatEngineWrapper, Socket, ChatFeed, ChatList, ChatSocket } from "./CustomChatEngineV2";
import i18next from 'i18next';
class ChatMessage extends Component {
  constructor(props) {
    super();
  }
  close = () => {
    this.props.close && this.props.close();
  };
  render() {
    const SENDBIRD_CHAT_ID = "CB3E771D-3DE0-4AC4-A271-7068DBD1F13C"
    // const { userInfo, data, primary, language, logSendBirdUser } = this.props;
    const { LOG_CHART_USER, userInfo, logSendBirdUser, logPublicChatUser, selected_channel_url, role, boothChatlogPublicChatUser, sendNotification, SET_NOTIFICATION } = this.props;
    const { projectId, userName, userSecret } = logSendBirdUser;
    // let { access_key, id } = logPublicChatUser;
    console.log('logSendBirdUser', logSendBirdUser);
    console.log('userInfo', userInfo);
    console.log('logPublicChatUser', logPublicChatUser);
    if (!logSendBirdUser) {
      axios('getOrCreateChatUser', { params: {} })
        .then((response) => {
          console.log('getOrCreateChatUser', response)
          // 保存数据
          this.props.LOG_CHART_USER(response);
        });
      axios('getOrJoinPublicChat', { params: {} })
        .then((response) => {
          // console.log('getOrJoinPublicChat', response)
          this.props.LOG_PUBLIC_CHART_USER(response);
        });
    };
    if (boothChatlogPublicChatUser && boothChatlogPublicChatUser.access_key) {
      // access_key = boothChatlogPublicChatUser.access_key;
      // id = boothChatlogPublicChatUser.id;
    }
    const setNotification = (data) => SET_NOTIFICATION(data)
    return (
      <React.Fragment>
        <div className='ChatEngine-contenter' >

          <SBProvider appId={SENDBIRD_CHAT_ID} userId={userInfo._id} accessToken={logSendBirdUser.token} isReactionEnabled={false} >
            {/* <SBProvider appId="25C589FF-A493-4BF2-B02A-7F40DC3205E5" userId={"nike"} accessToken={"6c3c53113cb7dae520378716237ef2714dc7a919"} isReactionEnabled={false}> */}
            <CustomizedApp selected_channel_url={selected_channel_url} userInfo={userInfo} notification={sendNotification} setNotification={setNotification} />
          </SBProvider>
          <CloseOutlined className='closeOutlined' onClick={this.close} />
        </div>

      </React.Fragment >
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.loginUserInfo.userInfo,
  primary: state.profile.primary,
  language: state.profile.language,
  logSendBirdUser: state.loginUserInfo.logSendBirdUser,
  logPublicChatUser: state.loginUserInfo.logPublicChatUser,
  boothChatlogPublicChatUser: state.loginUserInfo.boothChatlogPublicChatUser,
  role: state.loginUserInfo.role,
  sendNotification: state.loginUserInfo.sendNotification
});

const mapDispatchToProps = (dispatch) => ({

  LOG_CHART_USER: (data) => {
    dispatch(LOG_CHART_USER(data));
  },
  SET_NOTIFICATION: (data) => {
    dispatch({
      type: "SET_NOTIFICATION", data
    })
  }

  // BACK_IFRAME: () => {
  //   dispatch({
  //     type: 'BACK_IFRAME',
  //     // data: item
  //   });
  // }
});

const enhance = compose(
  withRouter,
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(ChatMessage);
