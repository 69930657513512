import React, { Component } from 'react';
import './SeeAllRecommendation.less';
import CategoriesList from './components/CategoriesList';
import { withTranslation } from 'react-i18next';
import { Row } from 'antd';
import { connect } from 'react-redux';
import { compose } from 'redux';
import axios from '../../network/axios/$axios';
import { getBriefcasesList } from '../../actions/BriefcaseAction';
import i18next from 'i18next';

class SeeAllRecommendationScreen extends Component {
  constructor(props) {
    super();
    this.state = {
      recommendList: []
    };
  }
  componentDidMount() {
    axios('Booth')
      .then((response) => {
        console.log('/Booth', response.data);
        const mapObj = {
          ALL: response.data
        };
        response.data.forEach(item => {
          if (mapObj[item.ZoneId]) {
            mapObj[item.ZoneId].push(item);
          } else {
            mapObj[item.ZoneId] = [item];
          }
        });
        this.setState({ recommendList: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  clickItem = (item) => {
    this.props.history.push('/ExpoHall/Booth/' + item._id);
    this.clickUpload(item.name);
  };
  //统计点击事件
  clickUpload = (fieldname) => {
    axios('activity', {
      data: {
        event: '[Click] ExpoHall ' + fieldname
      }
    })
      .then(response => {
        console.log('/activity', response);
      })
  };
  // 重新请求briefcases列表
  getBriefcaseslist = () => {
    const { userInfo, getBriefcasesList } = this.props;
    getBriefcasesList(userInfo.id);
  };

  render() {
    const { briefcasesData, userInfo } = this.props;
    const booths = briefcasesData.exhibitor_booths;
    return (
      <div className="page-container center">
        <Row>
          <span className="title">{i18next.t('translations.recommendations')}</span>
        </Row>
        <CategoriesList
          data={this.state.recommendList}
          onSelectItem={this.clickItem}
          exhibitorBooths={booths}
          userInfo={userInfo}
          fetchBriefcase={this.getBriefcaseslist}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.loginUserInfo.userInfo,
  briefcasesData: state.briefcases.briefcasesData
});

const mapDispatchToProps = (dispatch) => ({
  getBriefcasesList: (userId) => {
    dispatch(getBriefcasesList(userId));
  }
});

const enhance = compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps));

export default enhance(SeeAllRecommendationScreen);