import { Button, Input, Modal } from 'antd';
import HelpDeskCSS from './Message.module.css';
import React, { Component } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import i18next from 'i18next';
const { TextArea } = Input;

class Message extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valueMessage: ''
    };
  }
  onChange = (e) => {
    const { value } = e.target;
    this.setState({
      valueMessage: value
    });
  };
  render() {
    const { isModalVisible, handleCancel, handleOk, primary } = this.props;
    const { valueMessage } = this.state;

    return (
      <Modal
        width={800}
        className={HelpDeskCSS.AboutUs}
        title={<div>{i18next.t('translations.leave-a-message')}</div>}
        visible={isModalVisible}
        onCancel={handleCancel}
        closeIcon={<CloseOutlined />}
        footer={
          <div className="flex flex_j_r">
            <Button
              style={{
                fontWeight: 'bold',
              }}
              onClick={() => handleCancel()}
            >
              {i18next.t('translations.close')}
            </Button>
            <Button
              style={{
                background: primary,
                color: 'white',
                fontWeight: 'bold',
                borderRadius: '.8rem'
              }}
              onClick={() => {
                handleOk(valueMessage);
                this.setState({ valueMessage: '' })
              }}
            >
              {i18next.t('translations.confirm')}
            </Button>
          </div>
        }
      >
        <div className="fw700">{i18next.t('translations.enquiry-message')}</div>
        <div>
          <TextArea rows={4} onChange={this.onChange} value={valueMessage} />
        </div>
      </Modal>
    );
  }
}
export default Message;

// export default function Message(props) {
//     const { isModalVisible, handleCancel } = props;
//     return (
//         <Modal width={800} className={HelpDeskCSS.AboutUs} title="Leave a message" visible={isModalVisible} onCancel={handleCancel} onOk={handleOk}>
//             <div className="fw700">Message</div>
//             <div>
//                 <TextArea rows={4} onChange={this.onChange} />
//             </div>
//         </Modal>
//     );
// }
