import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Radio, Divider, Button } from 'antd';
import './CategoryFilter.less';
import i18next from 'i18next';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import axios from '../../../network/axios/$axios';
import get from 'lodash/get';
import { RiArrowRightSLine, RiArrowDownSLine } from "react-icons/ri";

const { Search } = Input;
const DEFAULT = {
  _id: 'ALL',
  display_name: {
    'en-us': "All", 'zh-hans': "所有", 'zh-hant': "所有"
  },
  isAll: true
};

const CategoryFilter = (props) => {
  const [countrySwitch, setCountrySwitch] = useState(true);
  const [zoneSwitch, setZoneSwitch] = useState(true);
  const [businessSwitch, setBusinessSwitch] = useState(true);
  const [filterList, setFilterList] = useState([]);

  const { language, onCountryChange, onZoneChange, remoteFilter, currentCountry, currentZone, onBusinessChange, currentBusiness } = props;
  
  useEffect(() => {
    axios('hallTag')
      .then((response) => {
        console.log('/Info/HallTag', response);
        const data = response.data;
        axios('hallTagGroup')
          .then((res) => {
            console.log('/Info/HallTagGroup', res);
            const list = res.data;
            const filterData = [];
            list.map(v => {
              const arr = data.filter(obj => obj.GroupId === v._id && !obj.isAll);
              if (v.canFilter) {
                filterData.push({
                  title: (v.display_name)[language],
                  data: arr,
                  type: v.name
                })
              }
            })
            setFilterList(filterData);
          })
        .catch((error) => {
          console.log(error);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [language]);

  const down = <RiArrowDownSLine size={24} className="hp-collapse-arrow hp-text-color-black-60" />;
  const up = <RiArrowRightSLine
    size={24}
    className="hp-collapse-arrow hp-text-color-black-60"
  />

  const list1 = filterList.filter(v => v.type === 'Country / Region');
  const list2 = filterList.filter(v => v.type === 'Zone');
  const list3 = filterList.filter(v => v.type === 'Business Nature');
  const country = list1.length ? [DEFAULT, ...(list1[0].data)] : [];
  const zones = list2.length ? [DEFAULT, ...(list2[0].data)] : [];
  const businessNature = list3.length ? [DEFAULT, ...(list3[0].data)] : [];
  const title1 = list1.length ? list1[0].title : '';
  const title2 = list2.length ? list2[0].title : '';
  const title3 = list3.length ? list3[0].title : '';

  const countryChoose = (e) => {
    const arr = country.filter(v => v._id === e.target.value);
    onCountryChange(arr[0]);
  };

  const zoneChoose = (zone) => {
    onZoneChange(zone);
  };

  const zoneBusiness = (business) => {
    onBusinessChange(business);
  };

  const onRemoteFilter = () => {
    remoteFilter();
  };

  return (
    <div className="category-filter">
      {businessNature.length > 0 ? <div>
        <div onClick={() => { setBusinessSwitch(!businessSwitch) }} className='filter-title flex flex_rl'>
          <span style={{fontSize: '16px'}}>{title3}</span>
          <span className='mL05'>{businessSwitch ? down : up}</span>
        </div>
        {businessSwitch && businessNature.map((business, index) => (
          <div onClick={() => zoneBusiness(business)} className={'hp-mt-10 sub-title ' + (currentBusiness._id === business._id ? 'selected' : '')} key={get(get(business, 'display_name'), language)}>
            <div className='flex'>
              <i className="ri-price-tag-3-fill icon-bc" style={{ width: 30 }} />
              <span className='filter-item-text'>{get(get(business, 'display_name'), language)}</span>
            </div>
          </div>
        ))}
      </div> : null}
      {businessNature.length > 0 ? <Divider /> : null}
      {zones.length > 0 ? <div>
        <div onClick={() => { setZoneSwitch(!zoneSwitch) }} className='filter-title flex flex_rl'>
          <span style={{ fontSize: '16px' }}>{title2}</span>
          <span className='mL05'>{zoneSwitch ? down : up}</span>
        </div>
        {zoneSwitch && zones.map((zone, index) => (
          <div onClick={() => zoneChoose(zone)} className={'hp-mt-10 sub-title ' + (currentZone._id === zone._id ? 'selected' : '')} key={get(get(zone, 'display_name'), language)}>
            <div className='flex'>
              <i className="ri-price-tag-3-fill icon-bc" style={{ width: 30 }} />
              <span className='filter-item-text'>{get(get(zone, 'display_name'), language)}</span>
            </div>
          </div>
        ))}
      </div> : null}
      {zones.length > 0 ? <Divider /> : null}
      {country.length > 0  ? <div>
        <div onClick={() => { setCountrySwitch(!countrySwitch) }} className='filter-title flex flex_rl'>
          <span className='hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30' style={{ fontSize: '16px' }}>{title1}</span>
          <span className='mL05'>{countrySwitch ? down : up}</span>
        </div>
        {countrySwitch && <Radio.Group onChange={countryChoose} value={currentCountry._id}>
          {country.map(obj => (
            <Row key={obj._id} className={'hp-mt-10 sub-title ' + (currentCountry._id === obj._id ? 'selected' : '')} style={{ width: 200 }}>
              <Radio value={obj._id} className='filter-item-text'>{get(obj, 'display_name')[language]}</Radio>
            </Row>
          ))}
        </Radio.Group>}
      </div> : null}
      {country.length > 0 ? <Divider className='hp-mb-0' /> : null}
      <Button onClick={() => onRemoteFilter()} block type="primary" className="hp-mt-32" style={{ fontSize: 11, textTransform: 'uppercase' }}>{i18next.t('translations.filter')}</Button>
    </div>
  );
};

const enhance = compose(withTranslation('translations'));

export default enhance(CategoryFilter);
