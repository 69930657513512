import "@zoomus/websdk/dist/css/bootstrap.css"
import "@zoomus/websdk/dist/css/react-select.css"
import { useRef, useState, useEffect } from "react"
import queryString from "query-string"
import KJUR from 'jsrsasign'
import { compose } from 'redux';
import { connect } from 'react-redux';
const ZOOM_SDK_KEY = "9TP63UZOUpRtc9hTs8zUZe9SJdQ9VUha0in8"
const ZOOM_SDK_SECRET = "Y8SQHcfCfWGPimQYOVHlDbvQu8rmqtCw4FST"

const generateToken = (roomId) => {
    const iat = Math.round(new Date().getTime() / 1000) - 30;
    const exp = iat + 2 * 60 * 60

    const oHeader = { alg: 'HS256', typ: 'JWT' }

    const oPayload = {
        sdkKey: ZOOM_SDK_KEY,
        mn: roomId,
        role: 0,
        iat: iat,
        exp: exp,
        appKey: ZOOM_SDK_KEY,
        tokenExp: exp
    }
    const sHeader = JSON.stringify(oHeader)
    const sPayload = JSON.stringify(oPayload)
    const signature = KJUR.jws.JWS.sign('HS256', sHeader, sPayload, ZOOM_SDK_SECRET)

    return signature
}

const Zoom3 = (props) => {
    const { userInfo } = props;
    const ref = useRef(null)
    const [innerWidth, setInnerWidth] = useState(0)

    useEffect(() => {
        // console.log({ ref })
        // if (ref.current && ref.current !== null) {
        //     if (ref.current.clientWidth) setInnerWidth(ref.current.clientWidth)
        // }
    }, [])

    // const { style, zoomData } = props
    // const { meetingNumber, passWord } = JSON.parse(zoomData)


    const onLoad = () => {
        const leaveUrl = 'about:blank';
        let meetingNumber = props.match.params.id;
        const qs = queryString.parse(props.location.search);

        const { ZoomMtg } = require("@zoomus/websdk");
        const zoomJSLib = 'https://source.zoom.us/2.7.0/lib';
        const zoomJSAVLib = '/av'
        ZoomMtg.setZoomJSLib(zoomJSLib, zoomJSAVLib);
        ZoomMtg.preLoadWasm()
        ZoomMtg.prepareWebSDK()
        // loads language files, also passes any error messages to the ui
        ZoomMtg.i18n.load('en-US')
        ZoomMtg.i18n.reload('en-US')
        ZoomMtg.init({
            disableCORP: !window.crossOriginIsolated, // default true
            leaveUrl: leaveUrl,
            success: (success) => {
                console.log(success)

            },
            error: (error) => {
                console.log("zoom init error")
                console.log(error)
            }
        });
        console.log("zoom", userInfo)
        const email = userInfo.email
        let name = `${userInfo?.firstname || ""} ${userInfo?.lastname || ""}`
        if (name.trim() === "") name = userInfo.id
        console.log({ email, name, userInfo })
        ZoomMtg.join({
            signature: generateToken(meetingNumber),
            sdkKey: ZOOM_SDK_KEY,
            meetingNumber: meetingNumber,
            userName: name,
            userEmail: email,
            passWord: qs.p,
            success: (success) => {
                console.log("zoom join successful")
                console.log(success)
            },
            error: (error) => {
                console.log("zoom join error")
                console.log(error)
            }
        });
        return null;
    }
    return (
        <>
            {/* <div class="row" style="text-aloi">
            <div class="column">
                <div style={style} id="meetingSDKElement" ref={ref} >

                </div>
            </div>
            <div class="column">
                <div id="meetingSDKChatElement"  ref={ref}></div>
            </div>
        </div> */}
            {/* <div style={style} id="meetingSDKElement" ref={ref}> */}

            {/* </div> */}
            <div />
            {onLoad()}
        </>

    )
}

const mapStateToProps = (state) => ({
    userInfo: state.loginUserInfo.userInfo
});

const enhance = compose(connect(mapStateToProps, null));

export default enhance(Zoom3);