import './index.less';
// import React, { useState } from 'react';

export default function HeaderTitle({title}) {
  return (
    <div className="HeaderTitle">
      <span className="asi-big-title">{title}</span>
    </div>
  );
}
