import React, { Component } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import nipplejs from 'nipplejs';
import './style.css';
import keyState from "./key-state.js"
import paintingInfo from './paintingInfo.js';
import $ from 'jquery'
import { connect } from 'react-redux';
import SocialLoungeScreen from '../routes/SocialLounge/SocialLoungeScreen';

class SocialLounge3d extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.init3d({
      '3DUrl': this.props.url || 'https://d1a370nemizbjq.cloudfront.net/4f3f8eda-d682-4d21-ab14-074c395ab49c.glb'
    })
  }

  init3d(result) {
    set_human(result);
    // vars
    let speedPower = 1.2;
    let controlFocus = "player";
    let joyY = 0;
    let joyX = 0;
    let fwdValue = 0;
    let bkdValue = 0;
    let rgtValue = 0;
    let lftValue = 0;
    let tempVector = new THREE.Vector3();
    let upVector = new THREE.Vector3(0, 1, 0);
    let joyManager;
    var clock = new THREE.Clock();
    var container, stats;
    var mixers = [];
    var startY = 0;
    const width = window.innerWidth,
      height = window.innerHeight;
    const collisionObjs = []
    
    // Create a renderer and add it to the DOM.
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(width, height);
    const dom = document.getElementById('mobileInterface');
    dom.appendChild(renderer.domElement);

    const keys = keyState(document, {
      left: ["ArrowLeft", "KeyA"],
      right: ["ArrowRight", "KeyD"],
      up: ["ArrowUp", "KeyW"],
      down: ["ArrowDown", "KeyS"],
    })
    const camera = new THREE.PerspectiveCamera(50, width / height, 0.1, 10000);
    camera.position.z = 100;
    camera.position.x = 150;
    camera.position.y = 100;
    camera.zoom = 0.4;
    const scene = new THREE.Scene();


    const playerHitbox = new THREE.Mesh(
      new THREE.BoxGeometry(20, 50, 20),
      new THREE.MeshBasicMaterial({ color: 0x00ff00, wireframe: true }));
    playerHitbox.visible = false
    scene.add(playerHitbox);


    const rgbeLoader = new RGBELoader();
    scene.background = new THREE.Color().setHSL(0.6, 0, 0.2);

    render();

    let root

    let collidableMeshList = [];
    const gltfLoader = new GLTFLoader();
    // gltfLoader.load('https://r105.threejsfundamentals.org/threejs/resources/models/cartoon_lowpoly_small_city_free_pack/scene.gltf', (gltf) => {
    gltfLoader.load('https://du2r3hvqku65.cloudfront.net/3d/booth-models/vr-museum/vr-museum.gltf', (gltf) => {
      root = gltf.scene;
      const s = 52;
      root.scale.set(s, s, s);
      root.position.z = 100;
      root.position.y = -30;
      let min = new THREE.Vector3();
      let max = new THREE.Vector3();
      root.traverse((n) => {
        if (!n.isMesh) return
        console.log('hitbox', n)
        if (n.name.startsWith('hitbox')) {
          n.visible = false;
          console.log(n);
          let b3 = (new THREE.Box3()).setFromObject(n.clone())
          min.copy(b3.min)
          max.copy(b3.max)
          min.multiplyScalar(s)
          max.multiplyScalar(s)
          min.z += 80
          min.y += 10
          max.z += 80
          max.y += 10
          console.log({ b3, min, max })
          b3.set(min, max)
          collisionObjs.push(b3)

        } else if (n.name === "roof-top") n.visible = false;
      })
      root.castShadow = true;
      root.receiveShadow = true;

      scene.add(root);
      root.updateMatrixWorld();
      render();
    });

    scene.background = new THREE.Color().setHSL(0.6, 0, 0.2);

    const hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 0.6);
    hemiLight.color.setHSL(0.6, 1, 0.6);
    hemiLight.groundColor.setHSL(0.095, 1, 0.75);
    hemiLight.position.set(0, 50, 0);
    scene.add(hemiLight);
    hemiLight.castShadow = true;

    const hemiLightHelper = new THREE.HemisphereLightHelper(hemiLight, 10);
    // scene.add( hemiLightHelper );
    const dirLight = new THREE.DirectionalLight(0xffffff, 0.5);
    dirLight.color.setHSL(0.1, 1, 0.95);
    dirLight.position.set(-1, 1.75, 1);
    dirLight.position.multiplyScalar(50);
    scene.add(dirLight);

    dirLight.castShadow = true;

    dirLight.shadow.mapSize.width = 4096;
    dirLight.shadow.mapSize.height = 4096;

    const d = 5;

    dirLight.shadow.camera.left = -d;
    dirLight.shadow.camera.right = d;
    dirLight.shadow.camera.top = d;
    dirLight.shadow.camera.bottom = -d;

    dirLight.shadow.camera.far = 3500;
    dirLight.shadow.bias = -0.0001;

    const dirLightHelper = new THREE.DirectionalLightHelper(dirLight, 10);
    // scene.add( dirLightHelper );
    scene.add(new THREE.AmbientLight(0x020202));
    const controls = new OrbitControls(camera, renderer.domElement);
    const resetControl = () => {
      try {
        $('#bottom').hide();
        $("#dialog").dialog("close");
      } catch { }

      //controls.addEventListener( 'change', render ); // use if there is no animation loop
      controls.minDistance = 2;
      controls.maxDistance = 10;
      controls.target.set(0, 0, -0.2);

      controls.maxDistance = 100;
      controls.minDistance = 100;
      //controls.maxPolarAngle = (3.142 / 4) * 3;
      controls.maxPolarAngle = 3.142 / 2;
      controls.minPolarAngle = 0;
      controls.autoRotate = false;
      controls.autoRotateSpeed = 0;
      controls.rotateSpeed = 0.4;
      controls.enableDamping = false;
      controls.dampingFactor = 0.1;
      controls.enableZoom = false;
      controls.enablePan = false;
      controls.minAzimuthAngle = Infinity; // radians
      controls.maxAzimuthAngle = Infinity; // radians
    }
    resetControl();
    // Add grid
    const size = 500;
    const divisions = 30;

    const gridHelper = new THREE.GridHelper(size, divisions);
    //scene.add(gridHelper);

    const geometry = new THREE.BoxGeometry(0.1, 0.1, 0.1);
    const cubeMaterial = new THREE.MeshNormalMaterial();

    const mesh = new THREE.Mesh(geometry, cubeMaterial);
    scene.add(mesh);
    var mixer;
    var animationActionIdle;
    var animationActionWalk;
    var activeAction;
    var lastAction;
    var characterModel;
    var object;
    var modelReady = false;

    function set_human(result) {
      console.log("set_human");

      const loader = new GLTFLoader();
      console.log(result['3DUrl']);
      loader.load(result['3DUrl'], function (gltf) {
        console.log(gltf)
        // loader.load( 'models/business-girl.fbx', function ( object ) {
        object = gltf.scene
        mixer = new THREE.AnimationMixer(object);
        mixers.push(mixer);
        object.position.set(0, -30, 0);
        object.scale.set(40, 40, 40);
        object.metalness = 0;
        object.traverse(function (child) {
          if (child.isMesh) {
            child.castShadow = true;
            child.receiveShadow = true;
            child.material.metalness = 0;
            child.material.roughness = 1;
            child.material.shininess = 0;
            child.material.smoothShading = true;
          }
        });
        characterModel = object;
        scene.add(object);
        render();

        //add an animation from another file
        const fbx_loader = new FBXLoader();
        fbx_loader.load(process.env.PUBLIC_URL + '/models/Idle.fbx', (act) => {
          console.log('loaded idle');
          // act.animations[0].tracks.shift(); //delete the specific track that moves the object forward while running
          //console.dir((object as THREE.Object3D).animations[0])
          console.log("animations", act.animations[0])
          animationActionIdle = mixers[0].clipAction(act.animations[0]);
          animationActionIdle.play();
          console.log("animationActionIdle", animationActionIdle)
          modelReady = true
        },
          (xhr) => {
            console.log(
              (xhr.loaded / xhr.total) * 100 + '% loaded'
            )
          },
          (error) => {
            console.log(error)
          }
        );

        fbx_loader.load(process.env.PUBLIC_URL + '/models/Walk.fbx', (act) => {
          console.log('loaded walk');
          // act.animations[0].tracks.shift(); //delete the specific track that moves the object forward while running
          //console.dir((object as THREE.Object3D).animations[0])   
          animationActionWalk = mixers[0].clipAction(act.animations[0]);
          console.log("animationActionWalk", animationActionWalk)
          modelReady = true
        },
          (xhr) => {
            console.log(
              (xhr.loaded / xhr.total) * 100 + '% loaded'
            )
          },
          (error) => {
            console.log(error)
          }
        );
      });
    }

    let size_floor = 100;
    const geometry_floor = new THREE.BoxGeometry(size_floor, 1, size_floor);
    const material_floor = new THREE.MeshNormalMaterial();
    const floor = new THREE.Mesh(geometry_floor, material_floor);
    floor.position.y = -5;
    // scene.add(floor);


    addJoystick();
    resize();
    animate();

    // added joystick + movement

    function resize() {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    }

    function onMouseWheel(event) {


      if (event.deltaY > 0) {
        for (var i = 0; i < mixers.length; i++) {
          mixers[i].update(clock.getDelta() * 5);
        }
      } else {
        for (var i = 0; i < mixers.length; i++) {
          mixers[i].update(clock.getDelta() * -5);

        }
      }
    }

    function onTouchStart(event) {
      startY = event.touches[0].pageY;

    }

    function onTouchMove(event) {

      var delta = event.deltaY;

      if (event.deltaY > 0) {
        for (var i = 0; i < mixers.length; i++) {
          mixers[i].update(clock.getDelta() * 5);
        }
      } else {
        for (var i = 0; i < mixers.length; i++) {
          mixers[i].update(clock.getDelta() * -5);

        }
      }
    }
    let factor = 3;
    let factorRate = 4;
    function animate() {
      var delta = clock.getDelta();
      requestAnimationFrame(animate);
      if (modelReady) {
        // mixer.rotation.set(0,0,0)
        // console.log(mixer)
        mixer.update(factor * speedPower * delta / (factorRate + speedPower));
        // mixer.update( 0  );
      }
      render()
      keyboardControl()
      updatePlayer(delta);
      // console.log(object);
    }

    function keyboardControl() {
      let x = 0;
      let y = 0;
      // if ( keyboard.pressed("Q") )
      // 	MovingCube.rotation.y += rotateAngle;
      // if ( keyboard.pressed("E") )
      // 	MovingCube.rotation.y -= rotateAngle;

      if (keys.left && !keys.right)
        x = -1
      if (keys.right && !keys.left)
        x = 1
      if (keys.up && !keys.down)
        y = 1
      if (keys.down && !keys.up)
        y = -1

      if ((x !== 0) || (y !== 0)) {

        if (controlFocus == "painting") {
          resetControl()
          controls.target.copy(mesh.position);
          controls.reset()

        }
        controlFocus = "keyboard"
        joyY = x == 0 ? y : y * 0.7
        joyX = y == 0 ? x : x * 0.7
        if (keys.ShiftLeft && speedPower <= 5) { speedPower += 0.1 }
      }
      else if (controlFocus == "keyboard") {
        joyY = 0;
        joyX = 0;
        speedPower = 1.2
      }
    }


    function updatePlayer(delta) {

      const baseSpeed = 0.8;
      if (controlFocus == "painting") return
      if (!animationActionIdle || !animationActionWalk) return;
      // let position = JSON.stringify(characterModel);
      var stop = !(joyY || joyX);

      if (stop) {
        animationActionWalk.stop();
        animationActionIdle.play();
        return
      } else {
        animationActionIdle.stop();
        animationActionWalk.play();
      }


      let frontAngle = controls.getAzimuthalAngle() % 3.142;
      if (frontAngle < 0) frontAngle += 2 * 3.142
      let currentAngle = characterModel.rotation.y % (2 * 3.142)
      if (currentAngle < 0) currentAngle += 2 * 3.142
      let targetAngle = 3.142 / 2 - Math.atan2(Math.abs(joyY), Math.abs(joyX))
      if ((joyY < 0) && (joyX < 0)) targetAngle = 2 * 3.142 - targetAngle;
      if ((joyY >= 0) && (joyX >= 0)) targetAngle = 3.142 - targetAngle;
      if ((joyY >= 0) && (joyX < 0)) targetAngle = 3.142 + targetAngle;
      targetAngle = targetAngle + frontAngle;
      if (targetAngle > 2 * 3.142) targetAngle -= 2 * 3.142
      let shouldRotate = targetAngle - currentAngle
      const reverse = Math.abs(shouldRotate) > 3.142


      characterModel.rotation.y = currentAngle + (reverse ? - shouldRotate % 3.142 : shouldRotate) * delta / 0.2
      // characterModel.rotation.set(0, 0, 0)
      //     const clockwise = true //rotation decreases

      // console.log(characterModel.rotation.y) 

      //     if(bkdValue > 0){
      //         if(rgtValue > 0){
      //             characterModel.rotation.y = (45+rgtValue/(bkdValue + rgtValue)*90)/180*3.142+(angle-0.982);
      //             characterModel.rotation.y = 
      //                 (45+rgtValue/(bkdValue + rgtValue)*90)  /180 *3.142 + (angle-0.982);

      //         }else if(lftValue > 0){
      //             characterModel.rotation.y = (45-lftValue/(bkdValue + lftValue)*90)/180*3.142+(angle-0.982);
      //         }
      //     }else if(fwdValue > 0){
      //         if(rgtValue > 0){
      //             characterModel.rotation.y = (225-rgtValue/(fwdValue + rgtValue)*90)/180*3.142+(angle-0.982);
      //         }else if(lftValue > 0){
      //             characterModel.rotation.y = (225+lftValue/(fwdValue + lftValue)*90)/180*3.142+(angle-0.982);
      //         }
      //     }
      // mesh.position.add(tempVector)
      const safePosition = playerHitbox.position.clone();
      playerHitbox.position.x = playerHitbox.position.x + Math.sin(characterModel.rotation.y) * baseSpeed * speedPower;
      playerHitbox.position.z = playerHitbox.position.z + Math.cos(characterModel.rotation.y) * baseSpeed * speedPower;
      let playerHitbox3 = (new THREE.Box3).setFromObject(playerHitbox.clone());
      let collidedObj = collisionObjs.find(b => b.intersectsBox(playerHitbox3))
      if (collidedObj) {
        console.log({ collidedObj, playerHitbox3 })
        playerHitbox.position.copy(safePosition)
      } else {
        characterModel.position.x = playerHitbox.position.x
        characterModel.position.z = playerHitbox.position.z
        mesh.position.x = playerHitbox.position.x
        mesh.position.z = playerHitbox.position.z
      }
      mesh.updateMatrixWorld();
      // characterModel.position.x = mesh.position.x;
      // human.position.x = mesh.position.x;
      //  human.position.y = mesh.position.y;
      //  human.position.z = mesh.position.z;
      //controls.target.set( mesh.position.x, mesh.position.y, mesh.position.z );
      //   // reposition camera
      //      for (var vertexIndex = 0; vertexIndex < hitbox.geometry.attributes.position.array.length; vertexIndex++)
      // {       
      //     var localVertex = new THREE.Vector3().fromBufferAttribute(hitbox.geometry.attributes.position, vertexIndex).clone();
      //     var globalVertex = localVertex.applyMatrix4(hitbox.matrix);
      //     var directionVector = globalVertex.sub( hitbox.position );

      //     var ray = new THREE.Raycaster( hitbox.position, directionVector.clone().normalize() );
      //     var collisionResults = ray.intersectObjects( collidableMeshList );
      //     if ( collisionResults.length > 0 && collisionResults[0].distance < directionVector.length() ) 
      //     {
      //         // a collision occurred... do something...
      //         console.log('hit',collisionResults[0].object.name)
      //     }
      // }



      camera.position.sub(controls.target);
      controls.target.copy(mesh.position);
      camera.position.add(mesh.position);


    };

    function addJoystick() {
      const options = {
        zone: document.getElementById('joystickWrapper1'),
        size: 120,
        color: "lightyellow",
        multitouch: true,
        maxNumberOfNipples: 1,
        mode: 'static',
        restJoystick: true,
        shape: 'circle',
        // position: { top: 20, left: 20 },
        position: {
          top: '100px',
          left: '60px'
        },
        dynamicPage: true
      };



      joyManager = nipplejs.create(options);

      joyManager['0'].on('move', function (evt, data) {
        if (controlFocus == "painting") {
          resetControl()
          controls.target.copy(mesh.position);
          controls.reset()

        }
        controlFocus = "joystick";
        joyY = data.vector.y;
        joyX = data.vector.x;
        speedPower = data.force;

        joyManager['0'].on('end', function (evt) {
          joyY = 0;
          joyX = 0;
          speedPower = 1.2;
        });

      })
    }

    //click 
    var raycaster, mouse = {
      x: 0,
      y: 0
    };


    function raycast(e) {
      if (controlFocus == "painting") return

      //1. sets the mouse position with a coordinate system where the center
      //   of the screen is the origin
      mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(e.clientY / window.innerHeight) * 2 + 1;

      //2. set the picking ray from the camera position and mouse coordinates
      raycaster.setFromCamera(mouse, camera);

      //3. compute intersections
      var intersects = raycaster.intersectObjects(scene.children);
      const painting = intersects.find(elem => elem.object.name.startsWith("painting"))
      if (painting && paintingInfo[painting.object.name]) {
        let config = paintingInfo[painting.object.name];
        controlFocus = "painting"

        characterModel.position.set(config.characterModel.x, config.characterModel.y, config.characterModel.z)
        characterModel.rotation.set(config.characterModel.rx, -config.characterModel.ry, config.characterModel.rz)
        playerHitbox.position.x = characterModel.position.x
        playerHitbox.position.z = characterModel.position.z
        mesh.position.set(config.characterModel.x, 0, config.characterModel.z)
        mesh.rotation.set(0, 0, 0)
        controls.target.set(config.controls.x, config.controls.y, config.controls.z)
        let reverseCam = 0;
        if (config.characterModel.ry == -1.6) reverseCam = 3.14
        controls.minAzimuthAngle = 1.57 + reverseCam;
        controls.maxAzimuthAngle = 1.57 + reverseCam;
        controls.minPolarAngle = 1.57;
        controls.maxPolarAngle = 1.57;
        controls.update();

        controls.minAzimuthAngle = 0.35 + reverseCam;
        controls.maxAzimuthAngle = 2.79 + reverseCam;
        controls.minPolarAngle = 0.35;
        controls.maxPolarAngle = 2.79;
        controls.zoom = 0.9;
        controls.update()
        $('#bottom').show();
        $('#painting_name').text(config.painting.name)
        $('#dialog').attr('title', config.painting.name)
        $('#dialogImg').attr('src', config.painting.link)
      }
    }

    function render() {
      renderer.render(scene, camera);
      renderer.toneMappingExposure = 1;
      renderer.outputEncoding = THREE.sRGBEncoding;
      raycaster = new THREE.Raycaster();
      renderer.domElement.addEventListener('mousedown', raycast, false);
    }

    window.camera = camera;
    window.factor = factor;
    window.factorRate = factorRate;
    window.controls = controls;
    window.characterModel = characterModel;
    window.threeModel = scene;
    window.logNow = () => ({
      mesh,
      camera,
      characterModel,
      controls
    });
  }

  render() {
    return (
      <>
        <div id="mobileInterface" className="noSelect">
          <div id="joystickWrapper1"></div>
        </div>
        <SocialLoungeScreen {...this.props} />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  url: state.loginUserInfo.url
});

export default connect(mapStateToProps, null)(SocialLounge3d);
