import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Row, Col, Menu } from "antd";
import {
  User,
  Notification,
  Activity,
  Setting,
  Password,
} from "react-iconly";

import { get } from "lodash";
import i18next from "i18next";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { saveSelectedTabKey } from "../../actions/BusinessMatchingAction";
import { connect } from "react-redux";
import './Profile.less';

function MenuProfile(props) {
  const { userInfo, saveSelectedTabKey, onCloseDrawer, selectedKey, loadData } = props;
  let str = '';
  if (userInfo && userInfo.firstname) {
    str = (userInfo.firstname).substring(0, 1);
  }
  const menuIconClass = "remix-icon hp-mr-8";

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  let role = '';
  let customCSS = '';
  if (userInfo.role === 'Visitor') {
    role = i18next.t('translations.visitor');
    customCSS = 'visitor';
  } else if (userInfo.role === 'Organizer') {
    role = i18next.t('translations.organizer');
    customCSS = 'organizer';
  } else {
    role = i18next.t('translations.exhibitor');
    customCSS = 'exhibitor';
  }

  return (
    <Col flex="280px" className="hp-profile-menu hp-py-24 my-profile">
      <div className="hp-w-100">
        <div className="hp-profile-menu-header hp-mt-md-16 hp-text-center">

          <div className="flex-row-center hp-mt-16">
            <div className="flex-row-center" style={{ minWidth: 80, width: 80, height: 80, borderRadius: 40, backgroundColor: '#C4E2FC' }}>
              <span className="f30">{str}</span>
            </div>
          </div>

          <h3 className="hp-mt-24 hp-mb-4">{get(userInfo, 'firstname') + ' ' + get(userInfo, 'lastname')}</h3>
          <span className="hp-p1-body">
            {get(userInfo, 'email')}
          </span>
          <Row justify="center" className="hp-mt-16">
            <div className={'flex-row-center ' + customCSS}>
              <span>
                {role}
              </span>
            </div>
          </Row>
        </div>

        <Menu
          mode="inline"
          className="hp-w-100 hp-profile-menu-body"
          theme={"light"}
        >
          <Menu.Item
            key="1"
            icon={
              <span className="remix-icon f20">
                <i className="ri-user-fill" />
              </span>
            }
            className={`
              hp-mb-16 hp-pl-24 hp-pr-32
              ${splitLocation[splitLocation.length - 1] === "Profile"
                ? "ant-menu-item-selected"
                : "ant-menu-item-selected-in-active"}
            `}
            onClick={onCloseDrawer}
          >
            <Link to="/Profile">
              {i18next.t('translations.my-profile')}
            </Link>
          </Menu.Item>

          <Menu.Item
            key="2"
            icon={
              <span className="remix-icon f20">
                <i className="ri-bookmark-3-fill" />
              </span>
            }
            className={`
              hp-mb-16 hp-pl-24 hp-pr-32
              ${splitLocation[splitLocation.length - 1] === "Avatar"
                ? "ant-menu-item-selected"
                : "ant-menu-item-selected-in-active"}
            `}
            onClick={onCloseDrawer}
          >
            <Link to="/Avatar">{i18next.t('translations.my-avatar')}</Link>
          </Menu.Item>

          <Menu.Item
            key="3"
            icon={
              <span className="remix-icon f20">
                <i className="ri-bookmark-3-fill" />
              </span>
            }
            className={`
              hp-mb-16 hp-pl-24 hp-pr-32
              ${splitLocation[splitLocation.length - 1] === "Briefcase"
                ? "ant-menu-item-selected"
                : "ant-menu-item-selected-in-active"}
            `}
            onClick={onCloseDrawer}
          >
            <Link to="/Briefcase">{i18next.t('translations.my-bookmarks')}</Link>
          </Menu.Item>

          {/* <Menu.Item
            key="4"
            icon={
              <span className="remix-icon f20">
                <i className="ri-calendar-check-fill" />
              </span>
            }
            className={`
              hp-mb-16 hp-pl-24 hp-pr-32
              ${splitLocation[splitLocation.length - 1] === "Availability"
                ? "ant-menu-item-selected"
                : "ant-menu-item-selected-in-active"}
            `}
            onClick={() => {
              onCloseDrawer();
              loadData();
            }}
          >
            <Link to="/Availability">{i18next.t('translations.availability')}</Link>
          </Menu.Item> */}

          {/* <Menu.Item
            key="5"
            icon={
              <span className="remix-icon f20">
                <i className="ri-calendar-2-fill" />
              </span>
            }
            className={`
              hp-mb-16 hp-pl-24 hp-pr-32
              ${splitLocation[splitLocation.length - 1] === "MeetingManagement"
                ? "ant-menu-item-selected"
                : "ant-menu-item-selected-in-active"}
            `}
            onClick={onCloseDrawer}
          >
            <Link to="/MeetingManagement">{i18next.t('translations.meeting-management')}</Link>
          </Menu.Item> */}

          {/* <Menu.Item
            key="6"
            icon={
              <span className="remix-icon f20">
                <i className="ri-lock-password-fill" />
              </span>
            }
            className={`
              hp-mb-16 hp-pl-24 hp-pr-32
              ${splitLocation[splitLocation.length - 1] === "ChangePassword"
                ? "ant-menu-item-selected"
                : "ant-menu-item-selected-in-active"}
            `}
            onClick={onCloseDrawer}
          >
            <Link to="/ChangePassword">{i18next.t('translations.change-password')}</Link>
          </Menu.Item> */}

        </Menu>
      </div>

    </Col>
  );
}

const mapStateToProps = (state) => {
  const selectedKey = state.businessMatching.selectedKey;
  return {
    selectedKey,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveSelectedTabKey: (key) => {
    dispatch(saveSelectedTabKey(key));
  },
});

const enhance = compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(MenuProfile);
