import React from "react";

import { useSelector } from "react-redux";

import { Button, Badge, Row, Col, Dropdown, Divider, Avatar } from "antd";
import { NotificationBing, TickCircle } from 'iconsax-react';

import { useState } from "react";
import { useEffect } from "react";
import i18next from "i18next";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

function HeaderNotifications(props) {
  const { receivedNotification, notification, openChatView, saveSelectedTabKey } = props;
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);

  useEffect(() => {
    if (receivedNotification && notification) {
      if (notification.subtype === 'Chat Message(s)') {
        setCount1(count1 + 1);
      } else {
        setCount2(count2 + 1);
      }
    }
  }, [receivedNotification, notification])

  //打开私人聊天界面
  const openPrivateChat = () => {
    setCount1(0);
    openChatView(true, true);
  };
  //跳转我的会议页面
  const goMyMeetingPage = () => {
    setCount2(0);
    saveSelectedTabKey('2');
    props.history.push('/BusinessMatching');
  };

  const notificationMenu = (
    <div className="hp-notification-dropdown hp-border-radius hp-border-color-black-40 hp-bg-black-0 hp-bg-dark-100 hp-border-color-dark-80 hp-pt-24 hp-pb-18 hp-px-24" style={{ marginTop: 23 }}>
      <Row wrap={false} align="middle" justify="space-between" className="hp-mb-16">
        <Col className="h5 hp-text-color-black-100 hp-text-color-dark-0 hp-mr-64">
          {i18next.t('translations.notifications')}
        </Col>

        <Col className="hp-badge-text hp-font-weight-500 hp-text-color-black-80 hp-ml-24">
          {count1 + count2} {i18next.t('translations.new')}
        </Col>
      </Row>

      <Divider className="hp-mt-0 hp-mb-4" />

      <Row className="hp-mt-16 hp-mb-16">
        <a onClick={() => openPrivateChat()} style={{ color: '#000' }}>
          <span>{count1} {i18next.t('translations.chat-messages')}</span>
          <span style={{ marginLeft: '60px', marginRight: '10px' }}>-</span>
        </a>
      </Row>
      <Row>
        <a onClick={() => goMyMeetingPage()} style={{color: '#000'}}>
          <span>{count2} {i18next.t('translations.meeting-requests')}</span>
          <span style={{ marginLeft: '45px' }}>-</span>
        </a>
      </Row>
    </div>
  );

  return (
    <Col className="hp-d-flex-center">
      <Button
        ghost
        type="primary"
        className="hp-border-none hp-hover-bg-black-10 hp-hover-bg-dark-100"
        icon={
          <Dropdown overlay={notificationMenu} placement="bottomRight">
            <div className="hp-position-relative">
              <div className="hp-position-absolute" style={{ right: -5, top: -5 }}>
                <Badge
                  dot
                  status={count1 + count2 > 0 ? 'processing' : ''}
                  count={count1 + count2}
                />
              </div>

              <NotificationBing size="22" className="hp-text-color-black-80 hp-text-color-dark-30" />
            </div>
          </Dropdown>
        }
      />
    </Col>
  );
};

const enhance = compose(
  withRouter,
  withTranslation('translations')
);

export default enhance(HeaderNotifications);
