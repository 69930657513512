/*
 * @Description: 
 * @Version: 1.0
 * @Autor: ricky shen
 * @Date: 2022-04-08 21:55:58
 * @LastEditors: ricky shen
 * @LastEditTime: 2022-04-09 10:32:35
 */
import { Badge } from "antd";
import React, { useState } from "react";
import './AvatarChart.less';
const Avatar = props => {
  const [hovered, setHovered] = useState(false)

  return (
    <div
      className='ChatMessage-chatroom-button'
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => props.onClick && props.onClick()}
      style={{
        ...{ border: `4px solid #019EAF`, backgroundColor: '#019EAF' }
      }}
    >
      <span className="remix-icon" style={{ fontSize: '20px' }}>
        <i className="ri-chat-smile-fill" />
      </span>
      {/* <div className='ChatMessage-chatroom-button' onClick={this.openMessage}>
        <img src={chatroom} alt="" />
      </div> */}
      {props.chatNotification && <div className="hp-position-absolute" style={{ right: 5, top: 0 }}>
        <Badge
          dot
          status="success"
          color="red"
        />
      </div>}
    </div>
  )
}

export default Avatar;