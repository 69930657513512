import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import ProductListScreen from './product/ProductListScreen';
import ProductDetailScreen from './product/ProductDetailScreen';
import ProductSearchScreen from './product/ProductSearchScreen';
import Landing from './Landing/Landing';
// import Landing from '../3d/Landing3d';
// import Login from './loginRegister/Login';
import Login from './loginRegister/login/index';
// import forgotPW from './loginRegister/forgotPW';
import forgotPW from './loginRegister/recover-password/index';
import ResetPassword from './loginRegister/reset-password/index';
import AuthGate from './auth/AuthGate';
// import Lobby from './Lobby/Lobby';
import Lobby from '../3d/Lobby3diframe';

import HelpDesk from './HelpDesk/HelpDesk';
import Agenda from './Agenda/Agenda';
import MySessions from './Agenda/MySessions';
// import ExpoHallScreen from './ExpoHall/ExpoHallScreen';
import ExpoHallScreen from '../3d/Hall3d';
import ExpoHallBoothScreen from './ExpoHall/ExpoHallBoothScreen';
import SeeAllRecommendationScreen from './ExpoHall/SeeAllRecommendationScreen';
import BusinessMatchingScreen from './BusinessMatching/BusinessMatchingScreen';
import ContactScreen from './Networking/ContactScreen';
import SeeAllRecommendationMatchingScreen from './Networking/SeeAllRecommendationMatchingScreen';
import DetailDemand from './Agenda/DetailDemand';
// import Profile from './Profile/Profile';
import InfoProfile from './Profile/index';
import SearchScreen from './Search/SearchScreen';
// import Briefcase from './Briefcase/Briefcase';
// import RegisterScreen from './loginRegister/RegisterScreen';
import RegisterScreen from './loginRegister/register/index';
import IframeView from './IframeView/IframeView';
import SocialLoungeScreen from './SocialLounge/SocialLoungeScreen';
import Speaker from './Speaker';
import SpeakerDetail from './Speaker/SpeakerDetail';
import JoinDetailDemand from './Agenda/JoinDetailDemand';
import CreateHuman from '../3d/CreateHuman';
import Landing3d from '../3d/Landing3d';
import Lobby3d from '../3d/Lobby3diframe';
import Hall3d from '../3d/Hall3d';
import SocialLounge3d from '../3d/SocialLounge3d';
import Zoom3 from '../components/Zoom3';

export class Router extends Component {
  componentWillMount() {
    // 将url上的token缓存起来用于请求接口
    const url = document.location.toString();
    const arrObj = url.split('?');
    if (arrObj.length > 1) {
      const arrPara = arrObj[1].split('&');
      let arr = [];
      let urlStr = '';
      for (let i = 0; i < arrPara.length; i++) {
        arr = arrPara[i].split('=');
        if (arr !== null && (arr[0] === 'token' || arr[0] === 'jwt')) {
          localStorage.setItem('token', arr[1]);
        } else {
          urlStr =
            urlStr !== ''
              ? urlStr + '&' + arr[0] + '=' + arr[1]
              : urlStr + arr[0] + '=' + arr[1];
        }
      }
      // 隐藏地址栏token信息
      if (urlStr && urlStr !== '') {
        window.location.href = arrObj[0] + '?' + urlStr;
      } else {
        window.location.href = arrObj[0];
      }
    }
  }
  render() {
    return (
      <HashRouter>
        <Switch>
          <AuthGate exact path="/Search" component={SearchScreen} />
          <Route exact path="/product-list" component={ProductListScreen} />
          <Route
            exact
            path="/product-detail/:id"
            component={ProductDetailScreen}
          />
          <Route exact path="/product-search" component={ProductSearchScreen} />
          <Route exact path="/csm/HelpDesk" component={HelpDesk} />
          <Route
            exact
            path="/csm/ExpoHall/Booth/:id"
            component={ExpoHallBoothScreen}
          />
          <AuthGate exact path="/Landing" component={Landing} />
          <AuthGate exact path="/Lobby" component={Lobby} />
          <AuthGate exact path="/HelpDesk" component={HelpDesk} />
          <AuthGate exact path="/Agenda" component={Agenda} />
          <AuthGate exact path="/Agenda/MySessions" component={MySessions} />
          <AuthGate exact key="ExpoHall" path="/ExpoHall" component={ExpoHallScreen} />
          <AuthGate
            exact
            path="/ExpoHall/Booth/:id"
            component={ExpoHallBoothScreen}
          />
          <AuthGate
            exact
            path="/ExpoHall/SeeAllRecommendation"
            component={SeeAllRecommendationScreen}
          />
          <AuthGate key="Gallery" exact path="/Gallery" component={ExpoHallScreen} />
          <AuthGate
            exact
            path="/Gallery/Booth/:id"
            component={ExpoHallBoothScreen}
          />
          <AuthGate
            exact
            path="/Gallery/SeeAllRecommendation"
            component={SeeAllRecommendationScreen}
          />
          <AuthGate
            exact
            path="/BusinessMatching"
            component={BusinessMatchingScreen}
          />
          <AuthGate exact path="/Contact" component={ContactScreen} />
          <AuthGate
            exact
            path="/Contact/SeeAllRecommendationMatching"
            component={SeeAllRecommendationMatchingScreen}
          />
          <AuthGate
            exact
            path="/embeddedPage"
            component={IframeView}
          />
          <AuthGate exact path="/Profile" component={InfoProfile} />
          <AuthGate exact path="/Avatar" component={InfoProfile} />
          <AuthGate exact path="/Briefcase" component={InfoProfile} />
          <AuthGate exact path="/Availability" component={InfoProfile} />
          <AuthGate exact path="/MeetingManagement" component={InfoProfile} />
          <AuthGate exact path="/ChangePassword" component={InfoProfile} />
          <AuthGate exact path="/InfoPage" component={HelpDesk} />
          <AuthGate exact path="/SocialLounge" component={SocialLoungeScreen} />
          <AuthGate exact path="/Speaker" component={Speaker} />
          <AuthGate exact path="/SpeakerDetail/:id" component={SpeakerDetail} />
          <AuthGate exact path="/JoinDetailDemand/:id?" component={JoinDetailDemand} />
          <AuthGate exact path="/DetailDemand/:id" component={DetailDemand} />
          <AuthGate exact path="/Landing3d" component={Landing} />
          <AuthGate exact path="/Lobby3d" component={Lobby3d} />
          <AuthGate exact path="/ExpoHall3d" component={Hall3d} />
          <AuthGate exact path="/Gallery3d" component={Hall3d} />
          <AuthGate exact path="/SocialLounge3d" component={SocialLounge3d} />
          <Route exact path="/Zoom/:id" component={Zoom3} />
          <Route exact path="/createHuman" component={CreateHuman} />
          <Route exact path="/forgotPW" component={forgotPW} />
          <Route exact path="/ResetPassword" component={ResetPassword} />
          <Route exact path="/register" component={RegisterScreen} />
          <Route exact path="/" component={Login} />
        </Switch>
      </HashRouter>
    );
  }
}

export default Router;
