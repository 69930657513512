import React, { useState } from 'react';
import get from 'lodash/get';
import i18next from 'i18next';
import './MeetOurTeam.less';
import { Row } from 'antd';
import ScheduleMeetingModal from '../routes/BusinessMatching/components/ScheduleMeetingModal';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { CHAT_SWITCH } from '../actions/LoginUserAction';
import apiAxios from '../network/axios/$axios';
import ChatMessage from './ChatMessage';

function MeetOurTeam(props) {
  const { data = [], primary, language } = props;
  const [visiable, setVisiable] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [channel_url, setChannelURL] = useState('');

  const handleCancel = () => {
    setVisiable(false)
  }



  // chat-use
  const getOrCreatePrivateChat = (person) => {
    chatSwitch(false)
    apiAxios('getOrCreatePrivateChat', {
      data: {
        target_user_id: person._id
      }
    })
      .then((response) => {
        if (response == "") {
          const timeout = setTimeout(() => {
            apiAxios('getOrCreatePrivateChat', {
              data: {
                target_user_id: person._id
              }
            }).then((response2) => {
              setChannelURL(response2.channel_url);
              props.dispatchChatSwitch(true);
            });
          }, 500)
        } else {
          setChannelURL(response.channel_url);
          props.dispatchChatSwitch(true);
        }
      });
  };



  const chatSwitch = () => {
    props.dispatchChatSwitch(false);
  };

  return (
    <div className='meet-our-team'>
      {data && data.map((item, index) => {
        const firstname = get(item, 'info.firstname', get(item, 'info.lastname', ''));
        let str = '';
        if (firstname) {
          str = firstname.substring(0, 1);
        }
        return (
          <div key={index} className='cell' style={{ backgroundColor: index % 2 === 1 ? '#F7FAFC' : '' }}>
            <div
              href={item.href}
              target={item.target}
              className='cell-left'
            >
              <div className="flex-row-center avater">
                <span>{str}</span>
              </div>
              <div>
                <div className='bold'>
                  <span>{get(item, 'info.firstname', '') + ' ' + get(item, 'info.lastname', '')}</span>
                </div>
                <div>
                  <span>{get(item, 'info.jobTitle', '')}</span>
                </div>
              </div>
            </div>
            <Row>
              <div className="hp-text-right flex-center pointer right-btn" onClick={() => {
                setVisiable(true);
                setSelectedItem(item);
              }}>
                <div className="yuan flex-center" style={{ borderColor: '#4f17a8' }}>
                  <span className="remix-icon f18">
                    <i className="ri-calendar-2-fill" style={{ color: '#4f17a8' }} />
                  </span>
                </div>
                <span>{i18next.t('translations.meet')}</span>
              </div>
              <div className="hp-text-right flex-center pointer" onClick={() => getOrCreatePrivateChat(item)}>
                <div className="yuan flex-center">
                  <span className="remix-icon f18">
                    <i className="ri-chat-smile-fill" style={{ color: '#B2BEC3' }} />
                  </span>
                </div>
                <span>{i18next.t('translations.chat')}</span>
              </div>
            </Row>
          </div>
        )
      })}
      {(chatSwitch) && <ChatMessage selected_channel_url={channel_url} close={() => chatSwitch(false)} />}

      <ScheduleMeetingModal isModalVisible={visiable} selectedItem={selectedItem} handleCancel={() => handleCancel()} primary={primary} {...props} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  chatSwitch: state.loginUserInfo.chatSwitch,
  primary: state.profile.primary
});
const mapDispatchToProps = (dispatch) => ({
  dispatchChatSwitch: (flag) => {
    dispatch(CHAT_SWITCH(flag));
  }
});
const enhance = compose(withTranslation('translations'));

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(enhance(MeetOurTeam));
