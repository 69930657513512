//product
export const FETCH_PRODUCT_LIST = 'FETCH_PRODUCT_LIST';
export const RECEIVE_PRODUCT_LIST = 'RECEIVE_PRODUCT_LIST';
export const FETCH_PRODUCT_DETAIL = 'FETCH_PRODUCT_DETAIL';
export const RECEIVE_PRODUCT_DETAIL = 'RECEIVE_PRODUCT_DETAIL';
export const FETCH_PRODUCT_CATEGORY = 'FETCH_PRODUCT_CATEGORY';
export const RECEIVE_PRODUCT_CATEGORY = 'RECEIVE_PRODUCT_CATEGORY';
export const FETCH_SEARCH_PRODUCT_LIST = 'FETCH_SEARCH_PRODUCT_LIST';
export const RECEIVE_SEARCH_PRODUCT_LIST = 'RECEIVE_SEARCH_PRODUCT_LIST';
// login
export const FETCH_LOGIN = 'FETCH_LOGIN';
export const EXIT_LOGIN = 'EXIT_LOGIN';
export const UPDATE_USERINFO = 'UPDATE_USERINFO';
// Agenda
export const FETCH_AGENDA_LIST = 'fetchAgendaList'; // 查Agenda列表
export const FETCH_MY_SESSION_LIST = 'fetchMySessionsList'; // 查我的议程列表
export const CHANGE_MOVIEINDEX = 'CHANGE_MOVIEINDEX'; // 影视下标JoinDetailDemand
export const CHANGE_AGENDA_CURRENTINDEX = 'CHANGE_AGENDA_CURRENTINDEX'; // 影视下标JoinDetailDemand
export const JOINDETAILDEMANDITEM = 'JOINDETAILDEMANDITEM'; // 当前

//BusinessMatching
export const SAVE_SELECTED_TAB_KEY = 'SAVE_SELECTED_TAB_KEY';
export const FETCH_MYPRIVATEMEETING_LIST = 'FETCH_MYPRIVATEMEETING_LIST';
export const RECEIVE_MYPRIVATEMEETING_LIST = 'RECEIVE_MYPRIVATEMEETING_LIST';
//briefcases
export const FETCH_BRIEFCASES_LIST = 'FETCH_BRIEFCASES_LIST';
export const RECEIVE_BRIEFCASES_LIST = 'RECEIVE_BRIEFCASES_LIST';
//profile
export const CHANGE_MAIN_COLOR = 'CHANGE_MAIN_COLOR';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const UPDATE_SELF_IFRAME_ITEM = 'UPDATE_SELF_IFRAME_ITEM';
export const BACK_IFRAME = 'BACK_IFRAME';
export const SELECTED_TYPE = 'SELECTED_TYPE';
export const LOG_CHART_USER = 'LOG_CHART_USER';
export const LOG_PUBLIC_CHART_USER = 'LOG_PUBLIC_CHART_USER';
export const SELECTED_2D_OR_3D_MODEL = 'SELECTED_2D_OR_3D_MODEL';
export const SAVE_MENU = 'SAVE_MENU';

export const SHOW_BACK = 'SHOW_BACK';
export const TIMEZONE = 'TIMEZONE';
export const CHAT_SWITCH = 'CHAT_SWITCH';
export const BOOTH_CAHT_LOG_PUBLIC_CHART_USER = 'BOOTH_CAHT_LOG_PUBLIC_CHART_USER';
export const SET_NOTIFICATION = "SET_NOTIFICATION"


