import React, { Component } from 'react';
import { Descriptions } from 'antd';
import './BasicInfo.less';

class BasicInfo extends Component {
  render() {
    const { data } = this.props;
    const keys = Object.keys(data);
    return (
      <Descriptions
        title={<div className="basic-info-title">Basic Information</div>}
        bordered
        column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
      >
        {keys.map((key, index) => {
          const title = key.replace('_', ' ');
          return (
            <Descriptions.Item
              key={index}
              labelStyle={{ fontSize: '2rem' }}
              contentStyle={{ fontSize: '2rem' }}
              label={title}
            >
              {data[key].en}
            </Descriptions.Item>
          );
        })}
      </Descriptions>
    );
  }
}

export default BasicInfo;
