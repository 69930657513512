import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Col, Table, Row } from 'antd';
import get from 'lodash/get';
import { connect } from 'react-redux';
import i18next from 'i18next';
import './MyMeetings.less';
import CancelModal from './CancelModal';
import { get30MinLaterTime } from '../../../Utils/Util';
import axios from '../../../network/axios/$axios';
import dayjs from 'dayjs';
import Room from '../../SocialLounge/components/Room';
import ScheduleMeetingModal from './ScheduleMeetingModal';
import MeetingDetailModal from './MeetingDetailModal';

const MyMeetings = (props) => {
  const { userInfo, selectedKey } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [meetings, setMeetings] = useState([]);
  const [data, setData] = useState([]);
  const [visiable, setVisiable] = useState(false);
  const [isVisiable, setIsVisiable] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [isDetailVisiable, setIsDetailVisiable] = useState(false);

  useEffect(() => {
    getMeetingListByFilter();
    fetchData();
  }, [selectedKey, userInfo]);
  // 获取联系人列表
  const fetchData = () => {
    axios('networkingPeople', {})
      .then((response) => {
        console.log('networkingPeople', response);
        setData(response.data);
      });
  };
  // 获取meeting列表
  const getMeetingListByFilter = () => {
    axios('getFilterMeeting', {
      path: `?filter={"ParticipantIDs":"${userInfo._id}"}`
    })
      .then((response) => {
        console.log('/myPrivateMeeting=', response);
        setMeetings(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //打开会议详情弹窗
  const goMeetingAction = (item) => {
    setIsDetailVisiable(true);
    setSelectedItem(item);
  };
  //关闭会议详情弹窗
  const closeMeetingDetail = () => {
    setIsDetailVisiable(false);
    getMeetingListByFilter();
  };
  //打开更新会议弹窗
  const goRescheduleMeeting = (item) => {
    setIsVisiable(true);
    setSelectedItem(item);
  };
  //关闭更新会议弹窗
  const closeModal = () => {
    setIsVisiable(false);
    getMeetingListByFilter();
  };
  //关闭取消弹窗
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  //提交取消原因
  const handleOk = (valueMessage) => {
    axios('editPrivateMeeting', {
      pathObj: { id: selectedId, status: 'CANCELLED' },
      data: {
        note: valueMessage
      }
    })
      .then((response) => {
        console.log('/editPrivateMeeting=', response);
        setIsModalVisible(false);
        // 为了解决取消后跳转metting页面空白问题
        setTimeout(() => {
          getMeetingListByFilter();
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // 审核会议请求
  const approvedMeeting = (id) => {
    axios('editPrivateMeeting', {
      pathObj: { id, status: 'APPROVED' },
      data: {}
    })
      .then((response) => {
        console.log('/editPrivateMeeting=', response);
        // 为了解决审核后跳转metting页面空白问题
        setTimeout(() => {
          getMeetingListByFilter();
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getRowClassName = (record, index) => {
    const str = (record.startTime).split('T');
    const date = str[0];
    const time = (str[1]).substring(0, 5);
    const end = get30MinLaterTime(time);
    let begin = false;
    const startUnix = dayjs(date + ' ' + time).unix();
    const endUnix = dayjs(date + ' ' + end).unix();
    const currrentUnix = dayjs().unix();
    if (currrentUnix >= endUnix) {
      begin = true;
    }
    let className = "";
    className = index % 2 === 0 ? "oddRow" : "evenRow";
    if ((get(record, 'status') === 'CANCELLED') || begin) {
      return className + ' opacity';
    }
    return className;
  }

  const columns = [
    {
      title: i18next.t('translations.date'),
      dataIndex: "date",
      render: (dataIndex, record) => {
        const str = (record.startTime).split('T');
        const date = str[0];
        const time = (str[1]).substring(0, 5);
        const end = get30MinLaterTime(time);
        return (
          <div className="flex flex_d_c flex_a_c flex_j_c">
            <div className="row-title">
              <span>{date}</span>
            </div>
            <div>
              <span>{time} - {end}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "avatar",
      render: (dataIndex, record) => {
        const man = get(record, 'man', {});
        let str = '';
        if (get(man, 'info.firstname') || get(man, 'info.lastname')) {
          str = (get(man, 'info.firstname', '') + get(man, 'info.lastname', '')).substring(0, 1);
        }
        return (
          <div className="flex-row-center">
            <div className="flex-row-center" style={{ minWidth: 50, width: 50, height: 50, borderRadius: 25, backgroundColor: '#FF8091' }}>
              <span className="f20" style={{ color: '#ffffff' }}>{str}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: i18next.t('translations.userlist-name'),
      dataIndex: "firstname",
      render: (dataIndex, record) => {
        const man = get(record, 'man', {});
        return (
          <div className="flex-column-center">
            <div className="row-title">
              <span>{get(man, 'info.firstname')} {get(man, 'info.lastname')}</span>
            </div>
            <div>
              <span>{get(man, 'info.jobTitle')}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: i18next.t('translations.company'),
      dataIndex: "CompanyName",
      render: (dataIndex, record) => {
        const man = get(record, 'man', {});
        return (
          <div className="flex-column-center">
            <span>{get(man, 'info.companyName')}</span>
          </div>
        );
      },
    },
    {
      title: i18next.t('translations.type'),
      dataIndex: "role",
      render: (dataIndex, record) => {
        const man = get(record, 'man', {});
        let role = '';
        let customCSS = '';
        if (get(man, 'role') === 'Visitor') {
          role = i18next.t('translations.visitor');
          customCSS = 'visitor';
        } else if (get(man, 'role') === 'Organizer') {
          role = i18next.t('translations.organizer');
          customCSS = 'organizer';
        } else {
          role = i18next.t('translations.exhibitor');
          customCSS = 'exhibitor';
        }
        return (
          <Row justify="center">
            <div className={'flex-row-center ' + customCSS}>
              <span>
                {role}
              </span>
            </div>
          </Row>
        );
      },
    },
    {
      title: i18next.t('translations.action'),
      dataIndex: "action",
      render: (dataIndex, record) => {
        const waitingApproved = record.ParticipantIDs.indexOf(userInfo._id) > -1 && record.ApprovedParticipantIDs.indexOf(userInfo._id) === -1;
        const str = (record.startTime).split('T');
        const date = str[0];
        const time = (str[1]).substring(0, 5);
        const end = get30MinLaterTime(time);
        let begin = false;
        const startUnix = dayjs(date + ' ' + time).unix();
        const endUnix = dayjs(date + ' ' + end).unix();
        const currrentUnix = dayjs().unix();
        if (currrentUnix >= startUnix && currrentUnix < endUnix) {
          begin = true;
        }
        return (
          <Row justify="space-between">
            {/* <div
              onClick={() => goMeetingAction(record)}
              className="pointer flex flex_d_c"
            >
              <EyeFilled />
              <span className="f12">
                {i18next.t('translations.business-matching-view-detail')}
              </span>
            </div> */}
            {(get(record, 'status') == 'RESCHEDULE' || get(record, 'status') === 'PENDING') && waitingApproved && currrentUnix < startUnix && (
              <div
                onClick={(event) => {
                  event.stopPropagation();
                  approvedMeeting(record._id);
                }}
                className="pointer flex flex_d_c"
              >
                <span className="remix-icon">
                  <i className="ri-check-fill" style={{ fontSize: 24 }} />
                </span>
                <span className="f12">{i18next.t('translations.accept')}</span>
              </div>
            )}
            {get(record, 'status') === 'APPROVED' && begin && (
              <div
                onClick={(event) => {
                  window.open(record.url);
                  event.stopPropagation();
                }}
                className="pointer flex flex_d_c"
              >
                <span className="remix-icon">
                  <i className="ri-video-chat-fill" style={{ fontSize: 24, color: '#005a29' }} />
                </span>
                <span className="f12">{i18next.t('translations.video-call')}</span>
              </div>
            )}
            {(get(record, 'status') !== 'CANCELLED') && currrentUnix < startUnix && (
              <div
                onClick={(event) => {
                  setSelectedId(record._id);
                  setIsModalVisible(true);
                  event.stopPropagation();
                }}
                className="pointer flex flex_d_c"
              >
                <span className="remix-icon">
                  <i className="ri-close-fill" style={{ fontSize: 24 }} />
                </span>
                <span className="f12">{i18next.t('translations.cancel')}</span>
              </div>
            )}
            {(get(record, 'status') !== 'CANCELLED') && currrentUnix < startUnix && (
              <div
                onClick={(event) => {
                  goRescheduleMeeting(record);
                  event.stopPropagation();
                }}
                className="pointer flex flex_d_c"
              >
                <span className="remix-icon">
                  <i className="ri-calendar-todo-fill" style={{ fontSize: 24, color: '#005a29'}} />
                </span>
                <span className="f12">{i18next.t('translations.reschedule')}</span>
              </div>
            )}
          </Row>
        )
      },
    },
  ];

  const username = userInfo.firstname + ' ' + userInfo.lastname;
  let datas = [];
  meetings.map(item => {
    const person1 = data.filter(v => v._id === item.ParticipantIDs[0]);
    const person2 = data.filter(v => v._id === item.ParticipantIDs[1]);
    const createMan = person1.length ? person1[0] : {};
    const toMan = person2.length ? person2[0] : {};
    const man = userInfo._id === createMan._id ? toMan : createMan;
    datas.push({
      ...item,
      toMan,
      man
    })
  })
  // 根据会议startTime开始时间排序
  datas = datas.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));

  return (
    <div className="my-meetings-page">
      <h2>{i18next.t('translations.my-meetings')}</h2>
      <Table
        pagination={false}
        columns={columns}
        dataSource={datas}
        scroll={{ x: 'calc(500px + 50%)' }}
        rowClassName={getRowClassName}
        onRow={(record) => ({
          onClick: () => goMeetingAction(record),
        })}
      />
      <CancelModal
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
        handleOk={handleOk}
        primary={props.primary}
      />
      {visiable && <Room link='https://virtualplatform.daily.co/PM5LzjiSi6gmhFigmG8L' username={username} onClosed={() => setVisiable(false)} onJoined={() => { }} />}
      <ScheduleMeetingModal isModalVisible={isVisiable} selectedItem={selectedItem} handleCancel={() => closeModal()} action={"edit"} {...props} />
      <MeetingDetailModal isModalVisible={isDetailVisiable} selectedItem={selectedItem} handleCancel={() => closeMeetingDetail()} {...props} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  primary: state.profile.primary,
  userInfo: state.loginUserInfo.userInfo,
  selectedKey: state.businessMatching.selectedKey
});

const enhance = compose(withTranslation('translations'), connect(mapStateToProps, null));

export default enhance(MyMeetings);
