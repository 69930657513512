const IP = 'https://d2lll93x59366g.cloudfront.net';
export const BASE_URL = 'https://watsons-demo.chefdigital.io';
export const DOMAIN = 'watsons-demo-api.chefdigital.io';
// export const DOMAIN = 'watsons2022exhibitorcms.cyberport.hk'; //生产域名

export function requestAPI(params) {
  let headers = {
    Accept: 'application/json'
  };
  const method = params.method || 'GET';
  let isOk;
  let total;
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(
      () => reject(new Error('request timeout')),
      10000
    );
    const requestParams = {
      method,
      headers: new Headers(headers)
    };

    if (method === 'POST' || method === 'PUT' || method === 'PATCH') {
      requestParams.body = params.params || {};
    }
    fetch(IP + params.path || '', requestParams)
      .then((response) => {
        isOk = response.ok;
        const rangeStr = response.headers.get('Content-Range');
        if (rangeStr) {
          const list = rangeStr.split('/');
          total = list[1];
        }
        return response.json();
      })
      .then((responseData) => {
        clearTimeout(timeoutId);
        if (isOk) {
          if (total) {
            resolve({ data: responseData, total });
          } else {
            resolve({ data: responseData });
          }
        } else {
          reject(responseData);
        }
      })
      .catch((error) => {
        clearTimeout(timeoutId);
        reject(error);
      });
  });
}
