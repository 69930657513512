import React, { PureComponent } from 'react';
import FooterView from '../../components/FooterView';
import axios from '../../network/axios/$axios';
import { Route } from 'react-router-dom';
import { Layout, message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import './custom.less'
import Zoom from '../../components/Zoom';
import ChatMessage from '../../components/ChatMessage';
import AvatarChart from '../../components/AvatarChart';
import AnnouncementModal from '../../components/AnnouncementModal';
import Websocket from 'react-websocket';
import get from 'lodash/get';
import { exitLoginStatus, getUserDetail } from '../../actions/LoginUserAction';
import HeaderHorizontal from '../../layout/components/header/HeaderHorizontal';
import { saveSelectedTabKey } from '../../actions/BusinessMatchingAction';
import { saveMenu, changeLanguage } from '../../actions/ProfileAction';
import { DOMAIN } from '../../network/RequestUtils';
import i18next from 'i18next';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
const JWT = require('jsonwebtoken');

const { Content } = Layout;

class AuthGate extends PureComponent {
  constructor(props) {
    super();
    this.state = {
      data: {},
      languageData: null,
      footerData: {},
      designData: {},
      menuItems: [],
      eventAdditionalInfo: null,
      chatSwitch: false,
      webscoketType: '',
      webscoketData: {},
      isOpenPrivate: false,
      chatNotification: false, //接收到chat聊天信息
    };
  }

  componentDidMount() {
    const pathname = this.props.location.pathname;
    // 1.从缓存获取token
    const token = localStorage.getItem('token');
    if (token) {
      this.getUserId(token);
    }
    if (!token) {
      if (process.env.NODE_ENV === 'development') {
        window.location.href = '/';
      } else {
        window.location.href = process.env.REACT_APP_HOME_PAGE;
      }
    }
    // 头部！下拉数据
    axios('eventInfo')
      .then((response) => {
        console.log('/eventInfo', response);
        this.setState({ data: response });
      })
      .catch((error) => {
        console.log(error);
      });
    // 头部！下拉数据
    axios('EventAdditionalInfo')
      .then((response) => {
        console.log('/EventAdditionalInfo', response);
        this.setState({ eventAdditionalInfo: response.data[0].data });
      })
      .catch((error) => {
        console.log(error);
      });

    axios('Language')
      .then((response) => {
        console.log('/Language', response);
        this.setState({ languageData: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
    axios('footer')
      .then((response) => {
        console.log('/footer', response);
        this.setState({ footerData: response.count > 0 ? response.data[0] : {} });
        const count = response.data[0].design;
        axios('footerDesign')
          .then((res) => {
            console.log('/footerDesign', res);
            this.setState({ designData: res.data[0].design[count] })
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
    // 菜单
    axios('Menu')
      .then((response) => {
        console.log('/Menu', response);
        this.setState({ menuItems: response.data[0].menuItems });
        this.props.saveMenu(response.data[0].menuItems);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  //获取用户id,根据用户id获取用户信息
  getUserId = (token) => {
    const { getUserDetail } = this.props;
    const decoded = JWT.decode(token);
    getUserDetail({ _id: decoded._id });
  };
  // 打开或者关闭chat
  chatSwitch = (swith, value) => {
    this.setState({
      chatSwitch: swith, isOpenPrivate: value, chatNotification: false
    })
  };
  // 打开Websocket链接
  handleOpen = () => {
    console.log('打开了Websocket');
  };
  // 关闭Websocket链接
  handleClose = () => {
    console.log('关闭了Websocket');
  };
  // 接收Websocket数据
  handleData = (data) => {
    const { language } = this.props;
    const result = JSON.parse(data);
    console.log('Websocket send message:', result);
    if (result.type === 'Logout') {
      message.warning(get(result.data.display_message, language));
      // this.loginOut();
    } else
      if (result.type === 'Notification' && result.data.subtype === 'Chat Message(s)') {
        this.setState({ webscoketType: '', webscoketData: {} });
        this.setState({ webscoketType: result.type, webscoketData: result.data, chatNotification: true });
        this.OpenNotification();
      } else {
        // this.setState({ webscoketType: result.type, webscoketData: result.data })
      }
  };
  // 关闭公告弹窗
  closeModal = () => {
    this.setState({ webscoketType: '', webscoketData: {} })
  };
  //登出
  loginOut = () => {
    // return; //temp remove log out
    const { exitLogin, changeLanguage } = this.props;
    sessionStorage.removeItem('token');
    sessionStorage.clear();
    localStorage.clear();
    setTimeout(() => {
      exitLogin();
    }, 500);
    // 设置默认英文
    changeLanguage();
    if (process.env.NODE_ENV === 'development') {
      window.location.href = '/';
    } else {
      window.location.href = process.env.REACT_APP_HOME_PAGE;
    }
  };

  setVisibleAction = (flag) => {
    this.setState({ visible: flag })
  }
  //打开浏览器系统通知
  OpenNotification = () => {
    const _self = this;
    const { sendNotification } = this.props;
    console.log(sendNotification, "sendNotification")
    if (!sendNotification) return console.log("not permitted")
    // Notification.requestPermission();// 获取通知权限
    if (Notification.permission === "granted") {// denied (用户拒绝了通知的显示), granted (用户允许了通知的显示), 
      let notice = new Notification(i18next.t('translations.watsons'), {
        body: i18next.t('translations.notification'),
      });
      // notice.onshow = function () {
      //   // do something
      //   console.log('onshow=');
      // }
      // notice.onclick = function () {
      //   console.log('onclick=');
      //   notice.close();
      //   _self.chatSwitch(true, false)
      // }
      // notice.onerror = function (err) {
      //   // do something
      //   console.log('onerror=', err);
      // }
      // notice.onclose = function () {
      //   // do something
      //   console.log('onclose=');
      // }
    } else if (Notification.permission === "denied") {
      console.log('没有权限');
    }
  }

  render() {
    const { selfIframeItem, language, primary, userInfo, saveSelectedTabKey, C, cProps, ...rest } = this.props;
    const { data, languageData, footerData, designData, menuItems, eventAdditionalInfo, chatSwitch, webscoketType, webscoketData, isOpenPrivate, visible, chatNotification } = this.state;
    const token = localStorage.getItem('token');
    const pathname = this.props.location.pathname;
    let isHiddle = false;
    let popContainer
    if (!token && pathname === '/Landing') {
      isHiddle = true;
    }

    return (
      <Layout>
        <HeaderHorizontal
          menuItems={menuItems}
          languageData={languageData}
          loginOut={this.loginOut.bind(this)}
          receivedNotification={webscoketType === 'Notification'}
          notification={webscoketData}
          openChatView={this.chatSwitch.bind(this)}
          saveSelectedTabKey={saveSelectedTabKey}
          visible={visible}
          setVisible={this.setVisibleAction.bind(this)}
          isHiddle={isHiddle}
          {...this.props}
        />
        {/* 是否展示iframe */}
        {/* {selfIframeItem ? <Zoom link={selfIframeItem.href} /> : */}
        <Content style={{ backgroundColor: '#EEF1F3', minHeight: window.innerHeight - 134 }}>
          <Route {...rest} render={(props) => <C {...props} {...cProps} />} />
        </Content>
        <FooterView data={footerData} designData={designData} />
        {/* {chatSwitch && <ChatMessage private_active_id={isOpenPrivate ? 'list' : ''} close={() => this.chatSwitch(false, false)} />} */}

        {chatSwitch && (
          <div className='watsongpt-contenter' >
            <iframe src={`https://watsons.shopsoln.com${language == "en-us" ? "?lang=en" : ""}`} style={{ width: '100%', height: '100%' }}></iframe>
            <CloseOutlined className='closeOutlined' onClick={() => this.chatSwitch(false, false)} />

          </div>)}
        {!isHiddle && <AvatarChart primary={primary} onClick={() => this.chatSwitch(true, false)} chatNotification={chatNotification} />}
        <AnnouncementModal
          visible={webscoketType === 'Announcement'}
          data={webscoketData}
          language={language}
          handleCancel={this.closeModal.bind(this)} />
        <Websocket
          debug={true}
          url={`wss://${DOMAIN}/WebSocket/${token}`}
          onMessage={this.handleData.bind(this)}
          onOpen={this.handleOpen.bind(this)}
          onClose={this.handleClose.bind(this)}
        />
      </Layout>
    );
  }
}
// component
const mapStateToProps = (state) => ({
  selfIframeItem: state.loginUserInfo.selfIframeItem,
  language: state.profile.language,
  primary: state.profile.primary,
  userInfo: state.loginUserInfo.userInfo,
  sendNotification: state.loginUserInfo.sendNotification,
  selectedModel: state.profile.selectedModel,
});

const mapDispatchToProps = (dispatch) => ({
  exitLogin: () => {
    dispatch(exitLoginStatus());
  },
  saveSelectedTabKey: (key) => {
    dispatch(saveSelectedTabKey(key));
  },
  saveMenu: (menu) => {
    dispatch(saveMenu(menu))
  },
  changeLanguage: () => {
    dispatch(changeLanguage('en-us'));
  },
  getUserDetail: (data) => {
    dispatch(getUserDetail(data));
  }
});

const enhance = compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps)
);
export default enhance(AuthGate);

// export default AuthGate;
