import HelpDeskCSS from './AboutUs.module.css';
import { Row, Col } from 'antd';
import React from 'react';
import i18next from 'i18next';
import get from 'lodash/get';

export default function AboutUs(props) {
  const { isModalVisible, data = [], primary, language, logoUrl, companyName } = props;
  return (
    <div className={HelpDeskCSS.AboutUs}>
      <div>
        <span></span>
      </div>
      <Row>
        <Col span={24} md={5}>
          <img
            className={HelpDeskCSS.logo}
            src={logoUrl}
          />
        </Col>
        <Col span={24} md={19}>
          <div className="f25 fw700">{companyName}</div>

          <div className={HelpDeskCSS.companyProfileSection}>
            <div>
              <h5 className={HelpDeskCSS.modalSectionHeader + ' f25'}>
                {i18next.t('translations.profile')}
              </h5>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: get(get(data, '0.display_description'), [language])
              }}
            />
          </div>

          {
            get(get(data, '0.display_address'), [language]) && <div>
              <h5 className={HelpDeskCSS.modalSectionHeader + ' f25'}>
                {i18next.t('translations.location')}
              </h5>
              <div>{get(get(data, '0.display_address'), [language])}</div>
            </div>
          }
          
          {
            get(get(data, '0.display_contactPerson'), [language]) && <div className={HelpDeskCSS.companyProfileSection}>
              <h5 className={HelpDeskCSS.modalSectionHeader + ' f25'}>
                {i18next.t('translations.contact')}
              </h5>
              <div>
                <div>{get(get(data, '0.display_contactPerson'), [language])}</div>
                <a href={'mailto:' + get(get(data, '0.display_contactEmail'), [language])}>
                  {get(get(data, '0.display_contactEmail'), [language])}
                </a>
              </div>
            </div>    
          }      
          
        </Col>
      </Row>
    </div>
  );
}
