import React, { Component } from 'react';
import { Row, Col, Divider } from 'antd';
import './SpeakerDetail.less';
import { connect } from 'react-redux';
import get from 'lodash/get';
import axios from '../../network/axios/$axios';
import { enterCurrentPage } from '../../Utils/Util';
import BreadCrumbs from '../../components/BreadCrumbs';
import Agenda from '../Agenda/Agenda';
class DetailDemand extends Component {
  constructor(props) {
    super(props);
    // 解决跳转页面页面滚动位置错误问题
    window.scrollTo(0, 0);
    this.state = {
      detail: {},
    };
  }
  componentWillUnmount() {
    this.props.SHOW_BACK(false);
  }

  componentDidMount() {
    this.props.SHOW_BACK(true);
    // console.log(this.props);
    const { id } = this.props.match.params;
    axios('SpeakerByIds', { path: id })
      .then((response) => {
        // console.log('/SpeakerByIds', response);
        this.setState({
          detail: response
        });
      })
    enterCurrentPage();
  }
  goBack = () => {
    this.props.history.go(-1);
  };
  render() {
    const { detail } = this.state;
    const { id } = this.props.match.params;
    const { language, primary, menu } = this.props;
    const arr = menu.filter(obj => obj.href === '/Speaker');

    return (
      <div className="speaker-detail page-container center">
        <Row gutter={[32, 32]}>
          <BreadCrumbs
            breadCrumbParent={arr.length && (arr[0].display_title)[language]}
            breadCrumbActive={get(get(detail, 'display_name'), language)}
          />
        </Row>
        <div className='content'>
          <Row align="middle">
            <Col style={{ textAlign: 'center' }} md={8} xs={24}>
              <img className='profilePic' src={get(detail, 'profilePic.src')} alt={get(detail, 'profilePic.alt')} title={get(detail, 'profilePic.title')} />
            </Col>
            <Col md={16} xs={24}>
              <div className="speakerName">
                <span>{get(get(detail, 'display_name'), language)}</span>
              </div>
              <div className="speakerJobTitle f25">
                <div>{get(get(detail, 'display_jobTitle'), language)}</div>
                <div>{get(get(detail, 'display_company'), language)}</div>
              </div>
            </Col>
          </Row>
          <Divider />
          <p className='speaker_description' dangerouslySetInnerHTML={{
            __html: get(get(detail, 'display_description'), language)
          }}></p>
        </div>
        <div>
          <Agenda speakerId={id} links={'#/SpeakerDetail/' + this.props.match.params.id} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.loginUserInfo.userInfo,
  primary: state.profile.primary,
  language: state.profile.language,
  menu: state.profile.menu
});

const mapDispatchToProps = (dispatch) => ({
  SHOW_BACK: (data) => {
    dispatch({
      type: 'SHOW_BACK',
      data: data
    });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailDemand);
