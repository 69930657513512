import React, { Component } from 'react';
import { Row, Col } from 'antd';
import './MyAvailability.less';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import dayjs from 'dayjs';
import axios from '../../../network/axios/$axios';
import { getDaysBetween, getTwoDateRangeDate } from '../../../Utils/Util';
import SingleTimeList from './SingleTimeList';

class MyAvailabilityNew extends Component {
  constructor(props) {
    super();

    this.state = {
      selectIndex: 0,
      selectDate: [],
      refresh: false,
      initUnavailable: [],
      selectHeadDate: dayjs('2022-10-21').format('YYYY-MM-DD'),
      dateList: []
    };
  }

  componentDidMount() {
    setTimeout(() => {
      const today = dayjs('2022-10-21').format('YYYY-MM-DD');
      const days = getDaysBetween(today, '2022-11-16');
      const dateList = getTwoDateRangeDate(today, days);
      this.setState({ dateList });
    }, 500)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.data !== nextProps.data) {
      this.setState({ initUnavailable: nextProps.data })
    }
  }

  //选择tab
  selectTab = (index) => {
    this.setState({ selectIndex: index });
  };
  //选择时间段
  clickItem = (date, time) => {
    const { selectDate, refresh, initUnavailable } = this.state;
    let datas = [];
    const infos = selectDate.filter((v) => v.date === date);
    if (infos.length) {
      selectDate.map((item) => {
        if (item.date !== date) {
          datas.push(item);
        } else {
          let arr = item.times;
          let list = [];
          if (arr.indexOf(time) > -1) {
            list = arr.filter((v) => v !== time);
          } else {
            list = [...arr, ...[time]];
          }
          datas.push({
            date,
            times: list
          });
        }
      });
    } else {
      datas = selectDate;
      datas.push({
        date,
        times: [time]
      });
    }
    let unavailable = [];
    const dateTime = date + ' ' + time;
    const dateUnix = dayjs(dateTime).unix();
    if (initUnavailable.indexOf(dateUnix) > -1) {
      unavailable = initUnavailable.filter(v => v !== dateUnix);
    } else {
      unavailable = [...initUnavailable, ...[dateUnix]];
    }
    this.addToUnavailable(unavailable);
    this.setState({ selectDate: datas, refresh: !refresh });
  };
  //跳转会议详情
  goMeetingDetail = (date, time) => { };
  //选择所以item
  selectAllItem = (date, times, allDisabled) => {
    const { selectDate, refresh, initUnavailable } = this.state;
    let datas = [];
    const infos = selectDate.filter((v) => v.date === date);
    if (infos.length) {
      selectDate.map((item) => {
        if (item.date !== date) {
          datas.push(item);
        } else {
          datas.push({
            date,
            times
          });
        }
      });
    } else {
      datas = selectDate;
      datas.push({
        date,
        times
      });
    }
    let unavailable = [];
    times.map(v => {
      const dateTime = date + ' ' + v;
      const dateUnix = dayjs(dateTime).unix();
      unavailable.push(dateUnix);
    });
    const result = initUnavailable.filter(v => unavailable.indexOf(v) < 0);
    if (allDisabled) {
      this.addToUnavailable(result);
    } else {
      this.addToUnavailable([...unavailable, ...result]);
    }
    this.setState({ selectDate: datas, refresh: !refresh });
  };
  // 添加到unavailable
  addToUnavailable = (unavailable) => {
    const { userInfo, getData } = this.props;
    axios('addToUnavailable', {
      path: userInfo._id,
      data: {
        unavailable
      }
    })
      .then((response) => {
        console.log('/addBriefcases=', response);
        getData(); //更新成功后重新获取列表数据
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // 选择头部日期
  selectedHeadDate = (date) => {
    this.setState({ selectHeadDate: date })
  };

  render() {
    const Tabs = [
      i18next.t('translations.all'),
      i18next.t('translations.available'),
      i18next.t('translations.meeting-confirmed')
    ];
    const { selectIndex, selectDate, refresh, selectHeadDate, dateList } = this.state;
    const { primary, data, meetings } = this.props;
    return (
      <div className="my-availability-page">
        <h2>{i18next.t('translations.my-availability')}</h2>
        <Col span={24}>
          <Row className="hp-bg-color-black-0 hp-bg-color-dark-100 hp-pr-sm-16">
            {/* <Col flex="240px" className="hp-profile-menu">
              <div className="hp-w-100">
                <span className='f22'>Availablility</span>
                <div className="hp-mt-16 flex flex_a_c">
                  <div className='dian hp-mr-8' />
                  <span>{i18next.t('translations.all')}</span>
                </div>
                <div className="hp-mt-16 flex flex_a_c">
                  <div className='dian bg-2 hp-mr-8' />
                  <span>{i18next.t('translations.available')}</span>
                </div>
                <div className="hp-mt-16 flex flex_a_c">
                  <div className='dian bg-3 hp-mr-8' />
                  <span>{i18next.t('translations.meeting-confirmed')}</span>
                </div> 
              </div> 
            </Col> */}

            <Col
              flex="1 1"
              className="hp-pl-sm-16 hp-py-sm-24 hp-pb-24 hp-overflow-hidden"
            >
              <SingleTimeList
                date={selectHeadDate}
                start_time="00:00"
                end_time="23:59"
                selectedDate={selectDate}
                onSelect={this.clickItem}
                clickExist={this.goMeetingDetail}
                selectAllItem={this.selectAllItem}
                refresh={refresh}
                primary={primary}
                unavailableList={data}
                meetings={meetings}
                selectTab={selectIndex}
                dateList={dateList}
              />
            </Col>
          </Row>
        </Col>
      </div>
    );
  }
}

const enhance = compose(withTranslation('translations'));

export default enhance(MyAvailabilityNew);
