import React, { PureComponent } from 'react';
import { Rate, Button, Modal, Row, Col, message } from 'antd';
import { connect } from 'react-redux';
import './TabPaneItem.less';
import { YoutubeOutlined, PlusOutlined, MinusOutlined, VideoCameraFilled, CloseOutlined, DownloadOutlined, CalendarFilled, ClockCircleFilled } from '@ant-design/icons';
import axios from '../../../network/axios/$axios';
import { compareDate } from '../../../Utils/Util';
import Zoom from '../../../components/Zoom';
import get from 'lodash/get';
import i18next from 'i18next';
import { dateToStringTitle, getTime, formatTimezone } from '../../../Utils/Util';
import { withRouter } from 'react-router-dom';
import { CHANGE_AGENDA_CURRENTINDEX, JOINDETAILDEMANDITEM } from '../../../constants/ActionTypes';

class TabPaneItem extends PureComponent {
  constructor(props) {
    super();
    this.state = {
      currentData: {},
      agendaList: [],
      refersh: false,
      fetching: true,
      visiable: false,
      linkType: '',
      linkHref: '',
      modalItem: {},
    };
  }
  componentDidMount() { }
  openModal = (item, data) => {
    if (item.target === '_self') {
      // 当前页面iframe打开，保留上下头部信息
      // this.props.isSelfCallback(item);
      // this.props.UPDATE_SELF_IFRAME_ITEM(item)
      // this.props.history.push(`/embeddedPage`);
      data.currentMovItem = item;
      this.clickJoin(data);
      return;
    }
    if (item.target !== '_popup' && item.target !== 'zoom') {
      return;
    }
    // 保存内容类型：linkType供popup使用, 暫時有iframe, zoom, video, image, 如果沒有設置默認為用iframe直接打開
    this.setState({
      visiable: true,
      linkType: get(item, 'linkType'),
      linkHref: get(item, 'href'),
      modalItem: item,
    });
  };

  handleCancel = () => {
    this.setState({
      visiable: false,
    });
  };
  clickItem(item) {
    this.props.history.push({
      pathname: '/DetailDemand/' + item._id,
      state: { params: item }
    });
    this.clickUpload('detail ' + item.name);
  }

  onChange = (data, arr, value) => {
    const { isGuestLogin, fetchBriefcase } = this.props;
    if (value === 0) {
      // 删除Briefcase
      axios('deleteBriefcases', { path: arr[0]._id })
        .then((response) => {
          console.log('/deleteBriefcases=', response);
          message.success(i18next.t('translations.bookmark-deleted-success'));
          fetchBriefcase();
        })
        .catch((error) => {
          console.log(error);
        });
      this.clickUpload('delete Briefcases ' + arr[0].detail.name)
    } else {
      if (isGuestLogin) {
        this.goRegisterPage();
      } else {
        // 新增Briefcase
        axios('addBriefcases', {
          data: {
            "model": "Agenda/Agenda",
            "refId": data._id
          }
        })
          .then((response) => {
            console.log('/addBriefcases=', response);
            message.success(i18next.t('translations.bookmark-added-success'));
            fetchBriefcase();
          })
          .catch((error) => {
            console.log(error);
          });
      }
      this.clickUpload('add Briefcases ' + data.name);
    }
  };
  //统计点击事件
  clickUpload = (fieldname) => {
    axios('activity', {
      data: {
        event: '[Click] Agenda ' + fieldname
      }
    })
      .then(response => {
        console.log('/activity', response);
      })
  };
  //跳转注册页面
  goRegisterPage = () => {
    this.props.history.push('/register');
  };
  clickJoin(item) {
    const { currentData } = this.props;
    // this.props.history.push({
    //   pathname: '/JoinDetailDemand/' + item._id,
    //   state: { params: item }
    // });
    this.props.history.push({
      pathname: '/JoinDetailDemand',
    });
    item.timetitle = currentData.title;
    // sessionStorage.setItem('JoinDetailDemandItem', JSON.stringify(item));
    // 当前agenda
    this.props.CHANGE_AGENDA_CURRENTINDEX(item.index);
    // // 当前下标
    this.props.JOINDETAILDEMANDITEM(item);
    // 当前天的列表
    sessionStorage.setItem(
      'currentDataAgendaList',
      JSON.stringify(currentData)
    );
  }
  // 匹配speakers
  filterSpeakers = (allSpeakers, speakerIds) => {
    const speakerArr = [];
    speakerIds.forEach(id => {
      speakerArr.push(allSpeakers.find(speaker => speaker._id === id));
    });
    return speakerArr;
  };

  renderButton = (data) => {
    const { language } = this.props;
    /*canJoinAfter > now = coming soon
      canJoinAfter < now < endDate = Live
      endDate < now = if don't have video >> session ended, if have video >> playbac*/
    const timeA = data.mustJoinBefore || data.startDate;
    const timeB = data.canJoinAfter || data.endDate;
    const cominGsoonFlag = compareDate(timeA, new Date());
    const liveFlag = compareDate(timeB, new Date());
    // const cominGsoonFlag = compareDate(data.canJoinAfter, new Date());
    // const liveFlag = compareDate(data.endDate, new Date());
    if (cominGsoonFlag) {
      return <Button className={'agendaBtn' + ' play ' + 'antBtnRound mR20'} size="large">{i18next.t('translations.coming-soon')}</Button>;
    } else if (liveFlag && data.type === 'Live') {
      // 正在播放
      if (data.liveLink && data.liveLink.length) {
        return data.liveLink.map((video, index) => (
          <a href={video.target === '_popup' || video.target === 'zoom' || video.target === '_self' ? null : video.href} onClick={() => this.openModal(video, data)} target='_blank' rel="noreferrer" key={video.title} className={'mR20'}>
            <Button className={'agendaBtn live antBtnRound'} icon={<YoutubeOutlined />} size="large">{video.display_title[language]}</Button>
          </a>
        ))
      }
    } else {
      // session ended:但是有videos，可以回放
      if (data.type === 'Recordings' && data.videos && data.videos.length) {
        const videosArr = data.videos.map((video, index) => (
          <a href={video.target === '_popup' || video.target === 'zoom' || video.target === '_self' ? null : video.href} onClick={() => this.openModal(video, data)} target='_blank' rel="noreferrer" key={video.title} className={'mR20'}>
            <Button className={'agendaBtn' + ' play ' + 'antBtnRound'} icon={<YoutubeOutlined />} size="large">{get(video.display_title, language)}</Button>
          </a>
        ))
        return videosArr;
      } else {
        return <Button className={'agendaBtn' + ' ended ' + 'antBtnRound mR20'} size="large">{i18next.t('translations.session-ended')}</Button>
      }
    }
  };
  renderDownloadButton(data) {
    const { language } = this.props;
    return data.downloads && data.downloads.map((download) => (
      <a href={download.target === '_popup' || download.target === 'zoom' || download.target === '_self' ? null : download.href} download={download.title} target={download.target} rel="noreferrer" key={download.title} className="mR20" onClick={() => this.openModal(download)}>
        <Button className={'mR20' + 'antBtnRound'} icon={<DownloadOutlined />} size="large">{download.display_title[language]}</Button>
      </a>
    ))
  };
  renderLeft(data, arr, isSelected) {
    const { addMysession, remove, primary, language, selectIndex, speakers } = this.props;
    // const { visiable, linkType, linkHref } = this.state;
    data.event_speakers = this.filterSpeakers(speakers || [], data.speakers);

    return (
      <div>
        <div className="flex flex_j_l flex_a_c">
          <VideoCameraFilled style={{ color: data.type === 'Recordings' ? '#28a745' : '#17a2b8', marginRight: 10 }} />
          {/* <span>Pre-recorded Webina</span> */}
          <span>{data.type}</span>
        </div>
        <div className="f18 bold pB10">{get(get(data, 'display_name'), language)}</div>
        <div className="fw700 pB20">
          <div
            dangerouslySetInnerHTML={{
              __html: get(get(data, 'display_description'), language)
            }}
          />
        </div>
        <div>

          {this.renderButton(data)}
          {this.renderDownloadButton(data)}
          {selectIndex === 1 || data.mySessionId ? <Button className={'removeBtn' + ' mR20 ' + 'antBtnRound'} icon={<MinusOutlined />} size="large" onClick={remove && remove.bind(null, data)}>{i18next.t('translations.remove')}</Button> : <Button className={'agendaBtn' + ' mR20 ' + 'antBtnRound'} icon={<PlusOutlined />} size="large" onClick={addMysession && addMysession.bind(null, data)} style={{ backgroundColor: primary, borderColor: primary }}>{i18next.t('translations.add-to-my-session')}</Button>}
          <Button className={'antBtnRound' + ' mR20 mR20 details'} size="large" onClick={() => this.clickItem(data)}>{i18next.t('translations.details')}</Button>
          <Rate onChange={this.onChange.bind(this, data, arr)} className="mR20" count="1" value={isSelected ? 1 : 0} />
        </div>
      </div>
    );

  };
  render() {
    const { currentData, eventAgendas, primary, language, zoneNumber } = this.props;
    const { visiable, linkType, linkHref, modalItem } = this.state;
    if (currentData.data && currentData.data.length === 0) {
      return null;
    }
    return (
      <div className="TabPaneItem">
        <div className="tabCotent">
          <h4>{currentData.title}</h4>
          {currentData.data && currentData.data.map((data, index) => {
            data.index = index;
            const arr = eventAgendas.filter(obj => obj.refId === data._id);
            const isSelected = arr.length > 0;
            /*canJoinAfter > now = coming soon
            canJoinAfter < now < endDate = Live
            endDate < now = if don't have video >> session ended, if have video >> playbac*/
            return (
              <div key={index} className="item" style={{ borderRightColor: data.type === 'Recordings' ? '#28a745' : '#17a2b8' }}>
                <Row align="middle">
                  {data.type === 'Live' ? (<Col xs={24} sm={24} md={17} className='right-border'>
                    {this.renderLeft(data, arr, isSelected)}
                  </Col>) : this.renderLeft(data, arr, isSelected)}
                  {/* left */}
                  {data.type === 'Live' && <Col align="left" xs={24} sm={24} md={6} offset={1}>
                    <div><CalendarFilled className='mR10' />{dateToStringTitle(formatTimezone(data.startDate, zoneNumber))}</div>
                    <div><ClockCircleFilled className='mR10' />{getTime(formatTimezone(data.startDate, zoneNumber))} - {getTime(formatTimezone(data.endDate, zoneNumber))}</div>
                  </Col>}
                </Row>
                {visiable && <Modal
                  visible={visiable}
                  width={800}
                  title={<div style={{ backgroundColor: primary, padding: '15px 20px' }}>{get(modalItem, `display_title[${language}]`)}</div>}
                  footer={null}
                  closeIcon={<CloseOutlined style={{ color: 'white' }} />}
                  onCancel={this.handleCancel}
                  bodyStyle={{ padding: 0 }}
                >
                  {linkType === 'zoom' || linkType === 'iframe' || linkType === undefined ? ((linkType === 'iframe' && linkHref.indexOf('.pdf') > -1) ? <iframe src={linkHref} frameBorder="0" style={{ width: '100%', height: 800 }}></iframe> : <Zoom link={linkHref} />) : <Row align="middle">
                    {linkType === 'video' ? <video
                      autoPlay
                      playsInline //解决手机端无法自动播放功能
                      loop
                      style={{ width: '100%', height: '100%' }}
                      src={linkHref}
                    /> : <img
                      src={linkHref}
                      style={{ width: '100%', height: '100%' }}
                    />}
                  </Row>}
                </Modal>}
              </div>
            )
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isGuestLogin: state.loginUserInfo.isGuestLogin,
  zoneNumber: state.loginUserInfo.zoneNumber, // 时区数字
});
const mapDispatchToProps = (dispatch) => ({
  UPDATE_SELF_IFRAME_ITEM: (item) => {
    dispatch({
      type: 'UPDATE_SELF_IFRAME_ITEM',
      data: item
    });
  },
  // 保存当前点击的agenda下标
  CHANGE_AGENDA_CURRENTINDEX: (item) => {
    dispatch({
      type: CHANGE_AGENDA_CURRENTINDEX,
      data: item
    });
  },
  // 当前点击的agenda
  JOINDETAILDEMANDITEM: (item) => {
    dispatch({
      type: JOINDETAILDEMANDITEM,
      data: item
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TabPaneItem));
