import React, { Component } from 'react';
import { connect } from 'react-redux';
import './index.less';
import SwiperList from './components/SwiperList';
import ContactList from './components/ContactList';
import ContactHeader from './components/ContactHeader';
import HeaderTitle from '../../components/HeaderTitle';

import FilterCheck from './components/FilterCheck';
import { Row, Col, Input, message } from 'antd';
import axios from '../../network/axios/$axios';
import { getBriefcasesList } from '../../actions/BriefcaseAction';
import { LIST } from './constants';
import { UserOutlined } from '@ant-design/icons';
import BreadCrumbs from '../../components/BreadCrumbs';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import i18next from 'i18next';
import Game from '../../game/Game';

const { Search } = Input;

class ContactScreen extends Component {
  constructor(props) {
    super();
    this.tRef = React.createRef();
    this.state = {
      leftFilterCheckedValues: [],
      selectedRole: '',
      searchType: 'all',
      keyword: '',
      letter: 'ALL',
      searchValue: '',
    };
  }
  componentDidMount() {}
  // 跳转创建会议日程页面
  goBusinessmatching = (item) => {
    this.props.history.push(
      '/businessmatching/createmeeting/' + item._id
    );
  };
  // 添加或者移除书签
  addOrDeleteToBriefcases = (data, arr, value) => {
    if (value === 0) {
      // 删除Briefcase
      axios('deleteBriefcases', { path: arr[0]._id })
        .then((response) => {
          console.log('/deleteBriefcases=', response);
          message.success(i18next.t('translations.bookmark-deleted-success'));
          this.getBriefcaseslist();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      // 新增Briefcase
      axios('addBriefcases', { data: {
        "model": "Networking/Networking",
        "refId": data._id }})
        .then((response) => {
          console.log('/addBriefcases=', response);
          message.success(i18next.t('translations.bookmark-added-success'));
          this.getBriefcaseslist();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  // 重新请求briefcases列表
  getBriefcaseslist = () => {
    const { userInfo, getBriefcasesList } = this.props;
    getBriefcasesList(userInfo.id);
  };
  // left-filter选择
  leftFilter = (checkedValues) => {
    // console.log('checked = ', checkedValues);    
    this.setState({
      leftFilterCheckedValues: checkedValues,
    });
  };
  // 选择用户类型
  selectUserRole = (value) => {
    this.setState({ selectedRole: value })
  };
  // 根据关键词搜索
  searchPeopleByKeyword = (type, value) => {
    this.setState({ searchType: type, keyword: value })
  };
  // 按照首字母刷选联系人
  filterByFirstName = (item, index, ev) => {
    ev.preventDefault();
    this.tRef.current.style.left = 100 / 27 * index + '%';
    // setFirstLetter(item);
    this.setState({
      letter: item
    });
  };
  onSearchByKeyword = (value) => {
    this.searchPeopleByKeyword('all', value)
  };

  onSearchChange = (e) => {
    // setSearchValue(e.target.value);
    this.setState({
      searchValue: e.target.value
    });
  };

  render() {
    const { primary, language, briefcasesData, location, menu } = this.props;
    const { leftFilterCheckedValues, selectedRole, searchType, keyword, letter, searchValue } = this.state;
    const visitors = briefcasesData.network;
    const pathname = location.pathname;
    const arr = menu.filter(obj => obj.href === pathname);
    return (
      <div className="page-container contact-page center">
        <Row gutter={[32, 32]}>
          <BreadCrumbs
            breadCrumbActive={arr.length && (arr[0].display_title)[language]}
          />
        </Row>
        <HeaderTitle title={i18next.t('translations.networking')} />
        {/* <Row className="pT20 pB20 flex flex_rl bgWhite letters">
          {LIST.map((item, index) => {
            return (
              <div
                key={index}
                className={"pointer flex-item " + "filter-title" + (letter === item ? ' letterSelected' : '')}
                onClick={(ev) => this.filterByFirstName(item, index, ev)}
              >
                {item}
              </div>
            );
          })}
          <div ref={ this.tRef } className='line'></div>
        </Row> */}
        <Row>
          {/* <Col xs={{ span: 24 }} md={{ span: 6 }}> */}
            {/* <FilterCheck primary={primary} onSearch={this.searchPeopleByKeyword.bind(this)} language={language} onLeftFilter={this.leftFilter} /> */}
            {/* <FilterCheck primary={primary} language={language} onLeftFilter={this.leftFilter} />
          </Col> */}
          <Col xs={{ span: 24 }} md={{ span: 24 }}>
            <Row className='search-box mB20'>
              <Col md={10} style={{transform: 'translateX(-10px)'}}>
                <Search
                  prefix={<UserOutlined />}
                  allowClear
                  style={{ borderColor: primary, whiteSpace: "nowrap" }}
                  placeholder={i18next.t('translations.search')}
                  onSearch={this.onSearchByKeyword}
                  onChange={this.onSearchChange}
                  value={searchValue}
                  enterButton={i18next.t('translations.search')}
                />
              </Col>
            </Row>
            {/* <ContactHeader onSearch={this.searchPeopleByKeyword.bind(this)} onSelect={this.selectUserRole.bind(this)} primary={primary} /> */}
            {/* <SwiperList searchType={searchType} keyword={keyword} selectedRole={selectedRole} visitors={visitors || []} onChange={this.addOrDeleteToBriefcases} onClickMeet={this.goBusinessmatching} primary={primary} /> */}
            <ContactList letter={letter} searchType={searchType} keyword={keyword.toLowerCase()} selectedRole={selectedRole} leftFilterCheckedValues={leftFilterCheckedValues} visitors={visitors || []} onChange={this.addOrDeleteToBriefcases} onClickMeet={this.goBusinessmatching} primary={primary} {...this.props} />
          </Col>
        </Row>
        <Game link='#/Contact' />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  primary: state.profile.primary,
  language: state.profile.language,
  userInfo: state.loginUserInfo.userInfo,
  briefcasesData: state.briefcases.briefcasesData,
  menu: state.profile.menu
});

const mapDispatchToProps = (dispatch) => ({
  getBriefcasesList: (userId) => {
    dispatch(getBriefcasesList(userId));
  }
});


const enhance = compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(ContactScreen);
