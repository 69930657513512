import React, { Component } from 'react';
import { Breadcrumb, Button, Image, Row, Col, Card } from 'antd';
import './index.less';
import './ProductDetail.less';
import arrowUp from '../../Image/product/arrow-up.png';
import arrowDown from '../../Image/product/arrow-down.png';
import productImg from '../../Image/product/product-img.png';
import arrowLeft from '../../Image/product/arrow-left.png';
import arrowRight from '../../Image/product/arrow-right.png';
import chat from '../../Image/product/material-chat.png';
import video from '../../Image/product/video.png';
import ProductTags from './components/ProductTags';
import featherHeart from '../../Image/product/feather-heart.png';
import share from '../../Image/product/open-share-boxed.png';
import BasicInfo from './components/BasicInfo';
import OtherProductList from './components/OtherProductList';
import { isEmpty } from 'lodash';
import { getProductDetail } from '../../actions/ProductAction';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Slider from 'react-slick';
import { splitArr } from '../../Utils/Util';
import { OTHER_PRODUCT_LIST } from './constants';
const settings = {
  autoplay: false,
  dots: false,
  infinite: true,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false
};

class ProductDetailScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMore: false
    };
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    const { getProductDetail } = this.props;
    getProductDetail(id);
  }

  showMoreAction() {
    this.setState({ showMore: !this.state.showMore });
  }

  clickLeftAction() {
    this.slider.slickPrev();
  }

  clickRightAction() {
    this.slider.slickNext();
  }

  renderChildrenView = (list, index) => {
    return (
      <div key={index}>
        <Row justify="space-between">
          {list.map((obj, i) => {
            return (
              <Col sm={5} md={5} key={index * 4 + i}>
                <Image
                  className="more-image"
                  src={obj.link || productImg}
                  alt=""
                />
              </Col>
            );
          })}
        </Row>
      </div>
    );
  };

  render() {
    const { showMore } = this.state;
    const { detail } = this.props;
    if (isEmpty(detail)) {
      return null;
    }
    let categories = '',
      markets = '';
    detail.categories.map((obj, i) => {
      if (i === detail.categories.length - 1) {
        categories = categories + obj.display_name.en;
      } else {
        categories = categories + obj.display_name.en + ' / ';
      }
    });
    const str = detail.info ? detail.info.Origin.en : '';
    const list = str.split(',');
    list.map((obj, i) => {
      if (i === list.length - 1) {
        markets = markets + obj;
      } else {
        markets = markets + obj + ', ';
      }
    });
    const data = splitArr(detail.media, 4);
    return (
      <div className="content-view product_detail_page">
        <Breadcrumb separator=">" className="breadcrumb">
          <Breadcrumb.Item>Lobby</Breadcrumb.Item>
          <Breadcrumb.Item>Products</Breadcrumb.Item>
          <Breadcrumb.Item>{detail.name.en}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="content">
          <ProductTags tags={get(detail, 'tags_string') || ''} />
          <Row className="detail">
            <Col xs={24} sm={6} md={6}>
              <img
                className="product-logo"
                alt=""
                src={detail.media.length ? detail.media[0].link : productImg}
              />
              <Row
                justify="space-between"
                align="middle"
                className="product-img-list"
              >
                <Col xs={1} md={1}>
                  <img
                    onClick={() => this.clickLeftAction()}
                    className="pointer"
                    alt=""
                    src={arrowLeft}
                  />
                </Col>
                <Col xs={20} md={20}>
                  <Slider ref={(c) => (this.slider = c)} {...settings}>
                    {data.map((item, index) =>
                      this.renderChildrenView(item, index)
                    )}
                  </Slider>
                </Col>
                <Col xs={1} md={1}>
                  <img
                    onClick={() => this.clickRightAction()}
                    className="pointer"
                    alt=""
                    src={arrowRight}
                  />
                </Col>
              </Row>
              <Row justify="center" className="start">
                <Col xs={12} sm={12} className="common pointer">
                  <img className="btn-img" src={featherHeart} alt="" />
                  <span className="favourite-text">Add to Favourite</span>
                </Col>
                <Col xs={12} sm={12} className="common pointer">
                  <img className="btn-img" src={share} alt="" />
                  <span className="favourite-text">Share</span>
                </Col>
              </Row>
            </Col>
            <Col xs={0} sm={1} md={1} />
            <Col xs={24} sm={16} md={16} className="detail-right">
              <Row>
                <div className="sponsored">
                  <span className="sponsored-text">{detail.status}</span>
                </div>
              </Row>
              <Row>
                <span className="margin-title title">{detail.name.en}</span>
              </Row>
              <Row align="middle" className="margin-top pointer">
                <span className="show-text">Get Latest Price</span>
                <img className="arrow-right-img" src={arrowRight} alt="" />
              </Row>
              <Row>
                <span className="title-key">
                  Main target market (Country of Origin)：
                </span>
                <span className="title-value">{markets}</span>
              </Row>
              <Row>
                <span className="title-key">Categories:</span>
                <span className="title-value">{categories}</span>
              </Row>
              <Row className="product-btn-list">
                <Col xs={11} md={8} className="detail-chat-btn pointer">
                  <img className="right-btn-img" src={chat} alt="" />
                  <span className="chat-text">Chat</span>
                </Col>
                <Col xs={11} md={8} className="common detail-meet-btn pointer">
                  <img className="right-btn-img" src={video} alt="" />
                  <span className="meet-text">Meet</span>
                </Col>
              </Row>
              <div className="line" />
              <Row>
                <span className="des-text">Product Description</span>
              </Row>
              <Row>
                <span
                  className={!showMore ? 'des-value show-less' : 'des-value'}
                >
                  {get(detail, 'description.en')}
                </span>
              </Row>
              <Row justify="end">
                <Button
                  type="text"
                  onClick={() => this.showMoreAction()}
                  className="common show-btn"
                >
                  <span className="show-text">
                    {!showMore ? 'Show More' : 'Show Less'}
                  </span>
                  <img
                    className="arrow-up-img"
                    src={!showMore ? arrowDown : arrowUp}
                    alt=""
                  />
                </Button>
              </Row>
            </Col>
          </Row>
          <BasicInfo data={detail.info || {}} />
          <OtherProductList />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  detail: get(state.product.productDetail, 'data')
});

const mapDispatchToProps = (dispatch) => ({
  getProductDetail: (id) => {
    dispatch(getProductDetail(id));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductDetailScreen);
