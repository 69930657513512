import React, { Component } from 'react';
import { Row, List, Card } from 'antd';
import { connect } from 'react-redux';
import './SpeakerList.less';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import i18next from 'i18next';

class SpeakerList extends Component {
  constructor(props) {
    super();
  }
  componentDidMount() { }

  clickItem = (item) => {
    // 页面跳转
    this.props.history.push({
      pathname: '/SpeakerDetail/' + item._id,
      state: { params: item }
    });
  };

  render() {
    const { data, language } = this.props;
    if (data.length === 0) {
      return (
        <span>{i18next.t('translations.no-result')}</span>
      )
    }
    return (
      <div className="speakers-list">
        <List
          grid={{
            gutter: [32, 16],
            xs: 1,
            sm: 2,
            md: 4,
            lg: 4,
            xl: 4,
            xxl: 4
          }}
          dataSource={data}
          renderItem={(item, index) => {
            const profile = get(item, 'profilePic.src')
            return (
              <List.Item key={index} onClick={() => {
                this.clickItem(item);
              }}>

                <Card>
                  <div className='cell'>
                    <div className='flex-center'>
                      <img className='profilePic' src={get(item, 'profilePic.src')} alt={get(item, 'profilePic.alt')} title={get(item, 'profilePic.title')} />
                    </div>
                    <div className="speakerName">
                      <span>{get(get(item, 'display_name'), language)}</span>
                    </div>
                    <div className="speakerJobTitle">
                      <span>{get(get(item, 'display_jobTitle'), language)}</span>
                    </div>
                    <div className="speakerJobTitle f25 ">
                      <span>{get(get(item, 'display_company'), language)}</span>
                    </div>
                  </div>
                </Card>
              </List.Item>
            );
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.profile.language
});

export default connect(mapStateToProps, null)(withRouter(SpeakerList));
