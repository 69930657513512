import React, { Component } from 'react';
import './FooterView.less';
import { Col, Layout, Row } from 'antd';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { connect } from 'react-redux';
import get from 'lodash/get';
const { Footer } = Layout;

class FooterView extends Component {
  render() {
    const { data, primary, language, designData } = this.props;
    if (designData.id === 0) {
      return (
        <Footer className="footer" style={{ backgroundColor: '#ffffff' }}>
          <Row justify="space-between">
            <Col md={10} xs={24}>
              <Row className='copyrights'>
                <Col>
                  <span className="footer-text">
                    {get(data.display_copyrights, language)}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col md={0} xs={24} style={{ height: 10 }}></Col>
            <Col md={14} xs={24}>
              <Row className='footer-right-area'>
                <div className="flex-row-center">
                  <a className="footer-text" href={get(get(data.display_tnc, language), 'href')} target={get(data.display_tnc, 'target')}>
                    {get(get(data.display_tnc, language), 'text')}
                  </a>
                  {get(get(data.display_privacy, language), 'text') && <span className="footer-text mL10 mR10">|</span>}
                  <a className="footer-text" href={get(get(data.display_privacy, language), 'href')} target={get(data.display_privacy, 'target')}>
                    {get(get(data.display_privacy, language), 'text')}
                  </a>
                  {get(get(data.display_disclaimer, language), 'text') && <span className="footer-text mL10 mR10">|</span>}
                  <a className="footer-text" href={get(get(data.display_disclaimer, language), 'href')} target={get(data.display_disclaimer, 'target')}>
                    {get(get(data.display_disclaimer, language), 'text')}
                  </a>
                </div>
              </Row>
            </Col>
          </Row>
        </Footer>
      );
    }
    if (designData.id === 2) {
      return (
        <Footer className="footer" style={{ backgroundColor: '#ffffff' }}>
          <Row justify="space-between">
            <Col md={16} xs={24}>
              <Row className='footer-left-area'>
                <div className="flex-row-center">
                  <a className="footer-text" href={get(get(data.display_tnc, language), 'href')} target={get(data.display_tnc, 'target')}>
                    {get(get(data.display_tnc, language), 'text')}
                  </a>
                  {get(get(data.display_privacy, language), 'text') && <span className="footer-text mL10 mR10">|</span>}
                  <a className="footer-text" href={get(get(data.display_privacy, language), 'href')} target={get(data.display_privacy, 'target')}>
                    {get(get(data.display_privacy, language), 'text')}
                  </a>
                  {get(get(data.display_disclaimer, language), 'text') && <span className="footer-text mL10 mR10">|</span>}
                  <a className="footer-text" href={get(get(data.display_disclaimer, language), 'href')} target={get(data.display_disclaimer, 'target')}>
                    {get(get(data.display_disclaimer, language), 'text')}
                  </a>
                </div>
              </Row>
            </Col>
            <Col md={0} xs={24} style={{ height: 10 }}></Col>
            <Col md={8} xs={24}>
              <Row className='footer-right-area'>
                <div>
                  <span className="footer-text">
                    {get(data.display_copyrights, language)}
                  </span>
                </div>
              </Row>
            </Col>
          </Row>
        </Footer>
      );
    }
    return (
      <Footer className="footer" style={{ backgroundColor: '#ffffff' }}>
        <Row justify="space-between">
          <Col md={8} xs={24}>
            <a href={get(get(data.display_tnc, language), 'href')} target={get(data.display_tnc, 'target')}>
              <span className="footer-text">
                {get(get(data.display_tnc, language), 'text')}
              </span>
            </a>
          </Col>
          <Col md={8} xs={24}>
            <Row justify="center">
              <div>
                <span className="footer-text">
                  {get(data.display_copyrights, language)}
                </span>
              </div>
            </Row>
          </Col>
          <Col md={8} xs={24}>
            <Row className='footer-right-area'>
              <div className="flex-row-center">
                <a className="footer-text" href={get(get(data.display_privacy, language), 'href')} target={get(data.display_privacy, 'target')}>
                  {get(get(data.display_privacy, language), 'text')}
                </a>
                {get(get(data.display_disclaimer, language), 'text') && <span className="footer-text mL10 mR10">|</span>}
                <a className="footer-text" href={get(get(data.display_disclaimer, language), 'href')} target={get(data.display_disclaimer, 'target')}>
                  {get(get(data.display_disclaimer, language), 'text')}
                </a>
              </div>
            </Row>
          </Col>
        </Row>
      </Footer>
    );
  }
}

const mapStateToProps = (state) => ({
  primary: state.profile.primary,
  language: state.profile.language
});

const enhance = compose(
  withTranslation('translations'),
  connect(mapStateToProps, null)
);

export default enhance(FooterView);
