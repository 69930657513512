import React, { Component } from 'react';
import './LanguageView.less';
import { Row, Col, Dropdown, Menu } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { changeLanguage } from '../actions/ProfileAction';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

class LanguageView extends Component {
  constructor(props) {
    super();
    this.state = {
      codeMap: {
        'en-us': ['en', 'EN'],
        'zh-hant': ['hk', '繁'],
        'zh-hans': ['zh', '简']
      },
    };
  }
  //选择语言
  renderLanguageMenu = () => {
    const { changeLanguage, languageData } = this.props;
    const { codeMap } = this.state;
    return (
      <Menu
        style={{
          position: 'absolute',
          top: '20px',
          borderRadius: '6px',
          minWidth: '100px'
        }}
      >
        {languageData &&
          languageData.map((item) => (
            <Menu.Item key={item.code}>
              <a
                onClick={() => {
                  i18next.changeLanguage(codeMap[item.code][0]);
                  changeLanguage(item.code);
                }}
              >
                {codeMap[item.code][1]}
              </a>
            </Menu.Item>
          ))}
      </Menu>
    );
  };
  render() {
    // const {
    //   data,
    //   primary,
    //   language,
    // } = this.props;
    return (
      <div className='LanguageView'>
        <Dropdown
          overlay={this.renderLanguageMenu()}
          trigger={['click']}
        >
          <a
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            {i18next.t('language')}{' '}
            <CaretDownOutlined className="language" />
          </a>
        </Dropdown>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  primary: state.profile.primary,
  language: state.profile.language,
});

const mapDispatchToProps = (dispatch) => ({
  changeLanguage: (language) => {
    dispatch(changeLanguage(language));
  },
});

const enhance = compose(
  // withRouter,
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(LanguageView);
