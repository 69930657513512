/*
 * @Description: 
 * @Version: 1.0
 * @Autor: ricky shen
 * @Date: 2022-01-11 11:33:19
 * @LastEditors: ricky shen
 * @LastEditTime: 2022-03-25 21:35:56
 */
import { FETCH_AGENDA_LIST, FETCH_MY_SESSION_LIST, CHANGE_MOVIEINDEX, CHANGE_AGENDA_CURRENTINDEX, JOINDETAILDEMANDITEM } from '../constants/ActionTypes';

const initialState = {
  agendaList: null,
  mySessionsList: null,
  JoinDetailDemandItem: null, // 当前点击的agenda
  movieIndex: 0, // Agenda点击Join，播放的影视下标
  agendaCurrentIndex: 0, // 当前点击的Agenda下标，JoinSessionTabPane判断显示
};

const Agenda = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_AGENDA_LIST:
      return Object.assign({}, state, {
        agendaList: action.data
      });
    case FETCH_MY_SESSION_LIST:
      return Object.assign({}, state, {
        mySessionsList: action.data
      });
    case CHANGE_MOVIEINDEX:
      return Object.assign({}, state, {
        movieIndex: action.data
      });
    case CHANGE_AGENDA_CURRENTINDEX:
      return Object.assign({}, state, {
        agendaCurrentIndex: action.data
      });
    case JOINDETAILDEMANDITEM:
      return Object.assign({}, state, {
        JoinDetailDemandItem: action.data
      });
    default:
      return state;
  }
};

export default Agenda;
