import React, { Component } from 'react';
import { Row, List, Rate, Card, message } from 'antd';
import { connect } from 'react-redux';
import './CategoriesList.less';
import noCompanyLogo from '../../../Image/no-company-logo.png';
// import { StarOutlined } from '@ant-design/icons';
// import { BASE_URL } from '../../../network/RequestUtils';
import axios from '../../../network/axios/$axios';
import get from 'lodash/get';
import { RiHeartFill } from 'react-icons/ri';
import i18next from 'i18next';
import { LoadingOutlined } from '@ant-design/icons';

class CategoriesList extends Component {
  constructor(props) {
    super();
  }
  componentDidMount() { }

  clickItem = (item) => {
    this.props.onSelectItem(item);
  };

  onChange = (data, arr, value) => {
    const { isGuestLogin, fetchBriefcase } = this.props;
    if (value === 0) {
      // 删除Briefcase
      axios('deleteBriefcases', { path: arr[0]._id })
        .then((response) => {
          console.log('/deleteBriefcases=', response);
          message.success(i18next.t('translations.bookmark-deleted-success'));
          fetchBriefcase();
        })
        .catch((error) => {
          console.log(error);
        });
      this.clickUpload('delete Briefcases ' + arr[0].detail.name);
    } else {
      if (isGuestLogin) {
        this.goRegisterPage();
      } else {
        // 新增Briefcase
        axios('addBriefcases', {
          data: {
            model: 'Booth/Booth',
            refId: data._id
          }
        })
          .then((response) => {
            console.log('/addBriefcases=', response);
            message.success(i18next.t('translations.bookmark-added-success'));
            fetchBriefcase();
          })
          .catch((error) => {
            console.log(error);
          });
      }
      this.clickUpload('add Briefcases ' + data.name);
    }
  };
  //统计点击事件
  clickUpload = (fieldname) => {
    axios('activity', {
      data: {
        event: '[Click] ExpoHall ' + fieldname
      }
    }).then((response) => {
      console.log('/activity', response);
    });
  };
  //跳转注册页面
  goRegisterPage = () => {
    this.props.history.push('/register');
  };

  render() {
    const { data, exhibitorBooths, language, loading } = this.props;
    if (loading) {
      return (
        <div className='flex-center hp-py-16 hp-mt-16 hp-ml-16' style={{ backgroundColor: '#fff', borderRadius: '7px' }}>
          <LoadingOutlined />
          <span className='hp-mt-8'>{i18next.t('translations.loading')}</span>
        </div>
      )
    }
    if (data.length === 0) {
      return (
        <div className='hp-mt-16'>
          <span>{i18next.t('translations.no-result')}</span>
        </div>
      )
    }
    return (
      <div className="categories-page">
        <List
          grid={{
            gutter: [32, 16],
            xs: 1,
            sm: 2,
            md: 4,
            lg: 4,
            xl: 4,
            xxl: 4
          }}
          dataSource={data}
          renderItem={(item, index) => {
            // let url = item.hallLogo.url ? BASE_URL + item.hallLogo.url : noCompanyLogo;
            // if (url != noCompanyLogo && item.hallLogo.formats && item.hallLogo.formats.small && item.hallLogo.formats.small.url) {
            //   url = BASE_URL + item.hallLogo.formats.small.url;
            // }
            const arr = exhibitorBooths.filter((v) => v.refId === item._id);
            const isSelected = arr.length > 0;
            return (
              <List.Item key={index}>
                <Card className="hp-text-center">
                  <div className="list-content pointer">
                    <Row className="recommend-bottom-btn">
                      {
                        isSelected ? (
                          <div
                            className='hp-wish-button hp-cursor-pointer hp-border-radius-round remix-icon hp-p-8 hp-rate hp-text-color-danger-1 hp-bg-color-danger-4 hp-bg-color-dark-danger'
                            onClick={() => this.onChange(item, arr, 0)}
                          >
                            <RiHeartFill />
                          </div>
                        ) : (
                          <div
                            className='hp-wish-button hp-cursor-pointer hp-border-radius-round remix-icon hp-p-8 hp-rate hp-text-color-black-40 hp-text-color-dark-70 hp-bg-color-black-10 hp-bg-color-dark-90'
                            onClick={() => this.onChange(item, arr, 1)}
                          >
                            <RiHeartFill />
                          </div>
                        )
                      }
                    </Row>
                    <Row className='img-area' onClick={() => this.clickItem(item)}>
                      <img
                        className="recommend-product-img"
                        src={item?.hallLogo?.src || noCompanyLogo}
                        alt=""
                      />
                    </Row>
                    <Row className="recommend-bottom-title hp-mb-16">
                      <span className='span'>
                        {get(item, `display_name.${language}`) || item.name}
                      </span>
                    </Row>
                  </div>
                </Card>
              </List.Item>
            );
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isGuestLogin: state.loginUserInfo.isGuestLogin,
  language: state.profile.language
});

export default connect(mapStateToProps, null)(CategoriesList);
