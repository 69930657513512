import React, { Component } from 'react';
import './SearchCondition.less';
import { Tree, Row } from 'antd';
import { getProductCategory } from '../../../actions/ProductAction';
import { connect } from 'react-redux';

class SearchCondition extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: []
    };
  }

  componentDidMount() {
    const { getProductCategory } = this.props;
    getProductCategory();
  }

  onCheck = (checkedKeys, info) => {
    console.log('onCheck', checkedKeys, info);
    this.props.selectAction(checkedKeys);
  };

  render() {
    const { productCategory } = this.props;
    return (
      <div className="search-condition-area">
        <div className="search-area-list">
          <Tree
            checkable
            selectable={false}
            onCheck={this.onCheck}
            treeData={productCategory}
            titleRender={(e) => {
              if (!e.parent_id) {
                e.checkable = false;
              }
              return (
                <Row className="item-cell">
                  <Row>
                    <span className={!e.parent_id ? 'tree_title' : 'tree_title_in'}>
                      {e.title}
                    </span>
                  </Row>
                  <Row className={!e.parent_id ? 'search-area-top-line' : ''} />
                </Row>
              );
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  productCategory: state.product.productCategory
});

const mapDispatchToProps = (dispatch) => ({
  getProductCategory: () => {
    dispatch(getProductCategory());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchCondition);
