import { Link } from "react-router-dom";
import logoImg from '../../../../Image/menu_logo.png';

export default function MenuLogo(props) {

  return (
    <div className="hp-d-flex flex_a_c">
      <Link
        to={props.selectedModel === '3d' ? '/Landing3d' : '/Landing'}
        onClick={props.onClose}
        className="hp-position-relative hp-d-flex"
      >
        <img className="hp-logo" src={logoImg} alt="logo" />
      </Link>

    </div>
  );
};