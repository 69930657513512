import { FETCH_LOGIN, EXIT_LOGIN, UPDATE_USERINFO, UPDATE_SELF_IFRAME_ITEM, BACK_IFRAME, LOG_CHART_USER, SHOW_BACK, TIMEZONE, LOG_PUBLIC_CHART_USER, CHAT_SWITCH, BOOTH_CAHT_LOG_PUBLIC_CHART_USER, SET_NOTIFICATION } from '../constants/ActionTypes';
const initialState = {
  userInfo: {},
  token: null,
  isGuestLogin: false,
  selfIframeItem: null,
  logSendBirdUser: {}, // 登录人获取的chart数据 projectId, userName and userSecret
  logPublicChatUser: {}, // access_key id
  showBack: null,
  sendNotification: false,
  zoneNumber: 0,
  chatSwitch: false, // 是否创建聊天
  role: null,
  boothChatlogPublicChatUser: {}, // access_key id
  url: ''
};

const loginUserInfo = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LOGIN:
      return Object.assign({}, state, {
        userInfo: action.data.user,
        token: action.data.jwt,
        isGuestLogin: action.data.isGuestLogin,
        role: action.data.role,
        url: action.data.url
      });
    case EXIT_LOGIN:
      sessionStorage.removeItem('token');
      sessionStorage.clear();
      localStorage.clear();
      return Object.assign({}, initialState, {
        userInfo: {},
        token: null
      });
    case UPDATE_USERINFO:
      return Object.assign({}, state, {
        userInfo: action.data
      });
    case UPDATE_SELF_IFRAME_ITEM:
      return Object.assign({}, state, {
        selfIframeItem: action.data
      });
    case SHOW_BACK:
      console.log('AuthGate-AuthGate222');
      return Object.assign({}, state, {
        showBack: action.data
      });
    case BACK_IFRAME:
      return Object.assign({}, state, {
        selfIframeItem: null
      });
    case LOG_CHART_USER:
      return Object.assign({}, state, {
        logSendBirdUser: action.data
      });
    case LOG_PUBLIC_CHART_USER:
      return Object.assign({}, state, {
        logPublicChatUser: action.data
      });
    case BOOTH_CAHT_LOG_PUBLIC_CHART_USER:
      console.log('BOOTH_CAHT_LOG_PUBLIC_CHART_USER', action.data);
      return Object.assign({}, state, {
        boothChatlogPublicChatUser: action.data
      });
    case TIMEZONE:
      return Object.assign({}, state, {
        zoneNumber: action.data || 0
      });
    case CHAT_SWITCH:
      return Object.assign({}, state, {
        chatSwitch: action.data,
      });
    case SET_NOTIFICATION:
      return Object.assign({}, state, {
        sendNotification: action.data,
      });
    default:
      return state;
  }
};

export default loginUserInfo;
