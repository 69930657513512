import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import queryString from 'query-string';
import { Row, Col, Form, Input, Button, Checkbox } from "antd";

import LeftContent from "../leftContent";
import i18next from "i18next";
import { connect } from "react-redux";
import { loginFetch, LOG_CHART_USER, LOG_PUBLIC_CHART_USER } from "../../../actions/LoginUserAction";
import { selected2dOr3dModel } from "../../../actions/ProfileAction";
import apiAxios from "../../../network/axios/$axios";

function Login(props) {
  const { loginFetch, selectedModel, selected2dOr3dModel, language, userInfo } = props;
  if (userInfo && userInfo._id && localStorage.getItem("token")) {
    if (selectedModel === '3d') {
      props.history.replace('/Landing3d');
    } else {
      props.history.replace('/Landing');
    }
  }
  useEffect(() => {
    apiAxios('eventInfo')
      .then((response) => {
        console.log('/eventInfo', response);
        // document.title = response.display_name && response.display_name[language];
      })
      .catch((error) => {
        console.log(error);
      });
    const { type } = queryString.parse(props.location.search);
    if (type) {
      setTimeout(() => {
        selected2dOr3dModel(type);
      }, 1500)
    }
  }, [])

  const onFinish = (values) => {
    console.log('Success:', values);
    loginFetch(values, selectedModel);
  };

  return (
    <Row gutter={[32, 0]} className="hp-authentication-page bgWhite">
      <LeftContent />

      <Col lg={12} span={24} className="hp-py-sm-0 hp-py-md-64 hp-pb-96">
        <Row className="hp-h-100" align="middle" justify="center">
          <Col
            xxl={15}
            xl={15}
            lg={20}
            md={20}
            sm={24}
            className="hp-px-sm-8"
          >
            <h1 className="hp-mb-sm-0 bold">{i18next.t('translations.sign-in')}</h1>

            <Form
              layout="vertical"
              name="basic"
              initialValues={{ remember: true }}
              className="hp-mt-sm-16 hp-mt-32"
              onFinish={onFinish}
            >
              <Form.Item label={`${i18next.t('translations.username')} :`} className="hp-mb-16" name="id">
                <Input id="error" />
              </Form.Item>

              <Form.Item label={`${i18next.t('translations.password')} :`} className="hp-mb-8" name="pass">
                <Input.Password id="warning2" />
              </Form.Item>

              <Row align="middle" justify="space-between">
                <Form.Item className="hp-mb-0">
                  <Checkbox name="remember">{i18next.t('translations.remember-me')}</Checkbox>
                </Form.Item>
                {/* 
                <Link
                  className="hp-button hp-text-color-black-80 hp-text-color-dark-40"
                  to="/forgotPW"
                >
                  {i18next.t('translations.forgot-password')}?
                </Link> */}
              </Row>

              <Form.Item className="hp-mt-16 hp-mb-8">
                <Button block type="primary" htmlType="submit">
                  {i18next.t('translations.sign-in')}
                </Button>
              </Form.Item>
            </Form>

            {/* <Col className="hp-form-info hp-text-center">
              <span className="hp-text-color-black-80 hp-text-color-dark-40 hp-caption hp-font-weight-400 hp-mr-4">
                {i18next.t('translations.register-subtitle')}
              </span>

              <a
                className="hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-caption"
                href="https://watsonsreg.cyberport.hk/"
                target='_blank'
              >
                {i18next.t('translations.register')}
              </a>
            </Col> */}

          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.loginUserInfo.userInfo,
  primary: state.profile.primary,
  language: state.profile.language,
  selectedModel: state.profile.selectedModel,
});

const mapDispatchToProps = (dispatch, props) => ({
  loginFetch: (data, selectedModel) => {
    dispatch(
      loginFetch(data, (data) => {
        console.log('data', data, selectedModel);
        setTimeout(() => {
          if (selectedModel === '3d') {
            props.history.replace('/Landing3d');
          } else {
            props.history.replace('/Landing');
          }
        }, 100);
      })
    );
  },
  LOG_CHART_USER: (data) => {
    dispatch(LOG_CHART_USER(data));
  },
  LOG_PUBLIC_CHART_USER: (data) => {
    dispatch(LOG_PUBLIC_CHART_USER(data));
  },
  selected2dOr3dModel: (model) => {
    dispatch(selected2dOr3dModel(model));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
