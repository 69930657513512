import axios from 'axios';
import { message } from 'antd';
import API from './api';
import { Component } from 'react';
import { encrypt, decrypt } from './encrypt';
import qs from 'query-string'
const URL = ['/User/universal/login', '/User/universal/', '/User/Visitor/'];

// axios.defaults.withCredentials = true;
const $axios = axios.create({
  // baseURL: 'http://46.137.205.92', // sit
  // baseURL: 'https://watsons2022exhibitorcms.cyberport.hk',
  baseURL: 'https://watsons-demo-api.chefdigital.io', // 加密的api
  //baseURL: 'https://saas-uat.vootprint.tech', // vootprint
  headers: { Accept: 'application/json', 'Content-Type': "application/json" }
});

//请求拦截
const qsgenerator = (params) => {
  if (!params) return ""
  try {
    return Object.keys(params).map(k => `${k}=${typeof params[k] == 'object' ? JSON.stringify(params[k]) : params[k]}`).join("&")

  } catch {
    return qs.stringify(params, { encode: false, arrayFormat: 'bracket' })
  }
}
$axios.interceptors.request.use(
  (config) => {
    // 此处应根据具体业务写token
    const token = localStorage.getItem('token');
    //config.headers['Content-Type'] = 'application/json';
    console.log(JSON.parse(JSON.stringify(config)), 'config数据data==：');
    if (token && URL.indexOf(config.url) < 0)
      config.headers['Authorization'] = token;
    if (config.data) {
      try { config.data = JSON.stringify(config.data) } catch { }
      config.data = encrypt(config.data)
    }
    const originUrl = config.params ? (encodeURI(`${config.url}?${(qsgenerator(config.params))}`)) : (encodeURI(config.url))
    const newUrl = encrypt(originUrl)
    config.url = newUrl
    console.log("url", originUrl, newUrl)
    delete config.params;
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    message.error(error);
    return Promise.reject(error);
  }
);

// http响应拦截器
$axios.interceptors.response.use(
  (res) => {

    const serverVersion = res.headers["x-version"]
    console.log('michael x-version', serverVersion)
    const version = localStorage.getItem("x-version")
    if (serverVersion != "" && serverVersion !== version) {
      localStorage.setItem('x-version', serverVersion)
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => {
          registration.unregister();
        });
      });
      caches.keys().then((keyList) => {
        return Promise.all(
          keyList.map((key) => {
            return caches.delete(key);
          })
        );
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else localStorage.setItem('x-version', serverVersion)
    res.data = decrypt(res.data)
    if (res.status === 200 || res.status === 201) {
      if (decrypt(res.config.url).search('/User/universal/login') > -1) {
        console.log('******token*****' + res.data);
        localStorage.setItem('token', res.data);
        return res.data
      }
      try {

        console.log(JSON.parse(JSON.stringify(res.data)), 'res返回数据data==：');
        return JSON.parse(res.data);
      } catch (e) {
        return res.data
      }
    }
  },
  (error) => {
    //message.error('网络开小差，请重新尝试');
    return Promise.reject(error);
  }
);

const placeholder = /\{(\w+)\}?/gi; //占位符
export function buildPath(url, path) {
  if (!path) return url;
  return url.replace(placeholder, function ($1, $2) {
    return ($2 = encodeURIComponent(path[$2]));
  });
}
// export default axios
function apiAxios(url, payload, hideLoadFlag) {
  // console.log('url', url, API[url]);
  const req = API[url] || { url: '/' + url, method: 'get' };
  var urlPath = payload && payload.path ? req.url + payload.path : req.url;
  if (payload && payload.pathObj) {
    urlPath = payload && payload.pathObj ? buildPath(req.url, payload.pathObj) : req.url;
  }
  return new Promise((resolve, reject) => {
    const cofig = {
      url: urlPath,
      method: req.method.toUpperCase(),
      hideLoadFlag: hideLoadFlag
    }
    if (payload && payload.data) {
      cofig.data = payload.data;
    }
    if (payload && payload.params) {
      cofig.params = payload.params;
    }
    $axios(cofig)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
// 给所有组件绑定
Component.prototype.request = apiAxios;

export default apiAxios;
