import React, { Component } from 'react';
import './OtherProductList.less';
import productImg from '../../../Image/product/product-img.png';
import arrowLeft from '../../../Image/product/arrow-left.png';
import arrowRight from '../../../Image/product/arrow-right.png';
import { OTHER_PRODUCT_LIST } from '../constants';
import Slider from 'react-slick';
import { Col, Row, Card } from 'antd';
import { splitArr } from '../../../Utils/Util';
const settings = {
  autoplay: false,
  dots: false,
  infinite: true,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false
};

class OtherProductList extends Component {
  constructor(props) {
    super(props);
  }

  clickLeftAction() {
    this.slider.slickPrev();
  }

  clickRightAction() {
    this.slider.slickNext();
  }

  renderChildrenView = (list, index) => {
    return (
      <div key={index}>
        <Row justify="space-between">
          {list.map((obj, i) => {
            return (
              <Col
                xs={24}
                sm={4}
                md={4}
                key={index * 5 + i}
                className="pointer"
              >
                <Card
                  hoverable
                  bordered={false}
                  bodyStyle={{ padding: 0 }}
                  onClick={() => {}}
                >
                  <img className="other-product-img" src={productImg} alt="" />
                  <div className="other-product-title">
                    <span>{obj.title}</span>
                  </div>
                  <div className="other-product-des">
                    <span>{obj.company}</span>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  };

  render() {
    const list = splitArr(OTHER_PRODUCT_LIST, 5);
    return (
      <div className="other-page">
        <div className="You-May-Also-Like">
          <span>You May Also Like</span>
        </div>
        <Row
          justify="space-between"
          align="middle"
          className="other-product-list"
        >
          <Col xs={2} sm={1} md={1}>
            <img
              onClick={() => this.clickLeftAction()}
              className="arrow-left pointer"
              alt=""
              src={arrowLeft}
            />
          </Col>
          <Col xs={20} sm={22} md={22}>
            <Slider
              ref={(c) => (this.slider = c)}
              className="other-product-area"
              {...settings}
            >
              {list.map((item, index) => this.renderChildrenView(item, index))}
            </Slider>
          </Col>
          <Col xs={2} sm={1} md={1} style={{ textAlign: 'right' }}>
            <img
              onClick={() => this.clickRightAction()}
              className="arrow-right pointer"
              alt=""
              src={arrowRight}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default OtherProductList;
