import { SAVE_SELECTED_TAB_KEY, FETCH_MYPRIVATEMEETING_LIST, RECEIVE_MYPRIVATEMEETING_LIST } from '../constants/ActionTypes';
import axios from '../network/axios/$axios';

export const saveSelectedTabKey = (key) => {
  return {
    type: SAVE_SELECTED_TAB_KEY,
    rawData: key
  };
};

//获取MyPrivateMeeting列表
export function getMyPrivateMeetingList(userId) {
  return (dispatch) => {
    dispatch(fetchMyPrivateMeetingList());
    axios('myPrivateMeeting', { path: userId })
      .then((response) => {
        console.log('/myPrivateMeeting=', response);
        if (response.count) {
          dispatch(receiveMyPrivateMeetingList({ meetings: [], unavailable: [] }));
        } else {
          dispatch(receiveMyPrivateMeetingList(response));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

function fetchMyPrivateMeetingList() {
  return {
    type: FETCH_MYPRIVATEMEETING_LIST
  };
}

function receiveMyPrivateMeetingList(response) {
  return {
    type: RECEIVE_MYPRIVATEMEETING_LIST,
    rawData: response
  };
}
