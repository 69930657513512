import React, { Component } from 'react';
import './style.css';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import axios from '../network/axios/$axios';

class CreateHuman extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.init3d()
  }

  init3d() {
    const subdomain = 'vrchat'; // Replace with your custom subdomain
    const frame = document.getElementById('frame');

    frame.src = `https://${subdomain}.readyplayer.me/avatar?frameApi`;

    window.addEventListener('message', subscribe);
    document.addEventListener('message', subscribe);

    let _self = this;
    function subscribe(event) {
      const json = parse(event);

      if (json?.source !== 'readyplayerme') {
        return;
      }
      console.log(json);

      // Susbribe to all events sent from Ready Player Me once frame is ready
      if (json.eventName === 'v1.frame.ready') {
        frame.contentWindow.postMessage(
          JSON.stringify({
            target: 'readyplayerme',
            type: 'subscribe',
            eventName: 'v1.**'
          }),
          '*'
        );
      }

      // Get avatar GLB URL
      if (json.eventName === 'v1.avatar.exported') {
        console.log(`Avatar URL: ${json.data.url}`);
        console.log(`userInfo: ${_self.props.userInfo}`);
        // 更新用户虚拟头像
        axios('updateUser', { path: _self.props.userInfo._id, data: { '3DUrl': json.data.url } })
          .then((response) => {
            console.log('/updateUser', response);
            _self.props.history.push('/Landing3d');
          })
          .catch((error) => {
            console.log(error);
          });
      }

      // Get user id
      if (json.eventName === 'v1.user.set') {
        console.log(`User with id ${json.data.id} set: ${JSON.stringify(json)}`);
      }
    }

    function parse(event) {
      try {
        return JSON.parse(event.data);
      } catch (error) {
        return null;
      }
    }
  }

  render() {
    return (
      <iframe id="frame" className="frame" allow="camera *; microphone *"></iframe>
    )
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.loginUserInfo.userInfo,
});

const enhance = compose(
  withTranslation('translations'),
  connect(mapStateToProps, null)
);

export default enhance(CreateHuman);
