/*
 * @Description:
 * @Version: 1.0
 * @Autor: ricky shen
 * @Date: 2022-03-20 13:24:35
 * @LastEditors: ricky shen
 * @LastEditTime: 2022-04-25 18:52:30
 */
import React, { Component } from 'react';
import { Row, List, Col, Tabs, Menu, Dropdown } from 'antd';
import { connect } from 'react-redux';
import './JoinDetailSlider.less';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import { SoundOutlined, DownOutlined } from '@ant-design/icons';
import JoinInfoTabPane from './JoinInfoTabPane';
import JoinSessionTabPane from './JoinSessionTabPane';
import InteractionTabPane from './InteractionTabPane';
import { CHANGE_MOVIEINDEX } from '../../../constants/ActionTypes';
import UserAddOutlined from '../../../Image/code/user-search-fill.svg'
import DesktopOutlined from '../../../Image/code/mac-line.svg'
import ProfileOutlined from '../../../Image/code/question-answer-line.svg'
import i18next from 'i18next';
// import { withTranslation } from 'react-i18next';

const { TabPane } = Tabs;

class JoinDetailSlider extends Component {
  constructor(props) {
    super();
  }
  componentDidMount() { }
  componentWillUnmount() {
    console.log('componentWillUnmount');
    this.props.CHANGE_MOVIEINDEX(0);
  }

  clickItem = (item) => {
    // 页面跳转
    this.props.history.push({
      pathname: '/SpeakerDetail/' + item._id,
      state: { params: item }
    });
  };
  onClick = ({ key }) => {
    this.props.CHANGE_MOVIEINDEX(key);
  };
  getMenu = () => {
    const { data, language } = this.props;
    let list = [];
    // if (data && data.type === 'Live' && data.liveLink) {
    if (data && data.liveLink) {
      list = data.liveLink;
    } else if (data && data.videos) {
      list = data.videos;
    }
    return <Menu onClick={this.onClick}>
      {list.map((item, index) => (
        <Menu.Item key={index}>{get(get(item, 'display_title'), language)}</Menu.Item>
      ))}
    </Menu>
  };
  getTab = (title) => {
    // 页面跳转 
    let icon = null;
    if (title === i18next.t('translations.Info')) {
      icon = <img src={UserAddOutlined} />
    } else if (title === i18next.t('translations.Sessions')) {
      icon = <img src={DesktopOutlined} />
    } else if (title === i18next.t('translations.Interaction')) {
      icon = <img src={ProfileOutlined} />
    } else if (title === 'Org') {
      icon = (<Dropdown overlay={this.getMenu()}><div><SoundOutlined /><DownOutlined /></div></Dropdown>)
    }
    return (
      <div className="text-center">
        {icon}
        <div>{title}</div>
      </div>
    );
  };
  render() {
    const { data, language, currentDataAgendaList } = this.props;
    return (
      <div className="JoinDetailSlider">
        <Tabs defaultActiveKey="1" centered={true}>
          <TabPane tab={this.getTab(i18next.t('translations.Info'))} key="1">
            <JoinInfoTabPane data={data} />
          </TabPane>
          <TabPane tab={this.getTab(i18next.t('translations.Sessions'))} key="2">
            <JoinSessionTabPane
              currentDataAgendaList={currentDataAgendaList}
            ></JoinSessionTabPane>
          </TabPane>
          {data?.Slido && <TabPane tab={this.getTab(i18next.t('translations.Interaction'))} key="3">
            <InteractionTabPane data={data} />
          </TabPane>}
          {/* <TabPane disabled tab={this.getTab('Org')}></TabPane> */}
        </Tabs>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  language: state.profile.language
});
const mapDispatchToProps = (dispatch) => ({
  CHANGE_MOVIEINDEX: (item) => {
    dispatch({
      type: CHANGE_MOVIEINDEX,
      data: item
    });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(JoinDetailSlider));
