import { Button, Input, Modal } from 'antd';
import './PasswordModal.less';
import React, { Component } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

class PasswordModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valueMessage: ''
    };
  }
  onChange = (e) => {
    const { value } = e.target;
    this.setState({
      valueMessage: value
    });
  };
  render() {
    const { isModalVisible, handleCancel, handleOk, primary } = this.props;
    const { valueMessage } = this.state;

    return (
      <Modal
        width={'120rem'}
        className=''
        title={<div style={{backgroundColor: primary, padding: '15px 20px'}}>{i18next.t('translations.enter-password')}</div>}
        visible={isModalVisible}
        onCancel={handleCancel}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
        footer={
          <div className="flex flex_rl">
            <Button
              style={{
                background: '#6c757d',
                color: 'white',
                fontWeight: 'bold',
                borderRadius: '.8rem'
              }}
              onClick={() => handleCancel()}
            >
              {i18next.t('translations.close')}
            </Button>
            <Button
              style={{
                background: primary,
                color: 'white',
                fontWeight: 'bold',
                borderRadius: '.8rem'
              }}
              onClick={() => handleOk(valueMessage)}
            >
              {i18next.t('translations.join')}
            </Button>
          </div>
        }
      >
        <div className="fw700">{i18next.t('translations.password')}</div>
        <div>
          <Input.Password
            onChange={this.onChange}
            placeholder="input password"
            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </div>
      </Modal>
    );
  }
}
export default PasswordModal;
