import React, { Component } from 'react';
import { connect } from 'react-redux';
import './SeeAllRecommendationMatching.less';
import ContactHeader from './components/ContactHeader';
import RecommendationList from './components/RecommendationList';
import { message, Row } from 'antd';
import axios from '../../network/axios/$axios';
import { getBriefcasesList } from '../../actions/BriefcaseAction';
import i18next from 'i18next';

class SeeAllRecommendationMatchingScreen extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedRole: '',
      searchType: 'all',
      keyword: ''
    }
  }
  componentDidMount() {}
  // 跳转创建会议日程页面
  goBusinessmatching = () => {
    this.props.history.push(
      '/businessmatching/createmeeting/936c592e-dcab-45bd-b94c-449faae4796b'
    );
  };
  // 添加或者移除书签
  addOrDeleteToBriefcases = (data, arr, value) => {
    if (value === 0) {
      // 删除Briefcase
      axios('deleteBriefcases', { path: arr[0]._id })
        .then((response) => {
          console.log('/deleteBriefcases=', response);
          message.success(i18next.t('translations.bookmark-deleted-success'));
          this.getBriefcaseslist();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      // 新增Briefcase
      axios('addBriefcases', { data: {
        "model": "Networking/Networking",
        "refId": data._id }})
        .then((response) => {
          console.log('/addBriefcases=', response);
          message.success(i18next.t('translations.bookmark-added-success'));
          this.getBriefcaseslist();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  // 重新请求briefcases列表
  getBriefcaseslist = () => {
    const { userInfo, getBriefcasesList } = this.props;
    getBriefcasesList(userInfo.id);
  };
  // 选择用户类型
  selectUserRole = (value) => {
    this.setState({ selectedRole: value })
  };
  // 根据关键词搜索
  searchPeopleByKeyword = (type, value) => {
    this.setState({ searchType: type, keyword: value })
  };

  render() {
    const { selectedRole, searchType, keyword } = this.state;
    const { primary, briefcasesData } = this.props;
    const visitors = briefcasesData.network;
    return (
      <div className="page-container center">
        <ContactHeader onSearch={this.searchPeopleByKeyword.bind(this)} onSelect={this.selectUserRole.bind(this)} primary={primary} />
        <Row className="pT20 pB10">
          <span className="title">Recommendations for you</span>
        </Row>
        <RecommendationList
          onClickMeet={this.goBusinessmatching}
          primary={primary}
          visitors={visitors || []}
          onChange={this.addOrDeleteToBriefcases}
          selectedRole={selectedRole}
          searchType={searchType}
          keyword={keyword}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  primary: state.profile.primary,
  userInfo: state.loginUserInfo.userInfo,
  briefcasesData: state.briefcases.briefcasesData
});

const mapDispatchToProps = (dispatch) => ({
  getBriefcasesList: (userId) => {
    dispatch(getBriefcasesList(userId));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SeeAllRecommendationMatchingScreen);
