import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import get from 'lodash/get';
import { Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './AnnouncementModal.less';

const AnnouncementModal = (props) => {
  const { visible, primary, language, data, handleCancel } = props;
  const [isModalVisible, setIsModalVisible] = useState(visible);
  const [info, setInfo] = useState(data);

  useEffect(() => {
    setIsModalVisible(visible);
    setInfo(data);
  }, [visible, info]);

  return (
    <Modal
      width={800}
      className="announcemen-modal"
      title={<div style={{backgroundColor: primary, padding: '15px 20px'}}>{get(info.display_title, language)}</div>}
      visible={isModalVisible}
      footer={null}
      onCancel={handleCancel}
      closeIcon={<CloseOutlined style={{ color: 'white' }} />}
    >
      <div
        dangerouslySetInnerHTML={{
                      __html: get(info.display_content, language)
                    }}
      />
    </Modal>
  );
};

export default AnnouncementModal;
