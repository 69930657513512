import React, { useEffect, useState } from "react";
import './index.less';
import apiAxios from '../network/axios/$axios';
import Icon from '../Image/icon.png';
import { Button } from "antd";
import i18next from "i18next";
import { connect } from "react-redux";
let marginTop = null;
let marginLeft = null;

const Game = props => {
  const { isModalVisible, language, handleCancel } = props;
  const [resultData, setResultData] = useState({ score: 1 });


  useEffect(() => {
    marginTop = Math.floor(Math.random() * 65 + 5);
    marginLeft = Math.floor(Math.random() * 75 + 10);
  }, []);



  let title = i18next.t('translations.hurray');
  let secondTitle = <span className="game-title">{i18next.t('translations.game-second-1')} <span style={{ color: '#004096' }}>{resultData?.score === 1 && language === 'en-us' ? 'a' : resultData?.score}</span> {resultData?.score === 1 ? i18next.t('translations.game-second-2') : i18next.t('translations.game-second-2p')}</span>;
  let describe = <span className="game-des">{i18next.t('translations.game-describe')}</span>;

  return (
    <div className='game'>
      {isModalVisible && <div className="modal-view" style={{ height: window.innerHeight }}>
        <div className="modal-content-view">
          <img src={Icon} className='icon' />
          <div className="flex-center">
            <span className="game-title">{title}</span>
            {secondTitle}
            <span className="game-des">{describe}</span>
          </div>
          <Button
            className="buttom-btn"
            style={{
              fontWeight: 'bold',
              backgroundColor: '#004096',
              color: '#ffffff',
            }}
            onClick={() => handleCancel()}
          >
            {i18next.t('translations.confirm')}
          </Button>
        </div>
      </div>}
    </div>
  )
}

const mapStateToProps = (state) => ({
  language: state.profile.language
});

export default connect(mapStateToProps, null)(Game);