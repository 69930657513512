import React, { Component } from 'react';
import { Col, List, Pagination, Row, Card } from 'antd';
import './CardList.less';
import productImg from '../../../Image/product/product-img.png';
import awesomeTags from '../../../Image/product/awesome-tags.png';
import get from 'lodash/get';

class CardList extends Component {
  clickItem(item) {
    this.props.history.push({
      pathname: '/product-detail/' + item.id,
      state: { params: item }
    });
  }

  onChange = (page) => {
    this.props.getListData(page);
  };

  render() {
    const { data, total } = this.props;
    return (
      <Row justify="center" className="card">
        <List
          grid={{
            gutter: [16, 16],
            xs: 1,
            sm: 2,
            md: 4
          }}
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <Card
                hoverable
                bordered={false}
                bodyStyle={{ padding: 0 }}
                onClick={() => this.clickItem(item)}
              >
                <div className="card-list">
                  <Row>
                    <div className="card-status-area">
                      <span className="card-status-text">{item.status}</span>
                    </div>
                    <img
                      className="card-product-img"
                      alt=""
                      src={item.media.length ? item.media[0].link : productImg}
                    />
                  </Row>
                  <Row className="card-product-title">
                    <span className="span">{get(item, 'name.en')}</span>
                  </Row>
                  <Row className="card-product-des">
                    <span className="span">{get(item, 'description.en')}</span>
                  </Row>
                  <Row className="card-card-bottom">
                    <Col xs={4}>
                      <img className="tags-img" src={awesomeTags} alt="" />
                    </Col>
                    <Col xs={20}>
                      <span className="tag-text">
                        {get(item, 'short_description.en')}
                      </span>
                    </Col>
                  </Row>
                </div>
              </Card>
            </List.Item>
          )}
        />
        <div className="pagination">
          <Pagination
            showSizeChanger={false}
            className="page-item"
            defaultCurrent={1}
            total={total}
            pageSize={8}
            onChange={this.onChange}
          />
        </div>
      </Row>
    );
  }
}

export default CardList;
