import i18next from 'i18next';
import React from 'react';
import './ResultList.less';
import './ResultType.less';
import SpeakerList from '../../Speaker/components/SpeakerList';
import CategoriesList from '../../ExpoHall/components/CategoriesList';
import { dateToStringTitle, formatTimezone } from '../../../Utils/Util';
import TabSpeakerPaneItemAll from '../../Agenda/components/TabSpeakerPaneItemAll';
import apiAxios from '../../../network/axios/$axios';
import { get } from 'lodash';
import { message } from 'antd';

const ResultList = (props) => {
  const { data, primary, userInfo, getBriefcasesList, briefcasesData, zoneNumber, language, speakers } = props;
  let keys = Object.keys(data);
  keys = keys.filter(v => v !== 'count');

  const onSelectItem = (item) => {
    props.history.push('/ExpoHall/Booth/' + item._id);
  };
  // 重新请求briefcases列表
  const getBriefcaseslist = () => {
    getBriefcasesList(userInfo.id);
  };
  // 删除事件
  const deleteAction = (item) => {
    apiAxios('deleteBriefcases', { path: item._id })
      .then((response) => {
        console.log('/deleteBriefcases=', response);
        message.success(i18next.t('translations.bookmark-deleted-success'));
        getBriefcaseslist();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // Booth
  const booths = briefcasesData.exhibitor_booths || [];
  const event_agenda = briefcasesData.event_agenda || [];
  // Agenda
  const list1 = get(data, 'Agenda', []);
  const list2 = [];
  list1.map(item => {
    const arr2 = list2.filter(v => v.key === dateToStringTitle(formatTimezone(item.startDate, zoneNumber)));
    if (arr2.length > 0) {
      const info = arr2[0];
      const datas = info.data;
      datas.push(item);
    } else {
      list2.push({
        title: dateToStringTitle(formatTimezone(item.startDate, zoneNumber)),
        key: dateToStringTitle(formatTimezone(item.startDate, zoneNumber)),
        data: [item]
      })
    }
  });

  return (
    <div className="result-list-page">
      {keys.map((key) => {
        let title = i18next.t('translations.all-result');
        if (key === 'Agenda') {
          title = i18next.t('translations.agenda')
        } else if (key === 'Booth') {
          title = i18next.t('translations.showcase')
        }
        // if (key === 'Speaker') {
        //   return (
        //     <div className="hp-mb-32">
        //       <div className="asi-big-title hp-mb-16" style={{ color: primary }}>
        //         {title}
        //       </div>
        //       <SpeakerList data={data[key]} />
        //     </div>
        //   )
        // }
        if (key === 'Booth') {
          return (
            <div className="hp-mb-32">
              <div className="asi-big-title hp-mb-16" style={{ color: primary }}>
                {title}
              </div>
              <CategoriesList
                data={data[key]}
                onSelectItem={onSelectItem}
                exhibitorBooths={booths || []}
                userInfo={userInfo}
                fetchBriefcase={getBriefcaseslist}
              />
            </div>
          )
        }
        return (
          <div key={key} className="hp-mb-32">
            <div className="asi-big-title hp-mb-16" style={{ color: primary }}>
              {title}
            </div>
            {list2.length === 0 && <span>{i18next.t('translations.no-result')}</span>}
            {
              list2.map(item => {
                return (
                  <TabSpeakerPaneItemAll
                    page='Briefcase'
                    currentData={item}
                    key={item.key}
                    remove={deleteAction}
                    eventAgendas={event_agenda}
                    userInfo={userInfo}
                    fetchBriefcase={getBriefcaseslist}
                    primary={primary}
                    language={language}
                    speakers={speakers}
                    {...props}
                  />
                )
              })
            }
          </div>
        )
      })}
    </div>
  );
};

export default ResultList;
