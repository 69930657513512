/*
 * @Description:
 * @Version: 1.0
 * @Autor: ricky shen
 * @Date: 2022-03-20 13:24:35
 * @LastEditors: ricky shen
 * @LastEditTime: 2022-03-26 00:27:25
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './JoinSessionTabPane.less';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';
// import { UserAddOutlined } from '@ant-design/icons';
// const { TabPane } = Tabs;
import { getTime } from '../../../Utils/Util';
import { CHANGE_AGENDA_CURRENTINDEX, JOINDETAILDEMANDITEM, CHANGE_MOVIEINDEX } from '../../../constants/ActionTypes';
import { Divider } from 'antd';

class JoinSessionTabPane extends Component {
  constructor(props) {
    super();
  }
  componentDidMount() {}
  clickJoin(item, index) {
    const { agendaCurrentIndex } = this.props;
    // 循环的时候赋值是否被isJoin进入
    if (!item.isJoin || agendaCurrentIndex === index) {
      return;
    }
    if (agendaCurrentIndex === index) {
      return;
    }
    // 更新当前Agenda
    this.props.JOINDETAILDEMANDITEM(item);
    // 更新当前下标
    this.props.CHANGE_AGENDA_CURRENTINDEX(item.index);
    // 当前视频下标0
    this.props.CHANGE_MOVIEINDEX(0);
  }
  render() {
    const { currentDataAgendaList, language, agendaCurrentIndex } = this.props;
    console.log('currentDataAgendaList', agendaCurrentIndex);
    return (
      <div className="JoinSessionTabPane">
        {currentDataAgendaList &&
          currentDataAgendaList.data.map((item, index) => (
            <div style={{...item.style }} className={agendaCurrentIndex === index ? 'secction-item secction-item-current' : 'secction-item'} onClick={this.clickJoin.bind(this, item, index)} key={index}>
              <div className="flex flex_a_c">
                <div className="Catchup">Catchup</div>
                <div className="time">
                  {getTime(item.startDate)} - {getTime(item.endDate)}
                </div>
              </div>
              <div className="title-name">
                {get(get(item, 'display_name'), language)}
              </div>
              <div className="pT20">
                <div
                  dangerouslySetInnerHTML={{
                    __html: get(get(item, 'display_description'), language)
                  }}
                />
              </div>
              <Divider/>
            </div>
          ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.profile.language,
  agendaCurrentIndex: state.Agenda.agendaCurrentIndex
});
const mapDispatchToProps = (dispatch) => ({
  // 保存当前点击的agenda下标
  CHANGE_AGENDA_CURRENTINDEX: (item) => {
    dispatch({
      type: CHANGE_AGENDA_CURRENTINDEX,
      data: item
    });
  },
  // 当前点击的agenda
  JOINDETAILDEMANDITEM: (item) => {
    dispatch({
      type: JOINDETAILDEMANDITEM,
      data: item
    });
  },
  // 视频下标
  CHANGE_MOVIEINDEX: (item) => {
    dispatch({
      type: CHANGE_MOVIEINDEX,
      data: item
    });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(JoinSessionTabPane));
