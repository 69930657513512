import React from "react";
import { Link } from "react-router-dom";

import { Row, Col, Form, Input, Button } from "antd";

import LeftContent from "../leftContent";
import RegisterScreen from "../RegisterScreen";
import i18next from "i18next";

export default function SignUp(props) {
  return (
    <Row gutter={[32, 0]} className="hp-authentication-page bgWhite">
      <LeftContent />

      <Col lg={12} span={24} className="hp-py-sm-0 hp-py-md-64 hp-pb-96">
        <Row className="hp-h-100" align="middle" justify="center">
          <Col
            xxl={15}
            xl={15}
            lg={20}
            md={20}
            sm={24}
            className="hp-px-sm-8 hp-pt-24 hp-pb-48"
          >
            <span className="hp-d-block hp-p1-body hp-text-color-dark-0 hp-text-color-black-100 hp-font-weight-500 hp-mb-6">SIGN UP FOR FREE</span>
            <h1>Create Account</h1>
            <p className="hp-mt-8 hp-text-color-black-60">
              Please sign up to your personal account if you want to use all our premium products.
            </p>

            <RegisterScreen {...props} />

            <div className="hp-form-info hp-text-center">
              <span className="hp-text-color-black-80 hp-text-color-dark-40 hp-caption hp-mr-4">
                Already have an account?
              </span>

              <Link
                to="/"
                className="hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-caption"
              >
                {i18next.t('translations.sign-in')}
              </Link>
            </div>

          </Col>
        </Row>
      </Col>
    </Row>
  );
};