import React from "react";

import { Drawer } from "antd";
import { RiCloseFill } from "react-icons/ri";

import MenuLogo from "../logo";
import MenuItem from "../item";

export default function MenuMobile(props) {
    const { onClose, visible, navigation, selectedModel } = props;
    console.log('navigation', navigation);

    return (
        <Drawer
            title={
                <MenuLogo selectedModel={selectedModel} onClose={onClose} />
            }
            width={320}
            className="hp-mobile-sidebar hp-sidebar"
            placement="left"
            closable={true}
            onClose={onClose}
            visible={visible}
            closeIcon={
                <RiCloseFill
                    className="remix-icon hp-text-color-black-80"
                    size={24}
                />
            }
        >
            <MenuItem navigation={navigation} onClose={onClose} {...props} />
        </Drawer>
    );
};