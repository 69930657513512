// import crypto from 'crypto';
// const key = '12d86bf85f0dd6dcca6efa1a23b769650d7f4b184adc8e7d28594fdb8c0df34f';
// const iv = '5e18ba5e6e7fea261f349f5f8009e624';
// export const encrypt = (text) => {
//     return crypto.AES.encrypt(text, key, { iv }).toString();
// }
// export const decrypt = (text) => {
//     return crypto.AES.decrypt(text, key, { iv }).toString(crypto.enc.Utf8)
// }
import { Buffer } from 'buffer'
import crypto from 'crypto-browserify';
const algorithm = 'aes-256-cbc'; //Using AES encryption
const key = Buffer.from('b228ff721e757eca9c6638a3d467809f461356b1e52fbe43d50357e52a34e4f5', 'hex');
const iv = Buffer.from('ee18a92e0740858bf745c4bcfcc44d74', 'hex');
export const encrypt = (text) => {
    // return text
    let cipher = crypto.createCipheriv(algorithm, Buffer.from(key), Buffer.from(iv));
    let encrypted = cipher.update(text);
    encrypted = Buffer.concat([encrypted, cipher.final()]);
    return encrypted.toString('hex')
}

// Decrypting text
export const decrypt = (text) => {
    // return text
    try {
        let encryptedText = Buffer.from(text, 'hex');
        let decipher = crypto.createDecipheriv(algorithm, Buffer.from(key), Buffer.from(iv));
        let decrypted = decipher.update(encryptedText);
        decrypted = Buffer.concat([decrypted, decipher.final()]);
        return decrypted.toString();
    }
    catch (e) { return text }
}
