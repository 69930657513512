import React, { useState, useEffect } from 'react';
import { Col, message, Row, Spin } from 'antd';
import get from 'lodash/get';
import AboutUs from './AboutUs';
import Links from './Links';
import ChatModal from './ChatModal';
import SocialMediaModal from './SocialMediaModal';
import BrochuresModal from './BrochuresModal';
import ProductModal from './ProductModal';
import axios from '../network/axios/$axios';
import SpeicalOffers from './SpeicalOffers';
import './NavigationBar.less';
import MeetOurTeam from './MeetOurTeam';
import Message from './Message';

export default function NavigationBar(props) {
  const { data, navigationBar, language, primary, page, handleOk, userInfo } = props;
  const [chatModalVisible, setChatModalVisible] = useState(false);
  const [modalData, setModalData] = useState();
  const [selectItem, setSelectItem] = useState({});
  const [enquiryModalVisible, setEnquiryModalVisible] = useState(false);

  useEffect(() => {
    if (navigationBar.length) {
      setSelectItem(navigationBar[0]);
      if (navigationBar[0].type !== 'Enquiry') {
        selectTab(navigationBar[0]);
      }
    }
  }, [navigationBar]);

  const fetchProductionData = () => {
    if (page === 'HelpDesk') {
      axios('product', { params: { filter: { BoothId: 'infodesk' } } })
        .then((response) => {
          console.log('/navBarItem=', response);
          setModalData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios('product', { params: { filter: { BoothId: data._id } } })
        .then((response) => {
          console.log('/navBarItem=', response);
          setModalData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  // 显示聊天弹窗
  const chatModalShow = () => {
    setChatModalVisible(true);
    clickUpload('chat');
  };
  // 隐藏聊天弹窗
  const chatMadolHiddle = () => {
    setChatModalVisible(false);
  };
  // 获取Media图片
  const getMediaImg = (data) => {

    axios('SocialMediaIcon')
      .then((res) => {
        let icons = {}
        res.data.forEach(em => icons[em._id] = get(em, 'icon.src'))
        return icons
      }).then((icons) => {

        if (Array.isArray(data)) setModalData(data.map(item => { item.iconSrc = icons[item.SocialMediaIconId]; return JSON.parse(JSON.stringify(item)) }))
      })

    // 请求图片根据id
    const getIcon = (item, index, length) => {
      axios('SocialMediaIcon', {
        path: item.SocialMediaIconId
      })
        .then((res) => {
          // console.log('/SocialMediaIcon=', res);
          item.iconSrc = get(res.icon, 'src')
          if (index === length) {
            setModalData(data);
          }
        });
    };
    if (data && data.length) {
      data.forEach((item, index) => {
        getIcon(item, index + 1, data.length);
      })
    }
  };
  // 跳转创建会议日程页面
  const goBusinessmatching = () => {
    props.history.push(
      '/businessmatching/createmeeting/936c592e-dcab-45bd-b94c-449faae4796b'
    );
    clickUpload('meet');
  };
  //统计点击事件
  const clickUpload = (fieldname) => {
    axios('activity', {
      data: {
        event: `[Click] ${page} navBarItem ` + fieldname
      }
    }).then((response) => {
      console.log('/activity', response);
    });
  };
  // 选择navItem
  const selectTab = (item) => {
    setSelectItem(item);
    if (item.type === 'Chat') {
      chatModalShow();
    } else if (item.type === 'Product') {
      fetchProductionData(item)
    } else if (item.type === 'Meet') {
      goBusinessmatching();
    } else if (item.type === 'MeetOurTeam') {
      fetchExhibitortUserData(item)
    } else if (item.type === 'Enquiry') {
      setEnquiryModalVisible(true);
    } else {
      if (item.type === 'SocialMedia') {
        getMediaImg(item.data);
      } else {
        setModalData(item.data);
      }
    }
  };
  // 获取团队成员数据
  const fetchExhibitortUserData = (item) => {
    const list = item.data;
    const arr = [];
    list.map(v => {
      arr.push(`"${v._id}"`)
    })
    axios('geExhibitortUser', { path: `?filter={"_id":{"$in":[${arr}]}}` })
      .then((res) => {
        console.log('/geExhibitortUser=', res);
        setModalData(res.data);
      })
  }
  // 提交消息
  const messageHandleOk = (valueMessage) => {
    if (!valueMessage) {
      message.warning('please fill out this field');
      return;
    }
    setEnquiryModalVisible(false);
    axios('EnquiryMessage', { data: { ExhibitorId: 'exhibitor', BoothId: 'infodesk', message: valueMessage, SenderId: userInfo._id } }).then((res) => {
      console.log('/Design/Host', res);
    });
  };

  return (
    <div className='navigation-bar'>
      <Row className="btn-list">
        {navigationBar.map((item, index) => (
          <Col key={index}>
            <div
              className={
                item.type === selectItem.type
                  ? 'btn-area active pointer space'
                  : 'btn-area pointer space'
              }
              style={{ backgroundColor: item.type === selectItem.type ? '#212121' : '#ffffff', maxWidth: '160px' }}
              onClick={() => selectTab(item)}
            >
              <span
                style={{
                  color: item.type === selectItem.type ? '#ffffff' : '#212121',
                }}
              >
                {get(item, `display_title.${language}`)}
              </span>
            </div>
          </Col>
        ))}
      </Row>
      {selectItem.type !== 'Enquiry' &&
        <div className='page-container center'>
          {/* <div className='hp-mb-10'>
            <span className='f16 bold'>{get(selectItem, `display_title.${language}`)}</span>
          </div> */}
          {selectItem.type === 'AboutUs' &&
            <AboutUs
              data={modalData}
              primary={primary}
              language={language}
              logoUrl={get(data, 'hallLogo.src')}
              companyName={get(data, 'name')}
            />
          }
          {selectItem.type === 'Links' &&
            <Links
              data={modalData}
              primary={primary}
              language={language}
            />
          }
          {selectItem.type === 'Product' &&
            <ProductModal
              data={modalData}
              primary={primary}
              language={language}
            />
          }
          {selectItem.type === 'SocialMedia' &&
            <SocialMediaModal
              data={modalData}
              primary={primary}
              language={language}
            />
          }
          {selectItem.type === 'Brochures' &&
            <BrochuresModal
              data={modalData}
              primary={primary}
              language={language}
            />
          }
          {(selectItem.type === 'SpeicalOffers' || selectItem.type === 'FounderStage') &&
            <SpeicalOffers
              data={modalData}
              primary={primary}
              language={language}
            />
          }
          {selectItem.type === 'MeetOurTeam' &&
            <MeetOurTeam
              data={modalData}
              primary={primary}
              language={language}
            />
          }
        </div>
      }
      <ChatModal
        isModalVisible={chatModalVisible}
        handleCancel={chatMadolHiddle}
        data={modalData}
        primary={primary}
        language={language}
        handleOk={handleOk}
      />
      <Message
        isModalVisible={enquiryModalVisible}
        handleCancel={() => setEnquiryModalVisible(false)}
        handleOk={messageHandleOk}
        primary={primary}
      />
    </div>
  );
}
