import React, { Component } from 'react';
import indexCss from './TopHeader.module.css';
import './TopHeader.less';
import i18next from 'i18next';
import { Link } from 'react-router-dom'
import { Button } from 'antd';
import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';

class TopHeader extends Component {
  constructor(props) {
    super();
  }
  componentDidMount() {

  }
  render() {
    const { titleA, primary, selectIndex, onChange, count } = this.props;
    return (
      <div className={indexCss.agenda + ' flex' +  '  flex_rl'}>
        <Link to="/Agenda">
          <Button onClick={() => onChange(0)} icon={<CalendarOutlined />} shape="round" size="large" style={{ backgroundColor: selectIndex === 0 ? primary : '#fff', borderColor: primary, color: selectIndex === 1 ? primary : '#fff' }}>{titleA ? titleA : i18next.t('translations.menu-agenda')}</Button>
        </Link>
        <Link to="/Agenda/MySessions">
          <Button onClick={() => onChange(1)} icon={<ClockCircleOutlined />} shape="round" size="large" style={{ backgroundColor: selectIndex === 1 ? primary : '#fff', borderColor: selectIndex === 0 ? primary : '#fff', color: selectIndex === 0 ? primary : '#fff' }}>
            {i18next.t('translations.my-session')}
            <div className={indexCss.tag + ' flex flex_j_c flex_a_c' + ' ' + indexCss.fontMbs} style={{ backgroundColor: primary }}>{count}</div>
          </Button>
        </Link>
      </div>
    );
  }
}

export default TopHeader;
