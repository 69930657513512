import React from "react";

import { Row, Col } from "antd";

import bg from '../../Image/banner_signin.png';
import logoImg from '../../Image/menu_logo.png';

export default function LeftContent() {
  return (
    <Col lg={12} span={24} className="hp-p-0 hp-bg-color-black-20 hp-bg-color-dark-90 hp-position-relative">
      <Row className="hp-image-row hp-h-100 hp-pb-md-32">
        <Col className="hp-logo-item hp-m-sm-16 hp-m-md-32 hp-m-64">
          <img style={{ width: '15rem' }} src={logoImg} alt="logo" />
        </Col>

        <Col span={24}>
          <Row align="middle" justify="center" className="hp-h-100">
            <Col span={24} className="hp-bg-item hp-text-center hp-mb-md-32">
              <img src={bg} alt="Background Image" className="hp-w-100" />
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};