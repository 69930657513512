import { SAVE_SELECTED_TAB_KEY, FETCH_MYPRIVATEMEETING_LIST, RECEIVE_MYPRIVATEMEETING_LIST } from '../constants/ActionTypes';

const initialState = {
  selectedKey: '1',
  fetching: false,
  fetched: false,
  myPrivateMeetingData: {}
};

const businessMatching = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_SELECTED_TAB_KEY:
      return Object.assign({}, state, {
        selectedKey: action.rawData
      });
    case FETCH_MYPRIVATEMEETING_LIST:
      return Object.assign({}, state, {
        fetching: true
      });
    case RECEIVE_MYPRIVATEMEETING_LIST:
      return Object.assign({}, state, {
        fetching: false,
        fetched: true,
        myPrivateMeetingData: action.rawData
      });
    default:
      return state;
  }
};

export default businessMatching;
